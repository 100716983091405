/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "SendEmailAPI",
            "endpoint": "https://buykqjzjjh.execute-api.us-east-2.amazonaws.com/compassdev",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://22og6o2lhvflvh5daolbjcdtau.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-ojf6kmhjqba65kg42a5ymgcubm",
    "aws_cognito_identity_pool_id": "us-east-2:0bc4ca26-3c6d-4a77-a68b-573d8ecafa13",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_LzGS8qje7",
    "aws_user_pools_web_client_id": "3uvthjp4gr0gh5pvb8atvgj1td",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
