import React from 'react';

const Section28 = ({ formData, handleInputChange }) => {
  const handleOtherRescheduleChange = (e) => {
    handleInputChange({
      target:{
        name:'otherRescheduleChange',
        value: e.target.value.toLowerCase(),
      },
    });
  }
  return(
  <>
    {/* Section 28 */}
    <div className="form-section flyer-section">
      <div className="flyer-header">Reason for Reschedule or Callback</div>
      <label className="required-asterisk">
        <a href='https://www.picktime.com/login#'>https://www.picktime.com/login#</a>
      </label>
      <div className="checkbox-group">
        <label>
          <input
            type="checkbox"
            name="reschedule_reason"
            value="returnDocs"
            checked={formData.reschedule_reason.includes('returnDocs')}
            onChange={handleInputChange}
            required={formData.reschedule_reason.length === 0}
          />
          Needs to return with documentation to complete application
        </label>
        <label>
          <input
            type="checkbox"
            name="reschedule_reason"
            value="notReady"
            checked={formData.reschedule_reason.includes('notReady')}
            onChange={handleInputChange}
            required={formData.reschedule_reason.length === 0}
          />
          Not ready to choose health plan
        </label>
        <label>
          <input
            type="checkbox"
            name="reschedule_reason"
            value="identityNotVerified"
            checked={formData.reschedule_reason.includes('identityNotVerified')}
            onChange={handleInputChange}
            required={formData.reschedule_reason.length === 0}
          />
          Identity not verified
        </label>
        <label>
          <input
            type="checkbox"
            name="reschedule_reason"
            value="alreadyInsured"
            checked={formData.reschedule_reason.includes('alreadyInsured')}
            onChange={handleInputChange}
            required={formData.reschedule_reason.length === 0}
          />
          Already insured or offered insurance through Employer
        </label>
        <label>
          <input
            type="checkbox"
            name="reschedule_reason"
            value="hasCoverage"
            checked={formData.reschedule_reason.includes('hasCoverage')}
            onChange={handleInputChange}
            required={formData.reschedule_reason.length === 0}
          />
          Has coverage through Medicaid – CHIP – Medicare – Tricare – VA Benefits – etc.
        </label>
        <label>
          <input
            type="checkbox"
            name="reschedule_reason"
            value="noSubsidies"
            checked={formData.reschedule_reason.includes('noSubsidies')}
            onChange={handleInputChange}
            required={formData.reschedule_reason.length === 0}
          />
          Did not qualify for subsidies
        </label>
        <label>
          <input
            type="checkbox"
            name="reschedule_reason"
            value="cannotAfford"
            checked={formData.reschedule_reason.includes('cannotAfford')}
            onChange={handleInputChange}
            required={formData.reschedule_reason.length === 0}
          />
          Cannot afford plans – too expensive
        </label>
        <label>
          <input
            type="checkbox"
            name="reschedule_reason"
            value="legalStatus"
            checked={formData.reschedule_reason.includes('legalStatus')}
            onChange={handleInputChange}
            required={formData.reschedule_reason.length === 0}
          />
          Legal Status
        </label>
        <label>
          <input
            type="checkbox"
            name="reschedule_reason"
            value="belowFPL"
            checked={formData.reschedule_reason.includes('belowFPL')}
            onChange={handleInputChange}
            required={formData.reschedule_reason.length === 0}
          />
          Below 100% FPL (Medicaid gap)
        </label>
        <label>
          <input
            type="checkbox"
            name="reschedule_reason"
            value="hardshipExemption"
            checked={formData.reschedule_reason.includes('hardshipExemption')}
            onChange={handleInputChange}
            required={formData.reschedule_reason.length === 0}
          />
          Needs to apply for hardship exemption (schedule follow)
        </label>
        <label>
          <input
            type="checkbox"
            name="reschedule_reason"
            value="enroll"
            checked={formData.reschedule_reason.includes('enroll')}
            onChange={handleInputChange}
            required={formData.reschedule_reason.length === 0}
          />
          Enroll
        </label>
        <label>
          <input
            type="checkbox"
            name="reschedule_reason"
            value="reEnroll"
            checked={formData.reschedule_reason.includes('reEnroll')}
            onChange={handleInputChange}
            required={formData.reschedule_reason.length === 0}
          />
          ReEnroll
        </label>
        <label>
          <input
            type="checkbox"
            name="reschedule_reason"
            value="texasBenefits"
            checked={formData.reschedule_reason.includes('texasBenefits')}
            onChange={handleInputChange}
            required={formData.reschedule_reason.length === 0}
          />
          Your Texas Benefits
        </label>
        <label>
          <input
            type="checkbox"
            name="reschedule_reason"
            value="cancel"
            checked={formData.reschedule_reason.includes('cancel')}
            onChange={handleInputChange}
            required={formData.reschedule_reason.length === 0}
          />
          Cancel
        </label>
        <label>
          <input
            type="checkbox"
            name="reschedule_reason"
            value="noShow"
            checked={formData.reschedule_reason.includes('noShow')}
            onChange={handleInputChange}
            required={formData.reschedule_reason.length === 0}
          />
          No - show
        </label>
        <label>
          <input
            type="checkbox"
            name="reschedule_reason"
            value="other"
            checked={formData.reschedule_reason.includes('other')}
            onChange={handleInputChange}
            required={formData.reschedule_reason.length === 0}
          />
          Other
        </label>
        {formData.reschedule_reason.includes('other') && (
            <input
              type="text"
              name="otherRescheduleChange"
              value={formData.otherRescheduleChange}
              onChange={handleOtherRescheduleChange}
              placeholder="Your answer"
              required
            />
          )}

      </div>
    </div>
  </>
  );
};

export default Section28;
