import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { listPowerBanks, listUsers } from '../../../graphql/queries';
import { createPowerBank, deletePowerBank, updatePowerBank } from '../../../graphql/mutations';
import './PowerBank.css';
import { v4 as uuidv4 } from 'uuid';
import XLSX from 'xlsx-js-style';
import Inventory_header from '../../../components/layout/Inventory_header';
import { FaFileExcel } from 'react-icons/fa';


const PowerBank = () => {
  const [powerBank, setpowerBank] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [showpowerBankAddOverlay, setShowpowerBankAddOverlay] = useState(false);
  const [selectedpowerBank, setSelectedpowerBank] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const [newpowerBank, setNewpowerBank] = useState({
    id: '',
    tagid: '',
    model: '',
    brand: '',
    serial_Number: '',
    price: '',
    store: '',
    purchase_date: '',
    assignment_date: '',
    warranty_date: '',
    returnable_date: '',
    available: '',
    status: '',
    assigned_to: '',
    location: '',
    team: '',

  });
  const [sortConfig, setSortConfig] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchField, setSearchField] = useState('id');
  const [duplicateError, setDuplicateError] = useState('');
  const [priceError, setPriceError] = useState('');
  const [statusError, setStatusError] = useState('');
  const { loading1, error: userError, data: userData } = useQuery(listUsers);




  const handleSaveAsExcel = async () => {
    try {

      const powerBankToExport = filteredpowerBanks.length > 0 ? filteredpowerBanks : powerBank;
      if (powerBankToExport.length > 0) {
        // Sort the powerBanks by Timestamp in ascending order (earliest first)
        const sortedpowerBank = [...powerBankToExport].sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp));

        // Prepare the data for Excel export with custom column titles
        const worksheetData = sortedpowerBank.map((powerBank, index) => ({
          '#': index + 1, // Agregar el número de fila
          'TAG:': powerBank.tagid,
          'Brand:': powerBank.brand,
          'Available:': powerBank.available ? 'Yes' : 'No',
          'Serial Number:': powerBank.serial_Number,
          'Status:': powerBank.status,
          'Assigned to:': powerBank.assigned_to,
          'Team:': powerBank.team,

          // Add other fields as needed

        }));


        // Convert JSON to sheet starting from row 2
        const worksheet = XLSX.utils.json_to_sheet([]);
        let endCol = 0;

        XLSX.utils.sheet_add_aoa(worksheet, [['Power Banks']], { origin: 'A1' });

        if (worksheetData.length > 0) {
          // Agrega los datos a partir de la fila A2
          XLSX.utils.sheet_add_json(worksheet, worksheetData, { origin: 'A2', skipHeader: false });

          // Define el rango de referencia (!ref)
          const endRow = worksheetData.length + 1; // +1 para el título en A1
          const endCol = Object.keys(worksheetData[0]).length - 1;
          worksheet['!ref'] = XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: endRow, c: endCol } });
        } else {
          console.error("No hay datos para exportar.");
          return;
        }

        // Merge title cells across the columns
        worksheet['!merges'] = worksheet['!merges'] || [];
        worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 7 } }); // A1 to H1 for the added column

        // Style the title row
        worksheet['A1'].s = {
          font: {
            bold: true,
            sz: 20, // Font size
            color: { rgb: '1F4E79' } // Dark blue text color
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center'
          }
        };

        // Apply styling for the header row (now starting at row 2)
        const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
        for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
          const cellAddress = XLSX.utils.encode_cell({ r: 1, c: col }); // Header row is now row 1
          if (!worksheet[cellAddress]) continue;
          worksheet[cellAddress].s = {
            fill: {
              fgColor: { rgb: '1F4E79' } // Dark blue background
            },
            font: {
              bold: true,
              color: { rgb: 'FFFFFF' } // White text
            },
            alignment: {
              horizontal: 'center'
            },
            border: {
              top: { style: 'thin', color: { rgb: '000000' } },
              left: { style: 'thin', color: { rgb: '000000' } },
              bottom: { style: 'thin', color: { rgb: '000000' } },
              right: { style: 'thin', color: { rgb: '000000' } },
            }
          };
        }

        // Apply alternating row colors and borders starting from row 3
        for (let row = 2; row <= headerRange.e.r; row++) {
          const isEvenRow = row % 2 === 0;
          for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
            if (!worksheet[cellAddress]) continue;

            worksheet[cellAddress].s = {
              fill: {
                fgColor: { rgb: isEvenRow ? 'D3D3D3' : 'FFFFFF' } // Light gray for even rows, white for odd rows
              },
              border: {
                top: { style: 'thin', color: { rgb: '000000' } },
                left: { style: 'thin', color: { rgb: '000000' } },
                bottom: { style: 'thin', color: { rgb: '000000' } },
                right: { style: 'thin', color: { rgb: '000000' } },
              },
              alignment: {
                horizontal: 'center'
              }
            };
          }
        }
        // Adjust columns to fit content
        worksheet['!cols'] = [
          { wch: 5 },
          { wch: 10 },
          { wch: 40 }, // Adjust width for each column
          { wch: 10 },
          { wch: 25 },
          { wch: 10 },
          { wch: 20 },
          { wch: 15 },
          { wch: 5 }
        ];
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'powerBanks');

        // Write file
        XLSX.writeFile(workbook, 'powerBanksData.xlsx');
      } else {
        console.error('No data to export');
      }
    } catch (error) {
      console.error('Error exporting data to Excel:', error);
    }
  };

  const handleEditpowerBank = (powerBank) => {
    setSelectedpowerBank(powerBank);
    setIsEditing(true);
    setShowpowerBankAddOverlay(true);
  };

  const { loading, data, fetchMore } = useQuery(listPowerBanks, {
    variables: { limit: 100 },
  });

  const [addpowerBank] = useMutation(createPowerBank, {
    refetchQueries: [{ query: listPowerBanks, variables: { limit: 100 } }]
  });

  const [removepowerBank] = useMutation(deletePowerBank, {
    refetchQueries: [{ query: listPowerBanks, variables: { limit: 100 } }]
  });
  const [updpowerBank] = useMutation(updatePowerBank, {
    refetchQueries: [{ query: listPowerBanks, variables: { limit: 100 } }]
  });

  const [fetchAllpowerBanks, { loading: countLoading, data: AllpowerBanksData }] = useLazyQuery(listPowerBanks, {
    variables: { limit: 100 },
    fetchPolicy: "network-only",
  });


  useEffect(() => {
    const fetchAllpowerBanks = async () => {
      let AllpowerBanks = [];
      let currentNextToken = null;

      do {
        const { data } = await fetchMore({
          variables: { limit: 100, nextToken: currentNextToken },
        });

        AllpowerBanks = [...AllpowerBanks, ...data.listPowerBanks.items];
        currentNextToken = data.listPowerBanks.nextToken;
      } while (currentNextToken);

      setpowerBank(AllpowerBanks);
      setNextToken(currentNextToken);
    };

    if (data) {
      fetchAllpowerBanks();
    }
  }, [data, fetchMore]);

  const clearNewpowerBank = () => {
    setNewpowerBank({
      id: '',
      tagid: '',
      model: '',
      brand: '',
      serial_Number: '',
      price: '',
      store: '',
      purchase_date: '',
      assignment_date: '',
      warranty_date: '',
      returnable_date: '',
      available: '',
      status: '',
      assigned_to: '',
      location: '',
      team: '',
    });
  };

  // Define loadMore function
  const loadMore = () => {
    fetchMore({
      variables: { limit: 100, nextToken },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prevResult;
        setNextToken(fetchMoreResult.listPowerBanks.nextToken);
        return {
          listPowerBanks: {
            ...fetchMoreResult.listPowerBanks,
            items: [...prevResult.listPowerBanks.items, ...fetchMoreResult.listPowerBanks.items]
          }
        };
      }
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    // Convert to lowercase and trim whitespace for specific fields
    if (['serial_Number'].includes(name)) {
      formattedValue = value.toLowerCase().trim();
    }

    if (isEditing) {
      setSelectedpowerBank({
        ...selectedpowerBank,
        [name]: formattedValue
      });
    } else {
      setNewpowerBank({
        ...newpowerBank,
        id: uuidv4(),
        [name]: formattedValue
      });
    }
  };

  const handleAddpowerBank = () => {

    // Formatear la fecha en el formato YYYY-MM-DD si existe, o establecerla en null si está vacía
    const formattedPurchase = newpowerBank.purchase_date
      ? new Date(newpowerBank.purchase_date).toISOString().split('T')[0]
      : null;
    const formattedAssignment = newpowerBank.assignment_date
      ? new Date(newpowerBank.assignment_date).toISOString().split('T')[0]
      : null;

    const formattedWarranty = newpowerBank.warranty_date
      ? new Date(newpowerBank.warranty_date).toISOString().split('T')[0]
      : null;

    const formattedReturnable = newpowerBank.returnable_date
      ? new Date(newpowerBank.returnable_date).toISOString().split('T')[0]
      : null;


    // Validate that the price contains only digits and is a valid number
    let priceValue = null;
    if (newpowerBank.price !== "" && newpowerBank.price !== null && newpowerBank.price != undefined) {
      priceValue = parseFloat(newpowerBank.price);

      if (isNaN(priceValue)) {
        setPriceError('Price must be a valid number.');
        return;
      }
    }
    setPriceError('');

    // Check for duplicates based on `tagid` and `serial_Number`
    const isDuplicate = powerBank.some(powerBank =>
      powerBank.tagid === newpowerBank.tagid &&
      powerBank.serial_Number === newpowerBank.serial_Number
    );

    if (isDuplicate) {
      setDuplicateError('A powerBank with the same TAG and Serial Number already exists.');
      console.log('Duplicate validation failed');
      return;
    } else {
      setDuplicateError('');
    }

    // Check availability first
    if (newpowerBank.available === 'true') {

      // If available, status must be Inventario
      if (newpowerBank.status !== 'inv') {
        setStatusError('If the Power Bank is available, its status must be "Inventario".');
        console.log('Status validation failed');
        return; // Exit function if validation fails
      }
      // If available and status is Inventario, assigned_to must be empty or "N/A"
      if (newpowerBank.assigned_to !== '' && newpowerBank.assigned_to !== 'n/a') {
        setStatusError('If the Power Bank is available and its status is "Inventario", the "Assigned to" field must be empty or "N/A".');
        console.log('Assigned_to validation failed');
        return; // Exit function if validation fails
      }

    } else if (newpowerBank.available !== "") {
      // If not available, status must be one of the allowed statuses
      const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
      if (!allowedStatuses.includes(newpowerBank.status)) {
        setStatusError('If the Power Bank is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
        console.log('Status validation failed');
        return; // Exit function if validation fails
      }
      // Clear error if validation passes
      setStatusError('');
    }


    // If all validations pass, proceed with adding the powerBank to the database
    addpowerBank({
      variables: { input: { ...newpowerBank, price: priceValue, purchase_date: formattedPurchase, warranty_date: formattedWarranty, returnable_date: formattedReturnable, assignment_date: formattedAssignment } }
    })
      .then(() => {
        window.location.reload();
      }).catch(error => {
        console.error("Error adding powerBank:", error);
      });
  };

  const handleDeletepowerBank = () => {

    removepowerBank({
      variables: { input: { id: selectedpowerBank.id } }
    }).then(() => {
      window.location.reload();
    }).catch(error => {
      console.error("Error deleting powerBank", error);
    });

  };

  const handleUpdatepowerBank = async () => {

    const formattedData_purchase = selectedpowerBank.purchase_date ? new Date(selectedpowerBank.purchase_date).toISOString().split('T')[0] : null;
    const formattedData_warranty = selectedpowerBank.warranty_date ? new Date(selectedpowerBank.warranty_date).toISOString().split('T')[0] : null;
    const formattedData_returnable = selectedpowerBank.returnable_date ? new Date(selectedpowerBank.returnable_date).toISOString().split('T')[0] : null;
    const formattedData_assignment = selectedpowerBank.assignment_date ? new Date(selectedpowerBank.assignment_date).toISOString().split('T')[0] : null;

    //Check Price
    let priceValue = null;
    if (selectedpowerBank.price !== "" && selectedpowerBank.price !== null && selectedpowerBank.price != undefined) {
      priceValue = parseFloat(selectedpowerBank.price);

      if (isNaN(priceValue)) {
        setPriceError('Price must be a valid number.');
        return;
      }
    }
    setPriceError('');

    // Check availability first
    if (selectedpowerBank.available === 'true') {

      // If available, status must be Inventario
      if (selectedpowerBank.status !== 'inv') {
        setStatusError('If the Power Bank is available, its status must be "Inventario".');
        console.log('Status validation failed');
        return; // Exit function if validation fails
      }
      // If available and status is Inventario, assigned_to must be empty or "N/A"
      if (selectedpowerBank.assigned_to !== '' && selectedpowerBank.assigned_to !== 'n/a') {
        setStatusError('If the Power Bank is available and its status is "Inventario", the "Assigned to" field must be empty or "N/A".');
        console.log('Assigned_to validation failed');
        return; // Exit function if validation fails
      }

    } else if (selectedpowerBank.available === 'false') {
      // If not available, status must be one of the allowed statuses
      const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
      if (!allowedStatuses.includes(selectedpowerBank.status)) {
        setStatusError('If the Power Bank is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
        console.log('Status validation failed');
        return; // Exit function if validation fails
      }
      // Clear error if validation passes
      setStatusError('');
    }

    updpowerBank({
      variables: {
        input: {
          id: selectedpowerBank.id,
          tagid: selectedpowerBank.tagid,
          model: selectedpowerBank.model,
          brand: selectedpowerBank.brand,
          serial_Number: selectedpowerBank.serial_Number,
          price: priceValue,
          store: selectedpowerBank.store,
          purchase_date: formattedData_purchase,
          assignment_date: formattedData_assignment,
          warranty_date: formattedData_warranty,
          returnable_date: formattedData_returnable,
          available: selectedpowerBank.available,
          status: selectedpowerBank.status,
          assigned_to: selectedpowerBank.assigned_to,
          location: selectedpowerBank.location,
          team: selectedpowerBank.team,

        }
      }
    }).then(() => {
      setPriceError('');
      setStatusError('');
      setDuplicateError('');
      setShowpowerBankAddOverlay(false);

    }).catch(error => {
      console.error("Error updating powerBank:", error);
    });
  };

  const handleSelectpowerBank = (powerBank) => {

    for (const [key, value] of Object.entries(powerBank)) {
      console.log(`${key}: ${typeof value}`);
    }
    const formatDateForInput = (dateString) => {
      if (!dateString) return "";  // Si la fecha es nula o indefinida, retorna una cadena vacía

      const date = new Date(dateString);
      // Ajustar la fecha para evitar la conversión a UTC
      const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

      return localDate.toISOString().split('T')[0];  // Retorna la fecha en formato YYYY-MM-DD
    };

    setNewpowerBank({
      id: powerBank.id,
      tagid: powerBank.tagid,
      model: powerBank.model,
      brand: powerBank.brand,
      serial_Number: powerBank.serial_Number,
      price: powerBank.price,
      store: powerBank.store,
      purchase_date: formatDateForInput(powerBank.purchase_date),
      assignment_date: formatDateForInput(powerBank.assignment_date),
      warranty_date: formatDateForInput(powerBank.warranty_date),
      returnable_date: formatDateForInput(powerBank.returnable_date),
      available: powerBank.available,
      status: powerBank.status,
      assigned_to: powerBank.assigned_to,
      location: powerBank.location,
      team: powerBank.team,

    });
    setShowpowerBankAddOverlay(true);
    setIsEditing(true);
    setSelectedpowerBank(powerBank);
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedpowerBanks = React.useMemo(() => {
    let sortablepowerBanks = [...powerBank];
    if (sortConfig !== null) {
      sortablepowerBanks.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortablepowerBanks;
  }, [powerBank, sortConfig]);

  //listUsers 
  const users = React.useMemo(() => {
    if (userData && userData.listUsers) {
      return userData.listUsers.items || [];
    }
    return [];
  }, [userData]);
  if (userError) return <p>Error loading users: {userError.message}</p>;
  if (loading1) return <p>Error loading users: {userError.message}</p>;


  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredpowerBanks = sortedpowerBanks.filter(powerBank => {

    if (powerBank[searchField] == null) return false; // O puedes decidir cómo manejarlo

    let fieldValue = powerBank[searchField].toString().toLowerCase();

    // Convertir "true" a "yes" y "false" a "no"e
    if (fieldValue === "true") {
      fieldValue = "yes";
    } else if (fieldValue === "false") {
      fieldValue = "no";
    }

    return fieldValue.includes(searchQuery.toLowerCase());
  });

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

    return localDate.toLocaleDateString(undefined, options);
  };

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const formatPrice = (price) => {
    if (price === null || price === undefined || price === '') {
      return '';
    }
    const parsedPrice = parseFloat(price);
    return isNaN(parsedPrice) ? '' : parsedPrice.toFixed(2);
  };

  return (
    <div className="power_bank-page">
      <header className="inventory-header">
        <h1>Power Bank</h1>
      </header>
      <Inventory_header/>
      <div className="inventory-search">
        <button onClick={handleSaveAsExcel}> <FaFileExcel /> Export </button>

        <input
          type="text"
          placeholder="Search by TAG, Available, Model, Assigned to"
          value={searchQuery}
          onChange={handleSearch}
        />
        <select onChange={(e) => setSearchField(e.target.value)} defaultValue="">
          <option value="" disabled hidden>Select Option</option>
          <option value="tagid">TAG</option>
          <option value="status">Status</option>
          <option value="available">Available</option>
          <option value="model">Model</option>
          <option value="brand">Brand</option>
          <option value="serial_Number">Serial Number</option>
          <option value="store">Store</option>
          <option value="assigned_to">Assigned to</option>
        </select>
      </div>


      <button className="add-power_bank-btn" onClick={() => {
        clearNewpowerBank();
        setSelectedpowerBank(null);
        setIsEditing(false);
        setShowpowerBankAddOverlay(true);
      }}>+ Add New Power Bank</button>

      <div className="power_bank-table-container">
        <table className="power_bank-table">
          <thead>
            <tr>
              <th>#</th> {/* Columna para contar las filas */}

              <th onClick={() => requestSort('tagid')}>
                TAG
                {sortConfig?.key === 'tagid' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('model')}>
                Model
                {sortConfig?.key === 'model' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('brand')}>
                Brand
                {sortConfig?.key === 'brand' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('serial_Number')}>
                Serial Number
                {sortConfig?.key === 'serial_Number' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>

              <th onClick={() => requestSort('price')}>
                Price
                {sortConfig?.key === 'price' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('store')}>
                Store
                {sortConfig?.key === 'store' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('purchase_date')}>
                Purchase Date
                {sortConfig?.key === 'purchase_date' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>

              <th onClick={() => requestSort('assignment_date')}>
                Assignment Date
                {sortConfig?.key === 'assignment_date' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('warranty_date')}>
                Warranty Date
                {sortConfig?.key === 'warranty_date' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('returnable_date')}>
                Returnable Date
                {sortConfig?.key === 'returnable_date' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('available')}>
                Available
                {sortConfig?.key === 'available' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('status')}>
                Status
                {sortConfig?.key === 'status' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('assigned_to')}>
                Assigned To
                {sortConfig?.key === 'assigned_to' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('location')}>
                Location
                {sortConfig?.key === 'location' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('team')}>
                Team
                {sortConfig?.key === 'team' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>

              <th >Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredpowerBanks.map((powerBank, index) => (
              <tr key={powerBank.id} onClick={() => handleEditpowerBank(powerBank)} >
                <td>{index + 1}</td> {/* Número de fila */}
                <td className="powerBank-tagid">{capitalize(powerBank.tagid)}</td>
                <td className="powerBank-model">{capitalize(powerBank.model.toLowerCase())}</td>
                <td className="powerBank-brand">{capitalize(powerBank.brand.toLowerCase())}</td>
                <td className="powerBank-serial_Number">{powerBank.serial_Number.toUpperCase()}</td>
                <td className="powerBank-price">${formatPrice(powerBank.price)}</td>
                <td className="powerBank-store">{powerBank.store}</td>
                <td className="powerBank-purchase_date">{powerBank.purchase_date ? formatDate(powerBank.purchase_date) : 'No Date'}</td>
                <td className="powerBank-assignment_date">{powerBank.assignment_date ? formatDate(powerBank.assignment_date) : 'No Date'}</td>
                <td className="powerBank-warranty_date">{powerBank.warranty_date ? formatDate(powerBank.warranty_date) : 'No Date'}</td>
                <td className="powerBank-returnable_date">{powerBank.returnable_date ? formatDate(powerBank.returnable_date) : 'No Date'}</td>
                <td className="powerBank-available">{powerBank.available ? 'Yes' : 'No'} </td>
                <td className="powerBank-status">
                  {powerBank.status === 'broken' ? 'Broken' :
                    powerBank.status === 'use' ? 'Use' :
                      powerBank.status === 'transfer' ? 'Transfer' :
                        powerBank.status === 'lost' ? 'Lost' :
                          powerBank.status === 'inv' ? 'Inventario' :
                            'Use'} {/* Muestra 'Unknown' si el estado no coincide con ninguno de los anteriores */}
                </td>
                <td className="powerBank-assigned_to">{powerBank.assigned_to}</td>
                <td className="powerBank-location">{powerBank.location}</td>
                <td className="powerBank-team">{powerBank.team}</td>
                <td className='powerBank-table'>
                  <button className="delete-power_bank-btn" onClick={() => handleSelectpowerBank(powerBank)}>Delete/Update</button>

                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {
        nextToken && !loading && (
          <button onClick={loadMore}>Load More</button>
        )
      }


      {loading && <p>Loading...</p>}

      {
        showpowerBankAddOverlay && (

          <div className="power_bank_add_overlay">
            <div className="overlay-content-inventory">
              <div className="header-row">
                <h2 className="header-title">{isEditing ? 'Edit Power Bank' : 'Add New Power Bank'}</h2>
                <button onClick={() => {
                  setShowpowerBankAddOverlay(false);
                  clearNewpowerBank();
                  setSelectedpowerBank(null);
                  setIsEditing(false);
                }}>X</button>
              </div>

              <label>TAG:</label><input name="tagid" placeholder="TAG" value={isEditing ? selectedpowerBank.tagid : newpowerBank.tagid} onChange={handleInputChange} required />
              <label>Model:</label><input name="model" placeholder="Model" value={isEditing ? selectedpowerBank.model : newpowerBank.model} onChange={handleInputChange} required />

              <label>Brand:</label><input name="brand" placeholder="Brand" value={isEditing ? selectedpowerBank.brand : newpowerBank.brand} onChange={handleInputChange} />
              <label>Serial Number:</label><input name="serial_Number" placeholder="Serial Number" value={isEditing ? selectedpowerBank.serial_Number : newpowerBank.serial_Number} onChange={handleInputChange} required />

              <label>Price:</label><input name="price" placeholder="price" value={isEditing ? selectedpowerBank.price : newpowerBank.price} onChange={handleInputChange} />
              <label>Store:</label><input name="store" placeholder="store" value={isEditing ? selectedpowerBank.store : newpowerBank.store} onChange={handleInputChange} />

              <label htmlFor="purchase_date">Purchase Date:</label>
              <input
                type="Date"
                id="purchase_date"
                name="purchase_date"
                value={isEditing ? selectedpowerBank.purchase_date : newpowerBank.purchase_date}
                onChange={handleInputChange}

              />

              <label htmlFor="assignment_date">Assignment Date:</label>
              <input
                type="date"
                id="assignment_date"
                name="assignment_date"
                value={isEditing ? selectedpowerBank.assignment_date : newpowerBank.assignment_date}
                onChange={handleInputChange}

              />
              <label htmlFor="warranty_date">Warranty Date:</label>
              <input
                type="date"
                id="warranty_date"
                name="warranty_date"
                value={isEditing ? selectedpowerBank.warranty_date : newpowerBank.warranty_date}
                onChange={handleInputChange}


              />
              <label htmlFor="returnable_date">Returnable Date:</label>
              <input
                type="date"
                id="returnable_date"
                name="returnable_date"
                value={isEditing ? selectedpowerBank.returnable_date : newpowerBank.returnable_date}
                onChange={handleInputChange}

              />

              <label>Available:</label>

              <select name="available" placeholder="available" value={isEditing ? selectedpowerBank.available : newpowerBank.available} onChange={handleInputChange} >
                <option value="" disabled hidden className="placeholder-option">Select Availability</option>
                <option value="true">Yes</option>
                <option value="false">No</option>

              </select>
              <label>Status:</label>
              <select name="status" placeholder="status" value={isEditing ? selectedpowerBank.status : newpowerBank.status} onChange={handleInputChange} >
                <option value="" disabled hidden>Select Status</option>
                <option value="use">Use</option>
                <option value="broken">Broken</option>
                <option value="transfer">Transfer</option>
                <option value="lost">Lost</option>
                <option value="inv">Inventario</option>

              </select>
              <label>Assigned To:</label>
              <select
                name="assigned_to"
                value={isEditing ? selectedpowerBank.assigned_to : newpowerBank.assigned_to}
                onChange={handleInputChange}
              >
                <option value="">Select an Employee</option>

                {users.length > 0 ? (
                  [...users].sort((a, b) => {
                    const nameA = `${a.first_name} ${a.last_name}`.toLowerCase();
                    const nameB = `${b.first_name} ${b.last_name}`.toLowerCase();
                    return nameA.localeCompare(nameB);
                  }).map(user => (
                    <option key={user.id} value={`${capitalize(user.first_name)} ${capitalize(user.last_name)}`}>
                      {capitalize(user.first_name)} {capitalize(user.last_name)}
                    </option>
                  ))
                ) : (

                  <option value="">No users available</option>
                )}
                <option value="n/a">N/A</option> {/* Opción N/A */}
              </select>


              <label>Location:</label><input name="location" placeholder="location" value={isEditing ? selectedpowerBank.location : newpowerBank.location} onChange={handleInputChange} />
              <label>Team:</label><input name="team" placeholder="team" value={isEditing ? selectedpowerBank.team : newpowerBank.team} onChange={handleInputChange} />

              {duplicateError && <p className="error">{duplicateError}</p>} {/* Display duplicate error message */}
              {priceError && <p className="error">{priceError}</p>} {/* Display price error message */}
              {statusError && <p className="error">{statusError}</p>} {/* Display duplicate error message */}

              <div className="button-group">
                <button className="overlay-add-power_bank" onClick={isEditing ? handleUpdatepowerBank : handleAddpowerBank} >
                  {isEditing ? 'Update Power Bank' : 'Add Power Bank'}
                </button>
                {isEditing && (
                  <button
                    className="overlay-delet-power_bank"
                    onClick={() => {
                      if (window.confirm('Are you sure you want to delete this powerBank?')) {
                        handleDeletepowerBank();
                      }
                    }}
                  >
                    Delete Power Bank
                  </button>
                )}
              </div>
            </div>
          </div>
        )
      }

    </div >


  );










};

export default PowerBank;
