import React from 'react';
import './Dashboard.css'; // Ensure styles are applied

const GoalsProgress = ({
  currentQHP_yr,
  expectedQHP_yr,
  currentMedicaidChip_yr,
  expectedMedicaidChip_yr,
  current_one2one_yr,
  expected_one2one_yr,
  current_health_lit_edu_yr,
  expected_health_lit_edu_yr,
  current_post_enrollment_asst_enroll_iss_complex_cases_yr,
  expected_post_enrollment_asst_enroll_iss_complex_cases_yr,
  current_tax_forms_appeals_yr,
  expected_tax_forms_appeals_yr,

  currentQHP_week,
  expectedQHP_wk,
  currentMedicaidChip_week,
  expectedMedicaidChip_wk,
  current_one2one_week,
  expected_one2one_wk,
  current_health_lit_edu_week,
  expected_health_lit_edu_wk,
  current_post_enrollment_asst_enroll_iss_complex_cases_week,
  expected_post_enrollment_asst_enroll_iss_complex_cases_wk,
  current_tax_forms_appeals_week,
  expected_tax_forms_appeals_wk
}) => {
  // Helper function to calculate color based on percentage
  const getProgressColor = (percentage) => {
    const cappedPercentage = Math.min(percentage, 100); // Cap percentage at 100

    if (cappedPercentage <= 50) {
      // Transition from Red to Lime Green
      const red = Math.round(231 + ((cappedPercentage / 50) * (154 - 231)));
      const green = Math.round(76 + ((cappedPercentage / 50) * (205 - 76)));
      const blue = Math.round(60 + ((cappedPercentage / 50) * (50 - 60)));
      return `rgb(${red}, ${green}, ${blue})`;
    } else {
      // Transition from Lime Green to Dark Green
      const red = Math.round(154 + (((cappedPercentage - 50) / 50) * (34 - 154)));
      const green = Math.round(205 + (((cappedPercentage - 50) / 50) * (139 - 205)));
      const blue = Math.round(50 + (((cappedPercentage - 50) / 50) * (34 - 50)));
      return `rgb(${red}, ${green}, ${blue})`;
    }
  };

  return (
    <div className="comparison-card">
      <h2>Goals and Progress</h2>

      <div className="goals-columns">
        {/* Column A: Weekly Goals */}
        <div className="goals-column">
          <h3>Weekly Goals</h3>

          <h4>QHP's Weekly Goal</h4>
          <div className="progress-graph">
            <div className="progress-line">
              <div
                className="progress-fill"
                style={{
                  width: `${(currentQHP_week / expectedQHP_wk) * 100}%`,
                  backgroundColor: getProgressColor((currentQHP_week / expectedQHP_wk) * 100),
                }}
              >
                <span
                  className="fill-value-goals"
                  style={{
                    position: (currentQHP_week / expectedQHP_wk) > 1 ? 'absolute' : 'static',
                    right: (currentQHP_week / expectedQHP_wk) > 1 ? '0' : 'auto',
                    paddingRight: (currentQHP_week / expectedQHP_wk) > 1 ? '5px' : '5px',
                  }}
                >
                  {currentQHP_week}
                </span>              </div>
            </div>
            <div className="progress-labels">
              <span>0</span>
              <span>{expectedQHP_wk}</span>
            </div>
          </div>

          <h4>Medicaid/CHIP Weekly Goal</h4>
          <div className="progress-graph">
            <div className="progress-line">
              <div
                className="progress-fill"
                style={{
                  width: `${(currentMedicaidChip_week / currentMedicaidChip_week) * 100}%`,
                  backgroundColor: getProgressColor((currentMedicaidChip_week / currentMedicaidChip_week) * 100),
                }}
              >
                <span
                  className="fill-value-goals"
                  style={{
                    position: (currentMedicaidChip_week / expectedMedicaidChip_wk) > 1 ? 'absolute' : 'static',
                    right: (currentMedicaidChip_week / expectedMedicaidChip_wk) > 1 ? '0' : 'auto',
                    paddingRight: (currentMedicaidChip_week / expectedMedicaidChip_wk) > 1 ? '5px' : '5px',
                  }}
                >
                  {currentMedicaidChip_week}
                </span>              </div>
            </div>
            <div className="progress-labels">
              <span>0</span>
              <span>{expectedMedicaidChip_wk}</span>
            </div>
          </div>

          <h4>1:1 Interaction Weekly Goal</h4>
          <div className="progress-graph">
            <div className="progress-line">
              <div
                className="progress-fill"
                style={{
                  width: `${(current_one2one_week / expected_one2one_wk) * 100}%`,
                  backgroundColor: getProgressColor((current_one2one_week / expected_one2one_wk) * 100),
                }}
              >
                <span
                  className="fill-value-goals"
                  style={{
                    position: (current_one2one_week / expected_one2one_wk) > 1 ? 'absolute' : 'static',
                    right: (current_one2one_week / expected_one2one_wk) > 1 ? '0' : 'auto',
                    paddingRight: (current_one2one_week / expected_one2one_wk) > 1 ? '5px' : '5px',
                  }}
                >
                  {current_one2one_week}
                </span>              </div>
            </div>
            <div className="progress-labels">
              <span>0</span>
              <span>{expected_one2one_wk}</span>
            </div>
          </div>

          <h4>Health Literacy and Education Weekly Goal</h4>
          <div className="progress-graph">
            <div className="progress-line">
              <div
                className="progress-fill"
                style={{
                  width: `${(current_health_lit_edu_week / expected_health_lit_edu_wk) * 100}%`,
                  backgroundColor: getProgressColor((current_health_lit_edu_week / expected_health_lit_edu_wk) * 100),
                }}
              >
                <span
                  className="fill-value-goals"
                  style={{
                    position: (current_health_lit_edu_week / expected_health_lit_edu_wk) > 1 ? 'absolute' : 'static',
                    right: (current_health_lit_edu_week / expected_health_lit_edu_wk) > 1 ? '0' : 'auto',
                    paddingRight: (current_health_lit_edu_week / expected_health_lit_edu_wk) > 1 ? '5px' : '0',
                  }}
                >
                  {current_health_lit_edu_week}
                </span>              </div>
            </div>
            <div className="progress-labels">
              <span>0</span>
              <span
                className="fill-value"
              >
                {current_health_lit_edu_week}
              </span>
            </div>
          </div>

          <h4>Post Enrollment Assistance: Enrollment Issues/Complex Cases Weekly Goal</h4>
          <div className="progress-graph">
            <div className="progress-line">
              <div
                className="progress-fill"
                style={{
                  width: `${(current_post_enrollment_asst_enroll_iss_complex_cases_week / expected_post_enrollment_asst_enroll_iss_complex_cases_wk) * 100}%`,
                  backgroundColor: getProgressColor((current_post_enrollment_asst_enroll_iss_complex_cases_week / expected_post_enrollment_asst_enroll_iss_complex_cases_wk) * 100),
                }}
              >
                <span
                  className="fill-value-goals"
                  style={{
                    position: (current_post_enrollment_asst_enroll_iss_complex_cases_week / expected_post_enrollment_asst_enroll_iss_complex_cases_wk) > 1 ? 'absolute' : 'static',
                    right: (current_post_enrollment_asst_enroll_iss_complex_cases_week / expected_post_enrollment_asst_enroll_iss_complex_cases_wk) > 1 ? '0' : 'auto',
                    paddingRight: (current_post_enrollment_asst_enroll_iss_complex_cases_week / expected_post_enrollment_asst_enroll_iss_complex_cases_wk) > 1 ? '5px' : '5px',
                  }}
                >
                  {current_post_enrollment_asst_enroll_iss_complex_cases_week}
                </span>              </div>
            </div>
            <div className="progress-labels">
              <span>0</span>
              <span>{expected_post_enrollment_asst_enroll_iss_complex_cases_wk}</span>
            </div>
          </div>

          <h4>Post Enrollment: Tax Forms/Appeals Weekly Goal</h4>
          <div className="progress-graph">
            <div className="progress-line">
              <div
                className="progress-fill"
                style={{
                  width: `${(current_tax_forms_appeals_week / expected_tax_forms_appeals_wk) * 100}%`,
                  backgroundColor: getProgressColor((current_tax_forms_appeals_week / expected_tax_forms_appeals_wk) * 100),
                }}
              >
                <span
                  className="fill-value-goals"
                  style={{
                    position: (current_tax_forms_appeals_week / expected_tax_forms_appeals_wk) > 1 ? 'absolute' : 'static',
                    right: (current_tax_forms_appeals_week / expected_tax_forms_appeals_wk) > 1 ? '0' : 'auto',
                    paddingRight: (current_tax_forms_appeals_week / expected_tax_forms_appeals_wk) > 1 ? '5px' : '5px',
                  }}
                >
                  {current_tax_forms_appeals_week}
                </span>              </div>
            </div>
            <div className="progress-labels">
              <span>0</span>
              <span>{expected_tax_forms_appeals_wk}</span>
            </div>
          </div>
        </div>
        {/* Column B: Yearly Goals */}
        <div className="goals-column">
          <h3>Yearly Goals</h3>

          <h4>QHP's Year Goal</h4>
          <div className="progress-graph">
            <div className="progress-line">
              <div
                className="progress-fill"
                style={{
                  width: `${(currentQHP_yr / expectedQHP_yr) * 100}%`,
                  backgroundColor: getProgressColor((currentQHP_yr / expectedQHP_yr) * 100),
                }}
              >
                <span
                  className="fill-value-goals"
                  style={{
                    position: (currentQHP_yr / expectedQHP_yr) > 1 ? 'absolute' : 'static',
                    right: (currentQHP_yr / expectedQHP_yr) > 1 ? '0' : 'auto',
                    paddingRight: (currentQHP_yr / expectedQHP_yr) > 1 ? '5px' : '5px',
                  }}
                >
                  {currentQHP_yr}
                </span>              </div>
            </div>
            <div className="progress-labels">
              <span>0</span>
              <span>{expectedQHP_yr}</span>
            </div>
          </div>

          <h4>Medicaid/CHIP Year Goal</h4>
          <div className="progress-graph">
            <div className="progress-line">
              <div
                className="progress-fill"
                style={{
                  width: `${(currentMedicaidChip_yr / expectedMedicaidChip_yr) * 100}%`,
                  backgroundColor: getProgressColor((currentMedicaidChip_yr / expectedMedicaidChip_yr) * 100),
                }}
              >
                <span
                  className="fill-value-goals"
                  style={{
                    position: (currentMedicaidChip_yr / expectedMedicaidChip_yr) > 1 ? 'absolute' : 'static',
                    right: (currentMedicaidChip_yr / expectedMedicaidChip_yr) > 1 ? '0' : 'auto',
                    paddingRight: (currentMedicaidChip_yr / expectedMedicaidChip_yr) > 1 ? '5px' : '5px',
                  }}
                >
                  {currentMedicaidChip_yr}
                </span>
              </div>
            </div>
            <div className="progress-labels">
              <span>0</span>
              <span>{expectedMedicaidChip_yr}</span>
            </div>
          </div>
          <h4>1:1 Interaction Year Goal</h4>
          <div className="progress-graph">
            <div className="progress-line">
              <div
                className="progress-fill"
                style={{
                  width: `${(current_one2one_yr / expected_one2one_yr) * 100}%`,
                  backgroundColor: getProgressColor((current_one2one_yr / expected_one2one_yr) * 100),
                }}
              >
                <span
                  className="fill-value-goals"
                  style={{
                    position: (current_one2one_yr / expected_one2one_yr) > 1 ? 'absolute' : 'static',
                    right: (current_one2one_yr / expected_one2one_yr) > 1 ? '0' : 'auto',
                    paddingRight: (current_one2one_yr / expected_one2one_yr) > 1 ? '5px' : '5px',
                  }}
                >
                  {current_one2one_yr}
                </span>              </div>
            </div>
            <div className="progress-labels">
              <span>0</span>
              <span>{expected_one2one_yr}</span>
            </div>
          </div>
          <h4>Health Literacy and Education Year Goal</h4>
          <div className="progress-graph">
            <div className="progress-line">
              <div
                className="progress-fill"
                style={{
                  width: `${(current_health_lit_edu_yr / expected_health_lit_edu_yr) * 100}%`,
                  backgroundColor: getProgressColor((current_health_lit_edu_yr / expected_health_lit_edu_yr) * 100),
                }}
              >
                <span
                  className="fill-value-goals"
                  style={{
                    position: (current_health_lit_edu_yr / expected_health_lit_edu_yr) > 1 ? 'absolute' : 'static',
                    right: (current_health_lit_edu_yr / expected_health_lit_edu_yr) > 1 ? '0' : 'auto',
                    paddingRight: (current_health_lit_edu_yr / expected_health_lit_edu_yr) > 1 ? '5px' : '5px',
                  }}
                >
                  {current_health_lit_edu_yr}
                </span>              </div>
            </div>
            <div className="progress-labels">
              <span>0</span>
              <span>{expected_health_lit_edu_yr}</span>
            </div>
          </div>
          <h4>Post Enrollment Assistance: Enrollment Issues/Complex Cases Year Goal</h4>
          <div className="progress-graph">
            <div className="progress-line">
              <div
                className="progress-fill"
                style={{
                  width: `${(current_post_enrollment_asst_enroll_iss_complex_cases_yr / expected_post_enrollment_asst_enroll_iss_complex_cases_yr) * 100}%`,
                  backgroundColor: getProgressColor((current_post_enrollment_asst_enroll_iss_complex_cases_yr / expected_post_enrollment_asst_enroll_iss_complex_cases_yr) * 100),
                }}
              >
                <span
                  className="fill-value-goals"
                  style={{
                    position: (current_post_enrollment_asst_enroll_iss_complex_cases_yr / expected_post_enrollment_asst_enroll_iss_complex_cases_yr) > 1 ? 'absolute' : 'static',
                    right: (current_post_enrollment_asst_enroll_iss_complex_cases_yr / expected_post_enrollment_asst_enroll_iss_complex_cases_yr) > 1 ? '0' : 'auto',
                    paddingRight: (current_post_enrollment_asst_enroll_iss_complex_cases_yr / expected_post_enrollment_asst_enroll_iss_complex_cases_yr) > 1 ? '5px' : '0',
                  }}
                >
                  {current_post_enrollment_asst_enroll_iss_complex_cases_yr}
                </span>
              </div>
            </div>
            <div className="progress-labels">
              <span>0</span>
              <span
                className="fill-value"
              >
                {current_post_enrollment_asst_enroll_iss_complex_cases_yr}
              </span>            </div>
          </div>
          <h4>Post Enrollment: Tax Forms/Appeals Year Goal</h4>
          <div className="progress-graph">
            <div className="progress-line">
              <div
                className="progress-fill"
                style={{
                  width: `${(current_tax_forms_appeals_yr / expected_tax_forms_appeals_yr) * 100}%`,
                  backgroundColor: getProgressColor((current_tax_forms_appeals_yr / expected_tax_forms_appeals_yr) * 100),
                }}
              >
                <span
                  className="fill-value-goals"
                  style={{
                    position: (current_tax_forms_appeals_yr / expected_tax_forms_appeals_yr) > 1 ? 'absolute' : 'static',
                    right: (current_tax_forms_appeals_yr / expected_tax_forms_appeals_yr) > 1 ? '0' : 'auto',
                    paddingRight: (current_tax_forms_appeals_yr / expected_tax_forms_appeals_yr) > 1 ? '5px' : '5px',
                  }}
                >
                  {current_tax_forms_appeals_yr}
                </span>              </div>
            </div>
            <div className="progress-labels">
              <span>0</span>
              <span>{expected_tax_forms_appeals_yr}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoalsProgress;
