import React, { useState } from 'react';

const Section36 = ({ formData, handleInputChange }) => {
  const [showOtherTextInput, setShowOtherTextInput] = useState(formData.Answering_questions_about.includes('other'));

  const handleCheckboxChange = (e) => {
    handleInputChange(e);
    if (e.target.value === 'other') {
      setShowOtherTextInput(e.target.checked);
    }
  };

  return (
    <>
      {/* Section 36 */}
      <div className="form-section flyer-section">
        <div className="flyer-header">7. Answering questions about and/or making referrals to:</div>
        <label className="required-asterisk">Answering questions about and/or making referrals to:</label>
        <label>
          <input
            type="checkbox"
            name="Answering_questions_about"
            value="A. Agent/Broker"
            checked={formData.Answering_questions_about.includes('A. Agent/Broker')}
            onChange={handleCheckboxChange}
            required={formData.Answering_questions_about.length === 0} // Ensure one is selected

          />
          A. Agent/Broker
        </label>
        <label>
          <input
            type="checkbox"
            name="Answering_questions_about"
            value="B. Issuer"
            checked={formData.Answering_questions_about.includes('B. Issuer')}
            onChange={handleCheckboxChange}
            required={formData.Answering_questions_about.length === 0} // Ensure one is selected
          />
          B. Issuer
        </label>
        <label>
          <input
            type="checkbox"
            name="Answering_questions_about"
            value="C. Medicare"
            checked={formData.Answering_questions_about.includes('C. Medicare')}
            onChange={handleCheckboxChange}
            required={formData.Answering_questions_about.length === 0} // Ensure one is selected
          />
          C. Medicare
        </label>
        <label>
          <input
            type="checkbox"
            name="Answering_questions_about"
            value="D. Other consumer assistance/health insurance program"
            checked={formData.Answering_questions_about.includes('D. Other consumer assistance/health insurance program')}
            onChange={handleCheckboxChange}
            required={formData.Answering_questions_about.length === 0} // Ensure one is selected
          />
          D. Other consumer assistance/health insurance program
        </label>
        <label>
          <input
            type="checkbox"
            name="Answering_questions_about"
            value="E. State departments of insurance"
            checked={formData.Answering_questions_about.includes('E. State departments of insurance')}
            onChange={handleCheckboxChange}
            required={formData.Answering_questions_about.length === 0} // Ensure one is selected
          />
          E. State departments of insurance
        </label>
        <label>
          <input
            type="checkbox"
            name="Answering_questions_about"
            value="other"
            checked={formData.Answering_questions_about.includes('other')}
            onChange={handleCheckboxChange}
            required={formData.Answering_questions_about.length === 0} // Ensure one is selected
          />
          Other:
        </label>
        {showOtherTextInput && (
          <input
            type="text"
            name="Answering_questions_about_other"
            value={formData.Answering_questions_about_other || ''}
            onChange={handleInputChange}
            placeholder="Please specify"
          />
        )}
      </div>
    </>
  );
};

export default Section36;
