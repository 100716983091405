import React from 'react';

const Section32 = ({ formData, handleInputChange }) => (
  <>
    {/* Section 32 */}
    <div className="form-section flyer-section">
      <div className="flyer-header">Marketplace Eligibility</div>
      <label className="required-asterisk">B. Marketplace Eligibility Assessment and Results Review</label>
      <input
        type="number"
        name="MarketplaceEligibility"
        value={formData.MarketplaceEligibility}
        onChange={handleInputChange}
        placeholder="Your answer"
        min="0"
        required
      />
    </div>
    <div className='form-section'>
      <label className="required-asterisk">C. Marketplace Plan Compare</label>
      <input
        type='number'
        name='MarketPlanCompare'
        value={formData.MarketPlanCompare}
        onChange={handleInputChange}
        placeholder="Your answer"
        min="0"
        required
      />
    </div>
  </>
);

export default Section32;
