import React from 'react';

const Section27 = ({ formData, handleInputChange }) => (
  <>
    {/* Section 27 */}
    <div className="form-section flyer-section">
          <div className="flyer-header">Other Selections</div>
          <label className="required-asterisk">Did Client Complete Medicaid/Medicare/CHIP</label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name={"ClientCompleteMMC"}
                value="Yes"
                checked={formData.ClientCompleteMMC === 'Yes'}
                onChange={handleInputChange}
                required
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="ClientCompleteMMC"
                value="No"
                checked={formData.ClientCompleteMMC === 'No'}
                onChange={handleInputChange}
                required
              />
              No
            </label>
            <label>
              <input
                type="radio"
                name="ClientCompleteMMC"
                value="Referral Completed"
                checked={formData.ClientCompleteMMC === 'Referral Completed'}
                onChange={handleInputChange}
                required
              />
              Referral Completed
            </label>
          </div>
        </div>

        <div className='form-section'>
          <label>Was this an Appeal?</label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name={"AppealQuestion"}
                value="Yes"
                checked={formData.AppealQuestion === 'Yes'}
                onChange={handleInputChange}
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="AppealQuestion"
                value="No"
                checked={formData.AppealQuestion === 'No'}
                onChange={handleInputChange}
              />
              No
            </label>
            <label>
              <input
                type="radio"
                name="AppealQuestion"
                value="Maybe"
                checked={formData.AppealQuestion === 'Maybe'}
                onChange={handleInputChange}
              />
              Maybe
            </label>
          </div>
        </div>
        <div className='form-section'>
          <label>Does Client Need Call Back or Reschedule?</label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name={"RescheduleQuestion"}
                value="Yes"
                checked={formData.RescheduleQuestion === 'Yes'}
                onChange={handleInputChange}
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="RescheduleQuestion"
                value="No"
                checked={formData.RescheduleQuestion === 'No'}
                onChange={handleInputChange}
              />
              No
            </label>
          </div>
        </div>
  </>
);

export default Section27;
