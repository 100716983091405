import React from 'react';

const Section4 = ({ formData, handleInputChange }) => (
  <>
    {/* Section 4 */}
    <div className="form-section flyer-section">
      <div className="flyer-header">Commit Card</div>
      <label className="required-asterisk">Card Number:</label>
      <input
        type="text"
        name="commitcardNo"
        value={formData.commitcardNo}
        onChange={handleInputChange}
        placeholder="Your answer"
        required
      />
    </div>
    <div className='form-section'>
      <label>Signature:</label>
      <div className="radio-group">
        <label>
          <input
            type="radio"
            name="signature"
            value="yes"
            checked={formData.signature === 'yes'}
            onChange={handleInputChange}
          />
          Yes
        </label>
        <label>
          <input
            type="radio"
            name="signature"
            value="no"
            checked={formData.signature === 'no'}
            onChange={handleInputChange}
          />
          No
        </label>
      </div>
    </div>
    <div className='form-section'>
      <label className="required-asterisk">Best time to call:</label>
      <input
        type='time'
        name='bestTimeToCall'
        value={formData.bestTimeToCall}
        onChange={handleInputChange}
        placeholder="Your answer"
        required
      />
    </div>
    <div className="form-section">
      <label>Notes:</label>
      <textarea
        className="long-text"
        name="CommitCardNotes"
        value={formData.CommitCardNotes}
        onChange={handleInputChange}
        placeholder="Your answer"
      />
    </div>
  </>
);

export default Section4;
