
import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { listGeneralInventories, listUsers, listPowerBanks, listLaptops, listPrinters, listClickCounters, listTabletRemarkables, listSpeakers, listExternalDisks, listScanners, listDockingStations, listPhones, listHeadsets, listMice, listKeyboards, listMonitors, listExtensionCords, listBackupBatteries, listBags, listSupplies, listUSBS } from '../../../graphql/queries';
import { createUser, deleteUser, updateUser } from '../../../graphql/mutations';
import './Employees.css';
import './OverlayInventory.css';
import XLSX from 'xlsx-js-style';
import { FaFileExcel } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import Inventory_header from '../../../components/layout/Inventory_header';
import logo2 from '../../../assets/images/BCH Logo Final.png';
import { useNavigate } from 'react-router-dom';


const Employees = () => {

  const [employees, setEmployees] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [selectedEmployees, setSelectedEmployees] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [overlayType, setOverlayType] = useState(null);
  const [showEmployeesAddOverlay, setShowEmployeesAddOverlay] = useState(false);
  const [showEmployeesShowInventoryOverlay, setShowEmployeesShowInventoryOverlay] = useState(false);
  const [showPasswords, setShowPasswords] = useState(false);


  const [newEmployees, setNewEmployees] = useState({
    id: '',
    ThreeCX_id: '',
    ThreeCX_password: '',
    first_name: '',
    last_name: '',
    thc_email_id: '',
    thc_email_password: '',
    gmail_email_id: '',
    gmail_email_password: '',
    apple_id: '',
    apple_password: '',
    phone_number: '',
    navigator_id: '',
    team: '',
    location: '',
  });

  const [sortConfig, setSortConfig] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchField, setSearchField] = useState('first_name');
  const [duplicateError, setDuplicateError] = useState('');
  const [threeCX_idError, setThreeCX_idError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [thcEmailIdError, setThcEmailIdError] = useState('');
  const [gmailEmailIdError, setGmailEmailIdError] = useState('');
  const [appleIdError, setAppleIdError] = useState('');
  const navigate = useNavigate();
  const [selectedEquipment, setSelectedEquipment] = useState('');


  const { data: dataLaptops } = useQuery(listLaptops);
  const { data: dataPrinters } = useQuery(listPrinters);
  const { data: dataScanners } = useQuery(listScanners);
  const { data: dataPhones } = useQuery(listPhones);
  const { data: dataHeadset } = useQuery(listHeadsets);
  const { data: dataMice } = useQuery(listMice);
  const { data: dataKeyboard } = useQuery(listKeyboards);
  const { data: dataMonitors } = useQuery(listMonitors);
  const { data: dataExtensionCords } = useQuery(listExtensionCords);
  const { data: dataBackupBatteries } = useQuery(listBackupBatteries);
  const { data: dataBags } = useQuery(listBags);
  const { data: dataSupplies } = useQuery(listSupplies);
  const { data: dataUSBs } = useQuery(listUSBS);
  const { data: dataDockingStations } = useQuery(listDockingStations);
  const { data: dataSpeakers } = useQuery(listSpeakers);
  const { data: dataTablets } = useQuery(listTabletRemarkables);
  const { data: dataExternoDisks } = useQuery(listExternalDisks);
  const { data: dataClickCounter } = useQuery(listClickCounters);
  const { data: dataPowerBank } = useQuery(listPowerBanks);
  const { data: dataGeneralInventory } = useQuery(listGeneralInventories);

  const handlePrintOverlay = () => {
    const overlay = document.querySelector('.custom-overlay');
    if (!overlay) {
      console.error("No se encontró el elemento 'custom-overlay' en el DOM.");
      return;
    }

    const fields = ['notes', 'employeeName', 'employeeDate', 'supervisorName', 'supervisorDate'];
    const fieldValues = fields.reduce((acc, field) => {
      acc[field] = document.getElementById(field).value || '';
      return acc;
    }, {});

    // Asignamos los valores a los elementos correspondientes en overlay
    document.getElementById('printNotes').textContent = fieldValues.notes;
    document.getElementById('printEmployeeName').textContent = fieldValues.employeeName;
    document.getElementById('printEmployeeDate').textContent = fieldValues.employeeDate;
    document.getElementById('printSupervisorName').textContent = fieldValues.supervisorName;
    document.getElementById('printSupervisorDate').textContent = fieldValues.supervisorDate;

    // Mostrar los spans y ocultar los inputs temporalmente
    const spansToShow = [
      'printNotes',
      'printEmployeeName',
      'printEmployeeDate',
      'printSupervisorName',
      'printSupervisorDate'
    ];
    const inputsToHide = fields.map(field => document.getElementById(field));

    spansToShow.forEach(id => {
      document.getElementById(id).style.display = 'inline';
    });
    inputsToHide.forEach(input => {
      if (input) input.style.display = 'none';
    });

    // Crear el iframe oculto
    const printIframe = document.createElement('iframe');
    printIframe.style.position = 'absolute';
    printIframe.style.width = '0';
    printIframe.style.height = '0';
    printIframe.style.border = 'none';
    document.body.appendChild(printIframe);

    printIframe.onload = () => {
      printIframe.contentWindow.focus();
      printIframe.contentWindow.print();

      // Restaurar estilos después de imprimir
      spansToShow.forEach(id => {
        document.getElementById(id).style.display = 'none';
      });
      inputsToHide.forEach(input => {
        if (input) input.style.display = 'inline';
      });

      setTimeout(() => {
        printIframe.parentNode.removeChild(printIframe);
      }, 1000);
    };

    // Escribir el contenido en el iframe
    const iframeDoc = printIframe.contentWindow.document;
    iframeDoc.open();
    iframeDoc.write(`
      <html>
        <head>
          <title>Print Overlay</title>
          <style>
            ${document.querySelector('style') ? document.querySelector('style').innerHTML : ''}
            ${[...document.styleSheets]
        .map((sheet) => {
          try {
            return [...sheet.cssRules].map((rule) => rule.cssText).join('');
          } catch (e) {
            return '';
          }
        })
        .join('')}
            @media print {
              body {
                margin: 0;
                padding: 0;
                font-size: 12px;
                height: auto;
              }
              table {
                width: 100%;
                margin: 0 auto;
                table-layout: auto;
                border-collapse: collapse;
              }
              table th,
              table td {
                border: 1px solid #ddd;
                padding: 5px;
                text-align: left;
                word-wrap: break-word;
              }
            }
          </style>
        </head>
        <body>
          ${overlay.outerHTML}
        </body>
      </html>
    `);
    iframeDoc.close();
  };

  const handleSaveAsExcel = async () => {
    try {
      const employeesToExport = filteredEmployees.length > 0 ? filteredEmployees : employees;
      if (employeesToExport.length > 0) {
        // Ordena los empleados por la marca de tiempo
        const sortedEmployees = [...employeesToExport].sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp));

        // Prepara los datos para la exportación a Excel con títulos personalizados de las columnas y columnas vacías
        const worksheetData = sortedEmployees.map((employees, index) => ({
          '#': index + 1, // Agregar el número de fila
          'First Name': employees.first_name,
          'Last Name': employees.last_name,
          '3CX': employees.ThreeCX_id,
          '3CX Password': employees.ThreeCX_password,
          'THC Email': employees.thc_email_id,
          'Gmail': employees.gmail_email_id,
          'Apple': employees.apple_id,
          'Phone Number': employees.phone_number,
          'Navigator ID': employees.navigator_id,
          'Team': employees.team,
          'Location': employees.location,
        }));

        // Convert JSON to sheet starting from row 2
        const worksheet = XLSX.utils.json_to_sheet([]);
        let endCol = 0;

        XLSX.utils.sheet_add_aoa(worksheet, [['Employees']], { origin: 'A1' });

        if (worksheetData.length > 0) {
          // Agrega los datos a partir de la fila A2
          XLSX.utils.sheet_add_json(worksheet, worksheetData, { origin: 'A2', skipHeader: false });

          // Define el rango de referencia (!ref)
          const endRow = worksheetData.length + 1; // +1 para el título en A1
          const endCol = Object.keys(worksheetData[0]).length - 1;
          worksheet['!ref'] = XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: endRow, c: endCol } });
        } else {
          console.error("No hay datos para exportar.");
          return;
        }

        // Merge title cells across the columns
        worksheet['!merges'] = worksheet['!merges'] || [];
        worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 11 } }); // A1 to H1 for the added column

        // Style the title row
        worksheet['A1'].s = {
          font: {
            bold: true,
            sz: 20, // Font size
            color: { rgb: '1F4E79' } // Dark blue text color
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center'
          }
        };

        // Apply styling for the header row (now starting at row 2)
        const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
        for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
          const cellAddress = XLSX.utils.encode_cell({ r: 1, c: col }); // Header row is now row 1
          if (!worksheet[cellAddress]) continue;
          worksheet[cellAddress].s = {
            fill: {
              fgColor: { rgb: '1F4E79' } // Dark blue background
            },
            font: {
              bold: true,
              color: { rgb: 'FFFFFF' } // White text
            },
            alignment: {
              horizontal: 'center'
            },
            border: {
              top: { style: 'thin', color: { rgb: '000000' } },
              left: { style: 'thin', color: { rgb: '000000' } },
              bottom: { style: 'thin', color: { rgb: '000000' } },
              right: { style: 'thin', color: { rgb: '000000' } },
            }
          };
        }

        // Apply alternating row colors and borders starting from row 3
        for (let row = 2; row <= headerRange.e.r; row++) {
          const isEvenRow = row % 2 === 0;
          for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
            if (!worksheet[cellAddress]) continue;

            worksheet[cellAddress].s = {
              fill: {
                fgColor: { rgb: isEvenRow ? 'D3D3D3' : 'FFFFFF' } // Light gray for even rows, white for odd rows
              },
              border: {
                top: { style: 'thin', color: { rgb: '000000' } },
                left: { style: 'thin', color: { rgb: '000000' } },
                bottom: { style: 'thin', color: { rgb: '000000' } },
                right: { style: 'thin', color: { rgb: '000000' } },
              },
              alignment: {
                horizontal: 'center'
              }
            };
          }
        }


        // Ajusta el ancho de las columnas (opcional)
        worksheet['!cols'] = [
          { wch: 5 },
          { wch: 15 }, // Ancho de la columna 'First Name'
          { wch: 15 }, // Ancho de la columna 'Last Name'
          { wch: 5 }, // Ancho de la columna '3CX'
          { wch: 15 }, // Ancho de la columna '3CX'
          { wch: 45 }, // Ancho de la columna 'THC Email'
          { wch: 45 }, // Ancho de la columna 'Gmail'
          { wch: 45 }, // Ancho de la columna 'Apple'
          { wch: 15 }, // Ancho de la columna 'Phone Number'
          { wch: 15 },  // Ancho de la columna 'NAV'
          { wch: 5 }, // Ancho de la columna 'Team'
          { wch: 10 }, // Ancho de la columna 'location'
        ];

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Employees');

        // Exporta el archivo
        XLSX.writeFile(workbook, 'EmployeesData.xlsx');
      } else {
        console.error('No data to export');
      }
    } catch (error) {
      console.error('Error exporting data to Excel:', error);
    }
  };

  const handleEditEmployees = (employees) => {
    setSelectedEmployees(employees);
    setIsEditing(true);
    setShowEmployeesAddOverlay(true);
  };

  const { loading, data, fetchMore } = useQuery(listUsers, {
    variables: { limit: 100 },
  });


  const [addEmployees] = useMutation(createUser, {
    refetchQueries: [{ query: listUsers, variables: { limit: 100 } }]
  });

  const [removeEmployees] = useMutation(deleteUser, {
    refetchQueries: [{ query: listUsers, variables: { limit: 100 } }]
  });
  const [updEmployees] = useMutation(updateUser, {
    refetchQueries: [{ query: listUsers, variables: { limit: 100 } }]
  });

  useEffect(() => {
    const fetchAllEmployees = async () => {
      let AllEmployees = [];
      let currentNextToken = null;


      do {
        const { data } = await fetchMore({
          variables: { limit: 100, nextToken: currentNextToken },
        });

        AllEmployees = [...AllEmployees, ...data.listUsers.items];
        currentNextToken = data.listUsers.nextToken;
      } while (currentNextToken);


      // Ordenar los empleados por 'first_name' alfabéticamente
      AllEmployees.sort((a, b) => a.first_name.localeCompare(b.first_name));
      setEmployees(AllEmployees);
      setNextToken(currentNextToken);
    };

    if (data) {
      fetchAllEmployees();
    }
  }, [data, fetchMore]);
  // Muestra un indicador de carga mientras loading sea true


  const clearNewEmployess = () => {
    setNewEmployees({
      id: '',
      ThreeCX_id: '',
      ThreeCX_password: '',
      first_name: '',
      last_name: '',
      thc_email_id: '',
      thc_email_password: '',
      gmail_email_id: '',
      gmail_email_password: '',
      apple_id: '',
      apple_password: '',
      phone_number: '',
      navigator_id: '',
      team: '',
      location: '',
    });
  };

  const loadMore = () => {
    fetchMore({
      variables: { limit: 100, nextToken },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prevResult;
        setNextToken(fetchMoreResult.listUsers.nextToken);
        return {
          listUsers: {
            ...fetchMoreResult.listUsers,
            items: [...prevResult.listUsers.items, ...fetchMoreResult.listUsers.items]
          }
        };
      }
    });
  };


  const handleInputChange = (e) => {

    const { name, value } = e.target;
    let formattedValue = value;

    if (['first_name', 'last_name',].includes(name)) {
      formattedValue = value.toLowerCase();
    }

    if (name === 'phone_number') {
      formattedValue = value.replace(/[^\d]/g, ''); // Remove all non-digit characters
      if (formattedValue.length > 10) {
        setPhoneError('Phone number cannot be longer than 10 digits.');
      } else if (formattedValue.length < 10) {
        setPhoneError('');
      } else {
        setPhoneError('');
      }
    }
    if (isEditing) {
      setSelectedEmployees({
        ...selectedEmployees,
        [name]: formattedValue
      });
    } else {
      setNewEmployees({
        ...newEmployees,
        id: uuidv4(),
        [name]: formattedValue
      });
    }

  };

  // Función para alternar la visibilidad de las contraseñas
  const togglePasswords = () => {
    setShowPasswords(prevState => !prevState);
  };

  const handleAddEmployees = () => {

    // Check for duplicates based on `ThreeCX` and `first name`

    const isDuplicate = employees.some(employee =>
      String(employee.ThreeCX_id) === String(newEmployees.ThreeCX_id) &&
      employee.first_name.trim().toLowerCase() === newEmployees.first_name.trim().toLowerCase()
    );
    if (isDuplicate) {

      setDuplicateError('An employee with the same ThreeCX ID and first name already exists.');
      console.log('Duplicate validation failed');
      return;
    } else {
      setDuplicateError('');
    }


    // Check that phone_number is exactly 10 digits and contains only numbers
    const phoneNumber = newEmployees.phone_number ? parseInt(newEmployees.phone_number.trim(), 10) : null;

    if (phoneNumber !== null && !/^\d{10}$/.test(String(phoneNumber))) {
      console.log(`Número de teléfono inválido: ${phoneNumber}`);
      setPhoneError('Phone number must be exactly 10 digits and contain only numbers.');
      return;
    } else {
      setPhoneError('');
    }

    // Check ThreeCX ID must not be empty and must be an integer
    if (!newEmployees.ThreeCX_id) {
      setThreeCX_idError('The ThreeCX ID cannot be empty.');
      console.log('ThreeCX ID validation failed: Empty value');
      return;
    }

    const isThreeCXIdInteger = Number.isInteger(Number(newEmployees.ThreeCX_id));

    if (!isThreeCXIdInteger) {
      setThreeCX_idError('The ThreeCX ID must be an integer.');
      console.log('ThreeCX ID validation failed: Not an integer');
      return;
    } else {
      setThreeCX_idError('');
    }

    // Check email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const isValidEmail = (email) => emailPattern.test(email);

    const validateEmails = (thc_email_id, gmail_email_id, apple_id) => {
      let isValid = true;

      // Validar THC Email ID solo si no es nulo o vacío
      if (thc_email_id && thc_email_id.trim() !== '') {
        if (!isValidEmail(thc_email_id)) {
          setThcEmailIdError('THC Email ID is not a valid email address.');
          console.log('THC Email ID validation failed');
          isValid = false;
        } else {
          setThcEmailIdError('');
        }
      } else {
        setThcEmailIdError('');  // No mostrar error si está vacío o es nulo
      }

      // Validar Gmail Email ID solo si no es nulo o vacío
      if (gmail_email_id && gmail_email_id.trim() !== '') {
        if (!isValidEmail(gmail_email_id)) {
          setGmailEmailIdError('Gmail Email ID is not a valid email address.');
          console.log('Gmail Email ID validation failed');
          isValid = false;
        } else {
          setGmailEmailIdError('');
        }
      } else {
        setGmailEmailIdError('');  // No mostrar error si está vacío o es nulo
      }

      // Validar Apple ID solo si no es nulo o vacío
      if (apple_id && apple_id.trim() !== '') {
        if (!isValidEmail(apple_id)) {
          setAppleIdError('Apple ID is not a valid email address.');
          console.log('Apple ID validation failed');
          isValid = false;
        } else {
          setAppleIdError('');
        }
      } else {
        setAppleIdError('');  // No mostrar error si está vacío o es nulo
      }

      return isValid;
    };


    // Obtener los datos y validar
    const cleanedGmailEmailId = newEmployees.gmail_email_id?.trim() || null
    const cleanedThcEmailId = newEmployees.thc_email_id?.trim() || null
    const cleanedAppleId = newEmployees.apple_id?.trim() || null

    if (!validateEmails(cleanedThcEmailId, cleanedGmailEmailId, cleanedAppleId)) {
      console.log('One or more email addresses are invalid.');
      return;
    }


    // If all validations pass, proceed with adding the employees to the database
    addEmployees({
      variables: { input: { ...newEmployees, phone_number: phoneNumber, gmail_email_id: cleanedGmailEmailId, thc_email_id: cleanedThcEmailId, apple_id: cleanedAppleId, } }
    })
      .then(() => {
        window.location.reload();
      })

      .catch(error => {
        console.error("Error adding employees:", error);
      });
  };



  const handleDeleteEmployees = () => {
    removeEmployees({
      variables: { input: { id: selectedEmployees.id } }
    }).then(() => {
      setShowEmployeesAddOverlay(false);
      setIsEditing(false);
    }).catch(error => {
      console.error("Error deleting employees", error);
    });
  };


  const handleUpdateEmployees = async () => {

    // Check that phone_number is exactly 10 digits and contains only numbers
    let phoneNumber = selectedEmployees.phone_number ? String(selectedEmployees.phone_number).trim() : null;

    if (phoneNumber === null || phoneNumber === '') {
      // Si está vacío, envía null a GraphQL
      phoneNumber = null;
      setPhoneError('');
    } else if (!/^\d{10}$/.test(phoneNumber)) {
      // Si el número no tiene exactamente 10 dígitos, muestra un error
      console.log(phoneNumber);
      setPhoneError('Phone number must be exactly 10 digits and contain only numbers.');
      return;
    } else {
      // Si es válido, limpia el error
      setPhoneError('');
    }

    // Check ThreeCX ID must not be empty and must be an integer
    if (!selectedEmployees.ThreeCX_id) {
      setThreeCX_idError('The ThreeCX ID cannot be empty.');
      console.log('ThreeCX ID validation failed: Empty value');
      return;
    }

    const isThreeCXIdInteger = Number.isInteger(Number(selectedEmployees.ThreeCX_id));

    if (!isThreeCXIdInteger) {
      setThreeCX_idError('The ThreeCX ID must be an integer.');
      console.log('ThreeCX ID validation failed: Not an integer');
      return;
    } else {
      setThreeCX_idError('');
    }

    // Check email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const isValidEmail = (email) => emailPattern.test(email);

    const validateEmails = (thc_email_id, gmail_email_id, apple_id) => {
      let isValid = true;

      if (!thc_email_id || !isValidEmail(thc_email_id)) {
        setThcEmailIdError('THC Email ID is not a valid email address.');
        console.log('THC Email ID validation failed');
        isValid = false;
      } else {
        setThcEmailIdError('');
      }

      if (!gmail_email_id || !isValidEmail(gmail_email_id)) {
        setGmailEmailIdError('Gmail Email ID is not a valid email address.');
        console.log('Gmail Email ID validation failed');
        isValid = false;
      } else {
        setGmailEmailIdError('');
      }

      if (apple_id && !isValidEmail(apple_id)) {
        setAppleIdError('Apple ID is not a valid email address.');
        console.log('Apple ID validation failed');
        isValid = false;
      } else {
        setAppleIdError('');
      }

      return isValid;
    };

    // Obtener los datos y validar
    const { thc_email_id, gmail_email_id, apple_id } = selectedEmployees;
    const areEmailsValid = validateEmails(thc_email_id, gmail_email_id, apple_id);

    if (areEmailsValid) {
      // Continúa con el proceso de envío
      console.log('All email addresses are valid.');
    } else {
      // Maneja el error
      console.log('One or more email addresses are invalid.');
    }


    updEmployees({
      variables: {
        input: {
          id: selectedEmployees.id || '',
          ThreeCX_id: selectedEmployees.ThreeCX_id || '',
          ThreeCX_password: selectedEmployees.ThreeCX_password || '',
          first_name: selectedEmployees.first_name || '',
          last_name: selectedEmployees.last_name || '',
          thc_email_id: selectedEmployees.thc_email_id || null,
          thc_email_password: selectedEmployees.thc_email_password || '',
          gmail_email_id: selectedEmployees.gmail_email_id || null,
          gmail_email_password: selectedEmployees.gmail_email_password || '',
          apple_id: selectedEmployees.apple_id || null,
          apple_password: selectedEmployees.apple_password || '',
          phone_number: phoneNumber || '',
          navigator_id: selectedEmployees.navigator_id || "",
          team: selectedEmployees.team || '',
          location: selectedEmployees.location || '',

        }
      }
    }
    ).then(() => {
      setIsEditing(true);
      setShowEmployeesAddOverlay(false);
      setThreeCX_idError('');
      setDuplicateError('');
      setThcEmailIdError('');
      setGmailEmailIdError('');
      setAppleIdError('');
      setPhoneError('');

    }).catch(error => {
      console.error("Error updating employees:", error);
    });
  };

  const handleSelectEmployees = (employees) => {
    setSelectedEmployees(employees);
    setOverlayType('edit'); // Set the overlay type to 'edit'
    setIsEditing(true);
    setShowEmployeesAddOverlay(true);
  };

  const handleInventoryEmployees = (employees) => {
    setSelectedEmployees(employees);
    setShowEmployeesAddOverlay(false); // Hide the add overlay if it's visible
    setShowEmployeesShowInventoryOverlay(true); // Show the inventory overlay
    setOverlayType('inventory'); // Set the overlay type to 'inventory'
  };


  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedEmployees = React.useMemo(() => {
    let sortableEmployees = [...employees];
    if (sortConfig !== null) {
      sortableEmployees.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableEmployees;
  }, [employees, sortConfig]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  //users
  const filteredEmployees = sortedEmployees.filter(employees =>
    employees[searchField].toString().toLowerCase().includes(searchQuery.toLowerCase())
  );

  const laptops = dataLaptops?.listLaptops || [];
  const printers = dataPrinters?.listPrinters || [];
  const scanners = dataScanners?.listScanners || [];
  const phones = dataPhones?.listPhones || [];
  const headsets = dataHeadset?.listHeadsets || [];
  const mouses = dataMice?.listMice || [];
  const keyboards = dataKeyboard?.listKeyboards || [];
  const monitors = dataMonitors?.listMonitors || [];
  const extensionCords = dataExtensionCords?.listExtensionCords || [];
  const backupBaterries = dataBackupBatteries?.listBackupBatteries || [];
  const bags = dataBags?.listBags || [];
  const supplies = dataSupplies?.listSupplies || [];
  const usbs = dataUSBs?.listUSBS || [];
  const dockings = dataDockingStations?.listDockingStations || [];
  const speakers = dataSpeakers?.listSpeakers || [];
  const tablets = dataTablets?.listTabletRemarkables || [];
  const externoDisks = dataExternoDisks?.listExternalDisks || [];
  const clickCounter = dataClickCounter?.listClickCounters || [];
  const PowerBank = dataPowerBank?.listPowerBanks || [];
  const generalInventory = dataGeneralInventory?.listGeneralInventories || [];

  // laptop 
  const filteredLaptops = React.useMemo(() => {
    if (!Array.isArray(laptops?.items)) return [];

    // Función para capitalizar la primera letra de una palabra
    const capitalize = (str) => {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    return laptops.items.filter(laptop => {
      // console.log('Printer Assigned To:', laptop.assigned_to); // Imprime el valor de assigned_to para cada impresora

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return laptop.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [laptops?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);

  //printers  
  const filteredPrinters = React.useMemo(() => {
    if (!Array.isArray(printers?.items)) return [];

    // Función para capitalizar la primera letra de una palabra
    const capitalize = (str) => {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    return printers.items.filter(printer => {
      // console.log('Printer Assigned To:', printer.assigned_to); // Imprime el valor de assigned_to para cada impresora

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return printer.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [printers?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);

  //scanners 
  const filteredScanners = React.useMemo(() => {
    if (!Array.isArray(scanners?.items)) return [];

    // Función para capitalizar la primera letra de una palabra
    const capitalize = (str) => {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    return scanners.items.filter(scanner => {
      // console.log('Printer Assigned To:', scanner.assigned_to); // Imprime el valor de assigned_to para cada impresora

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return scanner.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [scanners?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);

  //phone 
  const filteredPhones = React.useMemo(() => {
    if (!Array.isArray(phones?.items)) return [];

    // Función para capitalizar la primera letra de una palabra
    const capitalize = (str) => {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    return phones.items.filter(phone => {

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return phone.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [phones?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);

  //Headset 
  const filteredHeadset = React.useMemo(() => {
    if (!Array.isArray(headsets?.items)) return [];

    // Función para capitalizar la primera letra de una palabra
    const capitalize = (str) => {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    return headsets.items.filter(headset => {

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return headset.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [headsets?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);

  //Mouse
  const filteredMouse = React.useMemo(() => {
    if (!Array.isArray(mouses?.items)) return [];

    // Función para capitalizar la primera letra de una palabra
    const capitalize = (str) => {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    return mouses.items.filter(mouse => {

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return mouse.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [mouses?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);

  //Keyboard
  const filteredKeyboard = React.useMemo(() => {
    if (!Array.isArray(keyboards?.items)) return [];

    // Función para capitalizar la primera letra de una palabra
    const capitalize = (str) => {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    return keyboards.items.filter(keyboard => {

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return keyboard.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [keyboards?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);

  //Monitor Desk
  const filteredMonitorDesk = React.useMemo(() => {
    if (!Array.isArray(monitors?.items)) return [];

    // Función para capitalizar la primera letra de una palabra
    const capitalize = (str) => {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    return monitors.items.filter(monitor => {
      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return monitor.assigned_to === `${employeeFirstName} ${employeeLastName}` && monitor.monitor_type === "desk";

    });
  }, [monitors?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);

  //Monitor Portable
  const filteredMonitorPortable = React.useMemo(() => {
    if (!Array.isArray(monitors?.items)) return [];

    // Función para capitalizar la primera letra de una palabra
    const capitalize = (str) => {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    return monitors.items.filter(monitor => {
      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return monitor.assigned_to === `${employeeFirstName} ${employeeLastName}` &&
        ["portable", "portable_screen", "mobile_pixels_trio_max"].includes(monitor.monitor_type);


    });
  }, [monitors?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);
  //extensionCords
  const filteredExtensionCords = React.useMemo(() => {
    if (!Array.isArray(extensionCords?.items)) return [];

    // Función para capitalizar la primera letra de una palabra
    const capitalize = (str) => {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    return extensionCords.items.filter(extensionCord => {

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return extensionCord.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [extensionCords?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);

  //backupBaterries
  const filteredBackupBaterries = React.useMemo(() => {
    if (!Array.isArray(backupBaterries?.items)) return [];

    // Función para capitalizar la primera letra de una palabra
    const capitalize = (str) => {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    return backupBaterries.items.filter(backupBaterry => {

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return backupBaterry.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [backupBaterries?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);

  //bag
  const filteredBag = React.useMemo(() => {
    if (!Array.isArray(bags?.items)) return [];

    // Función para capitalizar la primera letra de una palabra
    const capitalize = (str) => {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    return bags.items.filter(bag => {

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return bag.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [bags?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);

  //Supplies
  const filteredSupplies = React.useMemo(() => {
    if (!Array.isArray(supplies?.items)) return [];

    // Función para capitalizar la primera letra de una palabra
    const capitalize = (str) => {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    return supplies.items.filter(supplie => {

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return supplie.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [supplies?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);

  //Docking Station
  const filteredDockingsStation = React.useMemo(() => {
    if (!Array.isArray(dockings?.items)) return [];

    // Función para capitalizar la primera letra de una palabra
    const capitalize = (str) => {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    return dockings.items.filter(docking => {

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return docking.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [dockings?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);


  //USB
  const filteredUSB = React.useMemo(() => {
    if (!Array.isArray(usbs?.items)) return [];

    // Función para capitalizar la primera letra de una palabra
    const capitalize = (str) => {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    return usbs.items.filter(usb => {

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return usb.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [usbs?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);


  //Speakers
  const filteredSpeakers = React.useMemo(() => {
    if (!Array.isArray(speakers?.items)) return [];

    // Función para capitalizar la primera letra de una palabra
    const capitalize = (str) => {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    return speakers.items.filter(speaker => {

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return speaker.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [speakers?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);

  //Tablets
  const filteredtablets = React.useMemo(() => {
    if (!Array.isArray(tablets?.items)) return [];

    // Función para capitalizar la primera letra de una palabra
    const capitalize = (str) => {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    return tablets.items.filter(tablet => {

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return tablet.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [tablets?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);


  //Externasl Disk
  const filteredExternalDisk = React.useMemo(() => {
    if (!Array.isArray(externoDisks?.items)) return [];

    // Función para capitalizar la primera letra de una palabra
    const capitalize = (str) => {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    return externoDisks.items.filter(externoDisk => {

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return externoDisk.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [externoDisks?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);

  //Power Bank
  const filteredPowerBank = React.useMemo(() => {
    if (!Array.isArray(PowerBank?.items)) return [];

    // Función para capitalizar la primera letra de una palabra
    const capitalize = (str) => {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    return PowerBank.items.filter(PowerBank => {

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return PowerBank.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [PowerBank?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);

  //Click Counter
  const filteredClickCounter = React.useMemo(() => {
    if (!Array.isArray(clickCounter?.items)) return [];

    // Función para capitalizar la primera letra de una palabra
    const capitalize = (str) => {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    return clickCounter.items.filter(clickCounter => {

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return clickCounter.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [clickCounter?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);

  //other equipment
  const filteredgeneralInventorys = React.useMemo(() => {
    if (!Array.isArray(generalInventory?.items)) return [];

    // Función para capitalizar la primera letra de una palabra
    const capitalize = (str) => {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    return generalInventory.items.filter(generalInventory => {

      const employeeFirstName = capitalize(selectedEmployees?.first_name || '');
      const employeeLastName = capitalize(selectedEmployees?.last_name || '');

      // Filtro por primer nombre y apellido capitalizados
      return generalInventory.assigned_to === `${employeeFirstName} ${employeeLastName}`;
    });
  }, [generalInventory?.items, selectedEmployees?.first_name, selectedEmployees?.last_name]);

  const capitalize = (str) => {
    // console.log('Value passed to capitalize:', str);
    if (typeof str !== 'string') {
      console.error('Expected a string, but got:', typeof str);
      return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleClick = (equipment) => {
    setSelectedEquipment(equipment);
    navigate(`../equipment-${equipment}`);
  };

  return (
    <div className="employees-page">
      <div className="employees-header">
        <h1>Employees</h1>
      </div>
      <Inventory_header/>
      <div className="employees-search">
        <button onClick={handleSaveAsExcel}>
          <FaFileExcel /> Export
        </button>
        {/* Botón para mostrar u ocultar las contraseñas */}
        <button onClick={togglePasswords}>
          {showPasswords ? 'Hide Passwords' : 'Show Passwords'}
        </button>

        <input
          type="text"
          placeholder=" Search by NAV ID and First Name"
          value={searchQuery}
          onChange={handleSearch}
        />
        <select onChange={(e) => setSearchField(e.target.value)}>
          <option value="first_name">First Name</option>
          <option value="navigator_id">Navigator ID</option>
          <option value="ThreeCX_id">3CX ID</option>
        </select>
      </div>

      <button className="add-employees-btn" onClick={() => {
        setSelectedEmployees(null);
        setShowEmployeesAddOverlay(true);
        setShowEmployeesShowInventoryOverlay(false);
        setOverlayType('edit');
        clearNewEmployess();
        setIsEditing(false);
      }}>+ Add New Employee</button>


      <div className="employees-table-container">

        <table className="employees-table">
          <thead>
            <tr>
              <th>#</th> { }

              <th onClick={() => requestSort('ThreeCX_id')}>
                3CX ID
                {sortConfig?.key === 'ThreeCX_id' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('ThreeCX_password')}>
                3CX Password
                {sortConfig?.key === 'ThreeCX_password' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('first_name')}>
                First_name
                {sortConfig?.key === 'first_name' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('last_name')}>
                Last_name
                {sortConfig?.key === 'last_name' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('thc_email_id')}>
                Thc email ID
                {sortConfig?.key === 'thc_email_id' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('thc_email_password')}>
                Thc email Password
                {sortConfig?.key === 'thc_email_password' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('gmail_email_id')}>
                Gmail email Id
                {sortConfig?.key === 'gmail_email_id' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('gmail_email_password')}>
                Gmail email Password
                {sortConfig?.key === 'gmail_email_password' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('apple_id')}>
                Apple ID
                {sortConfig?.key === 'apple_id' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('apple_password')}>
                Apple Password
                {sortConfig?.key === 'apple_password' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('phone_number')}>
                Phone Number
                {sortConfig?.key === 'phone_number' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('navigator_id')}>
                Navigator ID
                {sortConfig?.key === 'navigator_id' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('team')}>
                Team
                {sortConfig?.key === 'team' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('location')}>
                Location
                {sortConfig?.key === 'location' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('createdAt')}>
                Create Date
                {sortConfig?.key === 'createdAt' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('updatedAt')}>
                Update Date
                {sortConfig?.key === 'updatedAt' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th >Actions</th>
              <th >View</th>

            </tr>
          </thead>
          <tbody>
            {filteredEmployees.map((employees, index) => (
              <tr key={employees.id} onClick={() => handleEditEmployees(employees)}>
                <td>{index + 1}</td> {/* Número de fila */}
                <td className="employees-ThreeCX_id">{employees.ThreeCX_id}</td>
                <td className="employees-ThreeCX-password">{showPasswords ? String(employees.ThreeCX_password) : '******'}</td>
                <td className="employees-first_name">{capitalize(employees.first_name.toLowerCase())}</td>
                <td className="employees-last_name">{capitalize(employees.last_name.toLowerCase())}</td>
                <td className="employees-thc_email_id">{employees.thc_email_id ? String(employees.thc_email_id).toLowerCase() : 'N/A'}</td>
                {/* Mostrar contraseña o asteriscos según showPasswords */}
                <td className="employees-thc_email_password">
                  {showPasswords ? String(employees.thc_email_password) : '******'}
                </td>
                <td className="employees-gmail_email_id">{employees.gmail_email_id ? String(employees.gmail_email_id).toLowerCase() : 'N/A'}</td>
                {/* Mostrar la contraseña de Gmail o puntos según showPasswords */}
                <td className="employees-gmail_email_password">
                  {showPasswords ? String(employees.gmail_email_password) : '******'}
                </td>
                <td className="employees-apple_id">{employees.apple_id ? String(employees.apple_id).toLowerCase() : 'N/A'}</td>
                <td className="employees-apple_password">{showPasswords ? String(employees.apple_password) : '******'}</td>
                <td className="employees-phone_number">{employees.phone_number}</td>
                <td className="employees-navigator_id">{String(employees.navigator_id)}</td>
                <td className="employees-team">{employees.team}</td>
                <td className="employees-location">{employees.location}</td>
                <td className="employees-createdAt">{employees.createdAt ? new Date(employees.createdAt).toLocaleString() : 'N/A'} </td>
                <td className="employees-updatedAt">{employees.updatedAt ? new Date(employees.updatedAt).toLocaleString() : 'N/A'}</td>
                <td className='employees-table'>
                  <button className="delete-employees-btn" onClick={() => handleSelectEmployees(employees)}>Delete/Update</button>
                </td>
                <td className='employees-table'>
                  <button className="delete-employees-btn" onClick={() => handleInventoryEmployees(employees)}> Inventory</button>
                </td>
              </tr>

            ))}
          </tbody>
        </table>
      </div>


      {
        nextToken && !loading && (
          <button onClick={loadMore}>Load More</button>
        )
      }

      {loading && <p>Loading...</p>}

      {
        overlayType === 'edit' && showEmployeesAddOverlay && (

          <div className="employees_add_overlay">
            <div className="overlay-content-employees">
              <div className="header-row">
                <h2 className="header-title">{isEditing ? 'Edit Employee' : 'Add New Employee'}</h2>
                <button onClick={() => {
                  setShowEmployeesAddOverlay(false);
                  clearNewEmployess();
                  setSelectedEmployees(null);
                  setIsEditing(false);
                }}>X</button>
              </div>
              <label>3CX ID</label>
              <input name="ThreeCX_id" placeholder="3CX ID" value={isEditing ? selectedEmployees.ThreeCX_id : newEmployees.ThreeCX_id} onChange={handleInputChange} required />

              <label>3CX Password</label>
              <input
                type={showPasswords ? "text" : "password"} // Alternar entre "text" y "password"
                name="ThreeCX_password"
                placeholder="3CX Password"
                value={isEditing ? selectedEmployees.ThreeCX_password : newEmployees.ThreeCX_password}
                onChange={handleInputChange}
                required
              />
              <label>First name </label>
              <input name="first_name" placeholder="First name" value={isEditing ? selectedEmployees.first_name : newEmployees.first_name} onChange={handleInputChange} required />
              <label>Last name</label>
              <input name="last_name" placeholder="Last Name" value={isEditing ? selectedEmployees.last_name : newEmployees.last_name} onChange={handleInputChange} required />
              <label>THC Email ID</label>
              <input name="thc_email_id" placeholder="THC Email ID" value={isEditing ? selectedEmployees.thc_email_id : newEmployees.thc_email_id} onChange={handleInputChange} required />
              <label>THC Email Password</label>
              <input type={showPasswords ? "text" : "password"} name="thc_email_password" placeholder="THC Email Password" value={isEditing ? selectedEmployees.thc_email_password : newEmployees.thc_email_password} onChange={handleInputChange} required />
              <label>Gmail Email ID</label><input name="gmail_email_id" placeholder="Gmail Email ID" value={isEditing ? selectedEmployees.gmail_email_id : newEmployees.gmail_email_id} onChange={handleInputChange} required />
              <label>Gmail Email Password</label>
              <input type={showPasswords ? "text" : "password"} name="gmail_email_password" placeholder="Gmail Email Password" value={isEditing ? selectedEmployees.gmail_email_password : newEmployees.gmail_email_password} onChange={handleInputChange} required />
              <label>Apple ID</label><input name="apple_id" placeholder="Apple ID" value={isEditing ? selectedEmployees.apple_id : newEmployees.apple_id} onChange={handleInputChange} required />
              <label>Apple Password</label><input type={showPasswords ? "text" : "password"} name="apple_password" placeholder="Apple Password" value={isEditing ? selectedEmployees.apple_password : newEmployees.apple_password} onChange={handleInputChange} required />
              <label>Phone Number</label><input name="phone_number" placeholder="phone_number" value={isEditing ? selectedEmployees.phone_number : newEmployees.phone_number} onChange={handleInputChange} required />
              <label>Navigator ID</label><input name="navigator_id" placeholder="navigator_id" value={isEditing ? selectedEmployees.navigator_id : newEmployees.navigator_id} onChange={handleInputChange} />
              <label>Location</label><input name="location" placeholder="location" value={isEditing ? selectedEmployees.location : newEmployees.location} onChange={handleInputChange} />
              <label>Team</label><input name="team" placeholder="team" value={isEditing ? selectedEmployees.team : newEmployees.team} onChange={handleInputChange} />


              {duplicateError && <p className="error">{duplicateError}</p>} {/* Display duplicate error message */}
              {phoneError && <p className="error">{phoneError}</p>} {/* Display price error message */}
              {threeCX_idError && <p className="error">{threeCX_idError}</p>} {/* Display  error message */}
              {thcEmailIdError && <p className="error">{thcEmailIdError}</p>} {/* Display  error message */}
              {gmailEmailIdError && <p className="error">{gmailEmailIdError}</p>} {/* Display  error message */}
              {appleIdError && <p className="error">{appleIdError}</p>} {/* Display  error message */}


              <div className="button-group">
                <button className="overlay-add-employees" onClick={isEditing ? handleUpdateEmployees : handleAddEmployees}>
                  {isEditing ? 'Update Employee' : 'Add Employee'}
                </button>
                {isEditing && (
                  <button
                    className="overlay-delete-employees"
                    onClick={() => {
                      if (window.confirm('Are you sure you want to delete this employees?')) {
                        handleDeleteEmployees();
                      }
                    }}
                  >
                    Delete Employee
                  </button>

                )}
                <button className="overlay-add-employees" onClick={togglePasswords}>
                  {showPasswords ? 'Hide Passwords' : 'Show Passwords'}
                </button>
              </div>
            </div>
          </div>
        )
      }

      {
        overlayType === 'inventory' && showEmployeesShowInventoryOverlay && (

          <div className="custom-overlay">
            <div className="logo-container">

              <img src={logo2} alt="Logo" className="logo" />
              <button className="close-button" onClick={() => {
                setShowEmployeesShowInventoryOverlay(false);
                setSelectedEmployees(null);
                setIsEditing(false);
              }}>X</button>

            </div>

            <table className="log-table">
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Cell Number</th>
                  <th>NAV ID</th>
                </tr>
              </thead>
              <tbody>

                <td>{isEditing ? selectedEmployees.first_name.toUpperCase() : newEmployees.first_name}</td>
                <td>{isEditing ? selectedEmployees.last_name.toUpperCase() : newEmployees.last_name}</td>
                <td>{isEditing ? selectedEmployees.phone_number : newEmployees.phone_number}</td>
                <td>{isEditing ? selectedEmployees.navigator_id : newEmployees.navigator_id}</td>

              </tbody>
            </table>

            {/* Sección de Acuerdo y Confirmación */}
            <div class="justified-text">
              <p>
                I acknowledge that while I am working for Bexar County Community Health Collaborative Navigator Program (BCCHCNP), I will take proper care of all company equipment that I am entrusted with. I further understand that upon termination, I will return all BCCHCNP property and that the property will be returned in proper working condition. I understand I may be held financially responsible for lost or damaged property. This agreement includes, but is not limited to intellectual property, laptops, cell phones, printers, and other equipment.
              </p>
              <p>
                I acknowledge that I have received the following items with the following passcodes, passwords, and other safety and privacy policies:
              </p>
            </div>

            {/* Tabla de Información del Empleado */}
            <table >
              <thead>
                <tr>
                  <th colSpan="6">Employee Information</th>
                </tr>
                <tr>
                  <th>Item</th>
                  <th>Email</th>
                  <th>Password</th>
                  <th>Date/Time</th>
                  <th>Initials</th>
                  <th>Returned</th>
                </tr>
              </thead>
              <tbody>
                {["Apple ID", "Gmail Email", "THC Email", "3CX"].map((item, index) => (
                  <tr key={index}>
                    <td>{item}</td>
                    <td>
                      {item === "Apple ID" ? (
                        isEditing ? selectedEmployees.apple_id : newEmployees.apple_id
                      ) : item === "Gmail Email" ? (
                        isEditing ? selectedEmployees.gmail_email_id : newEmployees.gmail_email_id
                      ) : item === "THC Email" ? (
                        isEditing ? selectedEmployees.thc_email_id : newEmployees.thc_email_id
                      ) : (
                        isEditing ? selectedEmployees.ThreeCX_id : newEmployees.ThreeCX_id
                      )}

                    </td>
                    <td>
                      {item === "Apple ID" ? (
                        isEditing ? selectedEmployees.apple_password : newEmployees.apple_password
                      ) : item === "Gmail Email" ? (
                        isEditing ? selectedEmployees.gmail_email_password : newEmployees.gmail_email_password
                      ) : item === "THC Email" ? (
                        isEditing ? selectedEmployees.thc_email_password : newEmployees.thc_email_password
                      ) : (
                        isEditing ? selectedEmployees.ThreeCX_password : newEmployees.ThreeCX_password
                      )}
                    </td>
                    <td>
                      {item === "Apple ID" ? (
                        isEditing ? selectedEmployees.createdAt : newEmployees.createdAt
                      ) : item === "Gmail Email" ? (
                        isEditing ? selectedEmployees.createdAt : newEmployees.createdAt
                      ) : item === "THC Email" ? (
                        isEditing ? selectedEmployees.createdAt : newEmployees.createdAt
                      ) : (
                        isEditing ? selectedEmployees.createdAt : newEmployees.createdAt
                      )}
                    </td>
                    <td> </td>
                    <td><input type="checkbox" /></td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Tabla de otros equipos */}
            <table>
              <thead>
                <tr >
                  <th colSpan="10">Item Information  </th>
                </tr>
                <tr>
                  <th>Item</th>
                  <th>TAG #</th>
                  <th>Serial #</th>
                  <th>Passcode</th>
                  <th>Status</th>
                  <th>Charger Cable</th>
                  <th>HDMI Cable</th>
                  <th>Date</th>
                  <th>Initials</th>
                  <th>Returned</th>
                </tr>
              </thead>
              <tbody>

                {filteredLaptops.map((laptop, index) => (
                  <tr key={`laptop-${index}`}>
                    <td>Laptop</td>
                    <td className="tag-column">{laptop.tagid}</td>
                    <td>{laptop.serial_Number}</td>
                    <td>{laptop.password}</td>
                    <td>{laptop.status}</td>
                    <td>{laptop.charger_cable ? 'Yes' : 'No'}</td>
                    <td>N/A</td>
                    <td className="date-column">{laptop.assignment_date}</td>
                    <td>   </td>
                    <td><input type="checkbox" checked={laptop.returned} readOnly /></td>
                  </tr>
                ))}

                {filteredPrinters.map((printer, index) => (
                  <tr key={`printer-${index}`}>
                    <td>Printer</td>
                    <td>{printer.tagid}</td>
                    <td>{printer.serial_Number}</td>
                    <td>{''}</td>
                    <td>{printer.status}</td>
                    <td>{printer.charger_cable ? 'Yes' : 'No'}</td>
                    <td>{printer.Connector_cable}</td>
                    <td>{printer.assignment_date}</td>
                    <td>{''}</td>
                    <td><input type="checkbox" checked={printer.returned} readOnly /></td>
                  </tr>
                ))}
                {filteredScanners.map((scanner, index) => (
                  <tr key={`scanner-${index}`}>
                    <td>Scanner</td>
                    <td>{scanner.tagid}</td>
                    <td>{scanner.serial_Number}</td>
                    <td>{''}</td>
                    <td>{scanner.status}</td>
                    <td>{scanner.charger_cable ? 'Yes' : 'No'}</td>
                    <td>{scanner.Connector_cable}</td>
                    <td>{scanner.assignment_date}</td>
                    <td>{''}</td>
                    <td><input type="checkbox" checked={scanner.returned} readOnly /></td>
                  </tr>
                ))}
                {filteredPhones.map((phone, index) => (
                  <tr key={`phone-${index}`}>
                    <td>Phone</td>
                    <td>{phone.tagid}</td>
                    <td>{phone.serial_Number}</td>
                    <td>{phone.pin}</td>
                    <td>{phone.status}</td>
                    <td>{phone.charger_cable ? 'Yes' : 'No'}</td>
                    <td>{phone.Connector_cable}</td>
                    <td>{phone.assignment_date}</td>
                    <td>{''}</td>
                    <td><input type="checkbox" checked={phone.returned} readOnly /></td>
                  </tr>
                ))}
                {filteredHeadset.map((headset, index) => (
                  <tr key={`headset-${index}`}>
                    <td>Headset</td>
                    <td>{headset.tagid}</td>
                    <td>{headset.serial_Number}</td>
                    <td>{''}</td>
                    <td>{headset.status}</td>
                    <td>{headset.charger_cable ? 'Yes' : 'No'}</td>
                    <td>{headset.Connector_cable}</td>
                    <td>{headset.assignment_date}</td>
                    <td>{''}</td>
                    <td><input type="checkbox" checked={headset.returned} readOnly /></td>
                  </tr>
                ))}
                {filteredMouse.map((mouse, index) => (
                  <tr key={`mouse-${index}`}>
                    <td>Mouse</td>
                    <td>{mouse.tagId}</td>
                    <td>{mouse.serial_Number}</td>
                    <td>{''}</td>
                    <td>{mouse.status}</td>
                    <td>{''}</td>
                    <td>{''}</td>
                    <td>{mouse.assignment_date}</td>
                    <td>{''}</td>
                    <td><input type="checkbox" checked={mouse.returned} readOnly /></td>
                  </tr>
                ))}
                {filteredKeyboard.map((keyboard, index) => (
                  <tr key={`keyboard-${index}`}>
                    <td>Keyboard</td>
                    <td>{keyboard.tagid}</td>
                    <td>{keyboard.serial_Number}</td>
                    <td>{''}</td>
                    <td>{keyboard.status}</td>
                    <td>{''}</td>
                    <td>{''}</td>
                    <td>{keyboard.assignment_date}</td>
                    <td>{''}</td>
                    <td><input type="checkbox" checked={keyboard.returned} readOnly /></td>
                  </tr>
                ))}
                {filteredMonitorDesk.map((monitor, index) => (
                  <tr key={`monitor-${index}`}>
                    <td> Desk Monitor </td>
                    <td>{monitor.tagid}</td>
                    <td>{monitor.serial_Number}</td>
                    <td>{''}</td>
                    <td>{monitor.status}</td>
                    <td>{monitor.charger_cable ? 'Yes' : 'No'}</td>
                    <td>{monitor.Connector_cable}</td>
                    <td>{monitor.assignment_date}</td>
                    <td>{''}</td>
                    <td><input type="checkbox" checked={monitor.returned} readOnly /></td>
                  </tr>
                ))}
                {filteredMonitorPortable.map((monitor, index) => (
                  <tr key={`monitor-${index}`}>
                    <td> Portable Monitor</td>
                    <td>{monitor.tagid}</td>
                    <td>{monitor.serial_Number}</td>
                    <td>{''}</td>
                    <td>{monitor.status}</td>
                    <td>{monitor.charger_cable ? 'Yes' : 'No'}</td>
                    <td>{monitor.Connector_cable}</td>
                    <td>{monitor.assignment_date}</td>
                    <td>{''}</td>
                    <td><input type="checkbox" checked={monitor.returned} readOnly /></td>
                  </tr>
                ))}
                {filteredExtensionCords.map((extensionCord, index) => (
                  <tr key={`extensionCord-${index}`}>
                    <td>Extension Cord</td>
                    <td>{extensionCord.tagid}</td>
                    <td>{extensionCord.serial_Number}</td>
                    <td>{''}</td>
                    <td>{extensionCord.status}</td>
                    <td>{''}</td>
                    <td>{''}</td>
                    <td>{extensionCord.assignment_date}</td>
                    <td>{''}</td>
                    <td><input type="checkbox" checked={extensionCord.returned} readOnly /></td>
                  </tr>
                ))}
                {filteredBackupBaterries.map((backupBaterry, index) => (
                  <tr key={`-${index}`}>
                    <td>Backup Baterry</td>
                    <td>{backupBaterry.tagid}</td>
                    <td>{backupBaterry.serial_Number}</td>
                    <td>{''}</td>
                    <td>{backupBaterry.status}</td>
                    <td>{ }</td>
                    <td>{ }</td>
                    <td>{backupBaterry.assignment_date}</td>
                    <td>{''}</td>
                    <td><input type="checkbox" checked={backupBaterry.returned} readOnly /></td>
                  </tr>
                ))}
                {filteredBag.map((bag, index) => (
                  <tr key={`-${index}`}>
                    <td>Bag</td>
                    <td>{bag.brand}</td>
                    <td>{bag.serial_Number}</td>
                    <td>{''}</td>
                    <td>{bag.status}</td>
                    <td>{ }</td>
                    <td>{ }</td>
                    <td>{bag.assignment_date}</td>
                    <td>{''}</td>
                    <td><input type="checkbox" checked={bag.returned} readOnly /></td>
                  </tr>
                ))}
                {filteredSupplies.map((supplies, index) => (
                  <tr key={`-${index}`}>
                    <td>Supplies</td>
                    <td>{supplies.name}</td>
                    <td>{ }</td>
                    <td>{''}</td>
                    <td>{ }</td>
                    <td>{ }</td>
                    <td>{ }</td>
                    <td>{supplies.assignment_date}</td>
                    <td>{''}</td>
                    <td><input type="checkbox" checked={supplies.returned} readOnly /></td>
                  </tr>
                ))}
                {filteredUSB.map((usb, index) => (
                  <tr key={`-${index}`}>
                    <td>USB</td>
                    <td>{usb.tagid}</td>
                    <td>{usb.serial_Number}</td>
                    <td>{ }</td>
                    <td>{usb.status}</td>
                    <td>{''}</td>
                    <td>{ }</td>
                    <td>{usb.assignment_date}</td>
                    <td>{''}</td>
                    <td><input type="checkbox" checked={usb.returned} readOnly /></td>
                  </tr>
                ))}
                {filteredDockingsStation.map((docking, index) => (
                  <tr key={`-${index}`}>
                    <td>Docking Station</td>
                    <td>{docking.tagid}</td>
                    <td>{docking.serial_Number}</td>
                    <td>{''}</td>
                    <td>{docking.status}</td>
                    <td>{ }</td>
                    <td>{ }</td>
                    <td>{docking.assignment_date}</td>
                    <td>{''}</td>
                    <td><input type="checkbox" checked={docking.returned} readOnly /></td>
                  </tr>
                ))}
                {filteredSpeakers.map((speaker, index) => (
                  <tr key={`-${index}`}>
                    <td>Speaker </td>
                    <td>{speaker.tagid}</td>
                    <td>{speaker.serial_Number}</td>
                    <td>{''}</td>
                    <td>{speaker.status}</td>
                    <td>{ }</td>
                    <td>{ }</td>
                    <td>{speaker.assignment_date}</td>
                    <td>{''}</td>
                    <td><input type="checkbox" checked={speaker.returned} readOnly /></td>
                  </tr>
                ))}
                {filteredPowerBank.map((powerBank, index) => (
                  <tr key={`-${index}`}>
                    <td>Power Bank </td>
                    <td>{powerBank.tagid}</td>
                    <td>{powerBank.serial_Number}</td>
                    <td>{''}</td>
                    <td>{powerBank.status}</td>
                    <td>{ }</td>
                    <td>{ }</td>
                    <td>{powerBank.assignment_date}</td>
                    <td>{''}</td>
                    <td><input type="checkbox" checked={powerBank.returned} readOnly /></td>
                  </tr>
                ))}
                {filteredtablets.map((tablet, index) => (
                  <tr key={`-${index}`}>
                    <td>Tablet </td>
                    <td>{tablet.tagid}</td>
                    <td>{tablet.serial_Number}</td>
                    <td>{tablet.password}</td>
                    <td>{tablet.status}</td>
                    <td>{tablet.charger_cable ? 'Yes' : 'No'}</td>
                    <td>{ }</td>
                    <td>{tablet.assignment_date}</td>
                    <td>{''}</td>
                    <td><input type="checkbox" checked={tablet.returned} readOnly /></td>
                  </tr>
                ))}
                {filteredExternalDisk.map((externoDisks, index) => (
                  <tr key={`-${index}`}>
                    <td>Externo Disk</td>
                    <td>{externoDisks.tagid}</td>
                    <td>{externoDisks.serial_Number}</td>
                    <td>{ }</td>
                    <td>{externoDisks.status}</td>
                    <td>{externoDisks.charger_cable ? 'Yes' : 'No'}</td>
                    <td>{externoDisks.output_cable ? 'Yes' : 'No'}</td>
                    <td>{externoDisks.assignment_date}</td>
                    <td>{''}</td>
                    <td><input type="checkbox" checked={externoDisks.returned} readOnly /></td>
                  </tr>
                ))}

                {filteredClickCounter.map((clickCounter, index) => (
                  <tr key={`-${index}`}>
                    <td>Click Counter</td>
                    <td>{clickCounter.store}</td>
                    <td>{ }</td>
                    <td>{ }</td>
                    <td>{ }</td>
                    <td>{ }</td>
                    <td>{clickCounter.assignment_date}</td>
                    <td>{''}</td>
                    <td><input type="checkbox" checked={clickCounter.returned} readOnly /></td>
                  </tr>
                ))}
              </tbody>
            </table>

            {filteredgeneralInventorys
              .filter(generalInventory => generalInventory.Item_Description && !generalInventory.Item_Description.includes('Access')) // Filtrar Item_Description contenga al menos Access
              .length > 0 && (
                <table>
                  <thead>
                    <tr >
                      <th colSpan="9">Other Equipment</th>
                    </tr>
                    <tr>
                      <th>Item</th>
                      <th>TAG #</th>
                      <th>Brand</th>
                      <th>Serial #</th>
                      <th>Date</th>
                      <th>Initials</th>
                      <th>Returned</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredgeneralInventorys
                      .filter(generalInventory => generalInventory.Item_Description && !generalInventory.Item_Description.includes('Access')) // Aplicar el filtro también en el mapeo
                      .map((generalInventory, index) => (
                        <tr key={`-${index}`}>
                          <td>{generalInventory.Item_Description}</td>
                          <td>{generalInventory.tagid || 'No Tag ID'}</td>
                          <td>{generalInventory.brand || 'No Brand'}</td>
                          <td>{generalInventory.serial_Number || 'No Serial Number'}</td>
                          <td>{generalInventory.assignment_date || 'No Date'}</td>
                          <td>{''}</td>
                          <td><input type="checkbox" checked={generalInventory.returned} readOnly /></td>
                        </tr>
                      ))}
                  </tbody>
                </table>)}

            {filteredgeneralInventorys
              .filter(generalInventory => !generalInventory.brand) // Filtrar cuando brand es null o vacío
              .length > 0 && (
                <table>
                  <thead>
                    <tr>
                      <th colSpan="5">Health Collaborative Account</th>
                    </tr>
                    <tr>
                      <th>Item</th>
                      <th>Account</th>
                      <th>Password</th>
                      <th>Date</th>
                      <th>Initials</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredgeneralInventorys
                      .filter(generalInventory => !generalInventory.brand) // Filtrar de nuevo aquí
                      .map((generalInventory, index) => (
                        <tr key={`-${index}`}>
                          <td>{generalInventory.Item_Description || 'No Description'}</td>
                          <td>{generalInventory.tagid || 'No Account ID'}</td>
                          <td>{generalInventory.serial_Number || 'No Password'}</td>
                          <td>{generalInventory.assignment_date || 'No Date'}</td>
                          <td>{'Initials'}</td> {/* Ajusta esto según necesites */}
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}


            <div className='notas_container'>
              <label htmlFor="notes">Notes:</label>
              <input type="text" id="notes" placeholder="" className='input-field' />
              <span id="printNotes" ></span>
            </div>

            {/* Tabla de Firma del Empleado */}
            <table>
              <thead>
                <tr>
                  <th>Employee Name</th>
                  <th>Employee Signature</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input type="text" id="employeeName" placeholder="" />
                    <span id="printEmployeeName" ></span>
                  </td>
                  <td>
                    <input type="text" id="employeeSignature" placeholder="" />
                    <span id="printEmployeeSignature"></span>
                  </td>
                  <td>
                    <input type="date" id="employeeDate" />
                    <span id="printEmployeeDate" ></span>
                  </td>
                </tr>
              </tbody>
            </table>

            {/* Tabla de Firma del Supervisor */}
            <table>
              <thead>
                <tr>
                  <th>Supervisor Name</th>
                  <th>Supervisor Signature</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input type="text" id="supervisorName" placeholder="" />
                    <span id="printSupervisorName" ></span>
                  </td>
                  <td>
                    <input type="text" id="supervisorSignature" placeholder="" />
                    <span id="printSupervisorSignature" ></span>
                  </td>
                  <td>
                    <input type="date" id="supervisorDate" />
                    <span id="printSupervisorDate" ></span>
                  </td>
                </tr>
              </tbody>
            </table>



            <button className="print-btn" onClick={handlePrintOverlay}>Print</button>
          </div>




        )
      }

    </div >

  );

};

export default Employees;
