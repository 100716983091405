import React from 'react';
import PlanSelection from './PlanSelection';

const Section11 = ({ formData, handleInputChange }) => (
  <>
    {/* Section 11 */}
    <div className="form-section flyer-section">
      <div className="flyer-header">QHP Selection</div>
      <label className="required-asterisk">How Many QHPs?</label>
      <input
        type="number"
        name="QHP_Amount"
        value={formData.QHP_Amount}
        onChange={handleInputChange}
        min="0"
        placeholder="Your answer"
      />

      <div>
        <label className="required-asterisk">Is it the same plan for all in the household?</label>
        <div>
          <label>Yes</label>

          <input
            type="radio"
            name="samePlanForAll"
            value="yes"
            checked={formData.samePlanForAll === 'yes'}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>No</label>
          <input
            type="radio"
            name="samePlanForAll"
            value="no"
            checked={formData.samePlanForAll === 'no'}
            onChange={handleInputChange}
          />
        </div>
      </div>
    </div>

    {formData.samePlanForAll === 'yes' && (
      <PlanSelection
        key={0}
        index={0}
        formData={formData}
        handleInputChange={handleInputChange}
      />
    )}

    {formData.samePlanForAll === 'no' &&
      Array.from({ length: formData.QHP_Amount }).map((_, index) => (
        <PlanSelection
          key={index}
          index={index}
          formData={formData}
          handleInputChange={handleInputChange}
        />
      ))}
  </>
);

export default Section11;
