import React from 'react';

const Section8 = ({ formData, handleInputChange }) => (
  <>
    {/* Section 8 */}
    <div className="form-section flyer-section">
          <div className="flyer-header">Income Information</div>
          <label className="required-asterisk">Income Type</label>
          <select
            name='IncomeType'
            value={formData.IncomeType}
            onChange={handleInputChange}
            required>
            <option value="" disabled>Your answer</option>
            <option value="Job">Job</option>
            <option value="Self Employed">Self Employed</option>
            <option value="Social Security Disability">Social Security Disability</option>
            <option value="Social Security Supplemental">Social Security Supplemental</option>
            <option value="Pension">Pension</option>
            <option value="Retirement">Retirement</option>
            <option value="Capital Gains">Capital Gains</option>
            <option value="Investment Income">Investment Income</option>
            <option value="Rental and Royalty Income">Rental and Royalty Income</option>
            <option value="Unemployment">Unemployment</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div className='form-section'>
          <label>Income Amount</label>
          <input
            type='number'
            name='IncomeAmount'
            value={formData.IncomeAmount}
            onChange={handleInputChange}
            placeholder="Your answer"
            min="0.0"
            step="0.01"
          />
          <label>Pay Rate and Frequency</label>
          <select
            name='PayFrequency'
            value={formData.PayFrequency}
            onChange={handleInputChange}
            required>
            <option value="" disabled>Your answer</option>
            <option value="Annual">Annual</option>
            <option value="Monthly">Monthly</option>
            <option value="BiWeekly">Bi-Weekly</option>
            <option value="Weekly">Weekly</option>
            <option value="None">None</option>
          </select>

        </div>
  </>
);

export default Section8;
