import React, { useState } from 'react';

const Section35 = ({ formData, handleInputChange }) => {
  const [showOtherTextInput, setShowOtherTextInput] = useState({
    data_matchingIssues: false,
    SEP_eligibility: false,
    Employer_sponsored_coverage_issues: false,
    APTC_CSR_assistance: false,
  });

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    handleInputChange(e);
    setShowOtherTextInput((prev) => ({
      ...prev,
      [name]: value === 'Other',
    }));
  };

  return (
    <>
      {/* Section 35 */}
      <div className="form-section flyer-section">
        <div className="flyer-header">6. Complex Cases and Other Marketplace Enrollment Issues</div>
        <label className="required-asterisk">A. Complex Case Help Center Assistance and Referrals</label>
        <input
          type="number"
          name="complex_case_help_center"
          value={formData.complex_case_help_center}
          onChange={handleInputChange}
          placeholder="Short answer text"
          min="0"
          required
        />
      </div>
      <div className="form-section">
        <label className="required-asterisk">B. Data matching issues/Periodic data matching issues assistance</label>
        <div className="radio-group">
          {['1', '2', '3', 'None', 'Other'].map((option) => (
            <label key={option}>
              <input
                type="radio"
                name="data_matchingIssues"
                value={option}
                checked={formData.data_matchingIssues === option}
                onChange={handleRadioChange}
                required
              />
              {option}
            </label>
          ))}
        </div>
        {showOtherTextInput.data_matchingIssues && (
          <input
            type="text"
            name="data_matchingIssues_other"
            value={formData.data_matchingIssues_other}
            onChange={handleInputChange}
            placeholder="Please specify"
          />
        )}
      </div>
      <div className="form-section">
        <label className="required-asterisk">C. SEP eligibility troubleshooting assistance</label>
        <div className="radio-group">
          {['1', '2', '3', 'None', 'Other'].map((option) => (
            <label key={option}>
              <input
                type="radio"
                name="SEP_eligibility"
                value={option}
                checked={formData.SEP_eligibility === option}
                onChange={handleRadioChange}
                required
              />
              {option}
            </label>
          ))}
        </div>
        {showOtherTextInput.SEP_eligibility && (
          <input
            type="text"
            name="SEP_eligibility_other"
            value={formData.SEP_eligibility_other}
            onChange={handleInputChange}
            placeholder="Please specify"
          />
        )}
      </div>
      <div className="form-section">
        <label className="required-asterisk">D. Employer-sponsored coverage issues assistance</label>
        <div className="radio-group">
          {['1', '2', '3', 'None', 'Other'].map((option) => (
            <label key={option}>
              <input
                type="radio"
                name="Employer_sponsored_coverage_issues"
                value={option}
                checked={formData.Employer_sponsored_coverage_issues === option}
                onChange={handleRadioChange}
                required
              />
              {option}
            </label>
          ))}
        </div>
        {showOtherTextInput.Employer_sponsored_coverage_issues && (
          <input
            type="text"
            name="Employer_sponsored_coverage_issues_other"
            value={formData.Employer_sponsored_coverage_issues_other}
            onChange={handleInputChange}
            placeholder="Please specify"
          />
        )}
      </div>
      <div className="form-section">
        <label className="required-asterisk">E. APTC/CSR assistance</label>
        <div className="radio-group">
          {['1', '2', '3', 'None', 'Other'].map((option) => (
            <label key={option}>
              <input
                type="radio"
                name="APTC_CSR_assistance"
                value={option}
                checked={formData.APTC_CSR_assistance === option}
                onChange={handleRadioChange}
                required
              />
              {option}
            </label>
          ))}
        </div>
        {showOtherTextInput.APTC_CSR_assistance && (
          <input
            type="text"
            name="APTC_CSR_assistance_other"
            value={formData.APTC_CSR_assistance_other}
            onChange={handleInputChange}
            placeholder="Please specify"
          />
        )}
      </div>
      <div className="form-section">
        <label>F. Other</label>
        <input
          type="text"
          name="Complex_Cases_Other"
          value={formData.Complex_Cases_Other}
          onChange={handleInputChange}
          placeholder="Your answer"
        />
      </div>
    </>
  );
};

export default Section35;
