import React, { useState, useEffect, useRef } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_LOCATION } from '../../graphql/mutations';
import { GET_ALL_LOCATIONS } from '../../graphql/queries';
import { fetchAuthSession } from 'aws-amplify/auth'; // Use the new fetchAuthSession method
import LoadingSpinner from '../../components/layout/LoadingSpinner'; // Adjust the path based on your folder structure
import * as XLSX from 'xlsx';
import './MobileOutreach.css';

const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

const MobileOutreach = () => {
  const [createLocation] = useMutation(CREATE_LOCATION);
  const { data, refetch } = useQuery(GET_ALL_LOCATIONS); // Fetch locations
  const [userGroup, setUserGroup] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // Fetch the user's group when the component mounts
    const fetchUserGroup = async () => {
      try {
        const session = await fetchAuthSession();
        const groups = session.tokens.accessToken.payload["cognito:groups"]; // Get the user's groups
        if (groups) {
          setUserGroup(groups); // Assuming a user belongs to one group. Adjust as needed.
        }
      } catch (error) {
        console.error('Error fetching user group:', error);
      }
    };

    fetchUserGroup();
  }, []);

  // Helper function to get the current date in YYYY-MM-DD format
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const [formData, setFormData] = useState({
    name: '',
    address: '',
    city: '',
    county: '',
    zipCode: '',
    pointOfContactName: '',
    pointOfContactPhone: '',
    pointOfContactEmail: '',
    outreachDate: getCurrentDate()
  });

  const [operationHours, setOperationHours] = useState({
    Monday: { checked: false, start: '', end: '' },
    Tuesday: { checked: false, start: '', end: '' },
    Wednesday: { checked: false, start: '', end: '' },
    Thursday: { checked: false, start: '', end: '' },
    Friday: { checked: false, start: '', end: '' },
    Saturday: { checked: false, start: '', end: '' },
    Sunday: { checked: false, start: '', end: '' }
  });

  const handleOperationHoursChange = (day, field, value) => {
    setOperationHours((prev) => ({
      ...prev,
      [day]: { ...prev[day], [field]: value }
    }));
  };
  const handleSameTimeApply = () => {
    // Find the first checked day
    const firstCheckedDay = Object.entries(operationHours).find(
      ([_, { checked }]) => checked
    );

    // If no day is checked, return early
    if (!firstCheckedDay) return;

    const [referenceDay, referenceTimes] = firstCheckedDay; // Get the first checked day's times

    // Apply the reference times to all other checked days
    setOperationHours((prev) => ({
      ...Object.fromEntries(
        Object.entries(prev).map(([day, times]) => {
          if (times.checked && day !== referenceDay) { // Apply to checked days, excluding the reference day
            return [
              day,
              {
                ...times,
                start: referenceTimes.start, // Use reference day's start time
                end: referenceTimes.end, // Use reference day's end time
              },
            ];
          }
          return [day, times]; // Leave other days unchanged
        })
      )
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Only allow numeric input for the phone field
    if (name === "pointOfContactPhone") {
      const numericValue = value.replace(/\D/g, ""); // Remove any non-digit characters
      setFormData({ ...formData, [name]: numericValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const isFormValid = () => {
    return (
      formData.name &&
      formData.address &&
      formData.city &&
      formData.county &&
      formData.zipCode &&
      formData.outreachDate
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show the loading overlay
    const {
      name,
      address,
      city,
      county,
      zipCode,
      pointOfContactName,
      pointOfContactPhone,
      pointOfContactEmail,
      outreachDate
    } = formData;

    const completeAddress = `${address}, ${city}, ${county}, ${zipCode}, TX`;

    // Convert operationHours to an array format
    const formattedOperationHours = Object.entries(operationHours)
      .filter(([day, { checked }]) => checked)
      .map(([day, { start, end }]) => ({
        day,
        startTime: start,
        endTime: end,
      }));

    // Prepare points of contact array
    const pointsOfContact = [
      {
        name: pointOfContactName,
        phone: pointOfContactPhone,
        email: pointOfContactEmail,
      },
    ];

    try {
      await createLocation({
        variables: {
          input: {
            name: name,
            address: completeAddress,
            cityID: city,
            outreachDate: outreachDate,
            operationHours: formattedOperationHours,
            pointsOfContact,
          },
        },
      });

      // Reset the form
      setFormData({
        name: '',
        address: '',
        city: '',
        county: '',
        zipCode: '',
        pointOfContactName: '',
        pointOfContactPhone: '',
        pointOfContactEmail: '',
        outreachDate: ''
      });

      setOperationHours({
        Monday: { checked: false, start: '', end: '' },
        Tuesday: { checked: false, start: '', end: '' },
        Wednesday: { checked: false, start: '', end: '' },
        Thursday: { checked: false, start: '', end: '' },
        Friday: { checked: false, start: '', end: '' },
        Saturday: { checked: false, start: '', end: '' },
        Sunday: { checked: false, start: '', end: '' }
      });

      console.log('Location added successfully!');
    } catch (error) {
      console.error('Error adding location:', error);
    } finally {
      setLoading(false); // Hide the loading overlay
    }
  };

  const formatPhoneNumber = (phone) => {
    if (phone.length === 10) {
      return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)} - ${phone.slice(6)}`;
    }
    return phone; // Return as-is if not 10 digits
  };

  const exportToExcel = async () => {
    try {
        setLoading(true); // Optional: Show loading indicator while fetching

        // Refetch to get the latest data
        const { data: latestData } = await refetch();

        if (!latestData || !latestData.listLocations) {
            console.error("No locations data found.");
            return;
        }

        const locations = latestData.listLocations.items;
        const formattedData = locations.map((location) => {
            const operationHoursMap = {
                Monday: '',
                Tuesday: '',
                Wednesday: '',
                Thursday: '',
                Friday: '',
                Saturday: '',
                Sunday: ''
            };

            // Populate operation hours if the day is checked and times are provided
            location.operationHours?.forEach(({ day, startTime, endTime }) => {
                if (day && startTime && endTime) {
                    operationHoursMap[day] = `${startTime} - ${endTime}`;
                }
            });

            return {
                Name: location.name,
                Address: location.address,
                City: location.city?.name || '',
                "Outreach Date": location.outreachDate,
                "Point of Contact Name": location.pointsOfContact?.[0]?.name || '',
                "Point of Contact Phone": formatPhoneNumber(location.pointsOfContact?.[0]?.phone || ''),
                "Point of Contact Email": location.pointsOfContact?.[0]?.email || '',
                Monday: operationHoursMap.Monday,
                Tuesday: operationHoursMap.Tuesday,
                Wednesday: operationHoursMap.Wednesday,
                Thursday: operationHoursMap.Thursday,
                Friday: operationHoursMap.Friday,
                Saturday: operationHoursMap.Saturday,
                Sunday: operationHoursMap.Sunday,
            };
        });

        const worksheet = XLSX.utils.json_to_sheet(formattedData);

        // Auto-adjust column width based on the maximum content in each column
        const columnWidths = Object.keys(formattedData[0]).map((key) => {
            const maxContentWidth = Math.max(
                ...formattedData.map(row => (row[key] ? row[key].toString().length : 0))
            );
            return { wch: maxContentWidth + 2 }; // Add padding for better readability
        });

        worksheet['!cols'] = columnWidths;

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Locations");

        // Download the Excel file
        XLSX.writeFile(workbook, `Locations_${getCurrentDate()}.xlsx`);
    } catch (error) {
        console.error("Error exporting to Excel:", error);
    } finally {
        setLoading(false); // Hide loading overlay
    }
};
  return (
    <div className="mobile-outreach">
      {/* Loading screen */}
      {loading && <LoadingSpinner />} {/* Use the reusable LoadingSpinner component */}
      <header className="mobile-outreach-header">
        <h1>Mobile Outreach</h1>
      </header>
      <div className='mobile-outreach-container'>
        {(userGroup?.includes('Manager') || userGroup?.includes('Admin')) && (
          <button className='exportToExcelBtn' onClick={exportToExcel}>Download Excel</button>
        )}
        <form onSubmit={handleSubmit} className="mobile-outreach-content">
          {/* Location Information Card */}
          <div className="info-card">
            <h2>Location Information</h2>
            <p>Outreach Date</p>
            <input
              type="date"
              name="outreachDate"
              value={formData.outreachDate}
              onChange={handleInputChange}
              required
            />
            <p>Location Name</p>
            <input
              type="text"
              name="name"
              value={formData.name}
              placeholder="Location Name"
              onChange={handleInputChange}
              required
            />
            <p>Street Address</p>
            <input
              type="text"
              name="address"
              value={formData.address}
              placeholder="Street Address"
              onChange={handleInputChange}
              required
            />
            <p>City</p>
            <input
              type="text"
              name="city"
              value={formData.city}
              placeholder="City"
              onChange={handleInputChange}
              required
            />
            <p>County</p>
            <input
              type="text"
              name="county"
              value={formData.county}
              placeholder="County"
              onChange={handleInputChange}
              required
            />
            <p>Zip Code</p>
            <input
              type="text"
              name="zipCode"
              value={formData.zipCode}
              placeholder="Zip Code"
              onChange={handleInputChange}
              required
            />
          </div>
          {/* Point of Contact Information Card */}
          <div className="info-card">
            <h2>Point of Contact Information</h2>
            <p>Point of Contact Name</p>
            <input
              type="text"
              name="pointOfContactName"
              value={formData.pointOfContactName}
              placeholder="Point of Contact Name"
              onChange={handleInputChange}
            />
            <p>Point of Contact Phone</p>
            <input
              type="tel"
              name="pointOfContactPhone"
              value={formData.pointOfContactPhone}
              placeholder="Point of Contact Phone"
              onChange={handleInputChange}
              pattern="\d*" // Enforce numeric input
            />
            <p>Point of Contact Email</p>
            <input
              type="email"
              name="pointOfContactEmail"
              value={formData.pointOfContactEmail}
              placeholder="Point of Contact Email"
              onChange={handleInputChange}
            />
          </div>
          {/* Operation Hours Card */}
          <div className="info-card operation-hours-card">
            <h2>Operation Hours</h2>
            <button
              type="button"
              onClick={handleSameTimeApply}
              className="same-time-btn"
            >
              Apply Same Time Frame to All Days
            </button>            {daysOfWeek.map((day) => (
              <div key={day} className="operation-hours-row">
                <div className="operation-hours-day">
                  <input
                    type="checkbox"
                    checked={operationHours[day].checked}
                    onChange={(e) => handleOperationHoursChange(day, 'checked', e.target.checked)}
                  />
                  <span>{day}</span>
                </div>
                <input
                  type="time"
                  value={operationHours[day].start}
                  onChange={(e) => handleOperationHoursChange(day, 'start', e.target.value)}
                  placeholder="Start Time"
                  disabled={!operationHours[day].checked}
                />
                <input
                  type="time"
                  value={operationHours[day].end}
                  onChange={(e) => handleOperationHoursChange(day, 'end', e.target.value)}
                  placeholder="End Time"
                  disabled={!operationHours[day].checked}
                />
              </div>
            ))}
          </div>
          <div className="button-container">
            <button
              type="submit"
              className="SubmitBtn"
              disabled={!isFormValid()}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>

  );
};

export default MobileOutreach;
