import React from 'react';

const PlanSelection = ({ index, formData, handleInputChange }) => {
  return (
    <div key={index}>
      {/* Section 12 - 18 */}
      <div className="form-section flyer-section">
        <div className="flyer-header">Plan Selection #{index + 1}</div>
        <label>Plan can apply to more than one person in household. Please list all family member names below.</label>
      </div>

      <div className='form-section'>
        <label>Client Name</label>
        <input
          type='text'
          name={`plan_ClientName_${index}`}
          value={formData[`plan_ClientName_${index}`] || ''}
          onChange={handleInputChange}
          placeholder="Your answer"
        />
        <label>Client Last Name</label>
        <input
          type="text"
          name={`plan_ClientLastName_${index}`}
          value={formData[`plan_ClientLastName_${index}`] || ''}
          onChange={handleInputChange}
          placeholder="Your answer"
        />
      </div>

      <div className='form-section'>
        <label>Insurer Selected (#{index + 1})</label>
        <select
          name={`Insurer_${index}`}
          value={formData[`Insurer_${index}`] || ''}
          onChange={handleInputChange}
        >
          <option value="" disabled>Select Insurer</option>
          <option value="Aetna CVS Health">Aetna CVS Health</option>
          <option value="Ascension">Ascension</option>
          <option value="Ambetter from Superior HealthPlan">Ambetter from Superior HealthPlan</option>
          <option value="Blue Cross Blue Shield of Texas (Blue Advantage)">Blue Cross Blue Shield of Texas (Blue Advantage)</option>
          <option value="Bright HealthCare">Bright HealthCare</option>
          <option value="CHRISTUS Health Plan">CHRISTUS Health Plan</option>
          <option value="Cigna">Cigna</option>
          <option value="Community Health Choice">Community Health Choice</option>
          <option value="Community First">Community First</option>
          <option value="FirstCare">FirstCare</option>
          <option value="Imperial">Imperial</option>
          <option value="Moda Health">Moda Health</option>
          <option value="Molina Healthcare">Molina Healthcare</option>
          <option value="Oscar">Oscar</option>
          <option value="Baylor Scott & White">Baylor Scott & White</option>
          <option value="Sendero">Sendero</option>
          <option value="UnitedHealthCare">UnitedHealthCare</option>
          <option value="US Health and Life">US Health and Life</option>
        </select>
      </div>

      <div className='form-section'>
        <label>Plan Metal</label>
        <div className="radio-group">
          <label>
            <input
              type="radio"
              name={`PlanMetal_${index}`}
              value="Bronze"
              checked={formData[`PlanMetal_${index}`] === 'Bronze'}
              onChange={handleInputChange}
            />
            Bronze
          </label>
          <label>
            <input
              type="radio"
              name={`PlanMetal_${index}`}
              value="Silver"
              checked={formData[`PlanMetal_${index}`] === 'Silver'}
              onChange={handleInputChange}
            />
            Silver
          </label>
          <label>
            <input
              type="radio"
              name={`PlanMetal_${index}`}
              value="Gold"
              checked={formData[`PlanMetal_${index}`] === 'Gold'}
              onChange={handleInputChange}
            />
            Gold
          </label>
        </div>
      </div>

      <div className='form-section'>
        <label>Plan #{index + 1} Price of Plan</label>
        <input
          type='number'
          name={`PriceofPlan_${index}`}
          value={formData[`PriceofPlan_${index}`] || ''}
          onChange={handleInputChange}
          placeholder="Your answer"
          min="0"
          step="0.01"
        />
      </div>

      <div className='form-section'>
        <label>Plan #{index + 1} APTC/Subsidy</label>
        <input
          type='number'
          name={`APCT_Subsidy_${index}`}
          value={formData[`APCT_Subsidy_${index}`] || ''}
          onChange={handleInputChange}
          placeholder="Your answer"
          min="0"
          step="0.01"
        />
      </div>
      {/* Section 19 - 26 */}
      <div className="form-section flyer-section">
        <div className="flyer-header">Dental Coverage</div>
        <label className="required-asterisk">Did Client Select Dental #{index + 1}</label>
        <div className="radio-group">
          <label>
            <input
              type="radio"
              name={`DentalConfirm${index}`}
              value="Yes"
              checked={formData[`DentalConfirm${index}`] === 'Yes'}
              onChange={handleInputChange}
              required
            />
            Yes
          </label>
          <label>
            <input
              type="radio"
              name={`DentalConfirm${index}`}
              value="No"
              checked={formData[`DentalConfirm${index}`] === 'No'}
              onChange={handleInputChange}
              required
            />
            No
          </label>
        </div>
      </div>
      {/* If yes on client select dental show this */}
      {formData[`DentalConfirm${index}`] === 'Yes' && (
        <>
          <div className="form-section flyer-section">
            <div className="flyer-header">Dental Plan</div>
            <label>Plan can apply to more than one person in household. Please list all family member names below.</label>
          </div>

          <div className='form-section'>
            <label>Client Name</label>
            <input
              type='text'
              name={`Dentalplan_ClientName_${index}`}
              value={formData[`Dentalplan_ClientName_${index}`] || ''}
              onChange={handleInputChange}
              placeholder="Your answer"
            />
            <label>Client Last Name</label>
            <input
              type="text"
              name={`Dentalplan_ClientLastName_${index}`}
              value={formData[`Dentalplan_ClientLastName_${index}`] || ''}
              onChange={handleInputChange}
              placeholder="Your answer"
            />
          </div>

          <div className='form-section one_one'>
            <label className="required-asterisk">Dental Plan Name</label>
            <input
              type='text'
              name={`Dentalplan_Name_${index}`}
              value={formData[`Dentalplan_Name_${index}`] || ''}
              onChange={handleInputChange}
              placeholder="Your answer"
              required
            />
          </div>
          <div className='form-section'>
            <label className="required-asterisk">Cost of the Dental Plan</label>
            <input
              type='number'
              name={`Dentalplan_Cost_${index}`}
              value={formData[`Dentalplan_Cost_${index}`] || ''}
              onChange={handleInputChange}
              placeholder="Your answer"
              min="0"
              step="0.01"
              required
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PlanSelection;
