import React from 'react';

const Section38 = ({ formData, handleInputChange }) => (
  <>
    {/* Section 38 */}
    <div className="form-section flyer-section">
          <div className="flyer-header">Additional Information</div>
          <label className="required-asterisk">Assisted with other consumer information</label>
          <input
            type="text"
            name="Assisted_with_other_consumer_information"
            value={formData.Assisted_with_other_consumer_information}
            onChange={handleInputChange}
            placeholder="Short answer text"
            required
          />
        </div>
        <div className='form-section'>
          <label >Pertinent information for tracking (i.e. DOB, family member DOB, medications, preferred doctors , etc.)</label>
          <input
            className='long-text'
            type='text'
            name='pertinent_info_tracking'
            value={formData.pertinent_info_tracking}
            onChange={handleInputChange}
            placeholder="Your answer"
          />
        </div>
  </>
);

export default Section38;
