import React from 'react';


// Define individual components for each option
const EventComponent = ({ formData, handleInputChange }) => (
  <div className="form-section flyer-section">
    <div className="flyer-header">Event</div>
    <label className="required-asterisk">What Event Did You Attend and See Us At? (Ex. Floresville Health Fair on 7/19/22)</label>
    <input
      className='long-text'
      type="text"
      name="event_hear_abt_us"
      value={formData.event_hear_abt_us || ''}
      onChange={handleInputChange}
      required
    />
  </div>
);

const FacebookComponent = ({ formData, handleInputChange }) => (
  <div className="form-section flyer-section">
    <div className="flyer-header">Facebook</div>
    <label className="required-asterisk">How or Where on Facebook Did You See Us?  (ex. Facebook Live yesterday, FB Ad about Chip, FB Link from friend, FB Post about changes - 7/12)</label>
    <input
      className='long-text'
      type="text"
      name="facebook_hear_abt_us"
      value={formData.facebook_hear_abt_us}
      onChange={handleInputChange}
      placeholder="Your answer"
      required
    />
  </div>
);

const OtherComponent = ({ formData, handleInputChange }) => (
  <div className="form-section flyer-section">
    <div className="flyer-header">Other</div>
    <label>Other Not Listed:</label>
    <input
      type="text"
      name="hear_from_us_other"
      value={formData.hear_from_us_other || ''}
      onChange={handleInputChange}
    />
  </div>
);

const InstagramComponent = ({ formData, handleInputChange }) => (
  <div className="form-section flyer-section">
    <div className="flyer-header">Instagram</div>
    <label className="required-asterisk">How or Where on Instagram Did You See Us?  (ex.   Instagram Live yesterday,  Instagram Ad about Chip,   Instagram Link from friend, Instagram Post about changes - 7/12)</label>
    <input
      type="text"
      name="insta_hear_abt_us"
      value={formData.insta_hear_abt_us}
      onChange={handleInputChange}
      placeholder="Short answer text"
      required
    />
  </div>
);

const SAC_Component = ({ formData, handleInputChange }) => (
  <div className="form-section flyer-section">
    <div className="flyer-header">SAC</div>
    <label className="required-asterisk">SAC - San Antonio College (Ex.  Event 7/12, Flyer at Student Services, Teacher - Ms. Z, Staff Mr. Lopez)</label>
    <input
      type="text"
      name="SAC_hear_abt_us"
      value={formData.SAC_hear_abt_us}
      onChange={handleInputChange}
      placeholder="Your answer"
      required
    />
  </div>
);

const TwitterComponent = ({ formData, handleInputChange }) => (
  <div className="form-section flyer-section">
    <div className="flyer-header">Twitter</div>
    <label className="required-asterisk">How or Where on Twitter Did You See Us?  (ex.  Twitter Live yesterday,  Twitter Ad about Chip,  Twitter Link from friend,  Twitter Post about changes - 7/12)</label>
    <input
      className='long-text'
      type="text"
      name="twitter_hear_abt_us"
      value={formData.twitter_hear_abt_us}
      onChange={handleInputChange}
      placeholder="Your answer"
      required
    />
  </div>
);

const TVComponent = ({ formData, handleInputChange }) => (
  <div className="form-section flyer-section">
    <div className="flyer-header">TV</div>
    <label className="required-asterisk">What TV Station Did You See Us In? (Ex. Univision - Phone Bank 7/21, KSAT - News 7/1, Telemundo - Commercial 7/2)</label>
    <input
      className='long-text'
      type="text"
      name="tv_hear_abt_us"
      value={formData.tv_hear_abt_us}
      onChange={handleInputChange}
      placeholder="Your answer"
      required
    />
  </div>
);

const HospitalComponent = ({ formData, handleInputChange }) => (
  <div className="form-section flyer-section">
    <div className="flyer-header">Hospital</div>
    <label className="required-asterisk">Which Hospital Did You See Us At? (Baptist Mission Trails, Cuero Regional, MHM -Floyd Curl)</label>
    <input
      type="text"
      name="hospital_hear_abt_us"
      value={formData.hospital_hear_abt_us}
      onChange={handleInputChange}
      placeholder="Your answer"
      required
    />
  </div>
);

const LibraryComponent = ({ formData, handleInputChange }) => (
  <div className="form-section flyer-section">
    <div className="flyer-header">Library</div>
    <label className="required-asterisk">Which Library Did You See Us At?</label>
    <select
      name="library_opt"
      value={formData.library_opt}
      onChange={handleInputChange}
      required
    >
      <option value="" disabled>Select library</option>
      <option value="Atascosa">Atascosa</option>
      <option value="Bazan">Bazan</option>
      <option value="BiblioTech - Pleasanton">BiblioTech - Pleasanton</option>
      <option value="BiblioTech - Zarzamora">BiblioTech - Zarzamora</option>
      <option value="BiblioTech East">BiblioTech East</option>
      <option value="Brook Hollow">Brook Hollow</option>
      <option value="Carver">Carver</option>
      <option value="Central - DownTown - SA">Central - DownTown - SA</option>
      <option value="Collins">Collins</option>
      <option value="Cody">Cody</option>
      <option value="Cortez">Cortez</option>
      <option value="DeWitt">DeWitt</option>
      <option value="Encino">Encino</option>
      <option value="Floresville Library">Floresville Library</option>
      <option value="Forest Hills">Forest Hills</option>
      <option value="Great Northwest">Great Northwest</option>
      <option value="Guerra">Guerra</option>
      <option value="Igo">Igo</option>
      <option value="Johnston">Johnston</option>
      <option value="Kampmann">Kampmann</option>
      <option value="Karnes">Karnes</option>
      <option value="Landa">Landa</option>
      <option value="Las Palmas">Las Palmas</option>
      <option value="Maverick">Maverick</option>
      <option value="McCreless">McCreless</option>
      <option value="Memorial">Memorial</option>
      <option value="Mission">Mission</option>
      <option value="Pan American">Pan American</option>
      <option value="Parman">Parman</option>
      <option value="Poteet Library">Poteet Library</option>
      <option value="Potranco">Potranco</option>
      <option value="Pruitt">Pruitt</option>
      <option value="San Pedro Library">San Pedro Library</option>
      <option value="Schaefer">Schaefer</option>
      <option value="Semmes">Semmes</option>
      <option value="Texana">Texana</option>
      <option value="Thousand Oaks">Thousand Oaks</option>
      <option value="Tobin">Tobin</option>
      <option value="Westfall">Westfall</option>
      <option value="Wilson">Wilson</option>
      <option value="Other:">Other:</option>
    </select>
  </div>
);

const WorkforceComponent = ({ formData, handleInputChange }) => (
  <div className="form-section flyer-section">
    <div className="flyer-header">Workforce</div>
    <label className="required-asterisk">Which Workforce and When? (Ex. Medical 7/20 -(same as Datapoint), Marbach 7/1, Flores 7/10)</label>
    <input
      type="text"
      name="workforce_hear_abt_us"
      value={formData.workforce_hear_abt_us}
      onChange={handleInputChange}
      placeholder="Your answer"
      required
    />
  </div>
);

const TablePresentationComponent = ({ formData, handleInputChange }) => (
  <div className="form-section flyer-section">
    <div className="flyer-header">TablePresentation</div>
    <label className="required-asterisk">What Table Presentation Did You Attend? (Ex. Community Event at Madonna 7/21 or Parent Presentation at Lanier 7/1/22)</label>
    <input
      type="text"
      name="tablepresentation_hear_abt_us"
      value={formData.tablepresentation_hear_abt_us}
      onChange={handleInputChange}
      placeholder="Your answer"
      required
    />
  </div>
);

const ReferralComponent = ({ formData, handleInputChange }) => (
  <div className="form-section flyer-section">
    <div className="flyer-header">Referral</div>
    <label className="required-asterisk">Who Referred You?</label>
    <input
      type="text"
      name="referral_hear_abt_us"
      value={formData.referral_hear_abt_us}
      onChange={handleInputChange}
      placeholder="Your answer"
      required
    />
  </div>
);

const NewspaperComponent = ({ formData, handleInputChange }) => (
  <div className="form-section flyer-section">
    <div className="flyer-header">Newspaper</div>
    <label className="required-asterisk">What Newspaper did you see our ad on? (Ex. San Antonio  Express, Karnes Daily...)</label>
    <input
      type="text"
      name="newspaper_hear_abt_us"
      value={formData.newspaper_hear_abt_us}
      onChange={handleInputChange}
      placeholder="Your answer"
      required
    />
  </div>
);

const RadioComponent = ({ formData, handleInputChange }) => (
  <div className="form-section flyer-section">
    <div className="flyer-header">Radio</div>
    <label className="required-asterisk">Which Radio Station Did You Here US? (Ex. La Nuestra or Tejano - in San Antonio or Karnes)</label>
    <input
      type="text"
      name="radio_hear_abt_us"
      value={formData.radio_hear_abt_us}
      onChange={handleInputChange}
      placeholder="Your answer"
      required
    />
  </div>
);

const FlyerComponent = ({ formData, handleInputChange }) => (
  <div className="form-section flyer-section">
    <div className="flyer-header">Flyer</div>
    <label className="required-asterisk">Where Did You Find or See Our Flyer? (Ex. MAUC, WSA, Culebra, Cody Library)</label>
    <input
      type="text"
      name="flyer_hear_abt_us"
      value={formData.flyer_hear_abt_us}
      onChange={handleInputChange}
      placeholder="Your answer"
      required
    />
  </div>
);

const MACComponent = ({ formData, handleInputChange }) => (
  <div className="form-section flyer-section">
    <div className="flyer-header">MAC</div>
  </div>
);

// Main section component that conditionally renders based on the selected value
const HearFromUsSection = ({ formData, handleInputChange }) => {
  switch (formData.hear_from_us) {
    //Section 40 - 54
    case 'Event':
      return <EventComponent formData={formData} handleInputChange={handleInputChange} />;
    case 'Facebook':
      return <FacebookComponent formData={formData} handleInputChange={handleInputChange} />;
    case 'Instagram':
      return <InstagramComponent formData={formData} handleInputChange={handleInputChange} />;
    case 'SAC':
      return <SAC_Component formData={formData} handleInputChange={handleInputChange} />;
    case 'Twitter':
      return <TwitterComponent formData={formData} handleInputChange={handleInputChange} />;
    case 'TV':
      return <TVComponent formData={formData} handleInputChange={handleInputChange} />;
    case 'Hospital':
      return <HospitalComponent formData={formData} handleInputChange={handleInputChange} />;
    case 'Library':
      return <LibraryComponent formData={formData} handleInputChange={handleInputChange} />;
    case 'Workforce':
      return <WorkforceComponent formData={formData} handleInputChange={handleInputChange} />;
    case 'TablePresentation':
      return <TablePresentationComponent formData={formData} handleInputChange={handleInputChange} />;
    case 'Referral':
      return <ReferralComponent formData={formData} handleInputChange={handleInputChange} />;
    case 'Newspaper':
      return <NewspaperComponent formData={formData} handleInputChange={handleInputChange} />;
    case 'Radio':
      return <RadioComponent formData={formData} handleInputChange={handleInputChange} />;
    case 'Flyer':
      return <FlyerComponent formData={formData} handleInputChange={handleInputChange} />;
    case 'MAC':
        return <MACComponent formData={formData} handleInputChange={handleInputChange} />;

    // Add cases for other components
    case 'Other':
      return <OtherComponent formData={formData} handleInputChange={handleInputChange} />;
    default:
      return null;
  }
};

export default HearFromUsSection;
