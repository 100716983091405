import React from 'react';

const Section55 = ({ formData, handleInputChange }) => (
  <>
    {/* Section 55 */}
    <div className="form-section flyer-section">
      <div className="flyer-header">Where Do You Live?</div>
      <label className="required-asterisk">County</label>
      <select
        name="county2"
        value={formData.county2}
        onChange={handleInputChange}
        required
      >
        <option value="" disabled>County</option>
        <option value="Atascosa">Atascosa</option>
        <option value="Bandera">Bandera</option>
        <option value="Bee">Bee</option>
        <option value="Brooks">Brooks</option>
        <option value="Bexar">Bexar</option>
        <option value="Caldwell">Caldwell</option>
        <option value="Cameron">Cameron</option>
        <option value="Comal">Comal</option>
        <option value="DeWitt">DeWitt</option>
        <option value="Frio">Frio</option>
        <option value="Goliad">Goliad</option>
        <option value="Gonzalez">Gonzalez</option>
        <option value="Guadalupe">Guadalupe</option>
        <option value="Hays">Hays</option>
        <option value="Hidalgo">Hidalgo</option>
        <option value="Jim Hogg">Jim Hogg</option>
        <option value="Jim Wells">Jim Wells</option>
        <option value="Karnes">Karnes</option>
        <option value="Kleberg">Kleberg</option>
        <option value="La Salle">La Salle</option>
        <option value="Medina">Medina</option>
        <option value="Maverick">Maverick</option>
        <option value="Nueces">Nueces</option>
        <option value="San Patricio">San Patricio</option>
        <option value="Uvalde">Uvalde</option>
        <option value="Wilson">Wilson</option>
        <option value="Other:">Other:</option>
      </select>
      {formData.county2 === 'Other:' && (
        <div>
          <label>Please specify:</label>
          <input
            type="text"
            name="other_county"
            value={formData.other_county}
            onChange={handleInputChange}
            required
          />
        </div>
      )}
    </div>
    <div className='form-section'>
      <label className="required-asterisk">Zip Code</label>
      <input
        type='number'
        name='zip_code'
        value={formData.zip_code}
        onChange={handleInputChange}
        placeholder="Your answer"
        min="0"
        required
      />
    </div>
  </>
);

export default Section55;
