import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { listHeadsets, listUsers } from '../../../graphql/queries';
import { createHeadset, deleteHeadset, updateHeadset } from '../../../graphql/mutations';
import './Headset.css';
import XLSX from 'xlsx-js-style';
import { v4 as uuidv4 } from 'uuid';
import { FaFileExcel } from 'react-icons/fa';
import Inventory_header from '../../../components/layout/Inventory_header';

const Headset = () => {
    const [headset, setHeadset] = useState([]);
    const [nextToken, setNextToken] = useState(null);
    const [showHeadsetAddOverlay, setShowHeadsetAddOverlay] = useState(false);
    const [selectedHeadset, setSelectedHeadset] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    const [newHeadset, setNewHeadset] = useState({
        id: '',
        tagid: '',
        brand: '',
        serial_Number: '',
        charger_cable: '',
        microphone: '',
        aux_cable: '',
        price: '',
        store: '',
        purchase_date: '',
        assignment_date: '',
        warranty_date: '',
        returnable_date: '',
        available: '',
        status: '',
        assigned_to: '',
        location: '',
        team: '',

    });
    const [sortConfig, setSortConfig] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchField, setSearchField] = useState('id');
    const [duplicateError, setDuplicateError] = useState('');
    const [priceError, setPriceError] = useState('');
    const [statusError, setStatusError] = useState('');
    const { loading1, error: userError, data: userData } = useQuery(listUsers);


    const handleSaveAsExcel = async () => {
        try {
            const headsetToExport = filteredHeadsets.length > 0 ? filteredHeadsets : headset;
            if (headsetToExport.length > 0) {
                // Sort the Headsets by Timestamp in ascending order (earliest first)
                const sortedHeadset = [...headsetToExport].sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp));

                // Prepare the data for Excel export with custom column titles
                const worksheetData = sortedHeadset.map((headset,index) => ({
                    '#': index + 1, // Agregar el número de fila
                    'TAG:': headset.tagid,
                    'Assigned to:': headset.assigned_to,
                    'Available:': headset.available ? 'Yes' : 'No',
                    'Serial Number:': headset.serial_Number,
                    'Status:': headset.status,
                    'Team:': headset.team,
                    'Location:': headset.location,
                    // Add other fields as needed

                }));

                // Convert JSON to sheet starting from row 2
                const worksheet = XLSX.utils.json_to_sheet([]);
                let endCol = 0;

                XLSX.utils.sheet_add_aoa(worksheet, [['Headsets']], { origin: 'A1' });

                if (worksheetData.length > 0) {
                    // Agrega los datos a partir de la fila A2
                    XLSX.utils.sheet_add_json(worksheet, worksheetData, { origin: 'A2', skipHeader: false });

                    // Define el rango de referencia (!ref)
                    const endRow = worksheetData.length + 1; // +1 para el título en A1
                    const endCol = Object.keys(worksheetData[0]).length - 1;
                    worksheet['!ref'] = XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: endRow, c: endCol } });
                } else {
                    console.error("No hay datos para exportar.");
                    return;
                }

                // Merge title cells across the columns
                worksheet['!merges'] = worksheet['!merges'] || [];
                worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 7 } }); // A1 to H1 for the added column

                // Style the title row
                worksheet['A1'].s = {
                    font: {
                        bold: true,
                        sz: 20, // Font size
                        color: { rgb: '1F4E79' } // Dark blue text color
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center'
                    }
                };

                // Apply styling for the header row (now starting at row 2)
                const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
                for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                    const cellAddress = XLSX.utils.encode_cell({ r: 1, c: col }); // Header row is now row 1
                    if (!worksheet[cellAddress]) continue;
                    worksheet[cellAddress].s = {
                        fill: {
                            fgColor: { rgb: '1F4E79' } // Dark blue background
                        },
                        font: {
                            bold: true,
                            color: { rgb: 'FFFFFF' } // White text
                        },
                        alignment: {
                            horizontal: 'center'
                        },
                        border: {
                            top: { style: 'thin', color: { rgb: '000000' } },
                            left: { style: 'thin', color: { rgb: '000000' } },
                            bottom: { style: 'thin', color: { rgb: '000000' } },
                            right: { style: 'thin', color: { rgb: '000000' } },
                        }
                    };
                }

                // Apply alternating row colors and borders starting from row 3
                for (let row = 2; row <= headerRange.e.r; row++) {
                    const isEvenRow = row % 2 === 0;
                    for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
                        if (!worksheet[cellAddress]) continue;

                        worksheet[cellAddress].s = {
                            fill: {
                                fgColor: { rgb: isEvenRow ? 'D3D3D3' : 'FFFFFF' } // Light gray for even rows, white for odd rows
                            },
                            border: {
                                top: { style: 'thin', color: { rgb: '000000' } },
                                left: { style: 'thin', color: { rgb: '000000' } },
                                bottom: { style: 'thin', color: { rgb: '000000' } },
                                right: { style: 'thin', color: { rgb: '000000' } },
                            },
                            alignment: {
                                horizontal: 'center'
                            }
                        };
                    }
                }
                worksheet['!cols'] = [
                    { wch: 5 },
                    { wch: 25 }, // Adjust width for each column
                    { wch: 15 },
                    { wch: 10 },
                    { wch: 15 },
                    { wch: 15 },
                    { wch: 5 },
                    { wch: 25 }
                ];
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Headsets');

                // Write file
                XLSX.writeFile(workbook, 'HeadsetsData.xlsx');
            } else {
                console.error('No data to export');
            }
        } catch (error) {
            console.error('Error exporting data to Excel:', error);
        }
    };

    const handleEditHeadset = (scanner) => {
        setSelectedHeadset(scanner);
        setIsEditing(true);
        setShowHeadsetAddOverlay(true);
    };

    const { loading, data, fetchMore } = useQuery(listHeadsets, {
        variables: { limit: 100 },
    });

    const [addHeadset] = useMutation(createHeadset, {
        refetchQueries: [{ query: listHeadsets, variables: { limit: 100 } }]
    });

    const [removeHeadset] = useMutation(deleteHeadset, {
        refetchQueries: [{ query: listHeadsets, variables: { limit: 100 } }]
    });
    const [updHeadset] = useMutation(updateHeadset, {
        refetchQueries: [{ query: listHeadsets, variables: { limit: 100 } }]
    });

    const [fetchAllHeadsets, { loading: countLoading, data: AllHeadsetsData }] = useLazyQuery(listHeadsets, {
        variables: { limit: 100 },
        fetchPolicy: "network-only",
    });


    useEffect(() => {
        const fetchAllHeadsets = async () => {
            let AllHeadsets = [];
            let currentNextToken = null;

            do {
                const { data } = await fetchMore({
                    variables: { limit: 100, nextToken: currentNextToken },
                });

                AllHeadsets = [...AllHeadsets, ...data.listHeadsets.items];
                currentNextToken = data.listHeadsets.nextToken;
            } while (currentNextToken);

            setHeadset(AllHeadsets);
            setNextToken(currentNextToken);
        };

        if (data) {
            fetchAllHeadsets();
        }
    }, [data, fetchMore]);

    const clearNewHeadset = () => {
        setNewHeadset({
            id: '',
            tagid: '',
            brand: '',
            serial_Number: '',
            charger_cable: '',
            microphone: '',
            aux_cable: '',
            price: '',
            store: '',
            purchase_date: '',
            assignment_date: '',
            warranty_date: '',
            returnable_date: '',
            available: '',
            status: '',
            assigned_to: '',
            location: '',
            team: '',
        });
    };

    // Define loadMore function
    const loadMore = () => {
        fetchMore({
            variables: { limit: 100, nextToken },
            updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;
                setNextToken(fetchMoreResult.listHeadsets.nextToken);
                return {
                    listHeadsets: {
                        ...fetchMoreResult.listHeadsets,
                        items: [...prevResult.listHeadsets.items, ...fetchMoreResult.listHeadsets.items]
                    }
                };
            }
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let formattedValue = value;

        // Convert to lowercase and trim whitespace for specific fields
        if (['serial_Number'].includes(name)) {
            formattedValue = value.toLowerCase().trim();
        }

        if (isEditing) {
            setSelectedHeadset({
                ...selectedHeadset,
                [name]: formattedValue
            });
        } else {
            setNewHeadset({
                ...newHeadset,
                id: uuidv4(),
                [name]: formattedValue
            });
        }
    };

    const handleAddHeadset = () => {

        // Formatear la fecha en el formato YYYY-MM-DD si existe, o establecerla en null si está vacía
        const formattedPurchase = newHeadset.purchase_date
            ? new Date(newHeadset.purchase_date).toISOString().split('T')[0]
            : null;
        const formattedAssignment = newHeadset.assignment_date
            ? new Date(newHeadset.assignment_date).toISOString().split('T')[0]
            : null;

        const formattedWarranty = newHeadset.warranty_date
            ? new Date(newHeadset.warranty_date).toISOString().split('T')[0]
            : null;

        const formattedReturnable = newHeadset.returnable_date
            ? new Date(newHeadset.returnable_date).toISOString().split('T')[0]
            : null;

        // Validate that the price contains only digits and is a valid number
        let priceValue = null;

        if (newHeadset.price !== "" && newHeadset.price !== null && newHeadset.price != undefined) {
            priceValue = parseFloat(newHeadset.price);

            if (isNaN(priceValue)) {
                setPriceError('Price must be a valid number.');
                return;
            }
        }
        setPriceError('');


        // Check for duplicates based on `tagid` and `serial_Number`
        const isDuplicate = headset.some(headset =>
            headset.tagid === newHeadset.tagid &&
            headset.serial_Number === newHeadset.serial_Number
        );

        if (isDuplicate) {
            setDuplicateError('A headset with the same TAG and Serial Number already exists.');
            console.log('Duplicate validation failed');
            return;
        } else {
            setDuplicateError('');
        }
        // Check availability first
        if (newHeadset.available === 'true') {

            // If available, status must be Inventario
            if (newHeadset.status !== 'inv') {
                setStatusError('If the Headset is available, its status must be "Inventario".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // If available and status is Inventario, assigned_to must be empty or "N/A"
            if (newHeadset.assigned_to !== '' && newHeadset.assigned_to !== 'n/a') {
                setStatusError('If the Headset is available and its status is "Inventario", the "Assigned to" field must be empty or "N/A".');
                console.log('Assigned_to validation failed');
                return; // Exit function if validation fails
            }

        } else if (newHeadset.available !== "") {
            // If not available, status must be one of the allowed statuses
            const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
            if (!allowedStatuses.includes(newHeadset.status)) {
                setStatusError('If the Headset is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // Clear error if validation passes
            setStatusError('');
        }

        // If all validations pass, proceed with adding the headset to the database
        addHeadset({
            variables: { input: { ...newHeadset, price: priceValue, purchase_date: formattedPurchase, warranty_date: formattedWarranty, returnable_date: formattedReturnable, assignment_date: formattedAssignment } }
        })
            .then(() => {
                window.location.reload();
            }).catch(error => {
                console.error("Error adding headset:", error);
            });
    };

    const handleDeleteHeadset = () => {

        removeHeadset({
            variables: { input: { id: selectedHeadset.id } }
        }).then(() => {
            window.location.reload();
        }).catch(error => {
            console.error("Error deleting Headset", error);
        });

    };

    const handleUpdateHeadset = async () => {

        const formattedData_purchase = selectedHeadset.purchase_date ? new Date(selectedHeadset.purchase_date).toISOString().split('T')[0] : null;
        const formattedData_warranty = selectedHeadset.warranty_date ? new Date(selectedHeadset.warranty_date).toISOString().split('T')[0] : null;
        const formattedData_returnable = selectedHeadset.returnable_date ? new Date(selectedHeadset.returnable_date).toISOString().split('T')[0] : null;
        const formattedData_assignment = selectedHeadset.assignment_date ? new Date(selectedHeadset.assignment_date).toISOString().split('T')[0] : null;

        //Check Price
        let priceValue = null;
        if (selectedHeadset.price !== "" && selectedHeadset.price !== null && selectedHeadset.price != undefined) {
            priceValue = parseFloat(selectedHeadset.price);

            if (isNaN(priceValue)) {
                setPriceError('Price must be a valid number.');
                return;
            }
        }
        setPriceError('');

        // Check availability first
        if (selectedHeadset.available === 'true') {

            console.log(selectedHeadset.available);

            // If available, status must be Inventario
            if (selectedHeadset.status !== 'inv') {
                setStatusError('If the Headset is available, its status must be "Inventario".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // If available and status is Inventario, assigned_to must be empty or "N/A"
            if (selectedHeadset.assigned_to !== '' && selectedHeadset.assigned_to !== 'n/a') {
                setStatusError('If the Headset is available and its status is "Inventario", the "Assigned to" field must be empty or "N/A".');
                console.log('Assigned_to validation failed');
                return; // Exit function if validation fails
            }

        } else if (selectedHeadset.available === 'false') {
            // If not available, status must be one of the allowed statuses
            const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
            if (!allowedStatuses.includes(selectedHeadset.status)) {
                setStatusError('If the Headset is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // Clear error if validation passes
            setStatusError('');
        }


        updHeadset({
            variables: {
                input: {
                    id: selectedHeadset.id,
                    tagid: selectedHeadset.tagid,
                    brand: selectedHeadset.brand,
                    serial_Number: selectedHeadset.serial_Number,
                    charger_cable: selectedHeadset.charger_cable,
                    microphone: selectedHeadset.microphone,
                    aux_cable: selectedHeadset.aux,
                    price: priceValue,
                    store: selectedHeadset.store,
                    purchase_date: formattedData_purchase,
                    assignment_date: formattedData_assignment,
                    warranty_date: formattedData_warranty,
                    returnable_date: formattedData_returnable,
                    available: selectedHeadset.available,
                    status: selectedHeadset.status,
                    assigned_to: selectedHeadset.assigned_to,
                    location: selectedHeadset.location,
                    team: selectedHeadset.team,

                }
            }
        }).then(() => {
            setPriceError('');
            setStatusError('');
            setDuplicateError('');
            setShowHeadsetAddOverlay(false);
        }).catch(error => {
            console.error("Error updating Headset:", error);
        });
    };

    const handleSelectHeadset = (headset) => {

        for (const [key, value] of Object.entries(headset)) {
            console.log(`${key}: ${typeof value}`);
        }
        const formatDateForInput = (dateString) => {
            if (!dateString) return ''; // Return empty string if the date is not provided
            const date = new Date(dateString);
            return date.toISOString().split('T')[0]; // Convert to YYYY-MM-DD format
        };

        setNewHeadset({
            id: headset.id,
            tagid: headset.tagid,
            brand: headset.brand,
            serial_Number: headset.serial_Number,
            charger_cable: headset.charger_cable,
            microphone: headset.microphone,
            aux_cable: headset.aux,
            price: headset.price,
            store: headset.store,
            purchase_date: formatDateForInput(headset.purchase_date),
            assignment_date: formatDateForInput(headset.assignment_date),
            warranty_date: formatDateForInput(headset.warranty_date),
            returnable_date: formatDateForInput(headset.returnable_date),
            available: headset.available,
            status: headset.status,
            assigned_to: headset.assigned_to,
            location: headset.location,
            team: headset.team,

        });
        setShowHeadsetAddOverlay(true);
        setIsEditing(true);
        setSelectedHeadset(headset);
    };

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedHeadsets = React.useMemo(() => {
        let sortableHeadsets = [...headset];
        if (sortConfig !== null) {
            sortableHeadsets.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableHeadsets;
    }, [headset, sortConfig]);

    //listUsers 
    const users = React.useMemo(() => {
        if (userData && userData.listUsers) {
            return userData.listUsers.items || [];
        }
        return [];
    }, [userData]);
    if (userError) return <p>Error loading users: {userError.message}</p>;
    if (loading1) return <p>Error loading users: {userError.message}</p>;

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredHeadsets = sortedHeadsets.filter(headset => {

        if (headset[searchField] == null) return false; // O puedes decidir cómo manejarlo

        let fieldValue = headset[searchField].toString().toLowerCase();

        // Convertir "true" a "yes" y "false" a "no"
        if (fieldValue === "true") {
            fieldValue = "yes";
        } else if (fieldValue === "false") {
            fieldValue = "no";
        }

        return fieldValue.includes(searchQuery.toLowerCase());
    });

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(dateString);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

        return localDate.toLocaleDateString(undefined, options);
    };

    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };
    const formatPrice = (price) => {
        if (price === null || price === undefined || price === '') {
            return '';
        }
        const parsedPrice = parseFloat(price);
        return isNaN(parsedPrice) ? '' : parsedPrice.toFixed(2);
    };


    return (
        <div className="headset-page">
            <header className="inventory-header">
                <h1>Headset</h1>
            </header>
        <Inventory_header/>
            <div className="inventory-search">
                <button onClick={handleSaveAsExcel}> <FaFileExcel /> Export </button>

                <input
                    type="text"
                    placeholder=" Search by TAG, Available, Brand, Assigned to"
                    value={searchQuery}
                    onChange={handleSearch}
                />
                <select onChange={(e) => setSearchField(e.target.value)} defaultValue="">
                    <option value="" disabled hidden>Select Option</option>
                    <option value="tagid">TAG</option>
                    <option value="status">Status</option>
                    <option value="available">Available</option>
                    <option value="brand">Brand</option>
                    <option value="serial_Number">Serial Number</option>
                    <option value="store">Store</option>
                    <option value="assigned_to">Assigned to</option>
                </select>
            </div>


            <button className="add-headset-btn" onClick={() => {
                clearNewHeadset();
                setSelectedHeadset(null);
                setIsEditing(false);
                setShowHeadsetAddOverlay(true);
            }}>+ Add New Headset</button>

            <div className="headset-table-container">
                <table className="headset-table">
                    <thead>
                        <tr>
                            <th>#</th> {/* Columna para contar las filas */}

                            <th onClick={() => requestSort('tagid')}>
                                TAG
                                {sortConfig?.key === 'tagid' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('brand')}>
                                Brand
                                {sortConfig?.key === 'brand' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('serial_Number')}>
                                Serial Number
                                {sortConfig?.key === 'serial_Number' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('charger_cable')}>
                                Charger Cable
                                {sortConfig?.key === 'charger_cable' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('microphone')}>
                                Microphone
                                {sortConfig?.key === 'microphone' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('aux_cable')}>
                                Aux Cable
                                {sortConfig?.key === 'aux_cable' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('price')}>
                                Price
                                {sortConfig?.key === 'price' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('store')}>
                                Store
                                {sortConfig?.key === 'store' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('purchase_date')}>
                                Purchase Date
                                {sortConfig?.key === 'purchase_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('assignment_date')}>
                                Assignment Date
                                {sortConfig?.key === 'assignment_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('warranty_date')}>
                                Warranty Date
                                {sortConfig?.key === 'warranty_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('returnable_date')}>
                                Returnable Date
                                {sortConfig?.key === 'returnable_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('available')}>
                                Available
                                {sortConfig?.key === 'available' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('status')}>
                                Status
                                {sortConfig?.key === 'status' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('assigned_to')}>
                                Assigned To
                                {sortConfig?.key === 'assigned_to' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('location')}>
                                Location
                                {sortConfig?.key === 'location' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('team')}>
                                Team
                                {sortConfig?.key === 'team' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th >Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredHeadsets.map((headset, index) => (
                            <tr key={headset.id} onClick={() => handleEditHeadset(headset)} >
                                <td>{index + 1}</td> {/* Número de fila */}
                                <td className="headset-tagid">{capitalize(headset.tagid)}</td>
                                <td className="headset-brand">{capitalize(headset.brand.toLowerCase())}</td>
                                <td className="headset-serial_Number">{headset.serial_Number.toUpperCase()}</td>
                                <td className="headset-charger_cable">{headset.charger_cable === null || headset.charger_cable === '' ? 'Yes' : 'No'} </td>
                                <td className="headset-microphone">{headset.microphone === null || headset.microphone === '' ? 'Yes' : 'No'} </td>
                                <td className="headset-aux_cable">{headset.aux_cable === null || headset.aux_cable === '' ? 'Yes' : 'No'} </td>
                                <td className="headset-price">${formatPrice(headset.price)}</td>
                                <td className="headset-store">{headset.store}</td>
                                <td className="headset-purchase_date">{headset.purchase_date ? formatDate(headset.purchase_date) : 'No Date'}</td>
                                <td className="headset-assignment_date">{headset.assignment_date ? formatDate(headset.assignment_date) : 'No Date'}</td>
                                <td className="headset-warranty_date">{headset.warranty_date ? formatDate(headset.warranty_date) : 'No Date'}</td>
                                <td className="headset-returnable_date">{headset.returnable_date ? formatDate(headset.returnable_date) : 'No Date'}</td>
                                <td className="headset-available">{headset.available ? 'Yes' : 'No'} </td>
                                <td className="headset-status">
                                    {headset.status === 'broken' ? 'Broken' :
                                        headset.status === 'use' ? 'Use' :
                                            headset.status === 'transfer' ? 'Transfer' :
                                                headset.status === 'lost' ? 'Lost' :
                                                    headset.status === 'inv' ? 'Inventario' :
                                                        'Unknown'} {/* Muestra 'Unknown' si el estado no coincide con ninguno de los anteriores */}
                                </td>
                                <td className="headset-assigned_to">{headset.assigned_to}</td>
                                <td className="headset-location">{headset.location}</td>
                                <td className="headset-team">{headset.team}</td>
                                <td className='headset-table'>
                                    <button className="delete-headset-btn" onClick={() => handleSelectHeadset(headset)}>Delete/Update</button>

                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {nextToken && !loading && (
                <button onClick={loadMore}>Load More</button>
            )}


            {loading && <p>Loading...</p>}

            {showHeadsetAddOverlay && (

                <div className="headset_add_overlay">
                    <div className="overlay-content-inventory">
                        <div className="header-row">
                            <h2 className="header-title">{isEditing ? 'Edit Headset' : 'Add New Headset'}</h2>
                            <button onClick={() => {
                                setShowHeadsetAddOverlay(false);
                                clearNewHeadset();
                                setSelectedHeadset(null);
                                setIsEditing(false);
                            }}>X</button>
                        </div>

                        <label>TAG:</label><input name="tagid" placeholder="TAG" value={isEditing ? selectedHeadset.tagid : newHeadset.tagid} onChange={handleInputChange} required />

                        <label>Brand:</label><input name="brand" placeholder="Brand" value={isEditing ? selectedHeadset.brand : newHeadset.brand} onChange={handleInputChange} required />
                        <label>Serial Number:</label><input name="serial_Number" placeholder="Serial Number" value={isEditing ? selectedHeadset.serial_Number : newHeadset.serial_Number} onChange={handleInputChange} />

                        <label>Charger Cable:</label>

                        <select name="charger_cable" placeholder="charger_cable" value={isEditing ? selectedHeadset.charger_cable : newHeadset.charger_cable} onChange={handleInputChange} >
                            <option value="" disabled hidden className="placeholder-option">Select Y/N</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>

                        <label>Microphone: </label>
                        <select name="microphone" placeholder="microphone" value={isEditing ? selectedHeadset.microphone : newHeadset.microphone} onChange={handleInputChange} >
                            <option value="" disabled hidden className="placeholder-option">Select Y/N</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>

                        </select >

                        <label>Aux Cable:</label>
                        <select name="aux_cable" placeholder="aux_cable" value={isEditing ? selectedHeadset.aux_cable : newHeadset.aux_cable} onChange={handleInputChange} >
                            <option value="" disabled hidden className="placeholder-option">Select Y/N</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>

                        <label>Price:</label><input name="price" placeholder="price" value={isEditing ? selectedHeadset.price : newHeadset.price} onChange={handleInputChange} />
                        <label>Store:</label><input name="store" placeholder="store" value={isEditing ? selectedHeadset.store : newHeadset.store} onChange={handleInputChange} />

                        <label htmlFor="purchase_date">Purchase Date:</label>
                        <input
                            type="Date"
                            id="purchase_date"
                            name="purchase_date"
                            value={isEditing ? selectedHeadset.purchase_date : newHeadset.purchase_date}
                            onChange={handleInputChange}

                        />

                        <label htmlFor="assignment_date">Assignment Date:</label>
                        <input
                            type="date"
                            id="assignment_date"
                            name="assignment_date"
                            value={isEditing ? selectedHeadset.assignment_date : newHeadset.assignment_date}
                            onChange={handleInputChange}

                        />
                        <label htmlFor="warranty_date">Warranty Date:</label>
                        <input
                            type="date"
                            id="warranty_date"
                            name="warranty_date"
                            value={isEditing ? selectedHeadset.warranty_date : newHeadset.warranty_date}
                            onChange={handleInputChange}


                        />
                        <label htmlFor="returnable_date">Returnable Date:</label>
                        <input
                            type="date"
                            id="returnable_date"
                            name="returnable_date"
                            value={isEditing ? selectedHeadset.returnable_date : newHeadset.returnable_date}
                            onChange={handleInputChange}
                            reuired
                        />

                        <label>Available:</label>

                        <select name="available" placeholder="available" value={isEditing ? selectedHeadset.available : newHeadset.available} onChange={handleInputChange} >
                            <option value="" disabled hidden className="placeholder-option">Select Y/N</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>

                        </select>
                        <label>Status:</label>
                        <select name="status" placeholder="status" value={isEditing ? selectedHeadset.status : newHeadset.status} onChange={handleInputChange} >
                            <option value="" disabled hidden>Select Status</option>
                            <option value="use">Use</option>
                            <option value="broken">Broken</option>
                            <option value="transfer">Transfer</option>
                            <option value="lost">Lost</option>
                            <option value="inv">Inventario</option>
                        </select>
                        <label>Assigned To:</label>
                        <select
                            name="assigned_to"
                            value={isEditing ? selectedHeadset.assigned_to : newHeadset.assigned_to}
                            onChange={handleInputChange}
                        >
                            <option value="">Select an Employee</option>

                            {users.length > 0 ? (
                                [...users].sort((a, b) => {
                                    const nameA = `${a.first_name} ${a.last_name}`.toLowerCase();
                                    const nameB = `${b.first_name} ${b.last_name}`.toLowerCase();
                                    return nameA.localeCompare(nameB);
                                }).map(user => (
                                    <option key={user.id} value={`${capitalize(user.first_name)} ${capitalize(user.last_name)}`}>
                                        {capitalize(user.first_name)} {capitalize(user.last_name)}
                                    </option>
                                ))
                            ) : (

                                <option value="">No users available</option>
                            )}
                            <option value="n/a">N/A</option> {/*N/A */}

                        </select>

                        <label>Location:</label><input name="location" placeholder="location" value={isEditing ? selectedHeadset.location : newHeadset.location} onChange={handleInputChange} />
                        <label>Team:</label><input name="team" placeholder="team" value={isEditing ? selectedHeadset.team : newHeadset.team} onChange={handleInputChange} />

                        {duplicateError && <p className="error">{duplicateError}</p>} {/* Display duplicate error message */}
                        {priceError && <p className="error">{priceError}</p>} {/* Display price error message */}
                        {statusError && <p className="error">{statusError}</p>} {/* Display duplicate error message */}

                        <div className="button-group">
                            <button className="overlay-add-headset" onClick={isEditing ? handleUpdateHeadset : handleAddHeadset} >
                                {isEditing ? 'Update Headset' : 'Add Headset'}
                            </button>
                            {isEditing && (
                                <button
                                    className="overlay-delet-headset"
                                    onClick={() => {
                                        if (window.confirm('Are you sure you want to delete this headset?')) {
                                            handleDeleteHeadset();
                                        }
                                    }}
                                >
                                    Delete Headset
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}

        </div>


    );














};


export default Headset;
