import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Inventory_header.css';

const Inventory_header = () => {
    const navigate = useNavigate();
    const location = useLocation(); // Obtiene la ruta actual
    const [selectedEquipment, setSelectedEquipment] = useState(location.pathname.split('-').pop()); // Inicializa con la ruta actual

    const handleClick = (equipment) => {
        setSelectedEquipment(equipment);
        navigate(`../equipment-${equipment}`);
    };

    const equipmentList = [
        'mouse',
        'keyboard',
        'laptop',
        'phone',
        'monitor',
        'printer',
        'scanner',
        'extension-cord',
        'power-bank',
        'backup-battery',
        'docking-station',
        'speaker',
        'usb',
        'tablet-remarkable',
        'headset',
        'projector',
        'external-disk',
        'general-inventory',
    ];

    return (
        <div className="menu-header">
        {equipmentList.map((equipment) => (
            equipment === selectedEquipment ? (
                // Reemplaza el botón del equipo actual con "Employee"
                <button
                    key="employee"
                    onClick={() => navigate('../inventory-employees')}
                    className="employee-button"
                >
                    Employee
                </button>
            ) : (
                <button
                    key={equipment}
                    onClick={() => handleClick(equipment)}
                    className={selectedEquipment === equipment ? 'selected' : ''}
                >
                    {equipment.charAt(0).toUpperCase() + equipment.slice(1).replace('-', ' ')}
                </button>
            )
        ))}
    </div>
);
};

export default Inventory_header;

