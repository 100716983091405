import React from 'react';

const Section10 = ({ formData, handleInputChange }) => (
  <>
    {/* Section 10 */}
    <div className="form-section flyer-section">
          <div className="flyer-header">ACA - QHP Enrollment</div>
          <label className="required-asterisk">New or ReEnrollment</label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name="New_Re_Enrollment"
                value="New"
                checked={formData.New_Re_Enrollment === 'New'}
                onChange={handleInputChange}
                required
              />
              New
            </label>
            <label>
              <input
                type="radio"
                name="New_Re_Enrollment"
                value="ReEnrollment"
                checked={formData.New_Re_Enrollment === 'ReEnrollment'}
                onChange={handleInputChange}
                required
              />
              ReEnrollment
            </label>
          </div>
        </div>
        <div className='form-section'>
          <label className="required-asterisk">Did Client Enroll?</label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name="clientEnroll"
                value="Yes"
                checked={formData.clientEnroll === 'Yes'}
                onChange={handleInputChange}
                required
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="clientEnroll"
                value="No"
                checked={formData.clientEnroll === 'No'}
                onChange={handleInputChange}
                required
              />
              No
            </label>
            <label>
              <input
                type="radio"
                name="clientEnroll"
                value="Will Come Back to Complete"
                checked={formData.clientEnroll === 'Will Come Back to Complete'}
                onChange={handleInputChange}
                required
              />
              Will Come Back to Complete
            </label>
          </div>
        </div>
  </>
);

export default Section10;
