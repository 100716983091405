import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { fetchAuthSession } from 'aws-amplify/auth'; // Use the new fetchAuthSession method

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

// Function to retrieve Cognito ID token using fetchAuthSession
const getAuthToken = async () => {
  try {
    const session = await fetchAuthSession(); // Use fetchAuthSession to get tokens
    const idToken = session.tokens.idToken.toString(); // Ensure it's a string
    // console.log("ID Token (Encoded String):", idToken);
    return idToken; // Return as string
  } catch (error) {
    console.error("Error retrieving auth token:", error);
    return null;
  }
};

// HttpLink points to your GraphQL endpoint
const httpLink = new HttpLink({
  uri: 'https://22og6o2lhvflvh5daolbjcdtau.appsync-api.us-east-2.amazonaws.com/graphql', // Replace with your GraphQL endpoint
});

// ApolloLink for adding the Authorization header with Cognito token
const authLink = new ApolloLink((operation, forward) => {
  return fromPromise(
    getAuthToken() // Retrieve the token asynchronously
  ).flatMap(token => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '', // Add token to Authorization header
      },
    }));
    return forward(operation); // Forward the operation
  });
});

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink.concat(httpLink)]), // Chain authLink before httpLink
  cache: new InMemoryCache(),
});

export default client;