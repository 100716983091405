import React from 'react';

const Section37 = ({ formData, handleInputChange }) => (
  <>
    {/* Section 37 */}
    <div className="form-section flyer-section">
          <div className="flyer-header">8. Forms, Exemptions and Appeals:</div>
          <label className="required-asterisk">A. Marketplace tax forms (1095-A)</label>
          <input
            type="number"
            name="Marketplace_tax_forms"
            value={formData.Marketplace_tax_forms}
            onChange={handleInputChange}
            placeholder="Your answer"
            min="0"
            required
          />
          <label className="required-asterisk">B. Filing Marketplace exemptions</label>
          <input
            type="number"
            name="Filing_Marketplace"
            value={formData.Filing_Marketplace}
            onChange={handleInputChange}
            placeholder="Your answer"
            min="0"
            required
          />
          <label className="required-asterisk">C. Submitting Marketplace or insurance coverage appeals</label>
          <input
            type="number"
            name="Submitting_Marketplace"
            value={formData.Submitting_Marketplace}
            onChange={handleInputChange}
            placeholder="Your answer"
            min="0"
            required
          />
        </div>
  </>
);

export default Section37;
