import React from 'react';

const Section7 = ({ formData, handleInputChange }) => (
  <>
    {/* Section 7 */}
    <div className="form-section flyer-section">
          <div className="flyer-header">Questions about coverage</div>
          <label className="required-asterisk">
            Does your employer offer Health Coverage?
          </label>
          <p className='extra_info_label'>Remember to review cost and options: <br></br>
            <a href="https://marketplace.cms.gov/applications-and-forms/employer-coverage-tool.pdf">https://marketplace.cms.gov/applications-and-forms/employer-coverage-tool.pdf</a>
          </p>
          <p className='extra_info_label'>"AFFORDABLE" PLANS AND THE 8.39% STANDARD:</p>
          <a href="https://www.healthreformbeyondthebasics.org/wp-content/uploads/2REFERENCE_YearlyGuidelines_CY2024.pdf">https://www.healthreformbeyondthebasics.org/wp-content/uploads/2REFERENCE_YearlyGuidelines_CY2024.pdf</a>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name="employerCoverage"
                value="yes"
                checked={formData.employerCoverage === 'yes'}
                onChange={handleInputChange}
                required
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="employerCoverage"
                value="no"
                checked={formData.employerCoverage === 'no'}
                onChange={handleInputChange}
                required
              />
              No
            </label>
            <label>
              <input
                type="radio"
                name="employerCoverage"
                value="I_dont_know"
                checked={formData.employerCoverage === 'I_dont_know'}
                onChange={handleInputChange}
                required
              />
              I don't know
            </label>
          </div>
        </div>

        <div className='form-section'>
          <label>Cost to Employee Only?</label>
          <input
            type='text'
            name='cost_to_employee'
            value={formData.cost_to_employee}
            onChange={handleInputChange}
            placeholder="Your answer"
          />
        </div>

        <div className='form-section'>
          <label>Is coverage offered to family?</label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name="familyCovered"
                value="yes"
                checked={formData.familyCovered === 'yes'}
                onChange={handleInputChange}
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="familyCovered"
                value="no"
                checked={formData.familyCovered === 'no'}
                onChange={handleInputChange}
              />
              No
            </label>
          </div>
        </div>
        <div className='form-section'>
          <label>Cost for family coverage</label>
          <input
            type='number'
            name='amountFamCoverage'
            value={formData.amountFamCoverage}
            onChange={handleInputChange}
            min="0"
            step="0.01"
            placeholder="Your answer"
          />
        </div>
        <div className='form-section'>
          <label className="required-asterisk">Number of people in household:</label>
          <input
            type='number'
            name='NumPeopleHousehold'
            value={formData.NumPeopleHousehold}
            onChange={handleInputChange}
            min="0"
            placeholder="Your answer"
            required
          />
        </div>
        <div className='form-section'>
          <label className="required-asterisk">Number of people applying:</label>
          <input
            type='number'
            name='NumPeopleApplying'
            value={formData.NumPeopleApplying}
            onChange={handleInputChange}
            min="0"
            placeholder="Your answer"
            required
          />
        </div>
        <div className='form-section'>
          <label className="required-asterisk">Adults:</label>
          <input
            type='number'
            name='NumAdults'
            value={formData.NumAdults}
            onChange={handleInputChange}
            min="0"
            placeholder="Your answer"
            required
          />
        </div>
        <div className='form-section'>
          <label className="required-asterisk">Children:</label>
          <input
            type='number'
            name='NumChildren'
            value={formData.NumChildren}
            onChange={handleInputChange}
            min="0"
            placeholder="Your answer"
            required
          />
        </div>
        <div className='form-section'>
          <label className="required-asterisk">Does Client Need Employee Tool?</label>
          <a href='https://drive.google.com/drive/folders/1LoRrSrajjYdMNyeI1yz7BbU4tW4clvd1'>https://drive.google.com/drive/folders/1LoRrSrajjYdMNyeI1yz7BbU4tW4clvd1</a>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name="EmployeeTool"
                value="yes"
                checked={formData.EmployeeTool === 'yes'}
                onChange={handleInputChange}
                required
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="EmployeeTool"
                value="no"
                checked={formData.EmployeeTool === 'no'}
                onChange={handleInputChange}
                required
              />
              No
            </label>
          </div>
        </div>
  </>
);

export default Section7;
