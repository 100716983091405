import React from 'react';

const Section6 = ({ formData, handleInputChange }) => (
  <>
    {/* Section 6 */}
    <div className="form-section flyer-section">
          <div className="flyer-header">Consent</div>
          <label>
            Reminder: New consent needs to be obtained for re-enrollment and when reporting changes;
            however, if making changes and re-enrolling at the same appointment time only ONE consent form is needed.
          </label>
        </div>
        <div className='form-section'>
          <label className="required-asterisk">Consumer authorization on file?</label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name="ConsumerAuthOnFile"
                value="yes"
                checked={formData.ConsumerAuthOnFile === 'yes'}
                onChange={handleInputChange}
                required
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="ConsumerAuthOnFile"
                value="no"
                checked={formData.ConsumerAuthOnFile === 'no'}
                onChange={handleInputChange}
                required
              />
              No
            </label>
          </div>
        </div>
  </>
);

export default Section6;
