import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { fetchAuthSession } from 'aws-amplify/auth'; // Use your Auth session fetcher

const ProtectedRoute = ({ children, allowedRoles }) => {
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkAuthorization = async () => {
            try {
                const session = await fetchAuthSession();
                const userGroups = session.tokens.idToken.payload['cognito:groups'] || [];
                
                // Check if user belongs to the allowed roles (e.g., 'Admin')
                const isAuthorizedUser = userGroups.some(group => allowedRoles.includes('Admin'));
                setIsAuthorized(isAuthorizedUser);
            } catch (error) {
                console.error('Error fetching user session:', error);
                setIsAuthorized(false);
            } finally {
                setLoading(false);
            }
        };
        checkAuthorization();
    }, [allowedRoles]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return isAuthorized ? children : <Navigate to="/dashboard" />;
};

export default ProtectedRoute;
