import React from 'react';
import HearFromUsSection from './HearFromUsSection';


const Section39 = ({ formData, handleInputChange }) => (
  <>
    {/* Section 39 */}
    <div className="form-section flyer-section">
          <div className="flyer-header">How did you hear about us? (5 Ws)</div>
          <label className="required-asterisk">Where did you Hear from us?</label>
          <select
            name="hear_from_us"
            value={formData.hear_from_us}
            onChange={handleInputChange}
            required
          >
            <option value="" disabled>How did you hear from us?</option>
            <option value="Event">Event</option>
            <option value="Facebook">Facebook</option>
            <option value="Flyer">Flyer</option>
            <option value="Hospital">Hospital</option>
            <option value="Instagram">Instagram</option>
            <option value="Library">Library</option>
            <option value="Newspaper">Newspaper</option>
            <option value="Radio">Radio</option>
            <option value="Referral">Referral</option>
            <option value="SAC">SAC</option>
            <option value="TablePresentation">Table Presentation</option>
            <option value="TV">TV</option>
            <option value="Twitter">X (Twitter)</option>
            <option value="Workforce">WorkForce</option>
            <option value="MAC">MAC</option>
            <option value="Other">Other</option>
          </select>
          <HearFromUsSection formData={formData} handleInputChange={handleInputChange} />
        </div>
  </>
);

export default Section39;
