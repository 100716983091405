import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { listGeneralInventories, listUsers } from '../../../graphql/queries';
import { createGeneralInventory, deleteGeneralInventory, updateGeneralInventory } from '../../../graphql/mutations';
import './General_Inventory.css';
import { v4 as uuidv4 } from 'uuid';
import XLSX from 'xlsx-js-style';
import { FaFileExcel } from 'react-icons/fa';
import Inventory_header from '../../../components/layout/Inventory_header';


const General_Inventory = () => {
  const [generalInventory, setgeneralInventory] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [showgeneralInventoryAddOverlay, setShowgeneralInventoryAddOverlay] = useState(false);
  const [selectedgeneralInventory, setSelectedgeneralInventory] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const [newgeneralInventory, setNewgeneralInventory] = useState({
    id: '',
    Item_Description: '',
    tagid: '',
    model: '',
    brand: '',
    serial_Number: '',
    price: '',
    store: '',
    purchase_date: '',
    assignment_date: '',
    warranty_date: '',
    returnable_date: '',
    available: '',
    status: '',
    assigned_to: '',
    notes: '',
    location: '',
    team: '',

  });
  const [sortConfig, setSortConfig] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchField, setSearchField] = useState('id');
  const [duplicateError, setDuplicateError] = useState('');
  const [priceError, setPriceError] = useState('');
  const [statusError, setStatusError] = useState('');
  const { loading1, error: userError, data: userData } = useQuery(listUsers);

  const handleSaveAsExcel = async () => {
    try {
      const generalInventoryToExport = filteredgeneralInventorys.length > 0 ? filteredgeneralInventorys : generalInventory;

      if (generalInventoryToExport.length > 0) {
        const sortedgeneralInventory = [...generalInventoryToExport].sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp));

        const worksheetData = sortedgeneralInventory.map((generalInventory, index) => ({
          '#': index + 1, // Agregar el número de fila
          'TAG': generalInventory.tagid,
          'Name': generalInventory.Item_Description,
          'Brand': generalInventory.brand,
          'Assigned To': generalInventory.assigned_to,
          'Available': generalInventory.available ? 'Yes' : 'No',
          'Serial Number': generalInventory.serial_Number,
          'Status': generalInventory.status,
          'Team': generalInventory.team,
        }));

        // Convert JSON to sheet starting from row 2
        const worksheet = XLSX.utils.json_to_sheet([]);
        let endCol = 0;

        XLSX.utils.sheet_add_aoa(worksheet, [['Other Equipment']], { origin: 'A1' });

        if (worksheetData.length > 0) {
          // Agrega los datos a partir de la fila A2
          XLSX.utils.sheet_add_json(worksheet, worksheetData, { origin: 'A2', skipHeader: false });

          // Define el rango de referencia (!ref)
          const endRow = worksheetData.length + 1; // +1 para el título en A1
          const endCol = Object.keys(worksheetData[0]).length - 1;
          worksheet['!ref'] = XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: endRow, c: endCol } });
        } else {
          console.error("No hay datos para exportar.");
          return;
        }

        // Merge title cells across the columns
        worksheet['!merges'] = worksheet['!merges'] || [];
        worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 9 } }); // A1 to H1 for the added column

        // Style the title row
        worksheet['A1'].s = {
          font: {
            bold: true,
            sz: 20, // Font size
            color: { rgb: '1F4E79' } // Dark blue text color
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center'
          }
        };

        // Apply styling for the header row (now starting at row 2)
        const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
        for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
          const cellAddress = XLSX.utils.encode_cell({ r: 1, c: col }); // Header row is now row 1
          if (!worksheet[cellAddress]) continue;
          worksheet[cellAddress].s = {
            fill: {
              fgColor: { rgb: '1F4E79' } // Dark blue background
            },
            font: {
              bold: true,
              color: { rgb: 'FFFFFF' } // White text
            },
            alignment: {
              horizontal: 'center'
            },
            border: {
              top: { style: 'thin', color: { rgb: '000000' } },
              left: { style: 'thin', color: { rgb: '000000' } },
              bottom: { style: 'thin', color: { rgb: '000000' } },
              right: { style: 'thin', color: { rgb: '000000' } },
            }
          };
        }

        // Apply alternating row colors and borders starting from row 3
        for (let row = 2; row <= headerRange.e.r; row++) {
          const isEvenRow = row % 2 === 0;
          for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
            if (!worksheet[cellAddress]) continue;

            worksheet[cellAddress].s = {
              fill: {
                fgColor: { rgb: isEvenRow ? 'D3D3D3' : 'FFFFFF' } // Light gray for even rows, white for odd rows
              },
              border: {
                top: { style: 'thin', color: { rgb: '000000' } },
                left: { style: 'thin', color: { rgb: '000000' } },
                bottom: { style: 'thin', color: { rgb: '000000' } },
                right: { style: 'thin', color: { rgb: '000000' } },
              },
              alignment: {
                horizontal: 'center'
              }
            };
          }
        }

        // Adjust columns to fit content
        // Adjust columns to fit content
        worksheet['!cols'] = [
          { wch: 5 }, // Width for Row Number
          { wch: 30 },
          { wch: 30 },
          { wch: 10 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 }
        ];

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'generalInventorys');

        // Export the file
        XLSX.writeFile(workbook, 'generalInventorysData.xlsx');
      } else {
        console.error('No data to export');
      }
    } catch (error) {
      console.error('Error exporting data to Excel:', error);
    }
  };

  const handleEditgeneralInventory = (generalInventory) => {
    setSelectedgeneralInventory(generalInventory);
    setIsEditing(true);
    setShowgeneralInventoryAddOverlay(true);
  };

  const { loading, data, fetchMore } = useQuery(listGeneralInventories, {
    variables: { limit: 100 },
  });

  const [addgeneralInventory] = useMutation(createGeneralInventory, {
    refetchQueries: [{ query: listGeneralInventories, variables: { limit: 100 } }]
  });

  const [removegeneralInventory] = useMutation(deleteGeneralInventory, {
    refetchQueries: [{ query: listGeneralInventories, variables: { limit: 100 } }]
  });
  const [updgeneralInventory] = useMutation(updateGeneralInventory, {
    refetchQueries: [{ query: listGeneralInventories, variables: { limit: 100 } }]
  });

  const [fetchAllgeneralInventorys, { loading: countLoading, data: AllgeneralInventorysData }] = useLazyQuery(listGeneralInventories, {
    variables: { limit: 100 },
    fetchPolicy: "network-only",
  });


  useEffect(() => {
    const fetchAllgeneralInventorys = async () => {
      let AllgeneralInventorys = [];
      let currentNextToken = null;

      do {
        const { data } = await fetchMore({
          variables: { limit: 100, nextToken: currentNextToken },
        });

        AllgeneralInventorys = [...AllgeneralInventorys, ...data.listGeneralInventories.items];
        currentNextToken = data.listGeneralInventories.nextToken;
      } while (currentNextToken);

      setgeneralInventory(AllgeneralInventorys);
      setNextToken(currentNextToken);
    };

    if (data) {
      fetchAllgeneralInventorys();
    }
  }, [data, fetchMore]);

  const clearNewgeneralInventory = () => {
    setNewgeneralInventory({
      id: '',
      Item_Description: '',
      tagid: '',
      model: '',
      brand: '',
      serial_Number: '',
      price: '',
      store: '',
      purchase_date: '',
      assignment_date: '',
      warranty_date: '',
      returnable_date: '',
      available: '',
      status: '',
      assigned_to: '',
      notes: '',
      location: '',
      team: '',
    });
  };

  // Define loadMore function
  const loadMore = () => {
    fetchMore({
      variables: { limit: 100, nextToken },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prevResult;
        setNextToken(fetchMoreResult.listGeneralInventories.nextToken);
        return {
          listGeneralInventories: {
            ...fetchMoreResult.listGeneralInventories,
            items: [...prevResult.listGeneralInventories.items, ...fetchMoreResult.listGeneralInventories.items]
          }
        };
      }
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    // Convert to lowercase and trim whitespace for specific fields
    if (isEditing) {
      setSelectedgeneralInventory({
        ...selectedgeneralInventory,
        [name]: formattedValue
      });
    } else {
      setNewgeneralInventory({
        ...newgeneralInventory,
        id: uuidv4(),
        [name]: formattedValue
      });
    }
  };

  const handleAddgeneralInventory = () => {

    // Formatear la fecha en el formato YYYY-MM-DD si existe, o establecerla en null si está vacía
    const formattedPurchase = newgeneralInventory.purchase_date
      ? new Date(newgeneralInventory.purchase_date).toISOString().split('T')[0]
      : null;
    const formattedAssignment = newgeneralInventory.assignment_date
      ? new Date(newgeneralInventory.assignment_date).toISOString().split('T')[0]
      : null;

    const formattedWarranty = newgeneralInventory.warranty_date
      ? new Date(newgeneralInventory.warranty_date).toISOString().split('T')[0]
      : null;

    const formattedReturnable = newgeneralInventory.returnable_date
      ? new Date(newgeneralInventory.returnable_date).toISOString().split('T')[0]
      : null;


    // Validate that the price contains only digits and is a valid number
    let priceValue = null;
    if (newgeneralInventory.price !== "" && newgeneralInventory.price !== null && newgeneralInventory.price != undefined) {
      priceValue = parseFloat(newgeneralInventory.price);

      if (isNaN(priceValue)) {
        setPriceError('Price must be a valid number.');
        return;
      }
    }
    setPriceError('');

    // Check for duplicates based on `tagid` and `serial_Number`
    const isDuplicate = generalInventory.some(generalInventory =>
      generalInventory.tagid === newgeneralInventory.tagid &&
      generalInventory.serial_Number === newgeneralInventory.serial_Number
    );

    if (isDuplicate) {
      setDuplicateError('A equipment with the same TAG and Serial Number already exists.');
      console.log('Duplicate validation failed');
      return;
    } else {
      setDuplicateError('');
    }

    // Check availability first
    if (newgeneralInventory.available === 'true') {

      // If available, status must be Inventario
      if (newgeneralInventory.status !== 'inv') {
        setStatusError('If the Equipment is available, its status must be "Inventario".');
        console.log('Status validation failed');
        return; // Exit function if validation fails
      }
      // If available and status is Inventario, assigned_to must be empty or "N/A"
      if (newgeneralInventory.assigned_to !== '' && newgeneralInventory.assigned_to !== 'n/a') {
        setStatusError('If the Equipment is available and its status is "Inventario", the "Assigned to" field must be empty or "N/A".');
        console.log('Assigned_to validation failed');
        return; // Exit function if validation fails
      }

    } else if (newgeneralInventory.available !== "") {
      // If not available, status must be one of the allowed statuses
      const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
      if (!allowedStatuses.includes(newgeneralInventory.status)) {
        setStatusError('If the Equipment is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
        console.log('Status validation failed');
        return; // Exit function if validation fails
      }
      // Clear error if validation passes
      setStatusError('');
    }


    // If all validations pass, proceed with adding the generalInventory to the database
    addgeneralInventory({
      variables: { input: { ...newgeneralInventory, price: priceValue, purchase_date: formattedPurchase, warranty_date: formattedWarranty, returnable_date: formattedReturnable, assignment_date: formattedAssignment } }
    })
      .then(() => {
        window.location.reload();
      }).catch(error => {
        console.error("Error adding generalInventory:", error);
      });
  };

  const handleDeletegeneralInventory = () => {

    removegeneralInventory({
      variables: { input: { id: selectedgeneralInventory.id } }
    }).then(() => {
      window.location.reload();
    }).catch(error => {
      console.error("Error deleting generalInventory", error);
    });

  };

  const handleUpdategeneralInventory = async () => {

    const formattedData_purchase = selectedgeneralInventory.purchase_date ? new Date(selectedgeneralInventory.purchase_date).toISOString().split('T')[0] : null;
    const formattedData_warranty = selectedgeneralInventory.warranty_date ? new Date(selectedgeneralInventory.warranty_date).toISOString().split('T')[0] : null;
    const formattedData_returnable = selectedgeneralInventory.returnable_date ? new Date(selectedgeneralInventory.returnable_date).toISOString().split('T')[0] : null;
    const formattedData_assignment = selectedgeneralInventory.assignment_date ? new Date(selectedgeneralInventory.assignment_date).toISOString().split('T')[0] : null;

    //Check Price
    let priceValue = null;
    if (selectedgeneralInventory.price !== "" && selectedgeneralInventory.price !== null && selectedgeneralInventory.price != undefined) {
      priceValue = parseFloat(selectedgeneralInventory.price);

      if (isNaN(priceValue)) {
        setPriceError('Price must be a valid number.');
        return;
      }
    }
    setPriceError('');

    // Check availability first
    if (selectedgeneralInventory.available === 'true') {

      // If available, status must be Inventario
      if (selectedgeneralInventory.status !== 'inv') {
        setStatusError('If the Equipment is available, its status must be "Inventario".');
        console.log('Status validation failed');
        return; // Exit function if validation fails
      }
      // If available and status is Inventario, assigned_to must be empty or "N/A"
      if (selectedgeneralInventory.assigned_to !== '' && selectedgeneralInventory.assigned_to !== 'n/a') {
        setStatusError('If the Equipment is available and its status is "Inventario", the "Assigned to" field must be empty or "N/A".');
        console.log('Assigned_to validation failed');
        return; // Exit function if validation fails
      }

    } else if (selectedgeneralInventory.available === 'false') {
      // If not available, status must be one of the allowed statuses
      const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
      if (!allowedStatuses.includes(selectedgeneralInventory.status)) {
        setStatusError('If the Equipment is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
        console.log('Status validation failed');
        return; // Exit function if validation fails
      }
      // Clear error if validation passes
      setStatusError('');
    }

    updgeneralInventory({
      variables: {
        input: {
          id: selectedgeneralInventory.id,
          Item_Description: selectedgeneralInventory.Item_Description,
          tagid: selectedgeneralInventory.tagid,
          model: selectedgeneralInventory.model,
          brand: selectedgeneralInventory.brand,
          serial_Number: selectedgeneralInventory.serial_Number,
          price: priceValue,
          store: selectedgeneralInventory.store,
          purchase_date: formattedData_purchase,
          assignment_date: formattedData_assignment,
          warranty_date: formattedData_warranty,
          returnable_date: formattedData_returnable,
          available: selectedgeneralInventory.available,
          status: selectedgeneralInventory.status,
          assigned_to: selectedgeneralInventory.assigned_to,
          notes: selectedgeneralInventory.notes,
          location: selectedgeneralInventory.location,
          team: selectedgeneralInventory.team,

        }
      }
    }).then(() => {
      setPriceError('');
      setStatusError('');
      setDuplicateError('');
      setShowgeneralInventoryAddOverlay(false);

    }).catch(error => {
      console.error("Error updating generalInventory:", error);
    });
  };

  const handleSelectgeneralInventory = (generalInventory) => {

    for (const [key, value] of Object.entries(generalInventory)) {
      console.log(`${key}: ${typeof value}`);
    }
    const formatDateForInput = (dateString) => {
      if (!dateString) return "";  // Si la fecha es nula o indefinida, retorna una cadena vacía

      const date = new Date(dateString);
      // Ajustar la fecha para evitar la conversión a UTC
      const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

      return localDate.toISOString().split('T')[0];  // Retorna la fecha en formato YYYY-MM-DD
    };

    setNewgeneralInventory({
      id: generalInventory.id,
      Item_Description: generalInventory.Item_Description,
      tagid: generalInventory.tagid,
      model: generalInventory.model,
      brand: generalInventory.brand,
      serial_Number: generalInventory.serial_Number,
      price: generalInventory.price,
      store: generalInventory.store,
      purchase_date: formatDateForInput(generalInventory.purchase_date),
      assignment_date: formatDateForInput(generalInventory.assignment_date),
      warranty_date: formatDateForInput(generalInventory.warranty_date),
      returnable_date: formatDateForInput(generalInventory.returnable_date),
      available: generalInventory.available,
      status: generalInventory.status,
      assigned_to: generalInventory.assigned_to,
      notes: generalInventory.notes,
      location: generalInventory.location,
      team: generalInventory.team,

    });
    setShowgeneralInventoryAddOverlay(true);
    setIsEditing(true);
    setSelectedgeneralInventory(generalInventory);
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedgeneralInventorys = React.useMemo(() => {
    let sortablegeneralInventorys = [...generalInventory];
    if (sortConfig !== null) {
      sortablegeneralInventorys.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortablegeneralInventorys;
  }, [generalInventory, sortConfig]);

  //listUsers 
  const users = React.useMemo(() => {
    if (userData && userData.listUsers) {
      return userData.listUsers.items || [];
    }
    return [];
  }, [userData]);
  if (userError) return <p>Error loading users: {userError.message}</p>;
  if (loading1) return <p>Error loading users: {userError.message}</p>;


  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredgeneralInventorys = sortedgeneralInventorys.filter(generalInventory => {

    if (generalInventory[searchField] == null) return false; // O puedes decidir cómo manejarlo

    let fieldValue = generalInventory[searchField].toString().toLowerCase();

    // Convertir "true" a "yes" y "false" a "no"e
    if (fieldValue === "true") {
      fieldValue = "yes";
    } else if (fieldValue === "false") {
      fieldValue = "no";
    }

    return fieldValue.includes(searchQuery.toLowerCase());
  });

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

    return localDate.toLocaleDateString(undefined, options);
  };

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const formatPrice = (price) => {
    if (price === null || price === undefined || price === '') {
      return '';
    }
    const parsedPrice = parseFloat(price);
    return isNaN(parsedPrice) ? '' : parsedPrice.toFixed(2);
  };

  return (
    <div className="General_Inventory-page">
      <header className="inventory-header">
        <h1>Other Equipment</h1>
      </header>
      <Inventory_header/>
      <div className="inventory-search">
        <button onClick={handleSaveAsExcel} > <FaFileExcel /> Export </button>

        <input
          type="text"
          placeholder="Search by TAG, Available, Model, Assigned to"
          value={searchQuery}
          onChange={handleSearch}
        />
        <select onChange={(e) => setSearchField(e.target.value)} defaultValue="">
          <option value="" disabled hidden>Select Option</option>
          <option value="tagid">TAG</option>
          <option value="status">Status</option>
          <option value="available">Available</option>
          <option value="model">Model</option>
          <option value="brand">Brand</option>
          <option value="serial_Number">Serial Number</option>
          <option value="store">Store</option>
          <option value="assigned_to">Assigned to</option>
        </select>
      </div>


      <button className="add-General_Inventory-btn" onClick={() => {
        clearNewgeneralInventory();
        setSelectedgeneralInventory(null);
        setIsEditing(false);
        setShowgeneralInventoryAddOverlay(true);
      }}>+ Add New Equipment</button>

      <div className="General_Inventory-table-container">
        <table className="General_Inventory-table">
          <thead>
            <tr>
              <th>#</th> {/* Columna para contar las filas */}
              <th onClick={() => requestSort('Item_Description')}>
                Item Name
                {sortConfig?.key === 'Item_Description' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>

              <th onClick={() => requestSort('tagid')}>
                TAG
                {sortConfig?.key === 'tagid' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('model')}>
                Model
                {sortConfig?.key === 'model' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('brand')}>
                Brand
                {sortConfig?.key === 'brand' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('serial_Number')}>
                Serial Number
                {sortConfig?.key === 'serial_Number' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>

              <th onClick={() => requestSort('price')}>
                Price
                {sortConfig?.key === 'price' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('store')}>
                Store
                {sortConfig?.key === 'store' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('purchase_date')}>
                Purchase Date
                {sortConfig?.key === 'purchase_date' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>

              <th onClick={() => requestSort('assignment_date')}>
                Assignment Date
                {sortConfig?.key === 'assignment_date' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('warranty_date')}>
                Warranty Date
                {sortConfig?.key === 'warranty_date' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('returnable_date')}>
                Returnable Date
                {sortConfig?.key === 'returnable_date' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('available')}>
                Available
                {sortConfig?.key === 'available' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('status')}>
                Status
                {sortConfig?.key === 'status' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('assigned_to')}>
                Assigned To
                {sortConfig?.key === 'assigned_to' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('notes')}>
                Notes
                {sortConfig?.key === 'notes' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('location')}>
                Location
                {sortConfig?.key === 'location' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th onClick={() => requestSort('team')}>
                Team
                {sortConfig?.key === 'team' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>

              <th >Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredgeneralInventorys.map((generalInventory, index) => (
              <tr key={generalInventory.id} onClick={() => handleEditgeneralInventory(generalInventory)} >
                <td>{index + 1}</td> {/* Número de fila */}
                <td className="generalInventory-Item_Description">{generalInventory.Item_Description}</td>
                <td className="generalInventory-tagid">{capitalize(generalInventory.tagid)}</td>
                <td className="generalInventory-model">{capitalize(generalInventory.model.toLowerCase())}</td>
                <td className="generalInventory-brand">{capitalize(generalInventory.brand.toLowerCase())}</td>
                <td className="generalInventory-serial_Number">{generalInventory.serial_Number}</td>
                <td className="generalInventory-price">${formatPrice(generalInventory.price)}</td>
                <td className="generalInventory-store">{generalInventory.store}</td>
                <td className="generalInventory-purchase_date">{generalInventory.purchase_date ? formatDate(generalInventory.purchase_date) : 'No Date'}</td>
                <td className="generalInventory-assignment_date">{generalInventory.assignment_date ? formatDate(generalInventory.assignment_date) : 'No Date'}</td>
                <td className="generalInventory-warranty_date">{generalInventory.warranty_date ? formatDate(generalInventory.warranty_date) : 'No Date'}</td>
                <td className="generalInventory-returnable_date">{generalInventory.returnable_date ? formatDate(generalInventory.returnable_date) : 'No Date'}</td>
                <td className="generalInventory-available">{generalInventory.available === null || generalInventory.available === '' ? 'No' : 'Yes'} </td>
                <td className="generalInventory-status">
                  {generalInventory.status === 'broken' ? 'Broken' :
                    generalInventory.status === 'use' ? 'Use' :
                      generalInventory.status === 'transfer' ? 'Transfer' :
                        generalInventory.status === 'lost' ? 'Lost' :
                          generalInventory.status === 'inv' ? 'Inventario' :
                            'Inventario'} {/* Muestra 'Unknown' si el estado no coincide con ninguno de los anteriores */}
                </td>
                <td className="generalInventory-assigned_to">{generalInventory.assigned_to}</td>
                <td className="generalInventory-notes">{generalInventory.notes}</td>
                <td className="generalInventory-location">{generalInventory.location}</td>
                <td className="generalInventory-team">{generalInventory.team}</td>
                <td className='generalInventory-table'>
                  <button className="delete-General_Inventory-btn" onClick={() => handleSelectgeneralInventory(generalInventory)}>Delete/Update</button>

                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {
        nextToken && !loading && (
          <button onClick={loadMore}>Load More</button>
        )
      }


      {loading && <p>Loading...</p>}

      {
        showgeneralInventoryAddOverlay && (

          <div className="General_Inventory_add_overlay">
            <div className="overlay-content-inventory">
              <div className="header-row">
                <h2 className="header-title">{isEditing ? 'Edit Equipment' : 'Add New Equipment'}</h2>
                <button onClick={() => {
                  setShowgeneralInventoryAddOverlay(false);
                  clearNewgeneralInventory();
                  setSelectedgeneralInventory(null);
                  setIsEditing(false);
                }}>X</button>
              </div>

              <label>Item Name:</label><input name="Item_Description" placeholder="Name" value={isEditing ? selectedgeneralInventory.Item_Description : newgeneralInventory.Item_Description} onChange={handleInputChange} required />
              <label>TAG:</label><input name="tagid" placeholder="TAG" value={isEditing ? selectedgeneralInventory.tagid : newgeneralInventory.tagid} onChange={handleInputChange} required />
              <label>Model:</label><input name="model" placeholder="Model" value={isEditing ? selectedgeneralInventory.model : newgeneralInventory.model} onChange={handleInputChange} />

              <label>Brand:</label><input name="brand" placeholder="Brand" value={isEditing ? selectedgeneralInventory.brand : newgeneralInventory.brand} onChange={handleInputChange} />
              <label>Serial Number or Password:</label><input name="serial_Number" placeholder="Serial Number" value={isEditing ? selectedgeneralInventory.serial_Number : newgeneralInventory.serial_Number} onChange={handleInputChange} required />

              <label>Price:</label><input name="price" placeholder="price" value={isEditing ? selectedgeneralInventory.price : newgeneralInventory.price} onChange={handleInputChange} />
              <label>Store:</label><input name="store" placeholder="store" value={isEditing ? selectedgeneralInventory.store : newgeneralInventory.store} onChange={handleInputChange} />

              <label htmlFor="purchase_date">Purchase Date:</label>
              <input
                type="Date"
                id="purchase_date"
                name="purchase_date"
                value={isEditing ? selectedgeneralInventory.purchase_date : newgeneralInventory.purchase_date}
                onChange={handleInputChange}

              />

              <label htmlFor="assignment_date">Assignment Date:</label>
              <input
                type="date"
                id="assignment_date"
                name="assignment_date"
                value={isEditing ? selectedgeneralInventory.assignment_date : newgeneralInventory.assignment_date}
                onChange={handleInputChange}

              />
              <label htmlFor="warranty_date">Warranty Date:</label>
              <input
                type="date"
                id="warranty_date"
                name="warranty_date"
                value={isEditing ? selectedgeneralInventory.warranty_date : newgeneralInventory.warranty_date}
                onChange={handleInputChange}


              />
              <label htmlFor="returnable_date">Returnable Date:</label>
              <input
                type="date"
                id="returnable_date"
                name="returnable_date"
                value={isEditing ? selectedgeneralInventory.returnable_date : newgeneralInventory.returnable_date}
                onChange={handleInputChange}

              />

              <label>Available:</label>

              <select name="available" placeholder="available" value={isEditing ? selectedgeneralInventory.available : newgeneralInventory.available} onChange={handleInputChange} >
                <option value="" disabled hidden className="placeholder-option">Select Availability</option>
                <option value="true">Yes</option>
                <option value="false">No</option>

              </select>
              <label>Status:</label>
              <select name="status" placeholder="status" value={isEditing ? selectedgeneralInventory.status : newgeneralInventory.status} onChange={handleInputChange} >
                <option value="" disabled hidden>Select Status</option>
                <option value="use">Use</option>
                <option value="broken">Broken</option>
                <option value="transfer">Transfer</option>
                <option value="lost">Lost</option>
                <option value="inv">Inventario</option>

              </select>
              <label>Assigned To:</label>
              <select
                name="assigned_to"
                value={isEditing ? selectedgeneralInventory.assigned_to : newgeneralInventory.assigned_to}
                onChange={handleInputChange}
              >
                <option value="">Select an Employee</option>

                {users.length > 0 ? (
                  [...users].sort((a, b) => {
                    const nameA = `${a.first_name} ${a.last_name}`.toLowerCase();
                    const nameB = `${b.first_name} ${b.last_name}`.toLowerCase();
                    return nameA.localeCompare(nameB);
                  }).map(user => (
                    <option key={user.id} value={`${capitalize(user.first_name)} ${capitalize(user.last_name)}`}>
                      {capitalize(user.first_name)} {capitalize(user.last_name)}
                    </option>
                  ))
                ) : (

                  <option value="">No users available</option>
                )}
                <option value="n/a">N/A</option> {/* Opción N/A */}
              </select>

              <label>Notes:</label><input name="notes" placeholder="Notes" value={isEditing ? selectedgeneralInventory.notes : newgeneralInventory.notes} onChange={handleInputChange} />
              <label>Location:</label><input name="location" placeholder="location" value={isEditing ? selectedgeneralInventory.location : newgeneralInventory.location} onChange={handleInputChange} />
              <label>Team:</label><input name="team" placeholder="team" value={isEditing ? selectedgeneralInventory.team : newgeneralInventory.team} onChange={handleInputChange} />

              {duplicateError && <p className="error">{duplicateError}</p>} {/* Display duplicate error message */}
              {priceError && <p className="error">{priceError}</p>} {/* Display price error message */}
              {statusError && <p className="error">{statusError}</p>} {/* Display duplicate error message */}

              <div className="button-group">
                <button className="overlay-add-General_Inventory" onClick={isEditing ? handleUpdategeneralInventory : handleAddgeneralInventory} >
                  {isEditing ? 'Update Equipment' : 'Add Equipment'}
                </button>
                {isEditing && (
                  <button
                    className="overlay-delet-General_Inventory"
                    onClick={() => {
                      if (window.confirm('Are you sure you want to delete this generalInventory?')) {
                        handleDeletegeneralInventory();
                      }
                    }}
                  >
                    Delete Equipment
                  </button>
                )}
              </div>
            </div>
          </div>
        )
      }

    </div >


  );









};

export default General_Inventory;
