import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                {/* Other footer content can go here */}
            </div>
            <div className="cms-disclaimer">
                <p>
                    This website, is supported by the Centers for Medicare and 
                    Medicaid Services (CMS) of the U.S. Department of Health and Human 
                    Services (HHS) as part of a financial assistance award totaling $2,890,000.00 
                    with 100 percent funded by CMS/HHS. 
                    The contents are those of the author(s) and do not necessarily 
                    represent the official views of, nor an endorsement, by CMS/HHS, or the U.S. Government.
                </p>
            </div>
        </footer>
    );
};

export default Footer;
