import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { listExtensionCords, listUsers } from '../../../graphql/queries';
import { createExtensionCord, deleteExtensionCord, updateExtensionCord } from '../../../graphql/mutations';
import './Extension_Cord.css';
import XLSX from 'xlsx-js-style';
import { v4 as uuidv4 } from 'uuid';
import { FaFileExcel } from 'react-icons/fa';
import Inventory_header from '../../../components/layout/Inventory_header';

const ExtensionCord = () => {

    const [extensionCord, setExtensionCord] = useState([]);
    const [nextToken, setNextToken] = useState(null);
    const [showExtensionCordAddOverlay, setShowExtensionCordAddOverlay] = useState(false);
    const [selectedExtensionCord, setSelectedExtensionCord] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    const [newExtensionCord, setNewExtensionCord] = useState({
        id: '',
        tagid: '',
        model: '',
        brand: '',
        serial_Number: '',
        price: '',
        store: '',
        purchase_date: '',
        assignment_date: '',
        warranty_date: '',
        returnable_date: '',
        available: '',
        status: '',
        assigned_to: '',
        location: '',
        team: '',

    });
    const [sortConfig, setSortConfig] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchField, setSearchField] = useState('id');
    const [duplicateError, setDuplicateError] = useState('');
    const [priceError, setPriceError] = useState('');
    const [statusError, setStatusError] = useState('');
    const { loading1, error: userError, data: userData } = useQuery(listUsers);



    const handleSaveAsExcel = async () => {
        try {
            const extensionCordToExport = filteredExtensionCords.length > 0 ? filteredExtensionCords : extensionCord;
            if (extensionCordToExport.length > 0) {
                // Sort the ExtensionCords by Timestamp in ascending order (earliest first)
                const sortedExtensionCord = [...extensionCordToExport].sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp));

                // Prepare the data for Excel export with custom column titles
                const worksheetData = sortedExtensionCord.map((extensionCord, index) => ({
                    '#': index + 1, // Agregar el número de fila
                    'TAG:': extensionCord.tagid,
                    'Available:': extensionCord.available ? 'Yes' : 'No',
                    'Serial Number:': extensionCord.serial_Number,
                    'Status:': extensionCord.status,
                    'Assigned to:': extensionCord.assigned_to,
                    'Location:': extensionCord.location,

                    // Add other fields as needed

                }));

                // Convert JSON to sheet starting from row 2
                const worksheet = XLSX.utils.json_to_sheet([]);
                let endCol = 0;

                XLSX.utils.sheet_add_aoa(worksheet, [['Extension Cords']], { origin: 'A1' });

                if (worksheetData.length > 0) {
                    // Agrega los datos a partir de la fila A2
                    XLSX.utils.sheet_add_json(worksheet, worksheetData, { origin: 'A2', skipHeader: false });

                    // Define el rango de referencia (!ref)
                    const endRow = worksheetData.length + 1; // +1 para el título en A1
                    const endCol = Object.keys(worksheetData[0]).length - 1;
                    worksheet['!ref'] = XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: endRow, c: endCol } });
                } else {
                    console.error("No hay datos para exportar.");
                    return;
                }

                // Merge title cells across the columns
                worksheet['!merges'] = worksheet['!merges'] || [];
                worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 6 } }); // A1 to H1 for the added column

                // Style the title row
                worksheet['A1'].s = {
                    font: {
                        bold: true,
                        sz: 20, // Font size
                        color: { rgb: '1F4E79' } // Dark blue text color
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center'
                    }
                };

                // Apply styling for the header row (now starting at row 2)
                const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
                for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                    const cellAddress = XLSX.utils.encode_cell({ r: 1, c: col }); // Header row is now row 1
                    if (!worksheet[cellAddress]) continue;
                    worksheet[cellAddress].s = {
                        fill: {
                            fgColor: { rgb: '1F4E79' } // Dark blue background
                        },
                        font: {
                            bold: true,
                            color: { rgb: 'FFFFFF' } // White text
                        },
                        alignment: {
                            horizontal: 'center'
                        },
                        border: {
                            top: { style: 'thin', color: { rgb: '000000' } },
                            left: { style: 'thin', color: { rgb: '000000' } },
                            bottom: { style: 'thin', color: { rgb: '000000' } },
                            right: { style: 'thin', color: { rgb: '000000' } },
                        }
                    };
                }

                // Apply alternating row colors and borders starting from row 3
                for (let row = 2; row <= headerRange.e.r; row++) {
                    const isEvenRow = row % 2 === 0;
                    for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
                        if (!worksheet[cellAddress]) continue;

                        worksheet[cellAddress].s = {
                            fill: {
                                fgColor: { rgb: isEvenRow ? 'D3D3D3' : 'FFFFFF' } // Light gray for even rows, white for odd rows
                            },
                            border: {
                                top: { style: 'thin', color: { rgb: '000000' } },
                                left: { style: 'thin', color: { rgb: '000000' } },
                                bottom: { style: 'thin', color: { rgb: '000000' } },
                                right: { style: 'thin', color: { rgb: '000000' } },
                            },
                            alignment: {
                                horizontal: 'center'
                            }
                        };
                    }
                }

                // Adjust columns to fit content
                worksheet['!cols'] = [
                    { wch: 10 }, // Adjust width for each column
                    { wch: 15 },
                    { wch: 15 },
                    { wch: 30 },
                    { wch: 10 },
                    { wch: 25 },
                    { wch: 25 }
                ];
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'ExtensionCords');

                // Write file
                XLSX.writeFile(workbook, 'ExtensionCordsData.xlsx');
            } else {
                console.error('No data to export');
            }
        } catch (error) {
            console.error('Error exporting data to Excel:', error);
        }
    };

    const handleEditExtensionCord = (scanner) => {
        setSelectedExtensionCord(scanner);
        setIsEditing(true);
        setShowExtensionCordAddOverlay(true);
    };

    const { loading, data, fetchMore } = useQuery(listExtensionCords, {
        variables: { limit: 100 },
    });

    const [addExtensionCord] = useMutation(createExtensionCord, {
        refetchQueries: [{ query: listExtensionCords, variables: { limit: 100 } }]
    });

    const [removeExtensionCord] = useMutation(deleteExtensionCord, {
        refetchQueries: [{ query: listExtensionCords, variables: { limit: 100 } }]
    });
    const [updExtensionCord] = useMutation(updateExtensionCord, {
        refetchQueries: [{ query: listExtensionCords, variables: { limit: 100 } }]
    });

    const [fetchAllExtensionCords, { loading: countLoading, data: AllExtensionCordsData }] = useLazyQuery(listExtensionCords, {
        variables: { limit: 100 },
        fetchPolicy: "network-only",
    });


    useEffect(() => {
        const fetchAllExtensionCords = async () => {
            let AllExtensionCords = [];
            let currentNextToken = null;

            do {
                const { data } = await fetchMore({
                    variables: { limit: 100, nextToken: currentNextToken },
                });

                AllExtensionCords = [...AllExtensionCords, ...data.listExtensionCords.items];
                currentNextToken = data.listExtensionCords.nextToken;
            } while (currentNextToken);

            setExtensionCord(AllExtensionCords);
            setNextToken(currentNextToken);
        };

        if (data) {
            fetchAllExtensionCords();
        }
    }, [data, fetchMore]);

    const clearNewExtensionCord = () => {
        setNewExtensionCord({
            id: '',
            tagid: '',
            model: '',
            brand: '',
            serial_Number: '',
            price: '',
            store: '',
            purchase_date: '',
            assignment_date: '',
            warranty_date: '',
            returnable_date: '',
            available: '',
            status: '',
            assigned_to: '',
            location: '',
            team: '',
        });
    };

    // Define loadMore function
    const loadMore = () => {
        fetchMore({
            variables: { limit: 100, nextToken },
            updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;
                setNextToken(fetchMoreResult.listExtensionCords.nextToken);
                return {
                    listExtensionCords: {
                        ...fetchMoreResult.listExtensionCords,
                        items: [...prevResult.listExtensionCords.items, ...fetchMoreResult.listExtensionCords.items]
                    }
                };
            }
        });
    };

    const handleInputChange = (e) => {
        const { name, type, checked, value } = e.target;
        let formattedValue = value;

        // Convert to lowercase and trim whitespace for specific fields
        if (['serial_Number'].includes(name)) {
            formattedValue = value.toLowerCase().trim();
        }

        if (isEditing) {
            setSelectedExtensionCord({
                ...selectedExtensionCord,
                [name]: formattedValue
            });
        } else {
            setNewExtensionCord({
                ...newExtensionCord,
                id: uuidv4(),
                [name]: formattedValue
            });
        }
    };

    const handleAddExtensionCord = () => {

        // Formatear la fecha en el formato YYYY-MM-DD si existe, o establecerla en null si está vacía
        const formattedPurchase = newExtensionCord.purchase_date
            ? new Date(newExtensionCord.purchase_date).toISOString().split('T')[0]
            : null;
        const formattedAssignment = newExtensionCord.assignment_date
            ? new Date(newExtensionCord.assignment_date).toISOString().split('T')[0]
            : null;

        const formattedWarranty = newExtensionCord.warranty_date
            ? new Date(newExtensionCord.warranty_date).toISOString().split('T')[0]
            : null;

        const formattedReturnable = newExtensionCord.returnable_date
            ? new Date(newExtensionCord.returnable_date).toISOString().split('T')[0]
            : null;

        // Validate that the price contains only digits and is a valid number
        let priceValue = null;
        if (newExtensionCord.price !== "" && newExtensionCord.price !== null && newExtensionCord.price != undefined) {
            priceValue = parseFloat(newExtensionCord.price);

            if (isNaN(priceValue)) {
                setPriceError('Price must be a valid number.');
                return;
            }
        }
        setPriceError('');

        // Check for duplicates based on `tagid` and `serial_Number`
        const isDuplicate = extensionCord.some(extensionCord =>
            extensionCord.tagid === newExtensionCord.tagid &&
            extensionCord.serial_Number === newExtensionCord.serial_Number
        );

        if (isDuplicate) {
            setDuplicateError('A extensionCord with the same TAG and Serial Number already exists.');
            console.log('Duplicate validation failed');
            return;
        } else {
            setDuplicateError('');
        }

        // Check availability first
        if (newExtensionCord.available === 'true') {

            // If available, status must be Inventario
            if (newExtensionCord.status !== 'inv') {
                setStatusError('If the Extension Cord is available, its status must be "Inventario".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // If available and status is Inventario, assigned_to must be empty or "N/A"
            if (newExtensionCord.assigned_to !== '' && newExtensionCord.assigned_to !== 'n/a') {
                setStatusError('If the Extension Cord is available and its status is "Inventario", the "Assigned to" field must be empty or "N/A".');
                console.log('Assigned_to validation failed');
                return; // Exit function if validation fails
            }

        } else if (newExtensionCord.available !== "") {
            // If not available, status must be one of the allowed statuses
            const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
            if (!allowedStatuses.includes(newExtensionCord.status)) {
                setStatusError('If the Extension Cord is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // Clear error if validation passes
            setStatusError('');
        }


        // If all validations pass, proceed with adding the extensionCord to the database
        addExtensionCord({
            variables: { input: { ...newExtensionCord, price: priceValue, purchase_date: formattedPurchase, warranty_date: formattedWarranty, returnable_date: formattedReturnable, assignment_date: formattedAssignment } }
        })
            .then(() => {
                window.location.reload();
            }).catch(error => {
                console.error("Error adding extensionCord:", error);
            });
    };

    const handleDeleteExtensionCord = () => {

        removeExtensionCord({
            variables: { input: { id: selectedExtensionCord.id } }
        }).then(() => {
            window.location.reload();
        }).catch(error => {
            console.error("Error deleting ExtensionCord", error);
        });

    };

    const handleUpdateExtensionCord = async () => {

        const formattedData_purchase = selectedExtensionCord.purchase_date ? new Date(selectedExtensionCord.purchase_date).toISOString().split('T')[0] : null;
        const formattedData_warranty = selectedExtensionCord.warranty_date ? new Date(selectedExtensionCord.warranty_date).toISOString().split('T')[0] : null;
        const formattedData_returnable = selectedExtensionCord.returnable_date ? new Date(selectedExtensionCord.returnable_date).toISOString().split('T')[0] : null;
        const formattedData_assignment = selectedExtensionCord.assignment_date ? new Date(selectedExtensionCord.assignment_date).toISOString().split('T')[0] : null;

        //Check Price
        let priceValue = null;
        if (selectedExtensionCord.price !== "" && selectedExtensionCord.price !== null && selectedExtensionCord.price != undefined) {
            priceValue = parseFloat(selectedExtensionCord.price);

            if (isNaN(priceValue)) {
                setPriceError('Price must be a valid number.');
                return;
            }
        }
        setPriceError('');

        // Check availability first
        if (selectedExtensionCord.available === 'true') {

            // If available, status must be Inventario
            if (selectedExtensionCord.status !== 'inv') {
                setStatusError('If the Extension Cord is available, its status must be "Inventario".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // If available and status is Inventario, assigned_to must be empty or "N/A"
            if (selectedExtensionCord.assigned_to !== '' && selectedExtensionCord.assigned_to !== 'n/a') {
                setStatusError('If the Extension Cord is available and its status is "Inventario", the "Assigned to" field must be empty or "N/A".');
                console.log('Assigned_to validation failed');
                return; // Exit function if validation fails
            }

        } else if (selectedExtensionCord.available === 'false') {
            // If not available, status must be one of the allowed statuses
            const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
            if (!allowedStatuses.includes(selectedExtensionCord.status)) {
                setStatusError('If the Extension Cord is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // Clear error if validation passes
            setStatusError('');
        }


        updExtensionCord({
            variables: {
                input: {
                    id: selectedExtensionCord.id,
                    tagid: selectedExtensionCord.tagid,
                    model: selectedExtensionCord.model,
                    brand: selectedExtensionCord.brand,
                    serial_Number: selectedExtensionCord.serial_Number,
                    price: priceValue,
                    store: selectedExtensionCord.store,
                    purchase_date: formattedData_purchase,
                    assignment_date: formattedData_assignment,
                    warranty_date: formattedData_warranty,
                    returnable_date: formattedData_returnable,
                    available: selectedExtensionCord.available,
                    status: selectedExtensionCord.status,
                    assigned_to: selectedExtensionCord.assigned_to,
                    location: selectedExtensionCord.location,
                    team: selectedExtensionCord.team,

                }
            }
        }).then(() => {
            setPriceError('');
            setStatusError('');
            setDuplicateError('');
            setShowExtensionCordAddOverlay(false);

        }).catch(error => {
            console.error("Error updating ExtensionCord:", error);
        });
    };

    const handleSelectExtensionCord = (extensionCord) => {

        for (const [key, value] of Object.entries(extensionCord)) {
            console.log(`${key}: ${typeof value}`);
        }
        const formatDateForInput = (dateString) => {
            if (!dateString) return "";  // Si la fecha es nula o indefinida, retorna una cadena vacía
            const date = new Date(dateString);
            // Ajustar la fecha para evitar la conversión a UTC
            const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
            return localDate.toISOString().split('T')[0];
        };

        setNewExtensionCord({
            id: extensionCord.id,
            tagid: extensionCord.tagid,
            model: extensionCord.model,
            brand: extensionCord.brand,
            serial_Number: extensionCord.serial_Number,
            price: extensionCord.price,
            store: extensionCord.store,
            purchase_date: formatDateForInput(extensionCord.purchase_date),
            assignment_date: formatDateForInput(extensionCord.assignment_date),
            warranty_date: formatDateForInput(extensionCord.warranty_date),
            returnable_date: formatDateForInput(extensionCord.returnable_date),
            available: extensionCord.available,
            status: extensionCord.status,
            assigned_to: extensionCord.assigned_to,
            location: extensionCord.location,
            team: extensionCord.team,

        });
        setShowExtensionCordAddOverlay(true);
        setIsEditing(true);
        setSelectedExtensionCord(extensionCord);
    };

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedExtensionCords = React.useMemo(() => {
        let sortableExtensionCords = [...extensionCord];
        if (sortConfig !== null) {
            sortableExtensionCords.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableExtensionCords;
    }, [extensionCord, sortConfig]);

    //listUsers 
    const users = React.useMemo(() => {
        if (userData && userData.listUsers) {
            return userData.listUsers.items || [];
        }
        return [];
    }, [userData]);
    if (userError) return <p>Error loading users: {userError.message}</p>;
    if (loading1) return <p>Error loading users: {userError.message}</p>;

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredExtensionCords = sortedExtensionCords.filter(extensionCord => {
        let fieldValue = extensionCord[searchField].toString().toLowerCase();

        // Convertir "true" a "yes" y "false" a "no"
        if (fieldValue === "true") {
            fieldValue = "yes";
        } else if (fieldValue === "false") {
            fieldValue = "no";
        }

        return fieldValue.includes(searchQuery.toLowerCase());
    });

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(dateString);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

        return localDate.toLocaleDateString(undefined, options);
    };

    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    const formatPrice = (price) => {
        if (price === null || price === undefined || price === '') {
            return '';
        }
        const parsedPrice = parseFloat(price);
        return isNaN(parsedPrice) ? '' : parsedPrice.toFixed(2);
    };



    return (
        <div className="extensionCord-page">

            <header className="inventory-header">
                <h1>Extension Cord</h1>
            </header>
            <Inventory_header/>
            <div className="inventory-search">
                <button onClick={handleSaveAsExcel}> <FaFileExcel /> Export </button>

                <input
                    type="text"
                    placeholder="Search by TAG, Available, Model, Assigned to"
                    value={searchQuery}
                    onChange={handleSearch}
                />
                <select onChange={(e) => setSearchField(e.target.value)} defaultValue="">
                    <option value="" disabled hidden>Select Option</option>
                    <option value="tagid">TAG</option>
                    <option value="available">Available</option>
                    <option value="status">Status</option>
                    <option value="brand">Brand</option>
                    <option value="serial_Number">Serial Number</option>
                    <option value="store">Store</option>
                    <option value="assigned_to">Assigned to</option>
                </select>
            </div>


            <button className="add-extensionCord-btn" onClick={() => {
                clearNewExtensionCord();
                setSelectedExtensionCord(null);
                setIsEditing(false);
                setShowExtensionCordAddOverlay(true);
            }}>+ Add New Extension Cord</button>

            <div className="extensionCord-table-container" >
                <table className="extensionCord-table">

                    <thead>
                        <tr>
                            <th>#</th> {/* Columna para contar las filas */}

                            <th onClick={() => requestSort('tagid')}>
                                TAG
                                {sortConfig?.key === 'tagid' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('model')}>
                                Model
                                {sortConfig?.key === 'model' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('brand')}>
                                Brand
                                {sortConfig?.key === 'brand' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('serial_Number')}>
                                Serial Number
                                {sortConfig?.key === 'serial_Number' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('price')}>
                                Price
                                {sortConfig?.key === 'price' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('store')}>
                                Store
                                {sortConfig?.key === 'store' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('purchase_date')}>
                                Purchase Date
                                {sortConfig?.key === 'purchase_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('assignment_date')}>
                                Assignment Date
                                {sortConfig?.key === 'assignment_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('warranty_date')}>
                                Warranty Date
                                {sortConfig?.key === 'warranty_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('returnable_date')}>
                                Returnable Date
                                {sortConfig?.key === 'returnable_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('available')}>
                                Available
                                {sortConfig?.key === 'available' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('status')}>
                                Status
                                {sortConfig?.key === 'status' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('assigned_to')}>
                                Assigned To
                                {sortConfig?.key === 'assigned_to' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('location')}>
                                Location
                                {sortConfig?.key === 'location' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('team')}>
                                Team
                                {sortConfig?.key === 'team' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th >Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredExtensionCords.map((extensionCord, index) => (
                            <tr key={extensionCord.id} onClick={() => handleEditExtensionCord(extensionCord)} >
                                <td>{index + 1}</td> {/* Número de fila */}
                                <td className="extensionCord-tagid">{capitalize(extensionCord.tagid)}</td>
                                <td className="extensionCord-model">{capitalize(extensionCord.model.toLowerCase())}</td>
                                <td className="extensionCord-brand">{capitalize(extensionCord.brand.toLowerCase())}</td>
                                <td className="extensionCord-serial_Number">{extensionCord.serial_Number.toUpperCase()}</td>
                                <td className="extensionCord-price">${formatPrice(extensionCord.price)}</td>
                                <td className="extensionCord-store">{extensionCord.store}</td>
                                <td className="extensionCord-purchase_date">{extensionCord.purchase_date ? formatDate(extensionCord.purchase_date) : 'No Date'}</td>
                                <td className="extensionCord-assignment_date">{extensionCord.assignment_date ? formatDate(extensionCord.assignment_date) : 'No Date'}</td>
                                <td className="extensionCord-warranty_date">{extensionCord.warranty_date ? formatDate(extensionCord.warranty_date) : 'No Date'}</td>
                                <td className="extensionCord-returnable_date">{extensionCord.returnable_date ? formatDate(extensionCord.returnable_date) : 'No Date'}</td>
                                <td className="extensionCord-available">{extensionCord.available ? 'Yes' : 'No'} </td>
                                <td className="extensionCord-status">
                                    {extensionCord.status === 'broken' ? 'Broken' :
                                        extensionCord.status === 'use' ? 'Use' :
                                            extensionCord.status === 'transfer' ? 'Transfer' :
                                                extensionCord.status === 'lost' ? 'Lost' :
                                                    extensionCord.status === 'inv' ? 'Inventario' :
                                                        'Unknown'} {/* Muestra 'Unknown' si el estado no coincide con ninguno de los anteriores */}
                                </td>
                                <td className="extensionCord-assigned_to">{extensionCord.assigned_to}</td>
                                <td className="extensionCord-location">{extensionCord.location}</td>
                                <td className="extensionCord-team">{extensionCord.team}</td>
                                <td className='extensionCord-table'>
                                    <button className="delete-extensionCord-btn" onClick={() => handleSelectExtensionCord(extensionCord)}>Delete/Update</button>

                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {nextToken && !loading && (
                <button onClick={loadMore}>Load More</button>
            )}


            {loading && <p>Loading...</p>}

            {showExtensionCordAddOverlay && (

                <div className="extensionCord_add_overlay">
                    <div className="overlay-content-inventory">
                        <div className="header-row">
                            <h2 className="header-title">{isEditing ? 'Edit ExtensionCord' : 'Add New ExtensionCord'}</h2>
                            <button onClick={() => {
                                setShowExtensionCordAddOverlay(false);
                                clearNewExtensionCord();
                                setSelectedExtensionCord(null);
                                setIsEditing(false);
                            }}>X</button>
                        </div>

                        <label>TAG:</label><input name="tagid" placeholder="TAG" value={isEditing ? selectedExtensionCord.tagid : newExtensionCord.tagid} onChange={handleInputChange} required />
                        <label>Model:</label><input name="model" placeholder="Model" value={isEditing ? selectedExtensionCord.model : newExtensionCord.model} onChange={handleInputChange} required />

                        <label>Brand:</label><input name="brand" placeholder="Brand" value={isEditing ? selectedExtensionCord.brand : newExtensionCord.brand} onChange={handleInputChange} />
                        <label>Serial Number:</label><input name="serial_Number" placeholder="Serial Number" value={isEditing ? selectedExtensionCord.serial_Number : newExtensionCord.serial_Number} onChange={handleInputChange} required />

                        <label>Price:</label><input name="price" placeholder="price" value={isEditing ? selectedExtensionCord.price : newExtensionCord.price} onChange={handleInputChange} />
                        <label>Store:</label><input name="store" placeholder="store" value={isEditing ? selectedExtensionCord.store : newExtensionCord.store} onChange={handleInputChange} />

                        <label htmlFor="purchase_date">Purchase Date:</label>
                        <input
                            type="Date"
                            id="purchase_date"
                            name="purchase_date"
                            value={isEditing ? selectedExtensionCord.purchase_date : newExtensionCord.purchase_date}
                            onChange={handleInputChange}

                        />

                        <label htmlFor="assignment_date">Assignment Date:</label>
                        <input
                            type="date"
                            id="assignment_date"
                            name="assignment_date"
                            value={isEditing ? selectedExtensionCord.assignment_date : newExtensionCord.assignment_date}
                            onChange={handleInputChange}

                        />
                        <label htmlFor="warranty_date">Warranty Date:</label>
                        <input
                            type="date"
                            id="warranty_date"
                            name="warranty_date"
                            value={isEditing ? selectedExtensionCord.warranty_date : newExtensionCord.warranty_date}
                            onChange={handleInputChange}


                        />
                        <label htmlFor="returnable_date">Returnable Date:</label>
                        <input
                            type="date"
                            id="returnable_date"
                            name="returnable_date"
                            value={isEditing ? selectedExtensionCord.returnable_date : newExtensionCord.returnable_date}
                            onChange={handleInputChange}

                        />

                        <label>Available:</label>

                        <select name="available" placeholder="available" value={isEditing ? selectedExtensionCord.available : newExtensionCord.available} onChange={handleInputChange} >
                            <option value="" disabled hidden className="placeholder-option">Select Availability</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>

                        </select>
                        <label>Status:</label>
                        <select name="status" placeholder="status" value={isEditing ? selectedExtensionCord.status : newExtensionCord.status} onChange={handleInputChange} >
                            <option value="" disabled hidden>Select Status</option>
                            <option value="use">Use</option>
                            <option value="broken">Broken</option>
                            <option value="transfer">Transfer</option>
                            <option value="lost">Lost</option>
                            <option value="inv">Inventario</option>
                        </select>
                        <label>Assigned To:</label>
                        <select
                            name="assigned_to"
                            value={isEditing ? selectedExtensionCord.assigned_to : newExtensionCord.assigned_to}
                            onChange={handleInputChange}
                        >
                            <option value="">Select an Employee</option>

                            {users.length > 0 ? (
                                [...users].sort((a, b) => {
                                    const nameA = `${a.first_name} ${a.last_name}`.toLowerCase();
                                    const nameB = `${b.first_name} ${b.last_name}`.toLowerCase();
                                    return nameA.localeCompare(nameB);
                                }).map(user => (
                                    <option key={user.id} value={`${capitalize(user.first_name)} ${capitalize(user.last_name)}`}>
                                        {capitalize(user.first_name)} {capitalize(user.last_name)}
                                    </option>
                                ))
                            ) : (

                                <option value="">No users available</option>
                            )}
                            <option value="n/a">N/A</option> {/*N/A */}

                        </select>
                        <label>Location:</label><input name="location" placeholder="location" value={isEditing ? selectedExtensionCord.location : newExtensionCord.location} onChange={handleInputChange} />
                        <label>Team:</label><input name="team" placeholder="team" value={isEditing ? selectedExtensionCord.team : newExtensionCord.team} onChange={handleInputChange} />

                        {duplicateError && <p className="error">{duplicateError}</p>} {/* Display duplicate error message */}
                        {priceError && <p className="error">{priceError}</p>} {/* Display price error message */}
                        {statusError && <p className="error">{statusError}</p>} {/* Display duplicate error message */}

                        <div className="button-group">
                            <button className="overlay-add-extensionCord" onClick={isEditing ? handleUpdateExtensionCord : handleAddExtensionCord} >
                                {isEditing ? 'Update Extension Cord' : 'Add Extension Cord'}
                            </button>
                            {isEditing && (
                                <button
                                    className="overlay-delet-extensionCord"
                                    onClick={() => {
                                        if (window.confirm('Are you sure you want to delete this extensionCord?')) {
                                            handleDeleteExtensionCord();
                                        }
                                    }}
                                >
                                    Delete Extension Cord
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}

        </div>


    );

};

export default ExtensionCord;
