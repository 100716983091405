// AzureFunctions.jsx
import { PublicClientApplication } from "@azure/msal-browser";
import moment from 'moment';

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
    redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URI,
  },
};

const msalInstance = new PublicClientApplication(msalConfig);


let isInitialized = false;

const initializeMSAL = async () => {
  if (!isInitialized) {
    await msalInstance.initialize();
    isInitialized = true;
  }
};
export const signIn = async (redirect = null) => {
  try {
    await initializeMSAL(); // Ensure MSAL is initialized

    // First attempt to acquire the token silently
    const silentTokenResponse = await msalInstance.acquireTokenSilent({
      scopes: ["User.Read", "Mail.Send", "Calendars.ReadWrite"],
    });

    if (silentTokenResponse) {
      msalInstance.setActiveAccount(silentTokenResponse.account);
      return silentTokenResponse.accessToken;
    }
  } catch (silentError) {
    console.warn("Silent token acquisition failed, attempting interactive login:", silentError);
  }

  // If silent acquisition fails, prompt the user with the login popup
  try {
    const loginResponse = await msalInstance.loginPopup({
      scopes: ["User.Read", "Mail.Send", "Calendars.ReadWrite"],
      state: JSON.stringify({ redirect }),
    });

    msalInstance.setActiveAccount(loginResponse.account);

    const tokenResponse = await msalInstance.acquireTokenSilent({
      scopes: ["User.Read", "Mail.Send", "Calendars.ReadWrite"],
      account: loginResponse.account,
    });

    return tokenResponse.accessToken;
  } catch (popupError) {
    console.error("Failed to acquire token with popup:", popupError);

    // If the user closed the dialog, show a prompt and attempt again
    if (popupError.name === "PopupWindowError") {
      if (window.confirm("Authentication required. Please log in to proceed.")) {
        try {
          // Retry showing the popup to the user
          const retryLoginResponse = await msalInstance.loginPopup({
            scopes: ["User.Read", "Mail.Send", "Calendars.ReadWrite"],
            state: JSON.stringify({ redirect }),
          });

          msalInstance.setActiveAccount(retryLoginResponse.account);

          const retryTokenResponse = await msalInstance.acquireTokenSilent({
            scopes: ["User.Read", "Mail.Send", "Calendars.ReadWrite"],
            account: retryLoginResponse.account,
          });

          return retryTokenResponse.accessToken;
        } catch (retryError) {
          console.error("Retry login failed:", retryError);
          throw new Error("Authentication failed after retry");
        }
      }
    }

    throw new Error("Failed to acquire token");
  }
};


// Helper function to calculate end time based on start time and duration (in minutes)
const calculateEndTime = (startTime, duration) => {
  const start = moment(startTime);  // Parse the start time
  return start.add(duration, 'minutes').format('YYYY-MM-DDTHH:mm:ss');  // Add duration and format
};

const updateTimeInDate = (existingDate, newTime) => {
  // Check if existingDate is a Date object, if not, convert it to a Date object
  const parsedDate = typeof existingDate === 'string' ? new Date(existingDate) : existingDate;

  // Ensure parsedDate is now in ISO format, extracting only the date part (YYYY-MM-DD)
  const datePart = parsedDate.toISOString().split('T')[0]; // e.g., '2024-09-09'

  // Format the new time by appending seconds
  const timePart = `${newTime}:00`; // e.g., '14:30' becomes '14:30:00'

  // Return the new combined ISO 8601 date-time string
  return `${datePart}T${timePart}`;  // e.g., '2024-09-09T14:30:00'
};

export const handleOutlookEvent = async (appointmentData, accessToken, eventId = null) => {
  const eventEndpoint = eventId
    ? `https://graph.microsoft.com/v1.0/me/events/${eventId}`
    : 'https://graph.microsoft.com/v1.0/me/events';

  const method = eventId ? 'PATCH' : 'POST';

  // Ensure utility functions exist to handle time formatting correctly
  const formattedStartTime = updateTimeInDate(appointmentData.date, appointmentData.time);
  const endTime = calculateEndTime(formattedStartTime, appointmentData.duration);

  const eventDetails = {
    subject: appointmentData.title || 'Untitled Appointment',
    body: {
      contentType: 'HTML',
      content: appointmentData.notes || 'No additional details provided.'
    },
    start: {
      dateTime: formattedStartTime,
      timeZone: 'America/Chicago'  // Adjust timezone as needed
    },
    end: {
      dateTime: endTime,
      timeZone: 'America/Chicago'
    },
    attendees: appointmentData.attendees ? appointmentData.attendees.split(',').map(email => ({
      emailAddress: { address: email.trim() },
      type: 'required'
    })) : [],
    location: { displayName: appointmentData.location || 'TBD' }
  };

  // Log important variables for debugging
  // console.log("Access Token (length):", accessToken ? accessToken.length : "No Token");
  // console.log("Event Endpoint:", eventEndpoint);
  // console.log("Event Method:", method);
  // console.log("Event Details:", JSON.stringify(eventDetails, null, 2));

  try {
    const response = await fetch(eventEndpoint, {
      method,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(eventDetails)
    });

    // Handle the response
    const eventData = await response.json();
    
    if (response.ok) {
      // console.log("Event successfully created/updated in Outlook:", eventData);
      return eventData.id; // Return the eventId from the response
    } else {
      console.error("Error response from Outlook:", eventData);
      throw new Error(`Failed to ${eventId ? 'update' : 'create'} event in Outlook: ${eventData.error?.message || 'Unknown error'}`);
    }
  } catch (error) {
    console.error("Failed to interact with Outlook API:", error);
    throw error;  // Re-throw error after logging it
  }
};


export const deleteOutlookEvent = async (eventId, accessToken) => {
  if (!eventId) {
    throw new Error("Event ID is required to delete an event");
  }

  const eventEndpoint = `https://graph.microsoft.com/v1.0/me/events/${eventId}`;

  const response = await fetch(eventEndpoint, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(`Failed to delete event in Outlook: ${errorData.error.message}`);
  }

  return true; // Return success if the event is deleted
};

export const updateOutlookEventStatus = async (eventId, status, accessToken) => {
  if (!eventId) {
    throw new Error("Event ID is required to update an event");
  }

  if (status !== 'canceled') {
    throw new Error("Only 'canceled' status is supported for now");
  }

  const eventEndpoint = `https://graph.microsoft.com/v1.0/me/events/${eventId}`;

  const response = await fetch(eventEndpoint, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      isCancelled: true, // This marks the event as canceled
      responseStatus: {
        response: 'declined', // This marks the response status of attendees
        time: new Date().toISOString(), // Set the time the status was updated
      },
    }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(`Failed to update event status in Outlook: ${errorData.error.message}`);
  }

  return true; // Return success if the event status is updated
};


// Function to send emails using fetch
export const sendEmail = async (accessToken, emailContent) => {
  const sendMailEndpoint = 'https://graph.microsoft.com/v1.0/me/sendMail';

  const emailMessage = {
    message: {
      subject: emailContent.subject,
      body: {
        contentType: "HTML",
        content: emailContent.body,
      },
      toRecipients: [
        {
          emailAddress: {
            address: emailContent.to,
          },
        },
      ],
    },
    saveToSentItems: "true", // Save the email to sent items
  };

  try {
    const response = await fetch(sendMailEndpoint, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(emailMessage),
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Error response from Outlook:', errorData);
      throw new Error(`Failed to send email: ${errorData.error?.message || 'Unknown error'}`);
    } else {
      console.log('Email sent successfully!');
    }
  } catch (error) {
    console.error('Error sending email:', error);
    throw error;
  }
};
