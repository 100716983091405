import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { fetchAuthSession } from 'aws-amplify/auth'; // Use the new fetchAuthSession method
import { listAppointments, listUsersByTeamOne, listClientFix2s } from '../../graphql/queries';
import { createAppointment, updateAppointment, deleteAppointment } from '../../graphql/mutations';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { handleAddAppointment, handleUpdateAppointment, handleDeleteAppointment, handleCancelAppointment, generateDurationOptions, formatDuration } from '../Appointments/AppointmentHelperFuncs'; // Import the refactored function
import ClientForm from '../../components/ClientForm'; // Adjust the import path
import './Calendar.css';

const localizer = momentLocalizer(moment);

const Calendar = () => {
    const client = useApolloClient(); // Get Apollo Client
    const [appointments, setAppointments] = useState([]);
    const [nextToken, setNextToken] = useState(null);
    const [showAppointmentAddOverlay, setShowAppointmentAddOverlay] = useState(false);
    const [showClientFormOverlay, setShowClientFormOverlay] = useState(false);

    const [isEditing, setIsEditing] = useState(false);
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [newAppointment, setNewAppointment] = useState({
        id: '',
        title: '',
        customer: '',
        location: '',
        date: '',
        recurrence: '',
        service: '',
        navigator: '',
        time: '',      // Added time
        duration: '',  // Added duration
        notes: ''
    });

    // Search Client Variables
    const [clientSearchTerm, setClientSearchTerm] = useState('');
    const [matchingClients, setMatchingClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [clientIsEditing, setClientIsEditing] = useState(false);
    const [selectedClientEmail, setSelectedClientEmail] = useState(null);
    const [allClients, setAllClients] = useState([]); // New state for all clients
    const [isFetchingClients, setIsFetchingClients] = useState(false);

    // State to track user's group
    const [userGroup, setUserGroup] = useState(null);
    
    useEffect(() => {
        // Fetch the user's group when the component mounts
        const fetchUserGroup = async () => {
            try {
                const session = await fetchAuthSession();
                const groups = session.tokens.accessToken.payload["cognito:groups"]; // Get the user's groups
                if (groups) {
                    setUserGroup(groups); // Assuming a user belongs to one group. Adjust as needed.
                }
            } catch (error) {
                console.error('Error fetching user group:', error);
            }
        };

        fetchUserGroup();
    }, []);
    // Functions for sending email
    const fetchAllClients = async (nextToken = null, accumulatedItems = []) => {
        try {
            const { data } = await client.query({
                query: listClientFix2s,
                variables: {
                    nextToken, // Use nextToken for pagination
                    limit: 100 // Adjust limit as necessary, e.g., 100
                },
            });

            const currentItems = data.listClientFix2s.items;
            const allItems = [...accumulatedItems, ...currentItems];

            // If there's a nextToken, continue fetching more pages
            if (data.listClientFix2s.nextToken) {
                return fetchAllClients(data.listClientFix2s.nextToken, allItems);
            } else {
                console.log('Total clients fetched:', allItems.length); // Debug statement
                return allItems;
            }
        } catch (error) {
            console.error('Error fetching clients:', error);
            return accumulatedItems;
        }
    };

    const handleClientSearchChange = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        setClientSearchTerm(searchTerm);

        if (searchTerm === '') {
            setMatchingClients(allClients);
        } else {
            const filteredClients = allClients.filter(client =>
                client.name.toLowerCase().includes(searchTerm) ||
                client.last_name.toLowerCase().includes(searchTerm)
            );
            setMatchingClients(filteredClients);
        }
    };

    const handleAddAppointmentClick = async () => {
        setShowAppointmentAddOverlay(true);
        setIsEditing(false);
        setNewAppointment({
            id: '',
            title: '',
            customer: '',
            location: '',
            date: '',
            recurrence: '',
            service: '',
            navigator: '',
            time: '',      // Added time
            duration: '',  // Added duration
            notes: ''
        });
        // Fetch all clients
        try {
            const allClientsData = await fetchAllClients();
            setAllClients(allClientsData);
            setMatchingClients(allClientsData);
        } catch (error) {
            console.error('Error fetching clients:', error);
            setAllClients([]);
            setMatchingClients([]);
        }
    };

    // Handle client selection
    const handleClientSelect = (client) => {
        const customerName = `${client.name} ${client.last_name}`;
        setNewAppointment({
            ...newAppointment,
            customer: customerName
        });
        setClientSearchTerm(customerName); // Set the selected name in the search input field
        setSelectedClientEmail(client.email); // Store the email for sending a confirmation email
        setMatchingClients([]); // Hide the dropdown after selection
    };
    
    const handleClientAdded = () => {
        // Perform any actions needed after a client is added
    };

    const [createAppt] = useMutation(createAppointment, {
        refetchQueries: [{ query: listAppointments, variables: { limit: 100 } }]
    });

    const [updateAppt] = useMutation(updateAppointment, {
        refetchQueries: [{ query: listAppointments, variables: { limit: 100 } }]
    });

    const [deleteAppt] = useMutation(deleteAppointment, {
        refetchQueries: [{ query: listAppointments, variables: { limit: 100 } }]
    });
    const { loading: usersLoading, error: usersError, data: usersData } = useQuery(listUsersByTeamOne);
    
    const { loading, data, fetchMore } = useQuery(listAppointments, {
        variables: { limit: 100 },
    });
    const navigatorOptions = usersData?.listUsers?.items?.map(user => ({
        id: user.id,
        name: `${user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1).toLowerCase()} ${user.last_name.charAt(0).toUpperCase() + user.last_name.slice(1).toLowerCase()}`
    })) || [];


    useEffect(() => {
        if (data) {
            const formattedAppointments = data.listAppointments.items.map(appt => {
                const start = moment(appt.date).toDate(); // Convert date to a JavaScript Date object
                const duration = appt.duration || 30; // Default to 60 minutes if no duration is specified
                const end = moment(start).add(duration, 'minutes').toDate(); // Add duration to get the end time

                return {
                    ...appt,
                    start,  // Start time for the calendar
                    end,    // End time for the calendar
                    title: appt.title, // Use the title of the appointment as the event label
                };
            });

            setAppointments(formattedAppointments);
            setNextToken(data.listAppointments.nextToken);
        }
    }, [data]);

    const handleAddNewClient = () => {
        setClientIsEditing(false);
        setSelectedClient(null);
        setShowClientFormOverlay(true);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewAppointment({
            ...newAppointment,
            [name]: value
        });
    };

    const handleSelectSlot = async (slotInfo) => {
        setNewAppointment({
            id: '',
            title: '',
            customer: '',
            location: '',
            date: '',
            recurrence: '',
            service: '',
            navigator: '',
            time: '',      // Added time
            duration: '',  // Added duration
            notes: ''
        });
        setShowAppointmentAddOverlay(true);
        setIsEditing(false);
    
        // Fetch all clients (same as handleAddAppointmentClick)
        try {
            const allClientsData = await fetchAllClients();
            setAllClients(allClientsData);
            setMatchingClients(allClientsData);
        } catch (error) {
            console.error('Error fetching clients:', error);
            setAllClients([]);
            setMatchingClients([]);
        }
    };

    const handleSelectAppointment = (appt) => {
        setNewAppointment({
            id: appt.id,
            title: appt.title,
            customer: appt.customer,
            location: appt.location,
            date: appt.date,
            recurrence: appt.recurrence,
            service: appt.service,
            time: appt.time,
            duration: appt.duration,
            navigator: appt.navigator,
            notes: appt.notes
        });
        setShowAppointmentAddOverlay(true);
        setIsEditing(true);
        setSelectedAppointment(appt);
    };

    const onAddAppointment = async () => {
        await handleAddAppointment(newAppointment, selectedClientEmail, setShowAppointmentAddOverlay, createAppt);
    };
    const onUpdateAppointment = async () => {
        await handleUpdateAppointment(
            newAppointment,
            setShowAppointmentAddOverlay,
            setNewAppointment,
            setIsEditing,
            setSelectedAppointment,
            updateAppt
        );
    };
    const onDeleteAppointment = async () => {
        if (window.confirm('Are you sure you want to delete this appointment?')) {
            await handleDeleteAppointment(
                selectedAppointment,
                setShowAppointmentAddOverlay,
                setIsEditing,
                setSelectedAppointment,
                deleteAppt
            );
        }
    };

    const onCancelAppointment = async () => {
        if (window.confirm('Are you sure you want to cancel this appointment?')) {
            console.log("printing this ", selectedAppointment)
            await handleCancelAppointment(
                selectedAppointment,
                setShowAppointmentAddOverlay,
                setIsEditing,
                setSelectedAppointment,
                updateAppt,
                selectedAppointment.clientEmail
            );
        }
    };

    const isAddAppointmentDisabled = newAppointment.date === '';

    { usersLoading && <p>Loading navigators...</p> }
    { usersError && <p>Error loading navigators: {usersError.message}</p> }

    return (
        <div className="calendar-page">
            <header className="calendar-header">
                <h1>Calendar</h1>
            </header>
            <BigCalendar
                localizer={localizer}
                events={appointments}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500 }}
                selectable
                onSelectSlot={handleSelectSlot}
                onSelectEvent={handleSelectAppointment}
            />
            {showAppointmentAddOverlay && (
                <div className="event-add-overlay">
                    <div className="overlay-content-appointments">
                        <button className="close-btn_appointments" onClick={() => setShowAppointmentAddOverlay(false)}>X</button>
                        <h2>{isEditing ? 'Edit Appointment' : 'Add New Appointment'}</h2>
                        <input required name="id" placeholder="ID" value={newAppointment.id} onChange={handleInputChange} disabled />
                        <div className="appointment-form-grid">
                            {/* Customer on its own row */}
                            <div className="form-group full-width">
                                <label htmlFor="customer">Customer</label>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        id="customer"
                                        type="text"
                                        placeholder="Search customer..."
                                        value={clientSearchTerm}
                                        onChange={handleClientSearchChange}
                                        style={{ flex: 1 }} // Adjust the input to use available space
                                    />
                                    <button
                                        className="add-client-btn"
                                        onClick={() => handleAddNewClient()}
                                        title="Add New Client"
                                    >
                                        +
                                    </button>
                                </div>
                                {/* Display matching clients */}
                                {matchingClients.length > 0 && (
                                    <ul className="client-suggestions">
                                        {matchingClients.map((client) => (
                                            <li key={client.id} onClick={() => handleClientSelect(client)}>
                                                {client.name} {client.last_name}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                {isFetchingClients && <p>Loading clients...</p>}
                            </div>

                            {/* Location on its own row */}
                            <div className="form-group full-width">
                                <label htmlFor="location">Location</label>
                                <select
                                    id="location"
                                    value={newAppointment.location}
                                    onChange={handleInputChange}
                                    name='location'
                                >
                                    <option value="" disabled>Select a location</option>  {/* Disabled option */}
                                    <option value={"The Health Collaborative @ MAUC (78207)"}>The Health Collaborative @ MAUC (78207)</option>
                                </select>
                            </div>

                            {/* Date and Recurring in two columns */}
                            <div className="form-group">
                                <label htmlFor="date">Date</label>
                                <input
                                    required type="date"
                                    name="date"
                                    placeholder="Date Date"
                                    value={moment(newAppointment.date).format('YYYY-MM-DD')}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="recurrence">Recurring</label>
                                <select
                                    name="recurrence"
                                    value={newAppointment.recurrence}
                                    onChange={handleInputChange}
                                >
                                    <option value="" disabled>Select recurrence</option>  {/* Disabled option */}
                                    <option value={"Not Recurring"}>Not Recurring</option>
                                    <option value={"Daily"}>Daily</option>
                                    <option value={"Weekly"}>Weekly</option>
                                    <option value={"Monthly"}>Monthly</option>
                                </select>
                            </div>

                            {/* Service and Navigator in two columns */}
                            <div className="form-group">
                                <label htmlFor="service">Service</label>
                                <select
                                    name="service"
                                    value={newAppointment.service}
                                    onChange={handleInputChange}
                                >
                                    <option value="" disabled>Select a service</option>  {/* Disabled option */}
                                    <option value="Health Insurance / Seguro Medico">Health Insurance / Seguro Medico</option>
                                    <option value="Medicaid / Chip">Medicaid / Chip</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="navigator">Navigator</label>
                                <select required name="navigator" value={newAppointment.navigator} onChange={handleInputChange}>
                                    <option value="" disabled>Select a navigator</option>
                                    {navigatorOptions.map(option => (
                                        <option key={option.id} value={option.name}>{option.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group-row">
                                <div className="form-group">
                                    <label htmlFor="time">Time</label>
                                    <input
                                        type="time"
                                        name="time"
                                        value={newAppointment.time}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="duration">Duration</label>
                                    <select
                                        name="duration"
                                        value={newAppointment.duration}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="" disabled>Select a duration</option>  {/* Disabled option */}
                                        {generateDurationOptions()}
                                    </select>
                                </div>
                            </div>
                            {/* Booking Notes on its own row */}
                            <div className="form-group full-width notes">
                                <label htmlFor="Booking Notes">Booking Notes</label>
                                <textarea name="notes" value={newAppointment.notes} onChange={handleInputChange} className="notes-input" />
                            </div>
                        </div>
                        <div className="button-group">
                            <button
                                className="add-btn"
                                onClick={isEditing ? onUpdateAppointment : onAddAppointment}
                                disabled={isAddAppointmentDisabled}>
                                {isEditing ? "Update Appointment" : "Add Appointment"}
                            </button>
                            {isEditing && (
                                <>
                                  <button className="delete-btn" onClick={onCancelAppointment} disabled={!selectedAppointment}>
                                    Cancel Appointment
                                  </button>
                              
                                  {/* Show Delete button only for users in the dev group */}
                                  {userGroup?.includes('dev') && (
                                    <button className="delete-btn" onClick={onDeleteAppointment} disabled={!selectedAppointment}>
                                      Delete Appointment
                                    </button>
                                  )}
                                </>
                              )}
                        </div>
                    </div>
                </div>
            )}
            {showClientFormOverlay && (
                <div className="client-form-overlay">
                    <div className="overlay-content-client">
                        <ClientForm
                            isEditing={clientIsEditing}
                            selectedClient={selectedClient}
                            onClientAdded={handleClientAdded}
                            onClose={() => setShowClientFormOverlay(false)}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Calendar;
