import React from 'react';

const Section9 = ({ formData, handleInputChange }) => (
  <>
    {/* Section 9 */}
    <div className="form-section flyer-section">
      <div className="flyer-header">Coverage Type</div>
      <label className="required-asterisk">Applied for:</label>
      <div className="radio-group">
        <label>
          <input
            type="radio"
            name="coverageAppliedFor"
            value="ACA"
            checked={formData.coverageAppliedFor === 'ACA'}
            onChange={handleInputChange}
            required
          />
          ACA
        </label>
        <label>
          <input
            type="radio"
            name="coverageAppliedFor"
            value="CHIP"
            checked={formData.coverageAppliedFor === 'CHIP'}
            onChange={handleInputChange}
            required
          />
          CHIP
        </label>
        <label>
          <input
            type="radio"
            name="coverageAppliedFor"
            value="Medicaid"
            checked={formData.coverageAppliedFor === 'Medicaid'}
            onChange={handleInputChange}
            required
          />
          Medicaid
        </label>
        <label>
          <input
            type="radio"
            name="coverageAppliedFor"
            value="ACA & Other"
            checked={formData.coverageAppliedFor === 'ACA & Other'}
            onChange={handleInputChange}
            required
          />
          ACA & Other
        </label>
        <label>
          <input
            type="radio"
            name="coverageAppliedFor"
            value="Other"
            checked={formData.coverageAppliedFor === 'Other'}
            onChange={handleInputChange}
            required
          />
          Other:
        </label>

        {formData.coverageAppliedFor === 'Other' && (
          <input
            type="text"
            name="OtherCoverage"
            value={formData.OtherCoverage || ''}
            onChange={handleInputChange}
            placeholder="Your answer"
            required
          />
        )}
      </div>
    </div>
  </>
);

export default Section9;
