import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { listMice, listUsers } from '../../graphql/queries';
import { createMouse, deleteMouse, updateMouse, createDeleteEquipmentHistory } from '../../graphql/mutations';
import './Mouse.css';
import { FaFileExcel } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import XLSX from 'xlsx-js-style';
import Inventory_header from '../../components/layout/Inventory_header';


const MouseTable = () => {

  const [mouses, setMouses] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [showMouseAddOverlay, setShowMouseAddOverlay] = useState(false);
  const [selectedMouse, setSelectedMouse] = useState(null);
  const [activeFilter, setActiveFilter] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [newMouse, setNewMouse] = useState({
    id: '',
    tagId: '',
    model: '',
    brand: '',
    serial_Number: '',
    wireless: '',
    usb: '',
    combo: '',
    price: '',
    store: '',
    purchase_date: '',
    assignment_date: '',
    warranty_date: '',
    returnable_date: '',
    available: '',
    status: '',
    assigned_to: '',
    location: '',
    team: '',
  });

  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'ascending' }); // Initialized with default values
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFilterColumn, setSelectedFilterColumn] = useState(null); // Column being filtered
  const [columnFilterValue, setColumnFilterValue] = useState(''); // Value selected from the dropdown
  const [duplicateError, setDuplicateError] = useState('');
  const [priceError, setPriceError] = useState('');
  const [statusError, setStatusError] = useState('');
  const [view, setView] = useState('All');


  const { loading1, error: userError, data: userData } = useQuery(listUsers);

  const handleSaveAsExcel = async () => {
    try {
      const mouseToExport = filteredMouses.length > 0 ? filteredMouses : mouses;

      if (mouseToExport.length > 0) {
        const sortedMouse = [...mouseToExport].sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp));

        const worksheetData = sortedMouse.map((mouse, index) => ({
          '#': index + 1, // Agregar el número de fila
          'TAG': mouse.tagId,
          'Brand': mouse.brand,
          'Assigned To': mouse.assigned_to,
          'Available': mouse.available ? 'Yes' : 'No',
          'Serial Number': mouse.serial_Number,
          'Status': mouse.status,
          'Team': mouse.team,
        }));

        // Convert JSON to sheet starting from row 2
        const worksheet = XLSX.utils.json_to_sheet([]);
        let endCol = 0;

        XLSX.utils.sheet_add_aoa(worksheet, [['Mice']], { origin: 'A1' });

        if (worksheetData.length > 0) {
          // Agrega los datos a partir de la fila A2
          XLSX.utils.sheet_add_json(worksheet, worksheetData, { origin: 'A2', skipHeader: false });

          // Define el rango de referencia (!ref)
          const endRow = worksheetData.length + 1; // +1 para el título en A1
          const endCol = Object.keys(worksheetData[0]).length - 1;
          worksheet['!ref'] = XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: endRow, c: endCol } });
        } else {
          console.error("No hay datos para exportar.");
          return;
        }

        // Merge title cells across the columns
        worksheet['!merges'] = worksheet['!merges'] || [];
        worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 7 } }); // A1 to H1 for the added column

        // Style the title row
        worksheet['A1'].s = {
          font: {
            bold: true,
            sz: 20, // Font size
            color: { rgb: '1F4E79' } // Dark blue text color
          },
          alignment: {
            horizontal: 'center',
            vertical: 'center'
          }
        };

        // Apply styling for the header row (now starting at row 2)
        const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
        for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
          const cellAddress = XLSX.utils.encode_cell({ r: 1, c: col }); // Header row is now row 1
          if (!worksheet[cellAddress]) continue;
          worksheet[cellAddress].s = {
            fill: {
              fgColor: { rgb: '1F4E79' } // Dark blue background
            },
            font: {
              bold: true,
              color: { rgb: 'FFFFFF' } // White text
            },
            alignment: {
              horizontal: 'center'
            },
            border: {
              top: { style: 'thin', color: { rgb: '000000' } },
              left: { style: 'thin', color: { rgb: '000000' } },
              bottom: { style: 'thin', color: { rgb: '000000' } },
              right: { style: 'thin', color: { rgb: '000000' } },
            }
          };
        }

        // Apply alternating row colors and borders starting from row 3
        for (let row = 2; row <= headerRange.e.r; row++) {
          const isEvenRow = row % 2 === 0;
          for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
            if (!worksheet[cellAddress]) continue;

            worksheet[cellAddress].s = {
              fill: {
                fgColor: { rgb: isEvenRow ? 'D3D3D3' : 'FFFFFF' } // Light gray for even rows, white for odd rows
              },
              border: {
                top: { style: 'thin', color: { rgb: '000000' } },
                left: { style: 'thin', color: { rgb: '000000' } },
                bottom: { style: 'thin', color: { rgb: '000000' } },
                right: { style: 'thin', color: { rgb: '000000' } },
              },
              alignment: {
                horizontal: 'center'
              }
            };
          }
        }

        // Adjust columns to fit content
        // Adjust columns to fit content
        worksheet['!cols'] = [
          { wch: 5 }, // Width for Row Number
          { wch: 10 },
          { wch: 15 },
          { wch: 10 },
          { wch: 15 },
          { wch: 15 },
          { wch: 5 },
          { wch: 15 }
        ];

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Mouses');

        // Export the file
        XLSX.writeFile(workbook, 'mousesData.xlsx');
      } else {
        console.error('No data to export');
      }
    } catch (error) {
      console.error('Error exporting data to Excel:', error);
    }
  };


  const handleEditMouse = (mouses) => {
    setSelectedMouse(mouses);
    setIsEditing(true);
    setShowMouseAddOverlay(true);
  };

  const { loading, data, fetchMore } = useQuery(listMice, {
    variables: { limit: 100 },
  });

  const [addMouse] = useMutation(createMouse, {
    refetchQueries: [{ query: listMice, variables: { limit: 100 } }]
  });

  const [removeMouse] = useMutation(deleteMouse, {
    refetchQueries: [{ query: listMice, variables: { limit: 100 } }]
  });

  const [updMouse] = useMutation(updateMouse, {
    refetchQueries: [{ query: listMice, variables: { limit: 100 } }]
  });

  const [HistoryEquipment] = useMutation(createDeleteEquipmentHistory, {
    refetchQueries: [{ query: listMice, variables: { limit: 100 } }]
  });

  useEffect(() => {
    const fetchAllMouses = async () => {
      let allMouses = [];
      let currentNextToken = null;

      do {
        const { data } = await fetchMore({
          variables: { limit: 100, nextToken: currentNextToken },
        });

        allMouses = [...allMouses, ...data.listMice.items];
        currentNextToken = data.listMice.nextToken;
      } while (currentNextToken);

      setMouses(allMouses);
      setNextToken(currentNextToken);
    };

    if (data) {
      fetchAllMouses();
    }
  }, [data, fetchMore]);

  const clearNewMouse = () => {
    setNewMouse({
      id: '',
      tagId: '',
      model: '',
      brand: '',
      serial_Number: '',
      wireless: '',
      usb: '',
      combo: '',
      price: '',
      store: '',
      purchase_date: '',
      assignment_date: '',
      warranty_date: '',
      returnable_date: '',
      available: '',
      status: '',
      assigned_to: '',
      location: '',
      team: ''
    });
  };

  const loadMore = () => {
    if (!nextToken) return; // Check if nextToken is null

    fetchMore({
      variables: { limit: 100, nextToken },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prevResult;
        setNextToken(fetchMoreResult.listMice.nextToken);
        return {
          listMice: {
            ...fetchMoreResult.listMice,
            items: [...prevResult.listMice.items, ...fetchMoreResult.listMice.items]
          }
        };
      }
    });
  };


  const handleInputChange = (e) => {
    const { name, type, checked, value } = e.target;
    let formattedValue = value;

    // Convert to lowercase 
    if (['serial_Number'].includes(name)) {
      formattedValue = value.toLowerCase();
    }
    ;
    if (type === 'checkbox') {
      formattedValue = checked;
    }

    if (isEditing) {
      setSelectedMouse({
        ...selectedMouse,
        [name]: formattedValue
      });
    } else {
      setNewMouse({
        ...newMouse,
        id: uuidv4(),
        [name]: formattedValue
      });
    }

  };

  const handleAddMouse = () => {
    // Formatear la fecha en el formato YYYY-MM-DD si existe, o establecerla en null si está vacía
    const formattedPurchase = newMouse.purchase_date
      ? new Date(newMouse.purchase_date).toISOString().split('T')[0]
      : null;
    const formattedAssignment = newMouse.assignment_date
      ? new Date(newMouse.assignment_date).toISOString().split('T')[0]
      : null;

    const formattedWarranty = newMouse.warranty_date
      ? new Date(newMouse.warranty_date).toISOString().split('T')[0]
      : null;

    const formattedReturnable = newMouse.returnable_date
      ? new Date(newMouse.returnable_date).toISOString().split('T')[0]
      : null;

    // Validate that the price contains only digits and is a valid number
    let priceValue = null;


    if (newMouse.price !== "" && newMouse.price !== null && newMouse.price != undefined) {
      priceValue = parseFloat(newMouse.price);

      if (isNaN(priceValue)) {
        setPriceError('Price must be a valid number.');
        return;
      }
    }
    setPriceError('');


    // Check for duplicates based on `tagId` and `serial_Number`
    const isDuplicate = mouses.some(mouse =>
      mouse.tagId === newMouse.tagId &&
      mouse.serial_Number === newMouse.serial_Number
    );

    if (isDuplicate) {
      setDuplicateError('A Mouse with the same TAG and Serial Number already exists.');
      console.log('Duplicate validation failed');
      return;
    } else {
      setDuplicateError('');
    }


    // Check if newMouse and its properties are defined
    if (!newMouse || typeof newMouse.status === 'undefined' || typeof newMouse.available === 'undefined') {
      console.error('Mouse data is incomplete or invalid.');
      setStatusError('Mouse data is incomplete or invalid.');
      return;
    }

    // Check availability first
    if (newMouse.available === 'true') {

      // If available, status must be Inventario
      if (newMouse.status !== 'inv') {
        setStatusError('If the mouse is available, its status must be "Inventario".');
        console.log('Status validation failed');
        return; // Exit function if validation fails
      }
      // If available and status is Inventario, assigned_to must be empty or "N/A"
      if (newMouse.assigned_to !== '' && newMouse.assigned_to !== 'n/a') {
        setStatusError('If the mouse is available and its status is "Inventario", the "Assigned to" field must be empty or "N/A".');
        console.log('Assigned_to validation failed');
        return; // Exit function if validation fails
      }

    } else if (newMouse.available !== "") {
      // If not available, status must be one of the allowed statuses
      const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
      if (!allowedStatuses.includes(newMouse.status)) {
        setStatusError('If the mouse is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
        console.log('Status validation failed');
        return; // Exit function if validation fails
      }
      // Clear error if validation passes
      setStatusError('');
    }

    // If all validations pass, proceed with adding the mouse to the database
    addMouse({
      variables: { input: { ...newMouse, price: priceValue, purchase_date: formattedPurchase, warranty_date: formattedWarranty, returnable_date: formattedReturnable, assignment_date: formattedAssignment } }
    })
      .then(() => {
        window.location.reload();
      }).catch(error => {
        console.error("Error adding Mouse:", error);
      });
  };

  const handleDeleteMouse = () => {
    removeMouse({
      variables: { input: { id: selectedMouse.id } }
    }).then(() => {
      window.location.reload();
    }).catch(error => {
      console.error("Error deleting Mouse", error);
    });
  };


  const handleUpdateMouse = () => {

    const formattedData_purchase = selectedMouse.purchase_date ? new Date(selectedMouse.purchase_date).toISOString().split('T')[0] : null;
    const formattedData_warranty = selectedMouse.warranty_date ? new Date(selectedMouse.warranty_date).toISOString().split('T')[0] : null;
    const formattedData_returnable = selectedMouse.returnable_date ? new Date(selectedMouse.returnable_date).toISOString().split('T')[0] : null;
    const formattedData_assignment = selectedMouse.assignment_date ? new Date(selectedMouse.assignment_date).toISOString().split('T')[0] : null;

    //Check Price
    let priceValue = null;
    if (selectedMouse.price !== "" && selectedMouse.price !== null && selectedMouse.price != undefined) {
      priceValue = parseFloat(selectedMouse.price);

      if (isNaN(priceValue)) {
        setPriceError('Price must be a valid number.');
        return;
      }
    }
    setPriceError('');

    // Check availability first
    if (selectedMouse.available === 'true') {

      // If available, status must be "Inventario"
      if (selectedMouse.status !== 'inv') {
        setStatusError('If the mouse is available, its status must be "Inventario".');
        console.log('Status validation failed - Expected status "inv", but got:', selectedMouse.status);
        return; // Exit function if validation fails
      }

      // If available and status is "Inventario", assigned_to must be empty or "N/A"
      if (selectedMouse.assigned_to !== '' && selectedMouse.assigned_to.toLowerCase() !== 'n/a') {
        setStatusError('If the mouse is available and its status is "Inventario", the "assigned_to" field must be empty or "N/A".');
        console.log('Assigned_to validation failed - assigned_to value:', selectedMouse.assigned_to);
        return; // Exit function if validation fails
      }

    } else if (selectedMouse.available === 'false') {  // Ensure we're checking for availability correctly
      const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
      if (!allowedStatuses.includes(selectedMouse.status)) {
        setStatusError('If the Mouse is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
        console.log('Status validation failed - invalid status:', selectedMouse.status);
        return;
      }

      // Clear error if validation passes
      setStatusError('');
    }


    updMouse({
      variables: {
        input: {
          id: selectedMouse.id,
          tagId: selectedMouse.tagId,
          model: selectedMouse.model,
          brand: selectedMouse.brand,
          serial_Number: selectedMouse.serial_Number,
          wireless: selectedMouse.wireless,
          usb: selectedMouse.usb,
          combo: selectedMouse.combo,
          price: priceValue,
          store: selectedMouse.store,
          purchase_date: formattedData_purchase,
          assignment_date: formattedData_assignment,
          warranty_date: formattedData_warranty,
          returnable_date: formattedData_returnable,
          available: selectedMouse.available,
          status: selectedMouse.status,
          assigned_to: selectedMouse.assigned_to,
          location: selectedMouse.location,
          team: selectedMouse.team
        }
      }
    }).then(() => {
      setIsEditing(true);
      setShowMouseAddOverlay(false);
      setPriceError('');
      setStatusError('');
      setDuplicateError('');

    }).catch(error => {
      console.error("Error updating mouse:", error);
    });
  };

  const handleSelectMouse = (mouse) => {
    setSelectedMouse(mouse); // Set the selected mouse
    setIsEditing(true); // Indicate we are in editing mode
    setShowMouseAddOverlay(true); // Show the overlay
  };


  // Filter by selected column and value
  // Filter data based on selected column and filter value
  const filteredMouses = React.useMemo(() => {
    let filteredData = [...mouses];
  
    // Apply filter by selected column and value
    if (selectedFilterColumn && columnFilterValue) {
      filteredData = filteredData.filter((mouse) => {
        const mouseValue = mouse[selectedFilterColumn];
  
        // Handle boolean fields with "Yes"/"No" display values
        if (typeof mouseValue === "boolean") {
          const booleanDisplayValue = mouseValue ? "Yes" : "No";
          return booleanDisplayValue === columnFilterValue;
        }
  
        // Handle number fields, ensure columnFilterValue is converted to a number if needed
        if (typeof mouseValue === "number") {
          return mouseValue === Number(columnFilterValue);
        }
  
        // For other types, match the string values directly
        return mouseValue === columnFilterValue;
      });
    }
  
    // Apply search filtering
    if (searchQuery) {
      filteredData = filteredData.filter((mouse) =>
        Object.values(mouse).some((value) =>
          value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    }
  
    // Apply sorting
    if (sortConfig.key) {
      filteredData.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];
  
        if (aValue < bValue) return sortConfig.direction === 'ascending' ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === 'ascending' ? 1 : -1;
        return 0;
      });
    }
  
    return filteredData;
  }, [mouses, selectedFilterColumn, columnFilterValue, searchQuery, sortConfig]);

  const requestSort = (key) => {
    setSortConfig((prevConfig) => {
      // Toggle sorting direction if the same key is clicked
      const direction = prevConfig.key === key && prevConfig.direction === 'ascending' ? 'descending' : 'ascending';
      return { key, direction };
    });
  };

  // Get unique values for the column
  const getUniqueColumnValues = (columnKey) => {
    // Handle Boolean fields with predefined values
    if (columnKey === 'available' || columnKey === 'wireless' || columnKey === 'usb' || columnKey === 'combo') {
      return ["No", "Yes"];
    }
  
    // Get unique values from the data
    const uniqueValues = [...new Set(mouses.map((mouse) => mouse[columnKey]).filter(Boolean))];
    
    // Sort values: check if they are numbers, otherwise sort alphabetically
    return uniqueValues.sort((a, b) => {
      if (typeof a === "number" && typeof b === "number") {
        return a - b;  // Numeric sort for numbers
      } else {
        return a.toString().localeCompare(b.toString());  // Alphabetical sort for strings
      }
    });
  };
  
  
  // Handle column header click
  const handleColumnHeaderClick = (columnKey) => {
    if (selectedFilterColumn === columnKey) {
      setSelectedFilterColumn(null); // Close filter if already open
    } else {
      setSelectedFilterColumn(columnKey); // Open filter for the selected column
      setColumnFilterValue(''); // Reset the filter value when opening a new column filter
    }
  };

  //listUsers 
  const users = React.useMemo(() => {
    if (userData && userData.listUsers) {
      return userData.listUsers.items || [];
    }
    return [];
  }, [userData]);

  if (userError) return <p>Error loading users: {userError.message}</p>;
  if (loading1) return <p>Error loading users: {userError.message}</p>;


  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

    return localDate.toLocaleDateString(undefined, options);
  };

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const formatPrice = (price) => {
    if (price === null || price === undefined || price === '') {
      return '';
    }
    const parsedPrice = parseFloat(price);
    return isNaN(parsedPrice) ? '' : parsedPrice.toFixed(2);
  };

  return (
    <div className="mouse-page">
      <header className="inventory-header">
        <h1>Mouse</h1>
      </header>
      <Inventory_header/>
      <div className="inventory-search">
        <button onClick={handleSaveAsExcel} > <FaFileExcel /> Export </button>

        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      <button className="add-mouse-btn" onClick={() => {
        clearNewMouse();
        setSelectedMouse(null);
        setIsEditing(false);
        setShowMouseAddOverlay(true);
      }}>+ Add New Mouse</button>

      <div className="mouse-table-container" >
        <table className="mouse-table">
          <thead>
            <tr>
              <th onClick={() => handleColumnHeaderClick('tagId')}>TAG</th>
              <th onClick={() => handleColumnHeaderClick('model')}>Model</th>
              <th onClick={() => handleColumnHeaderClick('brand')}>Brand</th>
              <th onClick={() => handleColumnHeaderClick('serial_Number')}>Serial Number</th>
              <th onClick={() => handleColumnHeaderClick('wireless')}>Wireless</th>
              <th onClick={() => handleColumnHeaderClick('usb')}>USB</th>
              <th onClick={() => handleColumnHeaderClick('combo')}>Combo</th>
              <th onClick={() => handleColumnHeaderClick('price')}>Price</th>
              <th onClick={() => handleColumnHeaderClick('store')}>Store</th>
              <th onClick={() => handleColumnHeaderClick('purchase_date')}>Purchase Date</th>
              <th onClick={() => handleColumnHeaderClick('assignment_date')}>Assignment Date</th>
              <th onClick={() => handleColumnHeaderClick('warranty_date')}>Warranty Date</th>
              <th onClick={() => handleColumnHeaderClick('returnable_date')}>Returnable Date</th>
              <th onClick={() => handleColumnHeaderClick('available')}>Available</th>
              <th onClick={() => handleColumnHeaderClick('status')}>Status</th>
              <th onClick={() => handleColumnHeaderClick('assigned_to')}>Assigned To</th>
              <th onClick={() => handleColumnHeaderClick('location')}>Location</th>
              <th onClick={() => handleColumnHeaderClick('team')}>Team</th>
              <th >Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredMouses.map((mouse, index) => (
              <tr key={mouse.id} onClick={() => handleEditMouse(mouse)}>
                <td>{mouse.tagId}</td>
                <td>{mouse.model}</td>
                <td>{mouse.brand}</td>
                <td>{mouse.serial_Number}</td>
                <td>{mouse.wireless ? 'Yes' : 'No'}</td>
                <td>{mouse.usb ? 'Yes' : 'No'}</td>
                <td>{mouse.combo ? 'Yes' : 'No'}</td>
                <td>{mouse.price}</td>
                <td>{mouse.store}</td>
                <td>{mouse.purchase_date}</td>
                <td>{mouse.assignment_date}</td>
                <td>{mouse.warranty_date}</td>
                <td>{mouse.returnable_date}</td>
                <td>{mouse.available ? 'Yes' : 'No'}</td>
                <td>{mouse.status}</td>
                <td>{mouse.assigned_to}</td>
                <td>{mouse.location}</td>
                <td>{mouse.team}</td>
                <td className='mouses-table'>
                  <button className="delete-mouse-btn" onClick={() => handleSelectMouse(mouses)}>Delete/Update</button>

                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* Column Filter Dropdown */}
      {selectedFilterColumn && (
        <div className="column-filter-overlay">
          <label>Select {selectedFilterColumn}:</label>
          <select
            onChange={(e) => {
              const value = e.target.value === "true" ? true : e.target.value === "false" ? false : e.target.value;
              setColumnFilterValue(value);
              setActiveFilter(value ? { column: selectedFilterColumn, value } : null);
            }}
            value={columnFilterValue}
          >
            <option value="">All</option>
            {getUniqueColumnValues(selectedFilterColumn).map((value) => (
              <option key={value} value={value}>
                {value === true ? "Yes" : value === false ? "No" : value}
              </option>
            ))}
          </select>
          <button onClick={() => setSelectedFilterColumn(null)}>Close Filter</button>
        </div>
      )}
      <p>Total items: {filteredMouses.length}</p>

      {nextToken && !loading && (
        <button onClick={loadMore}>Load More</button>
      )}

      {loading && <p>Loading...</p>}

      {showMouseAddOverlay && (

        <div className="mouse_add_overlay">
          <div className="overlay-content-inventory">
            <div className="header-row">
              <h2 className="header-title">{isEditing ? 'Edit Mouse' : 'Add New Mouse'}</h2>
              <button onClick={() => {
                setShowMouseAddOverlay(false);
                clearNewMouse();
                setSelectedMouse(null);
                setIsEditing(false);
              }}>X</button>
            </div>


            <label>TAG:</label>
            <input
              name="tagId"
              placeholder="TAG"
              value={isEditing && selectedMouse ? selectedMouse.tagId : newMouse.tagId}
              onChange={handleInputChange}
              required
            />
            <label>Model:</label>
            <input
              name="model"
              placeholder="Model"
              value={isEditing && selectedMouse ? selectedMouse.model : newMouse.model}
              onChange={handleInputChange}
              required
            />


            <label>Brand:</label><input name="brand" placeholder="Brand" value={isEditing ? selectedMouse.brand : newMouse.brand} onChange={handleInputChange} />
            <label>Serial Number:</label><input name="serial_Number" placeholder="Serial Number" value={isEditing ? selectedMouse.serial_Number : newMouse.serial_Number} onChange={handleInputChange} />

            <div className='Inventory_checkboxes'>

              <label>
                Wireless</label>
              <input name="wireless"
                type="checkbox" checked={isEditing ? selectedMouse.wireless : newMouse.wireless}
                onChange={handleInputChange}
              />
              <label>
                USB</label>
              <input name="usb"
                type="checkbox" checked={isEditing ? selectedMouse.usb : newMouse.usb}
                onChange={handleInputChange}
              />
              <label>
                Combo</label>
              <input name="combo"
                type="checkbox" checked={isEditing ? selectedMouse.combo : newMouse.combo}
                onChange={handleInputChange}
              />
            </div>

            <label>Price:</label><input name="price" placeholder="price" value={isEditing ? selectedMouse.price : newMouse.price} onChange={handleInputChange} />
            <label>Store:</label><input name="store" placeholder="store" value={isEditing ? selectedMouse.store : newMouse.store} onChange={handleInputChange} />

            <label htmlFor="purchase_date">Purchase Date:</label>
            <input
              type="Date"
              id="purchase_date"
              name="purchase_date"
              value={isEditing ? selectedMouse.purchase_date : newMouse.purchase_date}
              onChange={handleInputChange}

            />
            <label htmlFor="assignment_date">Assignment Date:</label>
            <input
              type="date"
              id="assignment_date"
              name="assignment_date"
              value={isEditing ? selectedMouse.assignment_date : newMouse.assignment_date}
              onChange={handleInputChange}

            />
            <label htmlFor="returnable_date">Warranty Date:</label>
            <input
              type="date"
              id="warranty_date"
              name="warranty_date"
              value={isEditing ? selectedMouse.warranty_date : newMouse.warranty_date}
              onChange={handleInputChange}
            />


            <label htmlFor="returnable_date">Returnable Date:</label>
            <input
              type="date"
              id="returnable_date"
              name="returnable_date"
              value={isEditing ? selectedMouse.returnable_date : newMouse.returnable_date}
              onChange={handleInputChange}
            />

            <label>Available:</label>

            <select name="available" placeholder="available" value={isEditing ? selectedMouse.available : newMouse.available} onChange={handleInputChange} >
              <option value="" disabled hidden className="placeholder-option">Select Availability</option>
              <option value="true">Yes</option>
              <option value="false">No</option>

            </select>
            <label>Status:</label>
            <select name="status" placeholder="status" value={isEditing ? selectedMouse.status : newMouse.status} onChange={handleInputChange} >
              <option value="" disabled hidden>Select Status</option>
              <option value="use">Use</option>
              <option value="broken">Broken</option>
              <option value="transfer">Transfer</option>
              <option value="lost">Lost</option>
              <option value="inv">Inventario</option>
            </select>
            <label>Assigned To:</label>
            <select
              name="assigned_to"
              value={isEditing ? selectedMouse.assigned_to : newMouse.assigned_to}
              onChange={handleInputChange}
            >
              <option value="">Select an Employee</option>

              {users.length > 0 ? (
                [...users].sort((a, b) => {
                  const nameA = `${a.first_name} ${a.last_name}`.toLowerCase();
                  const nameB = `${b.first_name} ${b.last_name}`.toLowerCase();
                  return nameA.localeCompare(nameB);
                }).map(user => (
                  <option key={user.id} value={`${capitalize(user.first_name)} ${capitalize(user.last_name)}`}>
                    {capitalize(user.first_name)} {capitalize(user.last_name)}
                  </option>
                ))
              ) : (

                <option value="">No users available</option>
              )}
              <option value="n/a">N/A</option> {/* "N/A" option at the top */}
            </select>


            <label>Location:</label><input name="location" placeholder="location" value={isEditing ? selectedMouse.location : newMouse.location} onChange={handleInputChange} />
            <label>Team:</label><input name="team" placeholder="team" value={isEditing ? selectedMouse.team : newMouse.team} onChange={handleInputChange} />

            {duplicateError && <p className="error">{duplicateError}</p>} {/* Display duplicate error message */}
            {priceError && <p className="error">{priceError}</p>} {/* Display price error message */}
            {statusError && <p className="error">{statusError}</p>} {/* Display duplicate error message */}
            <div className="button-group">
              <button className="overlay-add-mouse" onClick={isEditing ? handleUpdateMouse : handleAddMouse} >
                {isEditing ? 'Update Mouse' : 'Add Mouse'}
              </button>
              {isEditing && (
                <button
                  className="overlay-delet-mouse"
                  onClick={() => {
                    if (window.confirm('Are you sure you want to delete this mouse?')) {
                      handleDeleteMouse();
                    }
                  }}
                >
                  Delete mouse
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MouseTable;