import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_ALL_LOCATIONS } from '../../graphql/queries'; // Adjust the path to your queries file
import './Locations.css';

const Locations = () => {
  const { loading, error, data } = useQuery(GET_ALL_LOCATIONS);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  // Function to format operation hours for each day of the week
  const getOperationHours = (operationHours, day) => {
    const hours = operationHours?.find((oh) => oh.day === day);
    if (hours) {
      return `${hours.startTime || 'N/A'} - ${hours.endTime || 'N/A'}`;
    }
    return 'N/A';
  };

  // Print the list of operation hours to the console for debugging purposes
  console.log("Operation Hours List:", data.listLocations.items.map(location => location.operationHours));

  return (
    <div className='locations'>
      <h1>Locations Page</h1>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Address</th>
              <th>City</th>
              <th>Date</th>
              <th>Point of Contact Name</th>
              <th>Phone</th>
              <th>Email</th>
              {/* Add days of the week as columns */}
              <th>Monday</th>
              <th>Tuesday</th>
              <th>Wednesday</th>
              <th>Thursday</th>
              <th>Friday</th>
              <th>Saturday</th>
              <th>Sunday</th>
            </tr>
          </thead>
          <tbody>
            {data.listLocations.items.map((location) => (
              <tr key={location.id}>
                <td>{location.name}</td>
                <td>{location.address}</td>
                <td>{location.city?.name || 'N/A'}</td>
                <td>{location.outreachDate || 'N/A'}</td>
                <td>{location.pointsOfContact?.[0]?.name || 'N/A'}</td>
                <td>{location.pointsOfContact?.[0]?.phone || 'N/A'}</td>
                <td>{location.pointsOfContact?.[0]?.email || 'N/A'}</td>
                {/* Display operation hours for each day */}
                <td>{getOperationHours(location.operationHours, 'Monday')}</td>
                <td>{getOperationHours(location.operationHours, 'Tuesday')}</td>
                <td>{getOperationHours(location.operationHours, 'Wednesday')}</td>
                <td>{getOperationHours(location.operationHours, 'Thursday')}</td>
                <td>{getOperationHours(location.operationHours, 'Friday')}</td>
                <td>{getOperationHours(location.operationHours, 'Saturday')}</td>
                <td>{getOperationHours(location.operationHours, 'Sunday')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Locations;
