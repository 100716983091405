import React, { useState, useEffect, useRef } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_REPORT, DELETE_REPORT } from '../../../graphql/mutations';
import { listUsersByTeamOne } from '../../../graphql/queries';
import { fetchAuthSession } from 'aws-amplify/auth';

import './ReportModal.css';

const ReportModal = ({ report, onClose, onDelete, onUpdate }) => {
  const [formData, setFormData] = useState({ ...report });
  const [updateReportMutation] = useMutation(UPDATE_REPORT);
  const [deleteReport] = useMutation(DELETE_REPORT);
  const modalRef = useRef();
  const { loading: usersLoading, error: usersError, data: usersData } = useQuery(listUsersByTeamOne);
  const navigatorOptions = usersData?.listUsers?.items?.map(user => ({
    id: user.id,
    name: `${user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1).toLowerCase()} ${user.last_name.charAt(0).toUpperCase() + user.last_name.slice(1).toLowerCase()}`
  })) || [];

  // State to track user's group
  const [userGroup, setUserGroup] = useState(null);

  useEffect(() => {
    // Fetch the user's group when the component mounts
    const fetchUserGroup = async () => {
      try {
        const session = await fetchAuthSession();
        const groups = session.tokens.accessToken.payload["cognito:groups"]; // Get the user's groups
        if (groups) {
          setUserGroup(groups); // Assuming a user belongs to one group. Adjust as needed.
        }
      } catch (error) {
        console.error('Error fetching user group:', error);
      }
    };

    fetchUserGroup();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const numericValue = value.replace(/[^0-9]/g, '');

    if (name === 'QHP_Amount') {
      const amount = parseInt(value, 10);
      setFormData((prevData) => ({
        ...prevData,
        QHP_Amount: value,
        plans: new Array(amount).fill(null)
      }));
      return;
    }

    if (type === 'checkbox') {
      if (name === 'prefered_lang') {
        let updatedLanguages = [...formData.prefered_lang];
        if (checked) {
          updatedLanguages.push(value);
        } else {
          updatedLanguages = updatedLanguages.filter((lang) => lang !== value);
        }
        setFormData({
          ...formData,
          prefered_lang: updatedLanguages,
        });
      } else if (name === 'Answering_questions_about') {
        let updatedQuestions = [...formData.Answering_questions_about];
        if (checked) {
          updatedQuestions.push(value);
        } else {
          updatedQuestions = updatedQuestions.filter((question) => question !== value);
        }
        setFormData({
          ...formData,
          Answering_questions_about: updatedQuestions,
        });
      } else if (name === 'reschedule_reason') {
        let updatedReasons = [...formData.reschedule_reason];
        if (checked) {
          updatedReasons.push(value);
        } else {
          updatedReasons = updatedReasons.filter((reason) => reason !== value);
        }
        setFormData({
          ...formData,
          reschedule_reason: updatedReasons,
        });
      }
    } else if (name === 'county') {
      setFormData({
        ...formData,
        county: value,
        other_county: ''
      });
    } else if (name === 'other_county') {
      setFormData({
        ...formData,
        [name]: value.toLowerCase()
      });
    } else {
      if (name === 'phone' && (numericValue.length > 10)) {
        return;
      }
      if (name === 'zip_code' && numericValue.length > 5) {
        return; // Do not update state if zip code exceeds 5 digits
      }
      setFormData({
        ...formData,
        [name]: name === 'phone' ? numericValue : value,
      });
    }
  };
  const getChangedFields = (original, updated) => {
    return Object.keys(updated).reduce((changes, key) => {
      if (original[key] !== updated[key]) {
        changes[key] = updated[key];
      }
      return changes;
    }, {});
  };
  const handleSave = async () => {
    try {
      const changedFields = getChangedFields(report, formData);
      if (Object.keys(changedFields).length === 0) {
        alert('No changes to save');
        return;
      }

      const { data } = await updateReportMutation({
        variables: {
          input: {
            id: formData.id,
            ...changedFields,
          },
        },
      });

      alert('Report updated successfully');
      onUpdate(data.updateReport); // Notify parent component of update
      onClose();
    } catch (error) {
      console.error('Error updating report:', error);
      alert('Error updating report');
    }
  };
  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      onClose();
    }
  };
  const handleDelete = async (reportId) => {
    try {
      await deleteReport({
        variables: {
          input: {
            id: reportId,
          },
        },
      });
      alert('Report deleted successfully');
      onDelete(reportId); // Notify parent component of deletion
      onClose();
    } catch (error) {
      console.error('Error deleting report:', error);
      alert('Error deleting report');
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.body.classList.add('modal-open');

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.body.classList.remove('modal-open');
    };
  }, []);
  const handleOtherLanguageChange = (e) => {
    handleInputChange({
      target: {
        name: 'otherLanguage',
        value: e.target.value.toLowerCase(),
      },
    });
  };
  const handleOtherRescheduleChange = (e) => {
    handleInputChange({
      target: {
        name: 'otherRescheduleChange',
        value: e.target.value.toLowerCase(),
      },
    });
  }
  const [showOtherTextInput, setShowOtherTextInput] = useState({
    data_matchingIssues: false,
    SEP_eligibility: false,
    Employer_sponsored_coverage_issues: false,
    APTC_CSR_assistance: false,
  });
  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    handleInputChange(e);
    setShowOtherTextInput((prev) => ({
      ...prev,
      [name]: value === 'Other',
    }));
  };

  const [showOtherTextInput2, setShowOtherTextInput2] = useState(formData.Answering_questions_about.includes('other'));

  const handleCheckboxChange = (e) => {
    handleInputChange(e);
    if (e.target.value === 'other') {
      setShowOtherTextInput2(e.target.checked);
    }
  };
  { usersLoading && <p>Loading navigators...</p> }
  { usersError && <p>Error loading navigators: {usersError.message}</p> }

  return (
    <div className="report-modal">
      <div className="modal-content" ref={modalRef}>
        <button className="close-btn_updateReport" onClick={onClose}>X</button>
        <h2>Edit Report</h2>
        {userGroup?.includes('dev') && (
          <button className="delete-btn" onClick={() => handleDelete(formData.id)}>
            Delete
          </button>
        )}
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Last Name</label>
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Navigator:</label>

          <select
            name="Navigator"
            value={formData.Navigator}
            onChange={handleInputChange}
          >
            <option value="" disabled>Select Navigator</option>
            {navigatorOptions.map(option => (
              <option key={option.id} value={option.name}>{option.name}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Date</label>
          <input
            type="date"
            name="Date"
            value={formData.Date}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Phone</label>
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
          />
        </div>

        {/* Add other fields as needed */}

        <div className="form-group">
          <label>Is this an outreach/colocation/phone bank event? (one-on-one interaction report)</label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name="outreachEvent"
                value="yes"
                checked={formData.outreachEvent === 'yes'}
                onChange={handleInputChange}
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="outreachEvent"
                value="no"
                checked={formData.outreachEvent === 'no'}
                onChange={handleInputChange}
              />
              No
            </label>
          </div>
        </div>
        <div className="form-group">
          <label>Contract number and Year Number:</label>
          <select
            name="year_contract"
            value={formData.year_contract}
            onChange={handleInputChange}
          >
            <option value="" disabled>Pick Year - Contract</option>
            <option value="Contract 1 Year 1" >Contract 1 Year 1</option>
            <option value="Contract 1 Year 2" >Contract 1 Year 2</option>
            <option value="Contract 1 Year 3" >Contract 1 Year 3</option>
            <option value="Contract 1 Year 4" >Contract 1 Year 4</option>
            <option value="Contract 2 Year 1" >Contract 2 Year 1</option>
            <option value="Contract 2 Year 2" >Contract 2 Year 2</option>
            <option value="Contract 2 Year 3" >Contract 2 Year 3</option>
            <option value="Contract 2 Year 4" >Contract 2 Year 4</option>
          </select>
        </div>
        <div className="form-group">
          <label>How many flyers did you give out or drop off?</label>
          <input
            type='number'
            name="flyers"
            value={formData.flyers}
            onChange={handleInputChange}
            placeholder="Your answer"
            min="0"
          />
        </div>
        <div className="form-group">
          <label>For existing consumers or follow-ups, how many did you attempt to contact by phone, but they did not respond?</label>
          <input
            type='number'
            name='Number_FollowUp_Attempt_Contact_NoResponse'
            value={formData.Number_FollowUp_Attempt_Contact_NoResponse}
            onChange={handleInputChange}
            placeholder="Your answer"
            min="0"
          />
        </div>
        <div className="form-group">
          <label>How many MAC touches have you conducted today?</label>
          <input
            type='number'
            name='MACtouches'
            value={formData.MACtouches}
            onChange={handleInputChange}
            placeholder="Your answer"
            min="0"
          />
        </div>
        <div className="form-group">
          <label>Address:</label>
          <input
            className='long-text'
            type='text'
            name='address'
            value={formData.address}
            onChange={handleInputChange}
            placeholder="Your answer"
          />
        </div>
        <div className="form-group">
          <label>City</label>
          <input
            type='text'
            name='city'
            value={formData.city}
            onChange={handleInputChange}
            placeholder="Your answer"
          />
        </div>
        <div className="form-group">
          <label>County</label>
          <input
            type="text"
            name="county"
            value={formData.county}
            onChange={handleInputChange}
            placeholder="Your answer"
          />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder="Your answer"
          />
        </div>
        <div className="form-group">
          <label>Preferred Language</label>
          <div className="check-group">
            <label>
              <input
                type="checkbox"
                name="prefered_lang"
                value="english"
                checked={formData.prefered_lang.includes('english')}
                onChange={handleInputChange}
              />
              English
            </label>
            <label>
              <input
                type="checkbox"
                name="prefered_lang"
                value="spanish"
                checked={formData.prefered_lang.includes('spanish')}
                onChange={handleInputChange}
              />
              Spanish
            </label>
            <label>
              <input
                type="checkbox"
                name="prefered_lang"
                value="other"
                checked={formData.prefered_lang.includes('other')}
                onChange={handleInputChange}
              />
              Other
            </label>
            {formData.prefered_lang.includes('other') && (
              <input
                type="text"
                name="otherLanguage"
                value={formData.otherLanguage}
                onChange={handleOtherLanguageChange}
                placeholder="Your answer"
              />
            )}
          </div>
        </div>
        <div className="form-group">
          <label>Is this a Commit Card?</label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name="commitCard"
                value="yes"
                checked={formData.commitCard === 'yes'}
                onChange={handleInputChange}
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="commitCard"
                value="no"
                checked={formData.commitCard === 'no'}
                onChange={handleInputChange}
              />
              No
            </label>
          </div>
        </div>
        <div className="form-group">
          <label>Commit Card No</label>
          <input
            type="text"
            name="commitcardNo"
            value={formData.commitcardNo}
            onChange={handleInputChange}
            placeholder="Your answer"
          />
        </div>
        <div className="form-group">
          <label>Signature</label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name="signature"
                value="yes"
                checked={formData.signature === 'yes'}
                onChange={handleInputChange}
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="signature"
                value="no"
                checked={formData.signature === 'no'}
                onChange={handleInputChange}
              />
              No
            </label>
          </div>
        </div>
        <div className="form-group">
          <label>Best Time to Call</label>
          <input
            type='time'
            name='bestTimeToCall'
            value={formData.bestTimeToCall}
            onChange={handleInputChange}
            placeholder="Your answer"
          />
        </div>
        <div className="form-group">
          <label>Commit Card Notes</label>
          <input
            type="text"
            name="CommitCardNotes"
            value={formData.CommitCardNotes}
            onChange={handleInputChange}
            placeholder="Your answer"
          />
        </div>
        <div className="form-group">
          <label>No Show</label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name="no_show"
                value="yes"
                checked={formData.no_show === 'yes'}
                onChange={handleInputChange}
              />
              Yes, consumer was a no-show
            </label>
            <label>
              <input
                type="radio"
                name="no_show"
                value="no"
                checked={formData.no_show === 'no'}
                onChange={handleInputChange}
              />
              No
            </label>
          </div>
        </div>
        <div className="form-group">
          <label>Consumer authorization on file?</label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name="ConsumerAuthOnFile"
                value="yes"
                checked={formData.ConsumerAuthOnFile === 'yes'}
                onChange={handleInputChange}
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="ConsumerAuthOnFile"
                value="no"
                checked={formData.ConsumerAuthOnFile === 'no'}
                onChange={handleInputChange}
              />
              No
            </label>
          </div>
        </div>
        <div className="form-group">
          <label>Does your employer offer Health Coverage?</label>
          <label>
            <input
              type="radio"
              name="employerCoverage"
              value="yes"
              checked={formData.employerCoverage === 'yes'}
              onChange={handleInputChange}
            />
            Yes
          </label>
          <label>
            <input
              type="radio"
              name="employerCoverage"
              value="no"
              checked={formData.employerCoverage === 'no'}
              onChange={handleInputChange}
            />
            No
          </label>
          <label>
            <input
              type="radio"
              name="employerCoverage"
              value="I_dont_know"
              checked={formData.employerCoverage === 'I_dont_know'}
              onChange={handleInputChange}
            />
            I don't know
          </label>
        </div>
        <div className="form-group">
          <label>Cost to Employee Only?</label>
          <input
            type='text'
            name='cost_to_employee'
            value={formData.cost_to_employee}
            onChange={handleInputChange}
            placeholder="Your answer"
          />
        </div>
        <div className="form-group">
          <label>Is coverage offered to family?</label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name="familyCovered"
                value="yes"
                checked={formData.familyCovered === 'yes'}
                onChange={handleInputChange}
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="familyCovered"
                value="no"
                checked={formData.familyCovered === 'no'}
                onChange={handleInputChange}
              />
              No
            </label>
          </div>
        </div>
        <div className="form-group">
          <label>Cost for family coverage</label>
          <input
            type='number'
            name='amountFamCoverage'
            value={formData.amountFamCoverage}
            onChange={handleInputChange}
            min="0"
            step="0.01"
            placeholder="Your answer"
          />
        </div>
        <div className="form-group">
          <label>Number of people in household:</label>
          <input
            type='number'
            name='NumPeopleHousehold'
            value={formData.NumPeopleHousehold}
            onChange={handleInputChange}
            min="0"
            placeholder="Your answer"
          />
        </div>
        <div className="form-group">
          <label>Number of people applying:</label>
          <input
            type='number'
            name='NumPeopleApplying'
            value={formData.NumPeopleApplying}
            onChange={handleInputChange}
            min="0"
            placeholder="Your answer"
            required
          />
        </div>
        <div className="form-group">
          <label>Adults:</label>
          <input
            type='number'
            name='NumAdults'
            value={formData.NumAdults}
            onChange={handleInputChange}
            min="0"
            placeholder="Your answer"
            required
          />
        </div>
        <div className="form-group">
          <label>Children:</label>
          <input
            type='number'
            name='NumChildren'
            value={formData.NumChildren}
            onChange={handleInputChange}
            min="0"
            placeholder="Your answer"
            required
          />
        </div>
        <div className="form-group">
          <label>Does Client Need Employee Tool?</label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name="EmployeeTool"
                value="yes"
                checked={formData.EmployeeTool === 'yes'}
                onChange={handleInputChange}
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="EmployeeTool"
                value="no"
                checked={formData.EmployeeTool === 'no'}
                onChange={handleInputChange}
              />
              No
            </label>
          </div>
        </div>
        <div className="form-group">
          <label>Income Type</label>
          <select
            name='IncomeType'
            value={formData.IncomeType}
            onChange={handleInputChange}
          >
            <option value="" disabled>Your answer</option>
            <option value="Job">Job</option>
            <option value="Self Employed">Self Employed</option>
            <option value="Social Security Disability">Social Security Disability</option>
            <option value="Social Security Supplemental">Social Security Supplemental</option>
            <option value="Pension">Pension</option>
            <option value="Retirement">Retirement</option>
            <option value="Capital Gains">Capital Gains</option>
            <option value="Investment Income">Investment Income</option>
            <option value="Rental and Royalty Income">Rental and Royalty Income</option>
            <option value="Unemployment">Unemployment</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div className="form-group">
          <label>Income Amount</label>
          <input
            type='number'
            name='IncomeAmount'
            value={formData.IncomeAmount}
            onChange={handleInputChange}
            placeholder="Your answer"
            min="0.0"
            step="0.01"
          />
        </div>
        <div className="form-group">
          <select
            name='PayFrequency'
            value={formData.PayFrequency}
            onChange={handleInputChange}
          >
            <option value="" disabled>Your answer</option>
            <option value="Annual">Annual</option>
            <option value="Monthly">Monthly</option>
            <option value="BiWeekly">Bi-Weekly</option>
            <option value="Weekly">Weekly</option>
            <option value="None">None</option>
          </select>
        </div>
        <div className="form-group">
          <label>Coverage Applied For</label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name="coverageAppliedFor"
                value="ACA"
                checked={formData.coverageAppliedFor === 'ACA'}
                onChange={handleInputChange}

              />
              ACA
            </label>
            <label>
              <input
                type="radio"
                name="coverageAppliedFor"
                value="CHIP"
                checked={formData.coverageAppliedFor === 'CHIP'}
                onChange={handleInputChange}

              />
              CHIP
            </label>
            <label>
              <input
                type="radio"
                name="coverageAppliedFor"
                value="Medicaid"
                checked={formData.coverageAppliedFor === 'Medicaid'}
                onChange={handleInputChange}

              />
              Medicaid
            </label>
            <label>
              <input
                type="radio"
                name="coverageAppliedFor"
                value="ACA & Other"
                checked={formData.coverageAppliedFor === 'ACA & Other'}
                onChange={handleInputChange}

              />
              ACA & Other
            </label>
            <label>
              <input
                type="radio"
                name="coverageAppliedFor"
                value="Other"
                checked={formData.coverageAppliedFor === 'Other'}
                onChange={handleInputChange}

              />
              Other:
            </label>

            {formData.coverageAppliedFor === 'Other' && (
              <input
                type="text"
                name="OtherCoverage"
                value={formData.OtherCoverage || ''}
                onChange={handleInputChange}
                placeholder="Your answer"

              />
            )}
          </div>
        </div>
        <div className="form-group">
          <label>New or Re Enrollment</label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name="New_Re_Enrollment"
                value="New"
                checked={formData.New_Re_Enrollment === 'New'}
                onChange={handleInputChange}

              />
              New
            </label>
            <label>
              <input
                type="radio"
                name="New_Re_Enrollment"
                value="ReEnrollment"
                checked={formData.New_Re_Enrollment === 'ReEnrollment'}
                onChange={handleInputChange}

              />
              ReEnrollment
            </label>
          </div>
        </div>
        <div className="form-group">
          <label>Did Client Enroll?</label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name="clientEnroll"
                value="Yes"
                checked={formData.clientEnroll === 'Yes'}
                onChange={handleInputChange}

              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="clientEnroll"
                value="No"
                checked={formData.clientEnroll === 'No'}
                onChange={handleInputChange}

              />
              No
            </label>
            <label>
              <input
                type="radio"
                name="clientEnroll"
                value="Will Come Back to Complete"
                checked={formData.clientEnroll === 'Will Come Back to Complete'}
                onChange={handleInputChange}

              />
              Will Come Back to Complete
            </label>
          </div>
        </div>
        <div className="form-group">
          <label>How Many QHPs?</label>
          <input
            type='number'
            name='QHP_Amount'
            value={formData.QHP_Amount}
            onChange={handleInputChange}
            min="0"
            placeholder="Your answer"
          />
        </div>
        {/* <div className="form-group">
          <label>Plans</label>
          <textarea
            name="plans"
            value={JSON.stringify(formData.plans)}
            onChange={handleInputChange}
          />
        </div> */}
        <div className="form-group">
          <label>Did Client Complete Medicaid/Medicare/CHIP</label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name={"ClientCompleteMMC"}
                value="Yes"
                checked={formData.ClientCompleteMMC === 'Yes'}
                onChange={handleInputChange}

              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="ClientCompleteMMC"
                value="No"
                checked={formData.ClientCompleteMMC === 'No'}
                onChange={handleInputChange}

              />
              No
            </label>
            <label>
              <input
                type="radio"
                name="ClientCompleteMMC"
                value="Referral Completed"
                checked={formData.ClientCompleteMMC === 'Referral Completed'}
                onChange={handleInputChange}

              />
              Referral Completed
            </label>
          </div>
        </div>
        <div className="form-group">
          <label>Was this an Appeal?</label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name={"AppealQuestion"}
                value="Yes"
                checked={formData.AppealQuestion === 'Yes'}
                onChange={handleInputChange}
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="AppealQuestion"
                value="No"
                checked={formData.AppealQuestion === 'No'}
                onChange={handleInputChange}
              />
              No
            </label>
            <label>
              <input
                type="radio"
                name="AppealQuestion"
                value="Maybe"
                checked={formData.AppealQuestion === 'Maybe'}
                onChange={handleInputChange}
              />
              Maybe
            </label>
          </div>
        </div>
        <div className="form-group">
          <label>Does Client Need Call Back or Reschedule?</label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name={"RescheduleQuestion"}
                value="Yes"
                checked={formData.RescheduleQuestion === 'Yes'}
                onChange={handleInputChange}
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="RescheduleQuestion"
                value="No"
                checked={formData.RescheduleQuestion === 'No'}
                onChange={handleInputChange}
              />
              No
            </label>
          </div>
        </div>
        <div className="form-group">
          <label>Reschedule Reason</label>
          <label>
            <input
              type="checkbox"
              name="reschedule_reason"
              value="returnDocs"
              checked={formData.reschedule_reason.includes('returnDocs')}
              onChange={handleInputChange}
            />
            Needs to return with documentation to complete application
          </label>
          <label>
            <input
              type="checkbox"
              name="reschedule_reason"
              value="notReady"
              checked={formData.reschedule_reason.includes('notReady')}
              onChange={handleInputChange}
            />
            Not ready to choose health plan
          </label>
          <label>
            <input
              type="checkbox"
              name="reschedule_reason"
              value="identityNotVerified"
              checked={formData.reschedule_reason.includes('identityNotVerified')}
              onChange={handleInputChange}
            />
            Identity not verified
          </label>
          <label>
            <input
              type="checkbox"
              name="reschedule_reason"
              value="alreadyInsured"
              checked={formData.reschedule_reason.includes('alreadyInsured')}
              onChange={handleInputChange}
            />
            Already insured or offered insurance through Employer
          </label>
          <label>
            <input
              type="checkbox"
              name="reschedule_reason"
              value="hasCoverage"
              checked={formData.reschedule_reason.includes('hasCoverage')}
              onChange={handleInputChange}
            />
            Has coverage through Medicaid – CHIP – Medicare – Tricare – VA Benefits – etc.
          </label>
          <label>
            <input
              type="checkbox"
              name="reschedule_reason"
              value="noSubsidies"
              checked={formData.reschedule_reason.includes('noSubsidies')}
              onChange={handleInputChange}
            />
            Did not qualify for subsidies
          </label>
          <label>
            <input
              type="checkbox"
              name="reschedule_reason"
              value="cannotAfford"
              checked={formData.reschedule_reason.includes('cannotAfford')}
              onChange={handleInputChange}
            />
            Cannot afford plans – too expensive
          </label>
          <label>
            <input
              type="checkbox"
              name="reschedule_reason"
              value="legalStatus"
              checked={formData.reschedule_reason.includes('legalStatus')}
              onChange={handleInputChange}
            />
            Legal Status
          </label>
          <label>
            <input
              type="checkbox"
              name="reschedule_reason"
              value="belowFPL"
              checked={formData.reschedule_reason.includes('belowFPL')}
              onChange={handleInputChange}
            />
            Below 100% FPL (Medicaid gap)
          </label>
          <label>
            <input
              type="checkbox"
              name="reschedule_reason"
              value="hardshipExemption"
              checked={formData.reschedule_reason.includes('hardshipExemption')}
              onChange={handleInputChange}

            />
            Needs to apply for hardship exemption (schedule follow)
          </label>
          <label>
            <input
              type="checkbox"
              name="reschedule_reason"
              value="enroll"
              checked={formData.reschedule_reason.includes('enroll')}
              onChange={handleInputChange}

            />
            Enroll
          </label>
          <label>
            <input
              type="checkbox"
              name="reschedule_reason"
              value="reEnroll"
              checked={formData.reschedule_reason.includes('reEnroll')}
              onChange={handleInputChange}

            />
            ReEnroll
          </label>
          <label>
            <input
              type="checkbox"
              name="reschedule_reason"
              value="texasBenefits"
              checked={formData.reschedule_reason.includes('texasBenefits')}
              onChange={handleInputChange}

            />
            Your Texas Benefits
          </label>
          <label>
            <input
              type="checkbox"
              name="reschedule_reason"
              value="cancel"
              checked={formData.reschedule_reason.includes('cancel')}
              onChange={handleInputChange}

            />
            Cancel
          </label>
          <label>
            <input
              type="checkbox"
              name="reschedule_reason"
              value="noShow"
              checked={formData.reschedule_reason.includes('noShow')}
              onChange={handleInputChange}

            />
            No - show
          </label>
          <label>
            <input
              type="checkbox"
              name="reschedule_reason"
              value="other"
              checked={formData.reschedule_reason.includes('other')}
              onChange={handleInputChange}

            />
            Other
          </label>
          {formData.reschedule_reason.includes('other') && (
            <input
              type="text"
              name="otherRescheduleChange"
              value={formData.otherRescheduleChange}
              onChange={handleOtherRescheduleChange}
              placeholder="Your answer"

            />
          )}
        </div>
        <div className="form-group">
          <label >1. Addressing general inquiries about health insurance options.</label>
          <p>
            How many one-on-one general inquiry interactions have you conducted with consumers?<br></br>
            Health insurance literacy can be combined here.
          </p>
          <input
            type='number'
            name='one2oneInquiry'
            value={formData.one2oneInquiry}
            onChange={handleInputChange}
            placeholder="Number"
            min="0"
          />
        </div>
        <div className="form-group">
          <label >2. Understanding basic concepts and rights related to health coverage and how to use it:</label>
          <label >A. Health Insurance literacy</label>
          <input
            type="number"
            name="HealthInsuranceLiteracy"
            value={formData.HealthInsuranceLiteracy}
            onChange={handleInputChange}
            placeholder="Your answer"
            min="0"
          />
        </div>
        <div className="form-group">
          <label >B. Locating providers</label>
          <input
            type='number'
            name='Locating_Providers'
            value={formData.Locating_Providers}
            onChange={handleInputChange}
            placeholder="Your answer"
            min="0"

          />
        </div>
        <div className="form-group">
          <label >C. Billing and payment questions</label>
          <input
            type='number'
            name='Billing_Payment_Questions'
            value={formData.Billing_Payment_Questions}
            onChange={handleInputChange}
            placeholder="Your answer"
            min="0"

          />
        </div>
        <div className="form-group">
          <label >D. Evaluating health care options using tools and information available through a consumer's health plan</label>
          <input
            type='number'
            name='EvaluateHealtcareOptionsUsingTools'
            value={formData.EvaluateHealtcareOptionsUsingTools}
            onChange={handleInputChange}
            placeholder="Your answer"
            min="0"

          />
        </div>
        <div className="form-group">
          <label >E. Accessing preventative health services</label>
          <input
            type='number'
            name='AccessingPreventativeHealthServices'
            value={formData.AccessingPreventativeHealthServices}
            onChange={handleInputChange}
            placeholder="Your answer"
            min="0"
          />
        </div>
        <div className="form-group">
          <label>3. Marketplace Application Assessment and Preparation</label>
          <label>A. Healthcare.gov Account Creation or Troubleshooting</label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name="HealtcareGovAcc"
                value="Creation"
                checked={formData.HealtcareGovAcc === 'Creation'}
                onChange={handleInputChange}

              />
              Creation
            </label>
            <label>
              <input
                type="radio"
                name="HealtcareGovAcc"
                value="Troubleshooting"
                checked={formData.HealtcareGovAcc === 'Troubleshooting'}
                onChange={handleInputChange}

              />
              Troubleshooting
            </label>
            <label>
              <input
                type="radio"
                name="HealtcareGovAcc"
                value="None"
                checked={formData.HealtcareGovAcc === 'None'}
                onChange={handleInputChange}

              />
              None
            </label>
            <label>If account creation, number of accounts created</label>
            <input
              type='number'
              name='createdAccounts'
              value={formData.createdAccounts}
              onChange={handleInputChange}
              placeholder="Your answer"
              min="0"
            />
          </div>
        </div>
        <div className="form-group">
          <label>If account troubleshooting, number of accounts</label>
          <input
            type='number'
            name='troubleshootedAccounts'
            value={formData.troubleshootedAccounts}
            onChange={handleInputChange}
            placeholder="Your answer"
            min="0"
          />
        </div>
        <div className="form-group">
          <label >B. Marketplace Eligibility Assessment and Results Review</label>
          <input
            type="number"
            name="MarketplaceEligibility"
            value={formData.MarketplaceEligibility}
            onChange={handleInputChange}
            placeholder="Your answer"
            min="0"
          />
        </div>
        <div className="form-group">
          <label>C. Marketplace Plan Compare</label>
          <input
            type='number'
            name='MarketPlanCompare'
            value={formData.MarketPlanCompare}
            onChange={handleInputChange}
            placeholder="Your answer"
            min="0"
          />
        </div>
        <div className="form-group">
          <label>4. Marketplace Application Submission and Enrollment (Re-enrollment)</label>
          <label>A. Electronic QHP Enrollment using Healthcare.gov</label>
          <input
            type="number"
            name="qhp_enrollment_hcgov"
            value={formData.qhp_enrollment_hcgov}
            onChange={handleInputChange}
            placeholder="Your answer"
            min="0"
          />
        </div>
        <div className="form-group">
          <label>B. Telephonic QHP Enrollment using Marketplace Call Center</label>
          <input
            type='number'
            name='elctronic_qhp_enrollment_marketplaceCallCenter'
            value={formData.elctronic_qhp_enrollment_marketplaceCallCenter}
            onChange={handleInputChange}
            placeholder="Your answer"
            min="0"
          />
        </div>
        <div className="form-group">
          <label>C. Written QHP Enrollment using a paper Marketplace Application</label>
          <input
            type='number'
            name='written_qhp_enrollment_hcgov'
            value={formData.written_qhp_enrollment_hcgov}
            onChange={handleInputChange}
            placeholder="Your answer"
            min="0"
          />
        </div>
        <div className="form-group">
          <label>A. Medicaid/CHIP applications or referrals</label>
          <input
            type="number"
            name="medicaid_chip_app_ref"
            value={formData.medicaid_chip_app_ref}
            onChange={handleInputChange}
            placeholder="Your answer"
            min="0"
          />
        </div>
        <div className="form-group">
          <label>6. Complex Cases and Other Marketplace Enrollment Issues</label>
          <label>A. Complex Case Help Center Assistance and Referrals</label>
          <input
            type="number"
            name="complex_case_help_center"
            value={formData.complex_case_help_center}
            onChange={handleInputChange}
            placeholder="Short answer text"
            min="0"
          />
        </div>
        <div className="form-group">
          <label>B. Data matching issues/Periodic data matching issues assistance</label>
          <div className="radio-group">
            {['1', '2', '3', 'None', 'Other'].map((option) => (
              <label key={option}>
                <input
                  type="radio"
                  name="data_matchingIssues"
                  value={option}
                  checked={formData.data_matchingIssues === option}
                  onChange={handleRadioChange}
                />
                {option}
              </label>
            ))}
          </div>
          {showOtherTextInput.data_matchingIssues && (
            <input
              type="text"
              name="data_matchingIssues_other"
              value={formData.data_matchingIssues_other}
              onChange={handleInputChange}
              placeholder="Please specify"
            />
          )}
        </div>
        <div className="form-group">
          <label>C. SEP eligibility troubleshooting assistance</label>
          <div className="radio-group">
            {['1', '2', '3', 'None', 'Other'].map((option) => (
              <label key={option}>
                <input
                  type="radio"
                  name="SEP_eligibility"
                  value={option}
                  checked={formData.SEP_eligibility === option}
                  onChange={handleRadioChange}
                />
                {option}
              </label>
            ))}
          </div>
          {showOtherTextInput.SEP_eligibility && (
            <input
              type="text"
              name="SEP_eligibility_other"
              value={formData.SEP_eligibility_other}
              onChange={handleInputChange}
              placeholder="Please specify"
            />
          )}
        </div>
        <div className="form-group">
          <label>Employer Sponsored Coverage Issues</label>
          <label>D. Employer-sponsored coverage issues assistance</label>
          <div className="radio-group">
            {['1', '2', '3', 'None', 'Other'].map((option) => (
              <label key={option}>
                <input
                  type="radio"
                  name="Employer_sponsored_coverage_issues"
                  value={option}
                  checked={formData.Employer_sponsored_coverage_issues === option}
                  onChange={handleRadioChange}

                />
                {option}
              </label>
            ))}
          </div>
          {showOtherTextInput.Employer_sponsored_coverage_issues && (
            <input
              type="text"
              name="Employer_sponsored_coverage_issues_other"
              value={formData.Employer_sponsored_coverage_issues_other}
              onChange={handleInputChange}
              placeholder="Please specify"
            />
          )}
        </div>
        <div className="form-group">
          <label>APTC CSR Assistance</label>
          <div className="radio-group">
            {['1', '2', '3', 'None', 'Other'].map((option) => (
              <label key={option}>
                <input
                  type="radio"
                  name="APTC_CSR_assistance"
                  value={option}
                  checked={formData.APTC_CSR_assistance === option}
                  onChange={handleRadioChange}

                />
                {option}
              </label>
            ))}
          </div>
          {showOtherTextInput.APTC_CSR_assistance && (
            <input
              type="text"
              name="APTC_CSR_assistance_other"
              value={formData.APTC_CSR_assistance_other}
              onChange={handleInputChange}
              placeholder="Please specify"
            />
          )}
        </div>
        <div className="form-group">
          <label>F. Other</label>
          <input
            type="text"
            name="Complex_Cases_Other"
            value={formData.Complex_Cases_Other}
            onChange={handleInputChange}
            placeholder="Your answer"
          />
        </div>
        <div className="form-group">
          <label>7. Answering questions about and/or making referrals to:</label>
          <label>Answering questions about and/or making referrals to:</label>
          <label>
            <input
              type="checkbox"
              name="Answering_questions_about"
              value="A. Agent/Broker"
              checked={formData.Answering_questions_about.includes('A. Agent/Broker')}
              onChange={handleCheckboxChange}


            />
            A. Agent/Broker
          </label>
          <label>
            <input
              type="checkbox"
              name="Answering_questions_about"
              value="B. Issuer"
              checked={formData.Answering_questions_about.includes('B. Issuer')}
              onChange={handleCheckboxChange}

            />
            B. Issuer
          </label>
          <label>
            <input
              type="checkbox"
              name="Answering_questions_about"
              value="C. Medicare"
              checked={formData.Answering_questions_about.includes('C. Medicare')}
              onChange={handleCheckboxChange}

            />
            C. Medicare
          </label>
          <label>
            <input
              type="checkbox"
              name="Answering_questions_about"
              value="D. Other consumer assistance/health insurance program"
              checked={formData.Answering_questions_about.includes('D. Other consumer assistance/health insurance program')}
              onChange={handleCheckboxChange}

            />
            D. Other consumer assistance/health insurance program
          </label>
          <label>
            <input
              type="checkbox"
              name="Answering_questions_about"
              value="E. State departments of insurance"
              checked={formData.Answering_questions_about.includes('E. State departments of insurance')}
              onChange={handleCheckboxChange}

            />
            E. State departments of insurance
          </label>
          <label>
            <input
              type="checkbox"
              name="Answering_questions_about"
              value="other"
              checked={formData.Answering_questions_about.includes('other')}
              onChange={handleCheckboxChange}

            />
            Other:
          </label>
          {showOtherTextInput && (
            <input
              type="text"
              name="Answering_questions_about_other"
              value={formData.Answering_questions_about_other || ''}
              onChange={handleInputChange}
              placeholder="Please specify"
            />
          )}
        </div>
        <div className="form-group">
          <label>A. Marketplace tax forms (1095-A)</label>
          <input
            type="number"
            name="Marketplace_tax_forms"
            value={formData.Marketplace_tax_forms}
            onChange={handleInputChange}
            placeholder="Your answer"
            min="0"
          />
        </div>
        <div className="form-group">
          <label>B. Filing Marketplace exemptions</label>
          <input
            type="number"
            name="Filing_Marketplace"
            value={formData.Filing_Marketplace}
            onChange={handleInputChange}
            placeholder="Your answer"
            min="0"
          />
        </div>
        <div className="form-group">
          <label>C. Submitting Marketplace or insurance coverage appeals</label>
          <input
            type="number"
            name="Submitting_Marketplace"
            value={formData.Submitting_Marketplace}
            onChange={handleInputChange}
            placeholder="Your answer"
            min="0"
          />
        </div>
        <div className="form-group">
          <label>Assisted with Other Consumer Information</label>
          <input
            type="text"
            name="Assisted_with_other_consumer_information"
            value={formData.Assisted_with_other_consumer_information}
            onChange={handleInputChange}
            placeholder="Short answer text"
          />
        </div>
        <div className="form-group">
          <label >Pertinent information for tracking (i.e. DOB, family member DOB, medications, preferred doctors , etc.)</label>
          <input
            className='long-text'
            type='text'
            name='pertinent_info_tracking'
            value={formData.pertinent_info_tracking}
            onChange={handleInputChange}
            placeholder="Your answer"
          />
        </div>
        <div className="form-group">
          <label>Where did you Hear from us?</label>
          <select
            name="hear_from_us"
            value={formData.hear_from_us}
            onChange={handleInputChange}

          >
            <option value="" disabled>How did you hear from us?</option>
            <option value="Event">Event</option>
            <option value="Facebook">Facebook</option>
            <option value="Flyer">Flyer</option>
            <option value="Hospital">Hospital</option>
            <option value="Instagram">Instagram</option>
            <option value="Library">Library</option>
            <option value="Newspaper">Newspaper</option>
            <option value="Radio">Radio</option>
            <option value="Referral">Referral</option>
            <option value="SAC">SAC</option>
            <option value="TablePresentation">Table Presentation</option>
            <option value="TV">TV</option>
            <option value="Twitter">X (Twitter)</option>
            <option value="Workforce">WorkForce</option>
            <option value="MAC">MAC</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div className="form-group">
          <label>What Event Did You Attend and See Us At? (Ex. Floresville Health Fair on 7/19/22)</label>
          <input
            className='long-text'
            type="text"
            name="event_hear_abt_us"
            value={formData.event_hear_abt_us || ''}
            onChange={handleInputChange}

          />
        </div>
        <div className="form-group">
          <label>How or Where on Facebook Did You See Us?  (ex. Facebook Live yesterday, FB Ad about Chip, FB Link from friend, FB Post about changes - 7/12)</label>
          <input
            className='long-text'
            type="text"
            name="facebook_hear_abt_us"
            value={formData.facebook_hear_abt_us}
            onChange={handleInputChange}
            placeholder="Your answer"

          />
        </div>
        <div className="form-group">
          <label>How or Where on Instagram Did You See Us?  (ex.   Instagram Live yesterday,  Instagram Ad about Chip,   Instagram Link from friend, Instagram Post about changes - 7/12)</label>
          <input
            type="text"
            name="insta_hear_abt_us"
            value={formData.insta_hear_abt_us}
            onChange={handleInputChange}
            placeholder="Short answer text"

          />
        </div>
        <div className="form-group">
          <label>SAC - San Antonio College (Ex.  Event 7/12, Flyer at Student Services, Teacher - Ms. Z, Staff Mr. Lopez)</label>
          <input
            type="text"
            name="SAC_hear_abt_us"
            value={formData.SAC_hear_abt_us}
            onChange={handleInputChange}
            placeholder="Your answer"

          />
        </div>
        <div className="form-group">
          <label>How or Where on Twitter Did You See Us?  (ex.  Twitter Live yesterday,  Twitter Ad about Chip,  Twitter Link from friend,  Twitter Post about changes - 7/12)</label>
          <input
            className='long-text'
            type="text"
            name="twitter_hear_abt_us"
            value={formData.twitter_hear_abt_us}
            onChange={handleInputChange}
            placeholder="Your answer"

          />
        </div>
        <div className="form-group">
          <label>What TV Station Did You See Us In? (Ex. Univision - Phone Bank 7/21, KSAT - News 7/1, Telemundo - Commercial 7/2)</label>
          <input
            className='long-text'
            type="text"
            name="tv_hear_abt_us"
            value={formData.tv_hear_abt_us}
            onChange={handleInputChange}
            placeholder="Your answer"

          />
        </div>
        <div className="form-group">
          <label>Which Hospital Did You See Us At? (Baptist Mission Trails, Cuero Regional, MHM -Floyd Curl)</label>
          <input
            type="text"
            name="hospital_hear_abt_us"
            value={formData.hospital_hear_abt_us}
            onChange={handleInputChange}
            placeholder="Your answer"

          />
        </div>
        <div className="form-group">
          <label>Which Library Did You See Us At?</label>
          <select
            name="library_opt"
            value={formData.library_opt}
            onChange={handleInputChange}

          >
            <option value="" disabled>Select library</option>
            <option value="Atascosa">Atascosa</option>
            <option value="Bazan">Bazan</option>
            <option value="BiblioTech - Pleasanton">BiblioTech - Pleasanton</option>
            <option value="BiblioTech - Zarzamora">BiblioTech - Zarzamora</option>
            <option value="BiblioTech East">BiblioTech East</option>
            <option value="Brook Hollow">Brook Hollow</option>
            <option value="Carver">Carver</option>
            <option value="Central - DownTown - SA">Central - DownTown - SA</option>
            <option value="Collins">Collins</option>
            <option value="Cody">Cody</option>
            <option value="Cortez">Cortez</option>
            <option value="DeWitt">DeWitt</option>
            <option value="Encino">Encino</option>
            <option value="Floresville Library">Floresville Library</option>
            <option value="Forest Hills">Forest Hills</option>
            <option value="Great Northwest">Great Northwest</option>
            <option value="Guerra">Guerra</option>
            <option value="Igo">Igo</option>
            <option value="Johnston">Johnston</option>
            <option value="Kampmann">Kampmann</option>
            <option value="Karnes">Karnes</option>
            <option value="Landa">Landa</option>
            <option value="Las Palmas">Las Palmas</option>
            <option value="Maverick">Maverick</option>
            <option value="McCreless">McCreless</option>
            <option value="Memorial">Memorial</option>
            <option value="Mission">Mission</option>
            <option value="Pan American">Pan American</option>
            <option value="Parman">Parman</option>
            <option value="Poteet Library">Poteet Library</option>
            <option value="Potranco">Potranco</option>
            <option value="Pruitt">Pruitt</option>
            <option value="San Pedro Library">San Pedro Library</option>
            <option value="Schaefer">Schaefer</option>
            <option value="Semmes">Semmes</option>
            <option value="Texana">Texana</option>
            <option value="Thousand Oaks">Thousand Oaks</option>
            <option value="Tobin">Tobin</option>
            <option value="Westfall">Westfall</option>
            <option value="Wilson">Wilson</option>
            <option value="Other:">Other:</option>
          </select>
        </div>
        <div className="form-group">
          <label>Which Workforce and When? (Ex. Medical 7/20 -(same as Datapoint), Marbach 7/1, Flores 7/10)</label>
          <input
            type="text"
            name="workforce_hear_abt_us"
            value={formData.workforce_hear_abt_us}
            onChange={handleInputChange}
            placeholder="Your answer"

          />
        </div>
        <div className="form-group">
          <label>What Table Presentation Did You Attend? (Ex. Community Event at Madonna 7/21 or Parent Presentation at Lanier 7/1/22)</label>
          <input
            type="text"
            name="tablepresentation_hear_abt_us"
            value={formData.tablepresentation_hear_abt_us}
            onChange={handleInputChange}
            placeholder="Your answer"

          />
        </div>
        <div className="form-group">
          <label>Who Referred You?</label>
          <input
            type="text"
            name="referral_hear_abt_us"
            value={formData.referral_hear_abt_us}
            onChange={handleInputChange}
            placeholder="Your answer"

          />
        </div>
        <div className="form-group">
          <label>What Newspaper did you see our ad on? (Ex. San Antonio  Express, Karnes Daily...)</label>
          <input
            type="text"
            name="newspaper_hear_abt_us"
            value={formData.newspaper_hear_abt_us}
            onChange={handleInputChange}
            placeholder="Your answer"

          />
        </div>
        <div className="form-group">
          <label>Which Radio Station Did You Here US? (Ex. La Nuestra or Tejano - in San Antonio or Karnes)</label>
          <input
            type="text"
            name="radio_hear_abt_us"
            value={formData.radio_hear_abt_us}
            onChange={handleInputChange}
            placeholder="Your answer"

          />
        </div>
        <div className="form-group">
          <label>Where Did You Find or See Our Flyer? (Ex. MAUC, WSA, Culebra, Cody Library)</label>
          <input
            type="text"
            name="flyer_hear_abt_us"
            value={formData.flyer_hear_abt_us}
            onChange={handleInputChange}
            placeholder="Your answer"

          />
        </div>
        <div className="form-group">
          <label>County</label>
          <select
            name="county2"
            value={formData.county2}
            onChange={handleInputChange}

          >
            <option value="" disabled>County</option>
            <option value="Atascosa">Atascosa</option>
            <option value="Bandera">Bandera</option>
            <option value="Bee">Bee</option>
            <option value="Brooks">Brooks</option>
            <option value="Bexar">Bexar</option>
            <option value="Caldwell">Caldwell</option>
            <option value="Cameron">Cameron</option>
            <option value="Comal">Comal</option>
            <option value="DeWitt">DeWitt</option>
            <option value="Frio">Frio</option>
            <option value="Goliad">Goliad</option>
            <option value="Gonzalez">Gonzalez</option>
            <option value="Guadalupe">Guadalupe</option>
            <option value="Hays">Hays</option>
            <option value="Hidalgo">Hidalgo</option>
            <option value="Jim Hogg">Jim Hogg</option>
            <option value="Jim Wells">Jim Wells</option>
            <option value="Karnes">Karnes</option>
            <option value="Kleberg">Kleberg</option>
            <option value="La Salle">La Salle</option>
            <option value="Medina">Medina</option>
            <option value="Maverick">Maverick</option>
            <option value="Nueces">Nueces</option>
            <option value="San Patricio">San Patricio</option>
            <option value="Uvalde">Uvalde</option>
            <option value="Wilson">Wilson</option>
            <option value="Other:">Other:</option>
          </select>
          {formData.county2 === 'Other:' && (
            <div>
              <label>Please specify:</label>
              <input
                type="text"
                name="other_county"
                value={formData.other_county}
                onChange={handleInputChange}

              />
            </div>
          )}
        </div>
        <div className="form-group">
          <label>Zip Code</label>
          <input
            type='number'
            name='zip_code'
            value={formData.zip_code}
            onChange={handleInputChange}
            placeholder="Your answer"
            min="0"
          />
        </div>
        <div className="form-group">
          <label>Any Notes</label>
          <input
            className='long-text'
            type="text"
            name="finalNotes"
            value={formData.finalNotes}
            onChange={handleInputChange}
            placeholder="Your answer"
          />
        </div>

        <div className="form-group">
          <label>Client Name</label>
          <input
            type='text'
            name={`plan_ClientName_0`}
            value={formData.plan_ClientName_0 || ''}
            onChange={handleInputChange}
            placeholder="Your answer"
          />
        </div>
        <div className="form-group">
          <label>Client Last Name</label>
          <input
            type="text"
            name={`plan_ClientLastName_0`}
            value={formData.plan_ClientLastName_0 || ''}
            onChange={handleInputChange}
            placeholder="Your answer"
          />
        </div>
        <div className="form-group">
          <label>Insurer Selected (#{0 + 1})</label>
          <select
            name={`Insurer_0`}
            value={formData[`Insurer_0`] || ''}
            onChange={handleInputChange}
          >
            <option value="" disabled>Select Insurer</option>
            <option value="Aetna CVS Health">Aetna CVS Health</option>
            <option value="Ascension">Ascension</option>
            <option value="Ambetter from Superior HealthPlan">Ambetter from Superior HealthPlan</option>
            <option value="Blue Cross Blue Shield of Texas (Blue Advantage)">Blue Cross Blue Shield of Texas (Blue Advantage)</option>
            <option value="Bright HealthCare">Bright HealthCare</option>
            <option value="CHRISTUS Health Plan">CHRISTUS Health Plan</option>
            <option value="Cigna">Cigna</option>
            <option value="Community Health Choice">Community Health Choice</option>
            <option value="Community First">Community First</option>
            <option value="FirstCare">FirstCare</option>
            <option value="Imperial">Imperial</option>
            <option value="Moda Health">Moda Health</option>
            <option value="Molina Healthcare">Molina Healthcare</option>
            <option value="Oscar">Oscar</option>
            <option value="Baylor Scott & White">Baylor Scott & White</option>
            <option value="Sendero">Sendero</option>
            <option value="UnitedHealthCare">UnitedHealthCare</option>
            <option value="US Health and Life">US Health and Life</option>
          </select>
        </div>
        <div className="form-group">
          <label>Plan Metal</label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name={`PlanMetal_0`}
                value="Bronze"
                checked={formData.PlanMetal_0 === 'Bronze'}
                onChange={handleInputChange}
              />
              Bronze
            </label>
            <label>
              <input
                type="radio"
                name={`PlanMetal_0`}
                value="Silver"
                checked={formData.PlanMetal_0 === 'Silver'}
                onChange={handleInputChange}
              />
              Silver
            </label>
            <label>
              <input
                type="radio"
                name={`PlanMetal_0`}
                value="Gold"
                checked={formData.PlanMetal_0 === 'Gold'}
                onChange={handleInputChange}
              />
              Gold
            </label>
          </div>
        </div>
        <div className="form-group">
          <label>Plan #{0 + 1} Price of Plan</label>
          <input
            type='number'
            name={`PriceofPlan_0`}
            value={formData[`PriceofPlan_0`] || ''}
            onChange={handleInputChange}
            placeholder="Your answer"
            min="0"
            step="0.01"
          />
        </div>
        <div className="form-group">
          <label>Plan #{0 + 1} APTC/Subsidy</label>
          <input
            type='number'
            name={`APCT_Subsidy_${0}`}
            value={formData[`APCT_Subsidy_${0}`] || ''}
            onChange={handleInputChange}
            placeholder="Your answer"
            min="0"
            step="0.01"
          />
        </div>
        <div className="form-group">
          <label>Did Client Select Dental #{0 + 1}</label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name={`DentalConfirm${0}`}
                value="Yes"
                checked={formData[`DentalConfirm${0}`] === 'Yes'}
                onChange={handleInputChange}

              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name={`DentalConfirm${0}`}
                value="No"
                checked={formData[`DentalConfirm${0}`] === 'No'}
                onChange={handleInputChange}

              />
              No
            </label>
          </div>
        </div>
        <div className="form-group">
          <label>Client Name</label>
          <input
            type="text"
            name="Dentalplan_ClientName_0"
            value={formData.Dentalplan_ClientName_0}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Client Last Name</label>
          <input
            type="text"
            name="Dentalplan_ClientLastName_0"
            value={formData.Dentalplan_ClientLastName_0}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Dental Plan Name</label>
          <input
            type="text"
            name="Dentalplan_Name_0"
            value={formData.Dentalplan_Name_0}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group">
          <label>Cost of the Dental Plan</label>
          <input
            type='number'
            name={`Dentalplan_Cost_${0}`}
            value={formData[`Dentalplan_Cost_${0}`] || ''}
            onChange={handleInputChange}
            placeholder="Your answer"
            min="0"
            step="0.01"

          />
        </div>
        {(userGroup?.includes('Admin') || userGroup?.includes('Senior-Navigator')) ? (
          <>
            <button className="save-btn" onClick={handleSave}>Update</button>
          </>
        ) : (
          <p>This report is view only, please contact your lead for required updates.</p>
        )}

      </div>
    </div>
  );
};

export default ReportModal;
