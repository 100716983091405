import React from 'react';
import one_to_one from '../../../assets/images/OneOnOne.jpg';


const Section29 = ({ formData, handleInputChange }) => (
  <>
    {/* Section 29 */}
    <div className="form-section flyer-section">
      <div className="flyer-header">One-on-One Client Report</div>
      <p>During one-on-one interactions with consumers, indicate the number of consumers Navigators have directly assisted with:</p>
    </div>

    <div className='form-section one_one'>
      <img src={one_to_one} alt="one_to_one" className="one_to_one" />
    </div>
    <div className='form-section'>
      <label className="required-asterisk">1. Addressing general inquiries about health insurance options.</label>
      <input
        type='number'
        name='one2oneInquiry'
        value={formData.one2oneInquiry}
        onChange={handleInputChange}
        placeholder="Number"
        min="0"
        required
      />


    </div>
  </>
);

export default Section29;
