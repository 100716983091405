import React from 'react';

const Section56 = ({ formData, handleInputChange }) => (
  <>
    {/* Section 56 */}
    <div className="form-section flyer-section">
      <div className="flyer-header">THANK YOU...please make follow-up appointment</div>

      {/* Link */}
      <a href="https://www.picktime.com/app#calendar/s/d/20241101/all/allTeam" target="_blank" rel="noopener noreferrer" className="follow-up-link">
        Picktime link to schedule a follow-up appointment
      </a>

      {/* Notes Section */}
      <label>Any Notes</label>
      <textarea
        className="long-text"
        name="finalNotes"
        value={formData.finalNotes}
        onChange={handleInputChange}
        placeholder="Your answer"
      />
    </div>
  </>
);

export default Section56;
