import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import GoalsProgress from './GoalsProgress'; // Import the new component
import { useLazyQuery } from '@apollo/client';
import { DashboardListReports } from '../../graphql/queries';
import { Pie, Bar } from 'react-chartjs-2';
import { getCurrentUser } from 'aws-amplify/auth';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend);

const Dashboard = () => {
  const [weekNumber, setWeekNumber] = useState(0);
  const [py, setPy] = useState(1); // Starting with PY 1
  const [quarterNumber, setQuarterNumber] = useState(1);
  const [progressPercentage, setProgressPercentage] = useState(0);
  // State variables for yearly metrics
  const [totalQHPAmount, setTotalQHPAmount] = useState(0);
  const [medicaidCount, setMedicaidCount] = useState(0);
  const [clientEnrollYesCount, setClientEnrollYesCount] = useState(0);
  const [qhpEnrollmentsByMonth, setQhpEnrollmentsByMonth] = useState(Array(12).fill(0));
  const [medicaidEnrollmentsByMonth, setMedicaidEnrollmentsByMonth] = useState(Array(12).fill(0));
  const [currentUserId, setCurrentUserId] = useState(null);

  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const startDate = new Date('2024-08-27'); // Start of the Program Year
  const endDate = new Date('2025-08-29');   // End of the Program Year
  //Pie chart variables
  const [enrollmentsByUser, setEnrollmentsByUser] = useState({});
  const [outreachData, setOutreachData] = useState({ yes: 0, no: 0 });
  const [totalEnrollments, setTotalEnrollments] = useState(0);
  // Define state variables for weekly and yearly goals
  const expectedQHP_yr = 190;
  const expectedQHP_wk = 10;
  const [currentQHP_yr, setCurrentQHP_yr] = useState(0);
  const [currentQHP_week, setCurrentQHP_week] = useState(0);

  const expectedMedicaidChip_yr = 100;
  const expectedMedicaidChip_wk = 3;
  const [currentMedicaidChip_yr, setCurrentMedicaid_yr] = useState(0);
  const [currentMedicaidChip_week, setCurrentMedicaid_week] = useState(0);

  const expected_one2one_yr = 16666;
  const expected_one2one_wk = 320;
  const [current_one2one_yr, setCurrent_one2one_yr] = useState(0);
  const [current_one2one_week, setCurrent_one2one_week] = useState(0);

  const expected_health_lit_edu_yr = 555;
  const expected_health_lit_edu_wk = 11;
  const [current_health_lit_edu_yr, setCurrent_health_lit_edu_yr] = useState(0);
  const [current_health_lit_edu_week, setCurrent_health_lit_edu_week] = useState(0);

  const expected_post_enrollment_asst_enroll_iss_complex_cases_yr = 250;
  const expected_post_enrollment_asst_enroll_iss_complex_cases_wk = 5;
  const [current_post_enrollment_asst_enroll_iss_complex_cases_yr, setCurrent_post_enrollment_asst_enroll_iss_complex_cases_yr] = useState(0);
  const [current_post_enrollment_asst_enroll_iss_complex_cases_week, setCurrent_post_enrollment_asst_enroll_iss_complex_cases_week] = useState(0);

  const expected_tax_forms_appeals_yr = 694;
  const expected_tax_forms_appeals_wk = 13;
  const [current_tax_forms_appeals_yr, setCurrent_tax_forms_appeals_yr] = useState(0);
  const [current_tax_forms_appeals_week, setCurrent_tax_forms_appeals_week] = useState(0);


  const pieChartData = {
    labels: ['Your Reports', 'Others'],
    datasets: [{
      data: [enrollmentsByUser["Your Enrollments"], enrollmentsByUser["Others"]],
      backgroundColor: ['rgba(189, 158, 250, 0.6)', 'rgba(142, 212, 212, 0.6)'],
    }],
  };

  const outreachPieData = {
    labels: ['Outreach - Yes', 'Outreach - No'],
    datasets: [{
      data: [outreachData.yes, outreachData.no],
      backgroundColor: ['rgba(142, 212, 212, 0.6)', 'rgba(189, 158, 250, 0.6)'],
    }],
  };

  const [fetchReports] = useLazyQuery(DashboardListReports);

  // Function to recursively fetch all pages of reports
  const loadAllReports = async () => {
    let allReports = [];
    let nextToken = null;
    let isLoading = true;

    try {
      while (isLoading) {
        const { data } = await fetchReports({
          variables: {
            limit: 50, // Reduce limit for smaller incremental loading
            nextToken: nextToken,
          },
        });

        if (data && data.listReports && data.listReports.items) {
          allReports = [...allReports, ...data.listReports.items];
          nextToken = data.listReports.nextToken; // Get the nextToken for pagination

          // Update the reports progressively
          setReports(prevReports => [...prevReports, ...data.listReports.items]);
        }

        isLoading = !!nextToken; // Stop loading if no more pages
      }
    } catch (error) {
      console.error('Error fetching reports:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadAllReports(); // Load all reports when the component mounts
  }, []);

  // Fetch the current logged-in user
  const fetchCurrentUser = async () => {
    try {
      const { signInDetails } = await getCurrentUser();
      setCurrentUserId(signInDetails.loginId); // Assume loginId is the identifier
    } catch (error) {
      console.error('Failed to fetch current user:', error);
      return null;
    }
  };
  useEffect(() => {
    // Fetch the current user when the component mounts
    fetchCurrentUser();
  }, []);

  // Recalculate values based on progressively loaded reports
  useEffect(() => {
    if (reports.length && currentUserId) {
      const today = new Date();
      const dayOfWeek = today.getDay();
      const startOfWeek = new Date(today);
      const endOfWeek = new Date(today);
      startOfWeek.setDate(today.getDate() - (dayOfWeek + 2) % 7); // Previous Friday
      endOfWeek.setDate(startOfWeek.getDate() + 6); // Next Thursday
      const weeklyReports = reports.filter(item => {
        const itemDate = new Date(item.Date);
        return itemDate >= startOfWeek && itemDate <= endOfWeek;
      });

      const filteredReports = reports.filter(item => {
        const itemDate = new Date(item.Date);
        return itemDate >= startDate && itemDate <= endDate; // Filter by date range
      });

      // Calculate total QHP amount across all reports
      const totalQHP = filteredReports.reduce((sum, item) => {
        const fieldsToSum = [
          'qhp_enrollment_hcgov',
          'elctronic_qhp_enrollment_marketplaceCallCenter',
          'written_qhp_enrollment_hcgov'
        ];

        // Sum up all specified fields, ignoring non-numeric values
        const amount = fieldsToSum.reduce((fieldSum, field) => {
          const value = parseFloat(item[field]);
          return !isNaN(value) ? fieldSum + value : fieldSum;
        }, 0);

        return sum + amount;
      }, 0);
      setTotalQHPAmount(totalQHP); // Set the total QHP amount

      // Calculate total QHP amount for the current logged-in user
      const userQHPAmount = filteredReports.reduce((sum, item) => {
        if (item.Username === currentUserId) {
          const fieldsToSum = [
            'qhp_enrollment_hcgov',
            'elctronic_qhp_enrollment_marketplaceCallCenter',
            'written_qhp_enrollment_hcgov'
          ];

          // Sum up all specified fields, ignoring non-numeric values
          const amount = fieldsToSum.reduce((fieldSum, field) => {
            const value = parseFloat(item[field]);
            return !isNaN(value) ? fieldSum + value : fieldSum;
          }, 0);

          return sum + amount;
        }
        return sum;
      }, 0);
      setCurrentQHP_yr(userQHPAmount); // Set the variable with the current user's QHP amount

      const userQHPWeeklyAmount = weeklyReports.reduce((sum, item) => {
        if (item.Username === currentUserId) {
          const fieldsToSum = [
            'qhp_enrollment_hcgov',
            'elctronic_qhp_enrollment_marketplaceCallCenter',
            'written_qhp_enrollment_hcgov'
          ];

          const amount = fieldsToSum.reduce((fieldSum, field) => {
            const value = parseFloat(item[field]);
            return !isNaN(value) ? fieldSum + value : fieldSum;
          }, 0);

          return sum + amount;
        }
        return sum;
      }, 0);
      setCurrentQHP_week(userQHPWeeklyAmount);

      // Calculate total Medicaid count
      const totalMedicaid = filteredReports.reduce((sum, item) => {
        const amount = parseFloat(item.medicaid_chip_app_ref);
        return !isNaN(amount) ? sum + amount : sum;
      }, 0);
      setMedicaidCount(totalMedicaid);

      // Calculate total Medicaid amount for the current logged-in user
      const userMedicaidAmount = filteredReports.reduce((sum, item) => {
        if (item.Username === currentUserId) {
          const amount = parseFloat(item.medicaid_chip_app_ref);
          return !isNaN(amount) ? sum + amount : sum;
        }
        return sum;
      }, 0);
      setCurrentMedicaid_yr(userMedicaidAmount); // Set the variable with the current user's amount

      // Calculate weekly Medicaid amount for the current logged-in user
      const userMedicaidWeeklyAmount = weeklyReports.reduce((sum, item) => {
        if (item.Username === currentUserId) {
          const amount = parseFloat(item.medicaid_chip_app_ref);
          return !isNaN(amount) ? sum + amount : sum;
        }
        return sum;
      }, 0);
      setCurrentMedicaid_week(userMedicaidWeeklyAmount);

      // Calculate total One2One amount for the current logged-in user
      const userOne2OneAmount = filteredReports.reduce((sum, item) => {
        if (item.Username === currentUserId) {
          const fieldsToSum = [
            'one2oneInquiry',
            'Locating_Providers',
            'Billing_Payment_Questions',
            'EvaluateHealtcareOptionsUsingTools',
            'AccessingPreventativeHealthServices'
          ];   

          // Sum up all specified fields, ignoring non-numeric values
          const amount = fieldsToSum.reduce((fieldSum, field) => {
            const value = parseFloat(item[field]);
            return !isNaN(value) ? fieldSum + value : fieldSum;
          }, 0);

          return sum + amount;
        }
        return sum;
      }, 0);
      setCurrent_one2one_yr(userOne2OneAmount); // Set the variable with the current user's total amount


      // Calculate weekly One2One amount for the current logged-in user
      const userOne2OneWeeklyAmount = weeklyReports.reduce((sum, item) => {
        if (item.Username === currentUserId) {
          const fieldsToSum = [
            'one2oneInquiry',
            'Locating_Providers',
            'Billing_Payment_Questions',
            'EvaluateHealtcareOptionsUsingTools',
            'AccessingPreventativeHealthServices'
          ];

          const amount = fieldsToSum.reduce((fieldSum, field) => {
            const value = parseFloat(item[field]);
            return !isNaN(value) ? fieldSum + value : fieldSum;
          }, 0);

          return sum + amount;
        }
        return sum;
      }, 0);
      setCurrent_one2one_week(userOne2OneWeeklyAmount);


      // Calculate total Insurance Literacy amount for the current logged-in user
      const userHealthInsuranceLiteracyAmount = filteredReports.reduce((sum, item) => {
        if (item.Username === currentUserId) {
          const amount = parseFloat(item.HealthInsuranceLiteracy);
          return !isNaN(amount) ? sum + amount : sum;
        }
        return sum;
      }, 0);
      setCurrent_health_lit_edu_yr(userHealthInsuranceLiteracyAmount); // Set the variable with the current user's amount


      // Calculate weekly Health Insurance Literacy amount for the current logged-in user
      const userHealthInsuranceLiteracyWeeklyAmount = weeklyReports.reduce((sum, item) => {
        if (item.Username === currentUserId) {
          const amount = parseFloat(item.HealthInsuranceLiteracy);
          return !isNaN(amount) ? sum + amount : sum;
        }
        return sum;
      }, 0);
      setCurrent_health_lit_edu_week(userHealthInsuranceLiteracyWeeklyAmount);

      // Calculate total Issues Complex Cases amount for the current logged-in user
      const userComplexCasesAmount = filteredReports.reduce((sum, item) => {
        if (item.Username === currentUserId) {
          const fieldsToSum = [
            'complex_case_help_center',
            'data_matchingIssues',
            'SEP_eligibility',
            'Employer_sponsored_coverage_issues',
            'APTC_CSR_assistance',
            'Complex_Cases_Other'
          ];

          // Sum up all specified fields, ignoring non-numeric values
          const amount = fieldsToSum.reduce((fieldSum, field) => {
            const value = parseFloat(item[field]);
            return !isNaN(value) ? fieldSum + value : fieldSum;
          }, 0);

          return sum + amount;
        }
        return sum;
      }, 0);
      setCurrent_post_enrollment_asst_enroll_iss_complex_cases_yr(userComplexCasesAmount); // Set the variable with the current user's total amount


      // Calculate weekly Complex Cases amount for the current logged-in user
      const userComplexCasesWeeklyAmount = weeklyReports.reduce((sum, item) => {
        if (item.Username === currentUserId) {
          const fieldsToSum = [
            'complex_case_help_center',
            'data_matchingIssues',
            'SEP_eligibility',
            'Employer_sponsored_coverage_issues',
            'APTC_CSR_assistance',
            'Complex_Cases_Other'
          ];

          const amount = fieldsToSum.reduce((fieldSum, field) => {
            const value = parseFloat(item[field]);
            return !isNaN(value) ? fieldSum + value : fieldSum;
          }, 0);

          return sum + amount;
        }
        return sum;
      }, 0);
      setCurrent_post_enrollment_asst_enroll_iss_complex_cases_week(userComplexCasesWeeklyAmount);

      // Calculate total Tax Forms/ Appeals amount for the current logged-in user
      const userTax_AppealsAmount = filteredReports.reduce((sum, item) => {
        if (item.Username === currentUserId) {
          const fieldsToSum = [
            'Marketplace_tax_forms',
            'Filing_Marketplace',
            'Submitting_Marketplace'
          ];

          // Sum up all specified fields, ignoring non-numeric values
          const amount = fieldsToSum.reduce((fieldSum, field) => {
            const value = parseFloat(item[field]);
            return !isNaN(value) ? fieldSum + value : fieldSum;
          }, 0);

          return sum + amount;
        }
        return sum;
      }, 0);
      setCurrent_tax_forms_appeals_yr(userTax_AppealsAmount); // Set the variable with the current user's total amount

      // Calculate weekly Tax Forms/Appeals amount for the current logged-in user
      const userTaxAppealsWeeklyAmount = weeklyReports.reduce((sum, item) => {
        if (item.Username === currentUserId) {
          const fieldsToSum = [
            'Marketplace_tax_forms',
            'Filing_Marketplace',
            'Submitting_Marketplace'
          ];

          const amount = fieldsToSum.reduce((fieldSum, field) => {
            const value = parseFloat(item[field]);
            return !isNaN(value) ? fieldSum + value : fieldSum;
          }, 0);

          return sum + amount;
        }
        return sum;
      }, 0);
      setCurrent_tax_forms_appeals_week(userTaxAppealsWeeklyAmount);
      // Calculate total client enrollments
      const clientEnrollYesCount = filteredReports.reduce((count, item) => {
        return item.clientEnroll === 'Yes' ? count + 1 : count;
      }, 0);
      setClientEnrollYesCount(clientEnrollYesCount);
      // Track enrollments for the logged-in user and others
      let userEnrollCount = 0;
      let otherEnrollCount = 0;
      let outreachYes = 0;
      let outreachNo = 0;

      filteredReports.forEach((item) => {
        if (item.Username === currentUserId) {
          userEnrollCount += 1;
        } else {
          otherEnrollCount += 1;
        }

        // Count Outreach Participation
        if (item.outreachEvent === "Yes") {
          outreachYes += 1;
        } else if (item.outreachEvent === "No") {
          outreachNo += 1;
        }
      });
      // Set outreach data
      setOutreachData({ yes: outreachYes, no: outreachNo });

      setEnrollmentsByUser({
        "Your Enrollments": userEnrollCount,
        "Others": otherEnrollCount,
      });
      setTotalEnrollments(userEnrollCount + otherEnrollCount);

      // Aggregate enrollments by month for the current year
      const qhpByMonth = Array(12).fill(0);
      const medicaidByMonth = Array(12).fill(0);

      filteredReports.forEach(item => {
        const itemDate = new Date(item.Date);
        const month = itemDate.getMonth();

        const qhpAmount = parseFloat(item.qhp_enrollment_hcgov);
        if (!isNaN(qhpAmount)) {
          qhpByMonth[month] += qhpAmount;
        }

        const medicaidAmount = parseFloat(item.medicaid_chip_app_ref);
        if (!isNaN(medicaidAmount)) {
          medicaidByMonth[month] += medicaidAmount;
        }
      });

      setQhpEnrollmentsByMonth(qhpByMonth);
      setMedicaidEnrollmentsByMonth(medicaidByMonth);
    }
  }, [reports, currentUserId]);


  useEffect(() => {
    // Calculate the current week, PY, and quarter
    const calculateWeekAndPY = () => {
      const startOfPY1 = new Date('2024-08-30'); // PY1 starts on August 30th, 2024 (Friday)
      const currentDate = new Date();

      // Calculate how many weeks have passed since PY 1 started
      const msInWeek = 7 * 24 * 60 * 60 * 1000; // Milliseconds in a week
      const diffInMs = currentDate - startOfPY1;
      const weeksPassed = Math.floor(diffInMs / msInWeek);

      // Set the current week number
      setWeekNumber(weeksPassed + 1);

      // Set the PY (Program Year) - increments every 52 weeks
      const currentPY = Math.floor(weeksPassed / 52) + 1;
      setPy(currentPY);

      // Calculate the current quarter
      const currentQuarter = Math.floor((weeksPassed % 52) / 13) + 1;
      setQuarterNumber(currentQuarter);

      // Calculate the progress percentage for the current PY
      const weeksIntoCurrentPY = weeksPassed % 52;
      const progressPercentage = (weeksIntoCurrentPY / 52) * 100;
      setProgressPercentage(progressPercentage);
    };

    calculateWeekAndPY();
  }, []);

  const chartData = {
    labels: [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ],
    datasets: [
      {
        label: 'QHP Enrollments',
        data: qhpEnrollmentsByMonth,
        backgroundColor: 'rgba(142, 212, 212, 0.6)', // Converted from #8ed4d4
        borderColor: 'rgba(142, 212, 212, 1)', // Converted from #8ed4d4
        borderWidth: 1,
      },
      {
        label: 'Medicaid Enrollments',
        data: medicaidEnrollmentsByMonth,
        backgroundColor: 'rgba(189, 158, 250, 0.6)', // Converted from #bd9efa
        borderColor: 'rgba(189, 158, 250, 1)', // Converted from #bd9efa
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="dashboard">
      <header className="dashboard-header">
        <h1>Dashboard</h1>
      </header>
      <div className="dashboard-stats">
        <div className="stat">
          <h2>Enrollments</h2>
          <p>{loading ? 'Loading...' : clientEnrollYesCount}</p>
        </div>
        <div className="stat">
          <h2>QHPs</h2>
          <p>{loading ? 'Loading...' : totalQHPAmount}</p>
        </div>
        <div className="stat">
          <h2>Medicaids</h2>
          <p>{loading ? 'Loading...' : medicaidCount}</p>
        </div>
      </div>
      <div className="dashboard-content">
        <p>There are no appointments or events today.</p>
        <p>Week {weekNumber} of PY {py}, Quarter {quarterNumber}</p>
        <div className="progress-bar">
          <div className="progress" style={{ width: `${progressPercentage}%` }}></div>
        </div>
      </div>
      <div className="dashboard-main">
        <div>
          <GoalsProgress
            // Yearly Goals
            currentQHP_yr={currentQHP_yr}
            expectedQHP_yr={expectedQHP_yr}
            currentMedicaidChip_yr={currentMedicaidChip_yr}
            expectedMedicaidChip_yr={expectedMedicaidChip_yr}
            current_one2one_yr={current_one2one_yr}
            expected_one2one_yr={expected_one2one_yr}
            current_health_lit_edu_yr={current_health_lit_edu_yr}
            expected_health_lit_edu_yr={expected_health_lit_edu_yr}
            current_post_enrollment_asst_enroll_iss_complex_cases_yr={current_post_enrollment_asst_enroll_iss_complex_cases_yr}
            expected_post_enrollment_asst_enroll_iss_complex_cases_yr={expected_post_enrollment_asst_enroll_iss_complex_cases_yr}
            current_tax_forms_appeals_yr={current_tax_forms_appeals_yr}
            expected_tax_forms_appeals_yr={expected_tax_forms_appeals_yr}

            // Weekly Goals
            currentQHP_week={currentQHP_week}
            expectedQHP_wk={expectedQHP_wk}
            currentMedicaidChip_week={currentMedicaidChip_week}
            expectedMedicaidChip_wk={expectedMedicaidChip_wk}
            current_one2one_week={current_one2one_week}
            expected_one2one_wk={expected_one2one_wk}
            current_health_lit_edu_week={current_health_lit_edu_week}
            expected_health_lit_edu_wk={expected_health_lit_edu_wk}
            current_post_enrollment_asst_enroll_iss_complex_cases_week={current_post_enrollment_asst_enroll_iss_complex_cases_week}
            expected_post_enrollment_asst_enroll_iss_complex_cases_wk={expected_post_enrollment_asst_enroll_iss_complex_cases_wk}
            current_tax_forms_appeals_week={current_tax_forms_appeals_week}
            expected_tax_forms_appeals_wk={expected_tax_forms_appeals_wk}
          />
        </div>

        {/* <div className="appointments-today">
          <h2>Appointments Today</h2>
          <p>No Appointments Scheduled today</p>
          <a href="https://www.picktime.com/3d813e3e-e5d9-4649-9b76-fac01303d046" class="ptbkbtn" target="_blank" style={{ float: 'none' }}>
          <img border="none" src="https://www.picktime.com/img/widgetButtons/BookingPage/picktime-book-online-darkblue.png" alt="Book an appointment with Health Collaborative - Pathways to Covearge"/>
          </a>
        </div> */}
        <div className="enrollments-by-month">
          <h2>Enrollments by Month ({new Date().getFullYear()})</h2>
          <Bar className='Graph_EnrollmentsbyMonth' data={chartData} options={options} />
        </div>
        <div className="pie-charts-container">
          <div className="pie-chart-section">
            <h2>Reports by Users</h2>
            {Object.keys(enrollmentsByUser).length > 0 && (
              <Pie data={pieChartData} />
            )}
          </div>
          <div className="pie-chart-section">
            <h2>Outreach Events</h2>
            {outreachData.yes + outreachData.no > 0 && (
              <Pie data={outreachPieData} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
