// EquipmentTable.js
import React from 'react';
import './EquipmenteTable.css';

const EquipmentTable = ({ title, data, totalPurchase, itemCount, selectedDateType, searchTerm, filterItems }) => {
  const filteredData = filterItems(data || [], searchTerm);

  if (searchTerm && filteredData.length === 0) return null; // No mostrar tabla si no hay resultados

  return (
<div className="equipment-table">
<h2 style={{ marginBottom: "5px" }}>{title}</h2>
  {selectedDateType === "purchase_date" && (
    <div style={{ textAlign: "center", fontWeight: "bold", marginBottom: "10px" }}>
      {`${title} Purchase Total: $${totalPurchase}`}
    </div>
  )}
  <table>
    <thead>
      <tr>
        <th>Item</th>
        <th>Tag ID</th>
        <th>Model</th>
        <th>Price</th>
        <th>Serial Number</th>
        <th>Assigned to</th>
        <th>Location</th>
        <th>{selectedDateType}</th>
      </tr>
    </thead>
    <tbody>
      {filteredData.map((item) => (
        <tr key={item.id}>
          <td>{title}</td>
          <td>{item.tagid}</td>
          <td>{item.model}</td>
          <td>{item.price}</td>
          <td>{item.serial_Number}</td>
          <td>{item.assigned_to}</td>
          <td>{item.location}</td>
          <td>{item[selectedDateType]}</td>
        </tr>
      ))}
    </tbody>
    <tfoot>
      <tr>
        <td colSpan="8" style={{ fontWeight: "bold", textAlign: "center" }}>
          {`Total ${title}s: ${itemCount}`}
        </td>
      </tr>
    </tfoot>
  </table>
</div>

  );
};

export default EquipmentTable;
