import React from 'react';

const Section34 = ({ formData, handleInputChange }) => (
  <>
    {/* Section 34 */}
    <div className="form-section flyer-section">
      <div className="flyer-header"> 5. Medicaid/CHIP applications or referrals:</div>
      <label className="required-asterisk">A. Medicaid/CHIP applications or referrals</label>
      <input
        type="number"
        name="medicaid_chip_app_ref"
        value={formData.medicaid_chip_app_ref}
        onChange={handleInputChange}
        placeholder="Your answer"
        min="0"
        required
      />
    </div>
  </>
);

export default Section34;
