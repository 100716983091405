import React from 'react';

const DeletedClients = () => {
  return (
    <div>
      <h1>DeletedClients Page</h1>
    </div>
  );
};

export default DeletedClients;
