import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import moment from 'moment';
import { fetchAuthSession } from 'aws-amplify/auth'; // Use the new fetchAuthSession method
import { listAppointments, listUsersByTeamOne, listClientFix2s } from '../../graphql/queries';
import { createAppointment, updateAppointment, deleteAppointment } from '../../graphql/mutations';
import { handleAddAppointment, handleUpdateAppointment, handleDeleteAppointment, handleCancelAppointment, generateDurationOptions, formatDuration } from './AppointmentHelperFuncs'; // Import the refactored function';
import ClientForm from '../../components/ClientForm'; // Adjust the import path
import './Appointments.css';


const Appointments = () => {
    const client = useApolloClient(); // Get Apollo Client
    const [appointments, setAppointments] = useState([]);
    const [nextToken, setNextToken] = useState(null);
    const [showAppointmentAddOverlay, setShowAppointmentAddOverlay] = useState(false);
    const [showClientFormOverlay, setShowClientFormOverlay] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [selectedAppointment, setSelectedAppointment] = useState(null);
    const [newAppointment, setNewAppointment] = useState({
        id: '',
        title: '',
        customer: '',
        location: '',
        date: '',
        recurrence: '',
        service: '',
        navigator: '',
        time: '',      // Added time
        duration: '',  // Added duration
        notes: '',

    });

    const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'ascending' });
    const [searchQuery, setSearchQuery] = useState('');
    const [searchColumn, setSearchColumn] = useState('title');
    // Search Client Variables
    const [clientSearchTerm, setClientSearchTerm] = useState('');
    const [matchingClients, setMatchingClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [clientIsEditing, setClientIsEditing] = useState(false);
    const [allClients, setAllClients] = useState([]); // New state for all clients
    const [isFetchingClients, setIsFetchingClients] = useState(false);

    // State to track user's group
    const [userGroup, setUserGroup] = useState(null);

    useEffect(() => {
        // Fetch the user's group when the component mounts
        const fetchUserGroup = async () => {
            try {
                const session = await fetchAuthSession();
                const groups = session.tokens.accessToken.payload["cognito:groups"]; // Get the user's groups
                if (groups) {
                    setUserGroup(groups); // Assuming a user belongs to one group. Adjust as needed.
                }
            } catch (error) {
                console.error('Error fetching user group:', error);
            }
        };

        fetchUserGroup();
    }, []);


    // Fetch clients as the user types
    const fetchClientsOnSearch = async (searchTerm) => {
        setIsFetchingClients(true); // Start loading
        try {
            const { data } = await client.query({
                query: listClientFix2s,
                variables: {
                    filter: {
                        or: [
                            { name: { contains: searchTerm } },
                            { last_name: { contains: searchTerm } }
                        ]
                    },
                    limit: 400 // Adjust limit as necessary, e.g., 100
                },
            });

            const clients = data.listClientFix2s.items;
            setMatchingClients(clients);
        } catch (error) {
            console.error('Error fetching clients:', error);
        } finally {
            setIsFetchingClients(false); // Stop loading
        }
    };

    const handleClientSearchChange = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        setClientSearchTerm(searchTerm);

        if (searchTerm === '') {
            setMatchingClients([]);
        } else {
            fetchClientsOnSearch(searchTerm);
        }
    };

    const handleAddAppointmentClick = async () => {
        setShowAppointmentAddOverlay(true);
        setIsEditing(false);
        setNewAppointment({
            id: '',
            title: '',
            customer: '',
            location: '',
            date: '',
            recurrence: '',
            service: '',
            navigator: '',
            time: '',      // Added time
            duration: '',  // Added duration
            notes: ''
        });
        setClientSearchTerm(""); // Set the selected name in the search input field
    };

    // Handle client selection
    const handleClientSelect = (client) => {
        const customerName = `${client.name} ${client.last_name}`;
        setNewAppointment({
            ...newAppointment,
            customer: customerName,
            clientEmail: client.email // Set clientEmail directly here
        });
        setClientSearchTerm(customerName); // Set the selected name in the search input field
        setMatchingClients([]); // Hide the dropdown after selection
    };

    const handleClientAdded = () => {
        // Perform any actions needed after a client is added
    };

    const { loading: usersLoading, error: usersError, data: usersData } = useQuery(listUsersByTeamOne, {
        variables: {
            filter: {
                navigator_id: { ne: "" } // 'ne' stands for 'not equal', so this filters out null values
            },
            limit: 100, // Or any desired limit
        }
    });
    const { loading, data, fetchMore } = useQuery(listAppointments, {
        variables: { limit: 100 },
    });

    const navigatorOptions = usersData?.listUsers?.items?.map(user => ({
        id: user.id,
        name: `${user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1).toLowerCase()} ${user.last_name.charAt(0).toUpperCase() + user.last_name.slice(1).toLowerCase()}`
    })) || [];

    const [createAppt] = useMutation(createAppointment, {
        refetchQueries: [{ query: listAppointments, variables: { limit: 100 } }]
    });

    const [updateAppt] = useMutation(updateAppointment, {
        refetchQueries: [{ query: listAppointments, variables: { limit: 100 } }]
    });

    const [deleteAppt] = useMutation(deleteAppointment, {
        refetchQueries: [{ query: listAppointments, variables: { limit: 100 } }]
    });

    useEffect(() => {
        if (data) {
            const formattedAppointments = data.listAppointments.items.map(appt => ({
                ...appt,
                date: new Date(appt.date),
                status: appt.status // Make sure the status field exists
            }));
            setAppointments(formattedAppointments);
            setNextToken(data.listAppointments.nextToken);
        }
    }, [data]);

    const handleAddNewClient = () => {
        setClientIsEditing(false);
        setSelectedClient(null);
        setShowClientFormOverlay(true);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewAppointment({
            ...newAppointment,
            [name]: value
        });
    };

    const handleSelectAppointment = (appt) => {
        setNewAppointment({
            id: appt.id,
            title: appt.title,
            customer: appt.customer,
            location: appt.location,
            date: appt.date,
            recurrence: appt.recurrence,
            service: appt.service,
            time: appt.time,
            duration: appt.duration,
            navigator: appt.navigator,
            notes: appt.notes,
            status: appt.status,
            clientEmail: appt.clientEmail,
        });
        setShowAppointmentAddOverlay(true);
        setIsEditing(true);
        setSelectedAppointment(appt);
    };

    const onAddAppointment = async () => {
        await handleAddAppointment(newAppointment, setShowAppointmentAddOverlay, createAppt);
    };

    const onUpdateAppointment = async () => {
        await handleUpdateAppointment(
            newAppointment,
            setShowAppointmentAddOverlay,
            setNewAppointment,
            setIsEditing,
            setSelectedAppointment,
            updateAppt,
            newAppointment.clientEmail
        );
    };

    const onDeleteAppointment = async () => {
        if (window.confirm('Are you sure you want to delete this appointment?')) {
            await handleDeleteAppointment(
                selectedAppointment,
                setShowAppointmentAddOverlay,
                setIsEditing,
                setSelectedAppointment,
                deleteAppt
            );
        }
    };

    const onCancelAppointment = async () => {
        if (window.confirm('Are you sure you want to cancel this appointment?')) {
            // console.log("printing this ", selectedAppointment)
            await handleCancelAppointment(
                selectedAppointment,
                setShowAppointmentAddOverlay,
                setIsEditing,
                setSelectedAppointment,
                updateAppt,
                selectedAppointment.clientEmail
            );
        }
    };

    const isAddAppointmentDisabled = newAppointment.date === '';

    const sortedAppointments = React.useMemo(() => {
        let sortableAppointments = [...appointments];
        if (sortConfig !== null) {
            sortableAppointments.sort((a, b) => {
                let aValue = a[sortConfig.key];
                let bValue = b[sortConfig.key];

                // Handle dates
                if (sortConfig.key === 'date' || sortConfig.key === 'updatedAt') {
                    aValue = new Date(aValue);
                    bValue = new Date(bValue);
                }

                // Handle times
                if (sortConfig.key === 'time') {
                    // Assuming time is stored as 'HH:mm' string
                    aValue = moment(aValue, 'HH:mm').toDate();
                    bValue = moment(bValue, 'HH:mm').toDate();
                }

                // Handle durations
                if (sortConfig.key === 'duration') {
                    aValue = parseInt(aValue, 10);
                    bValue = parseInt(bValue, 10);
                }

                // Convert to lowercase if values are strings
                if (typeof aValue === 'string') {
                    aValue = aValue.toLowerCase();
                    bValue = bValue.toLowerCase();
                }

                if (aValue < bValue) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableAppointments;
    }, [appointments, sortConfig]);


    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const renderHeader = (label, key) => (
        <th onClick={() => requestSort(key)}>
            {label}
            {sortConfig.key === key ? (
                <span className="sort-arrow">
                    {sortConfig.direction === 'ascending' ? '↑' : '↓'}
                </span>
            ) : null}
        </th>
    );

    const filteredAppointments = sortedAppointments.filter((appt) => {
        return appt[searchColumn]?.toLowerCase().includes(searchQuery.toLowerCase());
    });

    if (usersLoading) return <p>Loading navigators...</p>;
    if (usersError) return <p>Error loading navigators: {usersError.message}</p>;

    return (
        <div className="appointments-page">
            <header className="appointments-header">
                <h1>Appointments</h1>
            </header>
            <div className="appointments-controls">
                <button className="add-appointment-btn" onClick={handleAddAppointmentClick}>Add Appointment</button>
                <input
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <select
                    value={searchColumn}
                    onChange={(e) => setSearchColumn(e.target.value)}
                >
                    <option value="title">Title</option>
                    <option value="customer">Customer</option>
                    <option value="location">Location</option>
                    <option value="navigator">Navigator</option>
                    <option value="ModifiedBy">Modified By</option>
                </select>
            </div>
            <div className="table-container">
                <table className="appointments-table">
                    <thead>
                        <tr>
                            {renderHeader('Appointment ID', 'id')}
                            {renderHeader('Title', 'title')}
                            {renderHeader('Date', 'date')}
                            {renderHeader('Time', 'time')}
                            {renderHeader('Duration', 'duration')}
                            {renderHeader('Customer', 'customer')}
                            {renderHeader('Location', 'location')}
                            {renderHeader('Navigator', 'navigator')}
                            {renderHeader('Modified By', 'ModifiedBy')}
                            {renderHeader('Updated At', 'updatedAt')}
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredAppointments.map((appt) => {
                            // Determine the class based on the status of the appointment
                            const rowClass = appt.status === 'canceled'
                                ? 'status-canceled'
                                : appt.status === 'confirmed'
                                    ? 'status-confirmed'
                                    : 'status-created'; // Default to created if not canceled or confirmed

                            return (
                                <tr key={appt.id} className={rowClass}>
                                    <td>{appt.id}</td>
                                    <td>{appt.title}</td>
                                    <td>{moment(appt.date).format('MM-DD-YYYY')}</td>
                                    <td>{moment(appt.time, 'HH:mm').format('hh:mm A')}</td>
                                    <td>{formatDuration(appt.duration)}</td>
                                    <td>{appt.customer}</td>
                                    <td>{appt.location}</td>
                                    <td>{appt.navigator}</td>
                                    <td>{appt.ModifiedBy}</td>
                                    <td>{moment(appt.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                                    <td>
                                        <button className="edit-btn" onClick={() => handleSelectAppointment(appt)}>Update / Cancel</button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>

                </table>
            </div>

            {showAppointmentAddOverlay && (
                <div className="event-add-overlay">
                    <div className="overlay-content-appointments">
                        <button className="close-btn_appointments" onClick={() => setShowAppointmentAddOverlay(false)}>X</button>
                        <h2>{isEditing ? 'Edit Appointment' : 'Add New Appointment'}</h2>
                        <input required name="id" placeholder="ID" value={newAppointment.id} onChange={handleInputChange} disabled />
                        <div className="appointment-form-grid">
                            {/* Customer on its own row */}
                            <div className="form-group full-width">
                                <label htmlFor="customer">Customer</label>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        id="customer"
                                        type="text"
                                        placeholder="Search customer..."
                                        value={clientSearchTerm}
                                        onChange={handleClientSearchChange}
                                        style={{ flex: 1 }} // Adjust the input to use available space
                                    />
                                    <button
                                        className="add-client-btn"
                                        onClick={() => handleAddNewClient()}
                                        title="Add New Client"
                                    >
                                        +
                                    </button>
                                </div>
                                {/* Display matching clients */}
                                {matchingClients.length > 0 && (
                                    <ul className="client-suggestions">
                                        {matchingClients.map((client) => (
                                            <li key={client.id} onClick={() => handleClientSelect(client)}>
                                                {client.name} {client.last_name}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                {isFetchingClients && <p>Loading clients...</p>}
                            </div>

                            {/* Location on its own row */}
                            <div className="form-group full-width">
                                <label htmlFor="location">Location</label>
                                <select
                                    id="location"
                                    value={newAppointment.location}
                                    onChange={handleInputChange}
                                    name='location'
                                >
                                    <option value="" disabled>Select a location</option>  {/* Disabled option */}
                                    <option value={"The Health Collaborative @ MAUC (78207)"}>The Health Collaborative @ MAUC (78207)</option>
                                </select>
                            </div>

                            {/* Date and Recurring in two columns */}
                            <div className="form-group">
                                <label htmlFor="date">Date</label>
                                <input
                                    required type="date"
                                    name="date"
                                    placeholder="Date Date"
                                    value={moment(newAppointment.date).format('YYYY-MM-DD')}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="recurrence">Recurring</label>
                                <select
                                    name="recurrence"
                                    value={newAppointment.recurrence}
                                    onChange={handleInputChange}
                                >
                                    <option value="" disabled>Select recurrence</option>  {/* Disabled option */}
                                    <option value={"Not Recurring"}>Not Recurring</option>
                                    <option value={"Daily"}>Daily</option>
                                    <option value={"Weekly"}>Weekly</option>
                                    <option value={"Monthly"}>Monthly</option>
                                </select>
                            </div>

                            {/* Service and Navigator in two columns */}
                            <div className="form-group">
                                <label htmlFor="service">Service</label>
                                <select
                                    name="service"
                                    value={newAppointment.service}
                                    onChange={handleInputChange}
                                >
                                    <option value="" disabled>Select a service</option>  {/* Disabled option */}
                                    <option value="Health Insurance / Seguro Medico">Health Insurance / Seguro Medico</option>
                                    <option value="Medicaid / Chip">Medicaid / Chip</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="navigator">Navigator</label>
                                <select required name="navigator" value={newAppointment.navigator} onChange={handleInputChange}>
                                    <option value="" disabled>Select a navigator</option>
                                    {navigatorOptions.map(option => (
                                        <option key={option.id} value={option.name}>{option.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group-row">
                                <div className="form-group">
                                    <label htmlFor="time">Time</label>
                                    <input
                                        type="time"
                                        name="time"
                                        value={newAppointment.time}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="duration">Duration</label>
                                    <select
                                        name="duration"
                                        value={newAppointment.duration}
                                        onChange={handleInputChange}
                                        required
                                    >
                                        <option value="" disabled>Select a duration</option>  {/* Disabled option */}
                                        {generateDurationOptions()}
                                    </select>
                                </div>
                            </div>
                            {/* Booking Notes on its own row */}
                            <div className="form-group full-width notes">
                                <label htmlFor="Booking Notes">Booking Notes</label>
                                <textarea name="notes" value={newAppointment.notes} onChange={handleInputChange} className="notes-input" />
                            </div>
                        </div>
                        <div className="button-group">
                            <button
                                className="add-btn"
                                onClick={isEditing ? onUpdateAppointment : onAddAppointment}
                                disabled={isAddAppointmentDisabled}>
                                {isEditing ? "Update Appointment" : "Add Appointment"}
                            </button>
                            {isEditing && (
                                <>
                                  <button className="delete-btn" onClick={onCancelAppointment} disabled={!selectedAppointment}>
                                    Cancel Appointment
                                  </button>
                              
                                  {/* Show Delete button only for users in the dev group */}
                                  {userGroup?.includes('dev') && (
                                    <button className="delete-btn" onClick={onDeleteAppointment} disabled={!selectedAppointment}>
                                      Delete Appointment
                                    </button>
                                  )}
                                </>
                              )}
                        </div>
                    </div>
                </div>
            )}
            {showClientFormOverlay && (
                <div className="client-form-overlay">
                    <div className="overlay-content-client">
                        <ClientForm
                            isEditing={clientIsEditing}
                            selectedClient={selectedClient}
                            onClientAdded={handleClientAdded}
                            onClose={() => setShowClientFormOverlay(false)}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Appointments;
