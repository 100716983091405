import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { listTabletRemarkables, listUsers } from '../../../graphql/queries';
import { createTabletRemarkable, deleteTabletRemarkable, updateTabletRemarkable } from '../../../graphql/mutations';
import './Tablet_Remarkable.css';
import XLSX from 'xlsx-js-style';
import { v4 as uuidv4 } from 'uuid';
import Inventory_header from '../../../components/layout/Inventory_header';
import { FaFileExcel } from 'react-icons/fa';

const Tablet_Remarkable = () => {
    const [tabletRemarkable, setTabletRemarkable] = useState([]);
    const [nextToken, setNextToken] = useState(null);
    const [showTabletRemarkableAddOverlay, setShowTabletRemarkableAddOverlay] = useState(false);
    const [selectedTabletRemarkable, setSelectedTabletRemarkable] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    const [newTabletRemarkable, setNewTabletRemarkable] = useState({
        id: '',
        tagid: '',
        model: '',
        brand: '',
        serial_Number: '',
        price: '',
        store: '',
        charger_cable: '',
        password: '',
        os: '',
        ram: '',
        storage: '',
        apple_id: '',
        apple_password: '',
        phone_associate: '',
        purchase_date: '',
        assignment_date: '',
        warranty_date: '',
        returnable_date: '',
        available: '',
        status: '',
        assigned_to: '',
        location: '',
        team: '',

    });
    const [sortConfig, setSortConfig] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchField, setSearchField] = useState('id');
    const [duplicateError, setDuplicateError] = useState('');
    const [priceError, setPriceError] = useState('');
    const [statusError, setStatusError] = useState('');
    const [ramError, setRAMError] = useState('');
    const [storageError, setStorageError] = useState('');
    const [AppleIDError, setAppleIDError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [showPasswords, setShowPasswords] = useState(false);

    const { loading1, error: userError, data: userData } = useQuery(listUsers);



    const handleSaveAsExcel = async () => {
        try {

            const tableToExport = filteredTabletRemarkables.length > 0 ? filteredTabletRemarkables : tabletRemarkable;
            if (tableToExport.length > 0) {
                // Sort the TabletRemarkables by Timestamp in ascending order (earliest first)
                const sortedTabletRemarkable = [...tableToExport].sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp));

                // Prepare the data for Excel export with custom column titles
                const worksheetData = sortedTabletRemarkable.map((tabletRemarkable, index) => ({
                    '#': index + 1, // Agregar el número de fila
                    'TAG:': tabletRemarkable.tagid,
                    'Assigned to:': tabletRemarkable.assigned_to,
                    'Available:': tabletRemarkable.available ? 'Yes' : 'No',
                    'Serial Number:': tabletRemarkable.serial_Number,
                    'Operating System:': tabletRemarkable.os,
                    'RAM:': tabletRemarkable.ram,
                    'Storage:': tabletRemarkable.storage,
                    'Apple ID:': tabletRemarkable.apple_id,
                    'Phone Associate:': tabletRemarkable.phone_associate,
                    'Status:': tabletRemarkable.status,
                    'Team:': tabletRemarkable.team,


                    // Add other fields as needed

                }));

                // Convert JSON to sheet starting from row 2
                const worksheet = XLSX.utils.json_to_sheet([]);
                let endCol = 0;

                XLSX.utils.sheet_add_aoa(worksheet, [['Tablets']], { origin: 'A1' });

                if (worksheetData.length > 0) {
                    // Agrega los datos a partir de la fila A2
                    XLSX.utils.sheet_add_json(worksheet, worksheetData, { origin: 'A2', skipHeader: false });

                    // Define el rango de referencia (!ref)
                    const endRow = worksheetData.length + 1; // +1 para el título en A1
                    const endCol = Object.keys(worksheetData[0]).length - 1;
                    worksheet['!ref'] = XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: endRow, c: endCol } });
                } else {
                    console.error("No hay datos para exportar.");
                    return;
                }

                // Merge title cells across the columns
                worksheet['!merges'] = worksheet['!merges'] || [];
                worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 11 } }); // A1 to H1 for the added column

                // Style the title row
                worksheet['A1'].s = {
                    font: {
                        bold: true,
                        sz: 20, // Font size
                        color: { rgb: '1F4E79' } // Dark blue text color
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center'
                    }
                };

                // Apply styling for the header row (now starting at row 2)
                const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
                for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                    const cellAddress = XLSX.utils.encode_cell({ r: 1, c: col }); // Header row is now row 1
                    if (!worksheet[cellAddress]) continue;
                    worksheet[cellAddress].s = {
                        fill: {
                            fgColor: { rgb: '1F4E79' } // Dark blue background
                        },
                        font: {
                            bold: true,
                            color: { rgb: 'FFFFFF' } // White text
                        },
                        alignment: {
                            horizontal: 'center'
                        },
                        border: {
                            top: { style: 'thin', color: { rgb: '000000' } },
                            left: { style: 'thin', color: { rgb: '000000' } },
                            bottom: { style: 'thin', color: { rgb: '000000' } },
                            right: { style: 'thin', color: { rgb: '000000' } },
                        }
                    };
                }

                // Apply alternating row colors and borders starting from row 3
                for (let row = 2; row <= headerRange.e.r; row++) {
                    const isEvenRow = row % 2 === 0;
                    for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
                        if (!worksheet[cellAddress]) continue;

                        worksheet[cellAddress].s = {
                            fill: {
                                fgColor: { rgb: isEvenRow ? 'D3D3D3' : 'FFFFFF' } // Light gray for even rows, white for odd rows
                            },
                            border: {
                                top: { style: 'thin', color: { rgb: '000000' } },
                                left: { style: 'thin', color: { rgb: '000000' } },
                                bottom: { style: 'thin', color: { rgb: '000000' } },
                                right: { style: 'thin', color: { rgb: '000000' } },
                            },
                            alignment: {
                                horizontal: 'center'
                            }
                        };
                    }
                }
                // Adjust columns to fit content
                worksheet['!cols'] = [
                    { wch: 5 },
                    { wch: 10 },
                    { wch: 20 },
                    { wch: 10 },
                    { wch: 20 },
                    { wch: 10 },
                    { wch: 5 },
                    { wch: 5 },
                    { wch: 15 },
                    { wch: 15 },
                    { wch: 5 },

                ];

                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'TabletRemarkables');

                // Write file
                XLSX.writeFile(workbook, 'TabletRemarkablesData.xlsx');
            } else {
                console.error('No data to export');
            }
        } catch (error) {
            console.error('Error exporting data to Excel:', error);
        }
    };

    const handleEditTabletRemarkable = (scanner) => {
        setSelectedTabletRemarkable(scanner);
        setIsEditing(true);
        setShowTabletRemarkableAddOverlay(true);
    };

    const { loading, data, fetchMore } = useQuery(listTabletRemarkables, {
        variables: { limit: 100 },
    });

    const [addTabletRemarkable] = useMutation(createTabletRemarkable, {
        refetchQueries: [{ query: listTabletRemarkables, variables: { limit: 100 } }]
    });

    const [removeTabletRemarkable] = useMutation(deleteTabletRemarkable, {
        refetchQueries: [{ query: listTabletRemarkables, variables: { limit: 100 } }]
    });
    const [updTabletRemarkable] = useMutation(updateTabletRemarkable, {
        refetchQueries: [{ query: listTabletRemarkables, variables: { limit: 100 } }]
    });

    const [fetchAllTabletRemarkables, { loading: countLoading, data: AllTabletRemarkablesData }] = useLazyQuery(listTabletRemarkables, {
        variables: { limit: 100 },
        fetchPolicy: "network-only",
    });


    useEffect(() => {
        const fetchAllTabletRemarkables = async () => {
            let AllTabletRemarkables = [];
            let currentNextToken = null;

            do {
                const { data } = await fetchMore({
                    variables: { limit: 100, nextToken: currentNextToken },
                });

                AllTabletRemarkables = [...AllTabletRemarkables, ...data.listTabletRemarkables.items];
                currentNextToken = data.listTabletRemarkables.nextToken;
            } while (currentNextToken);

            setTabletRemarkable(AllTabletRemarkables);
            setNextToken(currentNextToken);
        };

        if (data) {
            fetchAllTabletRemarkables();
        }
    }, [data, fetchMore]);

    const clearNewTabletRemarkable = () => {
        setNewTabletRemarkable({
            id: '',
            tagid: '',
            model: '',
            brand: '',
            serial_Number: '',
            price: '',
            store: '',
            charger_cable: '',
            password: '',
            os: '',
            ram: '',
            storage: '',
            apple_id: '',
            apple_password: '',
            phone_associate: '',
            purchase_date: '',
            assignment_date: '',
            warranty_date: '',
            returnable_date: '',
            available: '',
            status: '',
            assigned_to: '',
            location: '',
            team: '',
        });
    };

    // Define loadMore function
    const loadMore = () => {
        fetchMore({
            variables: { limit: 100, nextToken },
            updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;
                setNextToken(fetchMoreResult.listTabletRemarkables.nextToken);
                return {
                    listTabletRemarkables: {
                        ...fetchMoreResult.listTabletRemarkables,
                        items: [...prevResult.listTabletRemarkables.items, ...fetchMoreResult.listTabletRemarkables.items]
                    }
                };
            }
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let formattedValue = value;

        // Convert to lowercase and trim whitespace for specific fields
        if (['serial_Number'].includes(name)) {
            formattedValue = value.toLowerCase().trim();
        }

        if (isEditing) {
            setSelectedTabletRemarkable({
                ...selectedTabletRemarkable,
                [name]: formattedValue
            });
        } else {
            setNewTabletRemarkable({
                ...newTabletRemarkable,
                id: uuidv4(),
                [name]: formattedValue
            });
        }
    };
    // Función para alternar la visibilidad de las contraseñas
    const togglePasswords = () => {
        setShowPasswords(!showPasswords);
    };
    const handleAddTabletRemarkable = () => {

        const formattedPurchase = newTabletRemarkable.purchase_date
            ? new Date(newTabletRemarkable.purchase_date).toISOString().split('T')[0]
            : null;
        const formattedAssignment = newTabletRemarkable.assignment_date
            ? new Date(newTabletRemarkable.assignment_date).toISOString().split('T')[0]
            : null;

        const formattedWarranty = newTabletRemarkable.warranty_date
            ? new Date(newTabletRemarkable.warranty_date).toISOString().split('T')[0]
            : null;

        const formattedReturnable = newTabletRemarkable.returnable_date
            ? new Date(newTabletRemarkable.returnable_date).toISOString().split('T')[0]
            : null;

        // Validate that the price contains only digits and is a valid number
        let priceValue = null;

        if (newTabletRemarkable.price !== "" && newTabletRemarkable.price !== null && newTabletRemarkable.price != undefined) {
            priceValue = parseFloat(newTabletRemarkable.price);

            if (isNaN(priceValue)) {
                setPriceError('Price must be a valid number.');
                return;
            }
        }
        setPriceError('');

        // Check for duplicates based on `tagid` and `serial_Number`
        const isDuplicate = tabletRemarkable.some(tabletRemarkable =>
            tabletRemarkable.tagid === newTabletRemarkable.tagid &&
            tabletRemarkable.serial_Number === newTabletRemarkable.serial_Number
        );

        if (isDuplicate) {
            setDuplicateError('A Tablet or Remarkable with the same TAG and Serial Number already exists.');
            console.log('Duplicate validation failed');
            return;
        } else {
            setDuplicateError('');
        }


        // Validate Phone Number
        const phoneInput = newTabletRemarkable.phone_associate.trim();
        console.log('Original Phone:', phoneInput);

        const containsOnlyNumbers = /^[0-9]*$/.test(phoneInput);

        if (!containsOnlyNumbers) {
            setPhoneError('Phone number must contain only numbers.');
            console.log('Phone validation failed: contains invalid characters');
            return;
        }

        // Limpiar el número eliminando caracteres no numéricos
        const cleanCellNumber = phoneInput.replace(/\D/g, '');
        console.log('Clean Phone:', cleanCellNumber);

        // Validar si el número tiene exactamente 10 dígitos o está vacío
        if (cleanCellNumber !== '' && cleanCellNumber.length !== 10) {
            setPhoneError('Phone number must be exactly 10 digits or empty.');
            return;
        } else {
            setPhoneError(''); // Limpia el error si el número es válido o está vacío
        }

        // Convertir a número entero si no está vacío, de lo contrario null
        const phoneAssociateInt = cleanCellNumber !== '' ? parseInt(cleanCellNumber, 10) : null;

        // Validación para GraphQL: `phoneAssociateInt` debe ser un `Int` o `null`
        if (phoneAssociateInt === null || Number.isInteger(phoneAssociateInt)) {
            console.log('Phone as integer:', phoneAssociateInt);

            // Continuar con la lógica de la mutación de GraphQL aquí, enviando `phoneAssociateInt`
        } else {
            setPhoneError('Phone number must be a valid integer.');
            console.log('Phone validation failed.');
        }

        //Validate RAM and Storage must be INT
        const ramValue = newTabletRemarkable.ram === '' || newTabletRemarkable.ram === null ? 0 : parseInt(newTabletRemarkable.ram, 10);
        const storageValue = newTabletRemarkable.storage === '' || newTabletRemarkable.storage === null ? 0 : parseInt(newTabletRemarkable.storage, 10);

        const isRAMInteger = Number.isInteger(ramValue);
        const isStorageInteger = Number.isInteger(storageValue);

        if (!isRAMInteger || !isStorageInteger) {
            if (!isRAMInteger) {
                setRAMError('The RAM must be an integer.');
                console.log('RAM validation failed');
            } else {
                setRAMError(''); // Limpia el error si la RAM es válida
            }

            if (!isStorageInteger) {
                setStorageError('The Storage must be an integer.');
                console.log('Storage validation failed');
            } else {
                setStorageError(''); // Limpia el error si el Storage es válido
            }

            return; // Detiene el proceso si cualquiera de las validaciones falla
        } else {
            // Si ambos son válidos, limpia los errores
            setRAMError('');
            setStorageError('');
        }

        // Check availability first
        if (newTabletRemarkable.available === 'true') {

            // If available, status must be Inventario
            if (newTabletRemarkable.status !== 'inv') {
                setStatusError('If the Tablet/Remarkable is available, its status must be "Inventario".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // If available and status is Inventario, assigned_to must be empty or "N/A"
            if (newTabletRemarkable.assigned_to !== '' && newTabletRemarkable.assigned_to !== 'n/a') {
                setStatusError('If the Tablet/Remarkable is available and its status is "Inventario", the "Assigned to" field must be empty or "N/A".');
                console.log('Assigned_to validation failed');
                return; // Exit function if validation fails
            }


        } else if (newTabletRemarkable.available !== "") {
            // If not available, status must be one of the allowed statuses
            const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
            if (!allowedStatuses.includes(newTabletRemarkable.status)) {
                setStatusError('If the Tablet/Remarkable is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // Clear error if validation passes
            setStatusError('');
        }

        //Check email
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        const isValidEmail = (email) => emailPattern.test(email);

        const validateAppleId = (apple_id) => {
            if (apple_id.trim() === '' || isValidEmail(apple_id)) {
                setAppleIDError(''); // No hay error
                console.log('Apple ID is valid or empty:', apple_id);
                return true;
            } else {
                setAppleIDError('Apple ID is not a valid email address.'); // Muestra error si no es un email válido
                console.log('Apple ID validation failed:', apple_id);
                return false;
            }
        };
        // Obtener el valor de apple_id y validar
        const { apple_id } = newTabletRemarkable; // Suponiendo que newTabletRemarkable tiene la propiedad apple_id
        console.log('Apple ID:', apple_id); // Verificar el valor de apple_id

        // Validar el valor de apple_id
        const isAppleIdValid = validateAppleId(apple_id);

        if (!isAppleIdValid) {
            console.log('Invalid Apple ID, please correct it.');
            return; // Detén el proceso si el Apple ID no es válido
        }

        // If all validations pass, proceed with adding the tabletRemarkable to the database
        addTabletRemarkable({
            variables: {
                input: {
                    ...newTabletRemarkable,
                    phone_associate: phoneAssociateInt, ram: ramValue, storage: storageValue, price: priceValue, purchase_date: formattedPurchase, warranty_date: formattedWarranty, returnable_date: formattedReturnable, assignment_date: formattedAssignment
                }
            }
        })
            .then(() => {
                window.location.reload();
            }).catch(error => {
                console.error("Error adding tabletRemarkable:", error);
            });
    };

    const handleDeleteTabletRemarkable = () => {

        removeTabletRemarkable({
            variables: { input: { id: selectedTabletRemarkable.id } }
        }).then(() => {
            window.location.reload();
        }).catch(error => {
            console.error("Error deleting TabletRemarkable", error);
        });

    };

    const handleUpdateTabletRemarkable = async () => {

        const formattedData_purchase = selectedTabletRemarkable.purchase_date ? new Date(selectedTabletRemarkable.purchase_date).toISOString().split('T')[0] : null;
        const formattedData_warranty = selectedTabletRemarkable.warranty_date ? new Date(selectedTabletRemarkable.warranty_date).toISOString().split('T')[0] : null;
        const formattedData_returnable = selectedTabletRemarkable.returnable_date ? new Date(selectedTabletRemarkable.returnable_date).toISOString().split('T')[0] : null;
        const formattedData_assignment = selectedTabletRemarkable.assignment_date ? new Date(selectedTabletRemarkable.assignment_date).toISOString().split('T')[0] : null;

        //Check Price
        let priceValue = null;
        if (selectedTabletRemarkable.price !== "" && selectedTabletRemarkable.price !== null && selectedTabletRemarkable.price != undefined) {
            priceValue = parseFloat(selectedTabletRemarkable.price);

            if (isNaN(priceValue)) {
                setPriceError('Price must be a valid number.');
                return;
            }
        }
        setPriceError('');

        // Check availability first
        if (selectedTabletRemarkable.available === 'true') {

            // If available, status must be Inventario
            if (selectedTabletRemarkable.status !== 'inv') {
                setStatusError('If the Tablet/Remarkable is available, its status must be "Inventario".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // If available and status is Inventario, assigned_to must be empty or "N/A"
            if (selectedTabletRemarkable.assigned_to !== '' && selectedTabletRemarkable.assigned_to !== 'n/a') {
                setStatusError('If the Tablet/Remarkable is available and its status is "Inventario", the "Assigned to" field must be empty or "N/A".');
                console.log('Assigned_to validation failed');
                return; // Exit function if validation fails
            }


        } else if (selectedTabletRemarkable.available === 'false') {  // Ensure we're checking for availability correctly
            // If not available, status must be one of the allowed statuses
            const allowedStatuses = ['broken', 'use', 'transfer', 'lost'];
            if (!allowedStatuses.includes(selectedTabletRemarkable.status)) {
                setStatusError('If the Tablet/Remarkable is not available, its status must be one of: "broken", "use", "transfer", or "lost".');
                console.log('Status validation failed');
                return; // Exit function if validation fails
            }
            // Clear error if validation passes
            setStatusError('');
        }

        //Validate RAM and Storage must be INT
        const ramValue = selectedTabletRemarkable.ram === '' || selectedTabletRemarkable.ram === null ? 0 : parseInt(selectedTabletRemarkable.ram, 10);
        const storageValue = selectedTabletRemarkable.storage === '' || selectedTabletRemarkable.storage === null ? 0 : parseInt(selectedTabletRemarkable.storage, 10);

        const isRAMInteger = Number.isInteger(ramValue);
        const isStorageInteger = Number.isInteger(storageValue);

        if (!isRAMInteger || !isStorageInteger) {
            if (!isRAMInteger) {
                setRAMError('The RAM must be an integer.');
                console.log('RAM validation failed');
            } else {
                setRAMError(''); // Limpia el error si la RAM es válida
            }

            if (!isStorageInteger) {
                setStorageError('The Storage must be an integer.');
                console.log('Storage validation failed');
            } else {
                setStorageError(''); // Limpia el error si el Storage es válido
            }

            return; // Detiene el proceso si cualquiera de las validaciones falla
        } else {
            // Si ambos son válidos, limpia los errores
            setRAMError('');
            setStorageError('');
        }

        // Validate Phone Number
        let cleanCellNumber = null;

        if (selectedTabletRemarkable && selectedTabletRemarkable.phone_associate !== null && selectedTabletRemarkable.phone_associate !== '') {
            // Convert phone_associate to a string if it's a valid number, otherwise skip
            const phoneAssociateStr = String(selectedTabletRemarkable.phone_associate);

            // Remove any non-numeric characters (though integers won't have them, this is future-proofing)
            cleanCellNumber = phoneAssociateStr.replace(/\D/g, '');

            // Check if the number has exactly 10 digits
            if (cleanCellNumber.length !== 10) {
                setPhoneError('Phone number must be exactly 10 digits.');
                return;
            }

            // Check if all digits are the same
            if (/^(\d)\1{9}$/.test(cleanCellNumber)) {
                setPhoneError('Phone number cannot contain the same digit repeated 10 times.');
                return;
            }

            // No need to convert back to an integer unless required elsewhere in the code

        } else {
            cleanCellNumber = null; // Assign null if phone_associate is null
        }


        //Check email
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        const isValidEmail = (email) => emailPattern.test(email);


        const validateAppleId = (apple_id) => {
            // Verificar si apple_id es null o undefined
            if (apple_id === null || apple_id === undefined) {
                setAppleIDError(''); // No hay error si apple_id es null o undefined
                console.log('Apple ID is null or undefined, treating as valid.');
                return true;
            }

            if (apple_id.trim() === '') {
                setAppleIDError('');
                console.log('Apple ID is empty, treating as valid.');
                return true;
            }

            if (isValidEmail(apple_id)) {
                setAppleIDError(''); // No hay error si es un correo válido
                console.log('Apple ID is a valid email:', apple_id);
                return true;
            } else {
                setAppleIDError('Apple ID is not a valid email address.'); // Muestra error si no es un correo válido
                console.log('Apple ID validation failed:', apple_id);
                return false;
            }
        };

        // Obtener el valor de apple_id y validar
        const { apple_id } = selectedTabletRemarkable || {};
        console.log('Apple ID:', apple_id);

        // Validar el valor de apple_id
        const isAppleIdValid = validateAppleId(apple_id);

        if (!isAppleIdValid) {
            console.log('Invalid Apple ID, please correct it.');
            return; // Detén el proceso si el Apple ID no es válido
        }

        updTabletRemarkable({
            variables: {
                input: {
                    id: selectedTabletRemarkable.id,
                    tagid: selectedTabletRemarkable.tagid,
                    model: selectedTabletRemarkable.model,
                    brand: selectedTabletRemarkable.brand,
                    serial_Number: selectedTabletRemarkable.serial_Number,
                    price: priceValue,
                    store: selectedTabletRemarkable.store,
                    charger_cable: selectedTabletRemarkable.charger_cable,
                    password: selectedTabletRemarkable.password,
                    os: selectedTabletRemarkable.os,
                    ram: ramValue,
                    storage: storageValue,
                    apple_id: selectedTabletRemarkable.apple_id,
                    apple_password: selectedTabletRemarkable.apple_password,
                    phone_associate: cleanCellNumber,
                    purchase_date: formattedData_purchase,
                    assignment_date: formattedData_assignment,
                    warranty_date: formattedData_warranty,
                    returnable_date: formattedData_returnable,
                    available: selectedTabletRemarkable.available,
                    status: selectedTabletRemarkable.status,
                    assigned_to: selectedTabletRemarkable.assigned_to,
                    location: selectedTabletRemarkable.location,
                    team: selectedTabletRemarkable.team,

                }
            }
        }).then(() => {
            setPriceError('');
            setStatusError('');
            setDuplicateError('');
            setShowTabletRemarkableAddOverlay(false);

        }).catch(error => {
            console.error("Error updating TabletRemarkable:", error);
        });
    };

    const handleSelectTabletRemarkable = (tabletRemarkable) => {

        for (const [key, value] of Object.entries(tabletRemarkable)) {
            console.log(`${key}: ${typeof value}`);
        }
        const formatDateForInput = (dateString) => {
            if (!dateString) return ''; // Return empty string if the date is not provided
            const date = new Date(dateString);
            return date.toISOString().split('T')[0]; // Convert to YYYY-MM-DD format
        };

        setNewTabletRemarkable({
            id: tabletRemarkable.id,
            tagid: tabletRemarkable.tagid,
            model: tabletRemarkable.model,
            brand: tabletRemarkable.brand,
            serial_Number: tabletRemarkable.serial_Number,
            price: tabletRemarkable.price,
            store: tabletRemarkable.store,
            charger_cable: tabletRemarkable.charger_cable,
            password: tabletRemarkable.password,
            os: tabletRemarkable.os,
            ram: tabletRemarkable.ram,
            storage: tabletRemarkable.storage,
            apple_id: tabletRemarkable.apple_id,
            apple_password: tabletRemarkable.apple_password,
            phone_associate: tabletRemarkable.phone_associate,
            purchase_date: formatDateForInput(tabletRemarkable.purchase_date),
            assignment_date: formatDateForInput(tabletRemarkable.assignment_date),
            warranty_date: formatDateForInput(tabletRemarkable.warranty_date),
            returnable_date: formatDateForInput(tabletRemarkable.returnable_date),
            available: tabletRemarkable.available,
            status: tabletRemarkable.status,
            assigned_to: tabletRemarkable.assigned_to,
            location: tabletRemarkable.location,
            team: tabletRemarkable.team,

        });
        setShowTabletRemarkableAddOverlay(true);
        setIsEditing(true);
        setSelectedTabletRemarkable(tabletRemarkable);
    };

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedTabletRemarkables = React.useMemo(() => {
        let sortableTabletRemarkables = [...tabletRemarkable];
        if (sortConfig !== null) {
            sortableTabletRemarkables.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableTabletRemarkables;
    }, [tabletRemarkable, sortConfig]);

    //listUsers 
    const users = React.useMemo(() => {
        if (userData && userData.listUsers) {
            return userData.listUsers.items || [];
        }
        return [];
    }, [userData]);
    if (userError) return <p>Error loading users: {userError.message}</p>;
    if (loading1) return <p>Error loading users: {userError.message}</p>;

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredTabletRemarkables = sortedTabletRemarkables.filter(tabletRemarkable => {
        if (tabletRemarkable[searchField] == null) return false; // O puedes decidir cómo manejarlo

        let fieldValue = tabletRemarkable[searchField].toString().toLowerCase();

        // Convertir "true" a "yes" y "false" a "no"
        if (fieldValue === "true") {
            fieldValue = "yes";
        } else if (fieldValue === "false") {
            fieldValue = "no";
        }

        return fieldValue.includes(searchQuery.toLowerCase());
    });

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(dateString);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

        return localDate.toLocaleDateString(undefined, options);
    };

    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    const formatPrice = (price) => {
        if (price === null || price === undefined || price === '') {
            return '';
        }
        const parsedPrice = parseFloat(price);
        return isNaN(parsedPrice) ? '' : parsedPrice.toFixed(2);
    };


    return (
        <div className="tabletRemarkable-page">
            <header className="inventory-header">
                <h1>Tablet & Remarkable Devices</h1>
            </header>
            <Inventory_header/>
            <div className="inventory-search">
                <button onClick={handleSaveAsExcel} > <FaFileExcel /> Export </button>
                <button onClick={togglePasswords}>
                    {showPasswords ? 'Hide Passwords' : 'Show Passwords'}
                </button>

                <input
                    type="text"
                    placeholder="Search by TAG, Available, Model, Assigned to"
                    value={searchQuery}
                    onChange={handleSearch}
                />

                <select onChange={(e) => setSearchField(e.target.value)} defaultValue="">
                    <option value="" disabled hidden>Select Option</option>
                    <option value="tagid">TAG</option>
                    <option value="available">Available</option>
                    <option value="status">Status</option>
                    <option value="model">Model</option>
                    <option value="brand">Brand</option>
                    <option value="serial_Number">Serial Number</option>
                    <option value="store">Store</option>
                    <option value="assigned_to">Assigned to</option>
                </select>

            </div>


            <button className="add-tabletRemarkable-btn" onClick={() => {
                clearNewTabletRemarkable();
                setSelectedTabletRemarkable(null);
                setIsEditing(false);
                setShowTabletRemarkableAddOverlay(true);
            }}>+ Add New Tablet/Remarkable</button>

            <div className="tabletRemarkable-table-container">
                <table className="tabletRemarkable-table">
                    <thead>
                        <tr>
                            <th>#</th> {/* Columna para contar las filas */}

                            <th onClick={() => requestSort('tagid')}>
                                TAG
                                {sortConfig?.key === 'tagid' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('model')}>
                                Model
                                {sortConfig?.key === 'model' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('brand')}>
                                Brand
                                {sortConfig?.key === 'brand' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('serial_Number')}>
                                Serial Number
                                {sortConfig?.key === 'serial_Number' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('price')}>
                                Price
                                {sortConfig?.key === 'price' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('store')}>
                                Store
                                {sortConfig?.key === 'store' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('charger_cable')}>
                                Charger Cable
                                {sortConfig?.key === 'charger_cable' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('password')}>
                                Password
                                {sortConfig?.key === 'password' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('os')}>
                                Operating System
                                {sortConfig?.key === 'os' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('ram')}>
                                RAM
                                {sortConfig?.key === 'ram' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('storage')}>
                                Storage
                                {sortConfig?.key === 'storage' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('apple_id')}>
                                Apple ID
                                {sortConfig?.key === 'apple_id' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('apple_password')}>
                                Apple Password
                                {sortConfig?.key === 'apple_password' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('phone_associate')}>
                                Phone Associate
                                {sortConfig?.key === 'phone_associate' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('purchase_date')}>
                                Purchase Date
                                {sortConfig?.key === 'purchase_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th onClick={() => requestSort('assignment_date')}>
                                Assignment Date
                                {sortConfig?.key === 'assignment_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('warranty_date')}>
                                Warranty Date
                                {sortConfig?.key === 'warranty_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('returnable_date')}>
                                Returnable Date
                                {sortConfig?.key === 'returnable_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('available')}>
                                Available
                                {sortConfig?.key === 'available' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('status')}>
                                Status
                                {sortConfig?.key === 'status' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('assigned_to')}>
                                Assigned To
                                {sortConfig?.key === 'assigned_to' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('location')}>
                                Location
                                {sortConfig?.key === 'location' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('team')}>
                                Team
                                {sortConfig?.key === 'team' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th >Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredTabletRemarkables.map((tabletRemarkable, index) => (
                            <tr key={tabletRemarkable.id} onClick={() => handleEditTabletRemarkable(tabletRemarkable)} >
                                <td>{index + 1}</td> {/* Número de fila */}
                                <td className="tabletRemarkable-tagid">{capitalize(tabletRemarkable.tagid)}</td>
                                <td className="tabletRemarkable-model">{capitalize(tabletRemarkable.model.toLowerCase())}</td>
                                <td className="tabletRemarkable-brand">{capitalize(tabletRemarkable.brand.toLowerCase())}</td>
                                <td className="tabletRemarkable-serial_Number">{tabletRemarkable.serial_Number.toUpperCase()}</td>
                                <td className="tabletRemarkable-price">${formatPrice(tabletRemarkable.price)}</td>
                                <td className="tabletRemarkable-store">{tabletRemarkable.store}</td>
                                <td className="tabletRemarkable-charger_cable">{tabletRemarkable.charger_cable ? 'Yes' : 'No'}</td>
                                <td className="tabletRemarkable-password">{showPasswords ? String(tabletRemarkable.password) : '******'}</td>
                                <td className="tabletRemarkable-os">{String(tabletRemarkable.os)}</td>
                                <td className="tabletRemarkable-ram">{tabletRemarkable.ram !== null && tabletRemarkable.ram !== undefined ? tabletRemarkable.ram + " GB" : "No RAM Info"}</td>
                                <td className="tabletRemarkable-storage">{tabletRemarkable.storage !== null && tabletRemarkable.storage !== undefined ? tabletRemarkable.storage + " GB" : "No Storage Info"}</td>
                                <td className="tabletRemarkable-apple_id">{tabletRemarkable.apple_id ? String(tabletRemarkable.apple_id) : ''}</td>
                                <td className="tabletRemarkable-apple_password">{showPasswords ? String(tabletRemarkable.apple_password) : '******'}</td>
                                <td className="tabletRemarkable-phone_associate">{tabletRemarkable.phone_associate}</td>
                                <td className="tabletRemarkable-purchase_date">{tabletRemarkable.purchase_date ? formatDate(tabletRemarkable.purchase_date) : 'No Date'}</td>
                                <td className="tabletRemarkable-assignment_date">{tabletRemarkable.assignment_date ? formatDate(tabletRemarkable.assignment_date) : 'No Date'}</td>
                                <td className="tabletRemarkable-warranty_date">{tabletRemarkable.warranty_date ? formatDate(tabletRemarkable.warranty_date) : 'No Date'}</td>
                                <td className="tabletRemarkable-returnable_date">{tabletRemarkable.returnable_date ? formatDate(tabletRemarkable.returnable_date) : 'No Date'}</td>
                                <td className="tabletRemarkable-available">{tabletRemarkable.available ? 'Yes' : 'No'} </td>
                                <td className="tabletRemarkable-status">
                                    {tabletRemarkable.status === 'broken' ? 'Broken' :
                                        tabletRemarkable.status === 'use' ? 'Use' :
                                            tabletRemarkable.status === 'transfer' ? 'Transfer' :
                                                tabletRemarkable.status === 'lost' ? 'Lost' :
                                                    tabletRemarkable.status === 'inv' ? 'Inventario' :
                                                        'Unknown'} {/* Muestra 'Unknown' si el estado no coincide con ninguno de los anteriores */}
                                </td>
                                <td className="tabletRemarkable-assigned_to">{tabletRemarkable.assigned_to}</td>
                                <td className="tabletRemarkable-location">{tabletRemarkable.location}</td>
                                <td className="tabletRemarkable-team">{tabletRemarkable.team}</td>
                                <td className='tabletRemarkable-table'>
                                    <button className="delete-tabletRemarkable-btn" onClick={() => handleSelectTabletRemarkable(tabletRemarkable)}>Delete/Update</button>

                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>



            {nextToken && !loading && (
                <button onClick={loadMore}>Load More</button>
            )}


            {loading && <p>Loading...</p>}

            {showTabletRemarkableAddOverlay && (

                <div className="tabletRemarkable_add_overlay">
                    <div className="overlay-content-inventory">
                        <div className="header-row">
                            <h2 className="header-title">{isEditing ? 'Edit Tablet/Remarkable' : 'Add New Tablet/Remarkable'}</h2>
                            <button onClick={() => {
                                setShowTabletRemarkableAddOverlay(false);
                                clearNewTabletRemarkable();
                                setSelectedTabletRemarkable(null);
                                setIsEditing(false);
                            }}>X</button>
                        </div>

                        <label>TAG:</label><input name="tagid" placeholder="TAG" value={isEditing ? selectedTabletRemarkable.tagid : newTabletRemarkable.tagid} onChange={handleInputChange} required />
                        <label>Model:</label><input name="model" placeholder="Model" value={isEditing ? selectedTabletRemarkable.model : newTabletRemarkable.model} onChange={handleInputChange} required />

                        <label>Brand:</label><input name="brand" placeholder="Brand" value={isEditing ? selectedTabletRemarkable.brand : newTabletRemarkable.brand} onChange={handleInputChange} />
                        <label>Serial Number:</label><input name="serial_Number" placeholder="Serial Number" value={isEditing ? selectedTabletRemarkable.serial_Number : newTabletRemarkable.serial_Number} onChange={handleInputChange} required />

                        <label>Price:</label><input name="price" placeholder="price" value={isEditing ? selectedTabletRemarkable.price : newTabletRemarkable.price} onChange={handleInputChange} />
                        <label>Store:</label><input name="store" placeholder="store" value={isEditing ? selectedTabletRemarkable.store : newTabletRemarkable.store} onChange={handleInputChange} />

                        <label htmlFor="purchase_date">Purchase Date:</label>
                        <input
                            type="Date"
                            id="purchase_date"
                            name="purchase_date"
                            value={isEditing ? selectedTabletRemarkable.purchase_date : newTabletRemarkable.purchase_date}
                            onChange={handleInputChange}

                        />
                        <label>Charger Cable:</label>
                        <select name="charger_cable" placeholder="charger_cable" value={isEditing ? selectedTabletRemarkable.charger_cable : newTabletRemarkable.charger_cable} onChange={handleInputChange} >
                            <option value="" disabled hidden className="placeholder-option">Select</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                        <label>Password:</label><input type={showPasswords ? "text" : "password"} name="password" placeholder="Password " value={isEditing ? selectedTabletRemarkable.password : newTabletRemarkable.password} onChange={handleInputChange} />
                        <label>Operating System:</label><input name="os" placeholder="Operating System" value={isEditing ? selectedTabletRemarkable.os : newTabletRemarkable.os} onChange={handleInputChange} />
                        <label> RAM:</label><input name="ram" placeholder="RAM" value={isEditing ? selectedTabletRemarkable.ram : newTabletRemarkable.ram} onChange={handleInputChange} />
                        <label> Storage:</label><input name="storage" placeholder="storage" value={isEditing ? selectedTabletRemarkable.storage : newTabletRemarkable.storage} onChange={handleInputChange} />
                        <label> Apple ID:</label><input name="apple_id" placeholder="apple_id" value={isEditing ? selectedTabletRemarkable.apple_id : newTabletRemarkable.apple_id} onChange={handleInputChange} />
                        <label> Apple Password:</label><input type={showPasswords ? "text" : "password"} name="apple_password" placeholder="apple_password" value={isEditing ? selectedTabletRemarkable.apple_password : newTabletRemarkable.apple_password} onChange={handleInputChange} />
                        <label> Phone Associate:</label><input name="phone_associate" placeholder="phone_associate" value={isEditing ? selectedTabletRemarkable.phone_associate : newTabletRemarkable.phone_associate} onChange={handleInputChange} />
                        <label htmlFor="assignment_date">Assignment Date:</label>
                        <input
                            type="date"
                            id="assignment_date"
                            name="assignment_date"
                            value={isEditing ? selectedTabletRemarkable.assignment_date : newTabletRemarkable.assignment_date}
                            onChange={handleInputChange}

                        />
                        <label htmlFor="warranty_date">Warranty Date:</label>
                        <input
                            type="date"
                            id="warranty_date"
                            name="warranty_date"
                            value={isEditing ? selectedTabletRemarkable.warranty_date : newTabletRemarkable.warranty_date}
                            onChange={handleInputChange}

                        />
                        <label htmlFor="returnable_date">Returnable Date:</label>
                        <input
                            type="date"
                            id="returnable_date"
                            name="returnable_date"
                            value={isEditing ? selectedTabletRemarkable.returnable_date : newTabletRemarkable.returnable_date}
                            onChange={handleInputChange}

                        />

                        <label>Available:</label>

                        <select name="available" placeholder="available" value={isEditing ? selectedTabletRemarkable.available : newTabletRemarkable.available} onChange={handleInputChange} >
                            <option value="" disabled hidden className="placeholder-option">Select Availability</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>

                        </select>
                        <label>Status:</label>
                        <select name="status" placeholder="status" value={isEditing ? selectedTabletRemarkable.status : newTabletRemarkable.status} onChange={handleInputChange} >
                            <option value="" disabled hidden>Select Status</option>
                            <option value="use">Use</option>
                            <option value="broken">Broken</option>
                            <option value="transfer">Transfer</option>
                            <option value="lost">Lost</option>
                            <option value="inv">Inventario</option>

                        </select>
                        <label>Assigned To:</label>
                        <select
                            name="assigned_to"
                            value={isEditing ? selectedTabletRemarkable.assigned_to : newTabletRemarkable.assigned_to}
                            onChange={handleInputChange}
                        >
                            <option value="">Select an Employee</option>

                            {users.length > 0 ? (
                                [...users].sort((a, b) => {
                                    const nameA = `${a.first_name} ${a.last_name}`.toLowerCase();
                                    const nameB = `${b.first_name} ${b.last_name}`.toLowerCase();
                                    return nameA.localeCompare(nameB);
                                }).map(user => (
                                    <option key={user.id} value={`${capitalize(user.first_name)} ${capitalize(user.last_name)}`}>
                                        {capitalize(user.first_name)} {capitalize(user.last_name)}
                                    </option>
                                ))
                            ) : (

                                <option value="">No users available</option>
                            )}
                            <option value="n/a">N/A</option> {/* Opción para N/A */}

                        </select>


                        <label>Location:</label><input name="location" placeholder="location" value={isEditing ? selectedTabletRemarkable.location : newTabletRemarkable.location} onChange={handleInputChange} />
                        <label>Team:</label><input name="team" placeholder="team" value={isEditing ? selectedTabletRemarkable.team : newTabletRemarkable.team} onChange={handleInputChange} />

                        {duplicateError && <p className="error">{duplicateError}</p>} {/* Display duplicate error message */}
                        {priceError && <p className="error">{priceError}</p>} {/* Display price error message */}
                        {statusError && <p className="error">{statusError}</p>} {/* Display status error message */}
                        {ramError && <p className="error">{ramError}</p>} {/* Display RAM error message */}
                        {storageError && <p className="error">{storageError}</p>} {/* Display Storage error message */}
                        {AppleIDError && <p className="error">{AppleIDError}</p>} {/* Display Apple ID error message */}
                        {phoneError && <p className="error">{phoneError}</p>} {/* Display Phone error message */}

                        <div className="button-group">
                            <button className="overlay-add-tabletRemarkable" onClick={isEditing ? handleUpdateTabletRemarkable : handleAddTabletRemarkable} >
                                {isEditing ? 'Update Tablet' : 'Add Tablet'}
                            </button>
                            {isEditing && (
                                <button
                                    className="overlay-delet-tabletRemarkable"
                                    onClick={() => {
                                        if (window.confirm('Are you sure you want to delete this Tablet/Remarkable?')) {
                                            handleDeleteTabletRemarkable();
                                        }
                                    }}
                                >
                                    Delete Tablet
                                </button>
                            )}
                            <button className="overlay-add-tabletRemarkable" onClick={togglePasswords}>
                                {showPasswords ? 'Hide Passwords' : 'Show Passwords'}
                            </button>
                        </div>
                    </div>
                </div>
            )}

        </div>


    );













};

export default Tablet_Remarkable;
