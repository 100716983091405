import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard/Dashboard';
import ProtectedRoute from './components/common/ProtectedRoute';  // Ensure correct path
import Appointments from './pages/Appointments/Appointments';
import AdminHome from './pages/Administrator/AdminHome';
import Calendar from './pages/Calendar/Calendar';
import WorkSchedules from './pages/WorkSchedules/WorkSchedules';
import Clients from './pages/Clients/Clients';
import Events from './pages/Events/Events';
import MobileOutreach from './pages/MobileOutreach/MobileOutreach';
import Activities from './pages/Activities/Activities';
import Reports from './pages/Reports/HybridReport/Reports';
import ReportsList from './pages/Reports/ReportsList/ReportsList'
import CallList from './pages/CallList/CallList';
import Locations from './pages/Locations/Locations';
import Counties from './pages/Counties/Counties';
import DeletedClients from './pages/DeletedClients/DeletedClients';
import Mouse from './pages/Inventory/Mouse'
import Keyboard from './pages/Inventory/Keyboard/Keyboard'
import Monitor from './pages/Inventory/Monitor/Monitor'
import Scanner from './pages/Inventory/Scanner/Scanner';
import Printer from './pages/Inventory/Printer/Printer';
import Extension_Cord from './pages/Inventory/Extension Cord/Extension_Cord';
import Phone from './pages/Inventory/Phone/Phone';
import Backup_Battery from './pages/Inventory/Backup Battery/Backup_Battery';
import USB from './pages/Inventory/USB/USB';
import Docking_Station from './pages/Inventory/Docking Station/Docking_Station';
import Speaker from './pages/Inventory/Speaker/Speaker';
import Laptop from './pages/Inventory/Laptop/Laptop';
import Tablet_Remarkable from './pages/Inventory/Tablet_Remarkable/Tablet_Remarkable';
import Headset from './pages/Inventory/Headset/Headset';
import Proyector from './pages/Inventory/Proyector/Proyector';
import External_Disk from './pages/Inventory/External Disk/External_Disk';
import Bag from './pages/Inventory/Bag/Bag';
import Click_Counter from './pages/Inventory/Click Counter/Click_Counter';
import Office_Supplies from './pages/Inventory/Office Supplies/Office_Supplies';
import ReportByDate from './pages/Inventory/ReportByDate/ReportByDate';


import Inventory_Home from './pages/Inventory/principal/principal'
import Employees from './pages/Inventory/Employees/Employees'
import WeeklyReports from './pages/Reports/WeeklyReports/ParentWeeklyReport'
import { maintenanceRoutes } from './pages/Maintenance/maintenanceConfig'; //Change this to set up pages under maintenance
import Maintenance from './pages/Maintenance/Maintenance';
import PowerBank from './pages/Inventory/PowerBank/PowerBank';
import General_Inventory from './pages/Inventory/General Inventory/General_Inventory';
import PromoActivities from './pages/Administrator/Promo_Activities/Promo_Activities';


const createRoute = (path, element, isProtected = false, allowedRoles = []) => {
  const isUnderMaintenance = maintenanceRoutes.includes(path);

  // If route is protected, wrap with ProtectedRoute, otherwise return normal Route
  return (
    <Route
      key={path}
      path={path}
      element={
        isUnderMaintenance
          ? <Maintenance />
          : isProtected
          ? <ProtectedRoute allowedRoles={allowedRoles}>{element}</ProtectedRoute>
          : element
      }
    />
  );
};
const AppRoutes = () => {
  console.log('AppRoutes rendered');
  return (
    <Routes>
      {createRoute('/dashboard', <Dashboard />)}
      {createRoute('/appointments', <Appointments />)}
      {createRoute('/admin-home', <AdminHome />, true, ['Admin'])}
      {createRoute('/calendar', <Calendar />)}
      {createRoute('/work-schedules', <WorkSchedules />)}
      {createRoute('/clients', <Clients />)}
      {createRoute('/events', <Events />)}
      {createRoute('/mobile-outreach', <MobileOutreach />)}
      {createRoute('/activities', <Activities />)}
      {createRoute('/reports', <Reports />)}
      {createRoute('/reports-list', <ReportsList />)}
      {createRoute('/call-list', <CallList />)}
      {createRoute('/locations', <Locations />)}
      {createRoute('/counties', <Counties />)}
      {createRoute('/deleted-clients', <DeletedClients />, true, ['Admin'])}
      {createRoute('/equipment-mouse', <Mouse />, true, ['Admin'])}
      {createRoute('/equipment-keyboard', <Keyboard />, true, ['Admin'])}
      {createRoute('/equipment-monitor', <Monitor />, true, ['Admin'])}
      {createRoute('/equipment-scanner', <Scanner />, true, ['Admin'])}
      {createRoute('/equipment-printer', <Printer />, true, ['Admin'])}
      {createRoute('/equipment-extension-cord', <Extension_Cord />, true, ['Admin'])}
      {createRoute('/equipment-phone', <Phone />, true, ['Admin'])}
      {createRoute('/equipment-backup-battery', <Backup_Battery />, true, ['Admin'])}
      {createRoute('/equipment-power-bank', <PowerBank />, true, ['Admin'])}
      {createRoute('/equipment-usb', <USB />, true, ['Admin'])}
      {createRoute('/equipment-docking-station', <Docking_Station />, true, ['Admin'])}
      {createRoute('/equipment-speaker', <Speaker />, true, ['Admin'])}
      {createRoute('/equipment-laptop', <Laptop />, true, ['Admin'])}
      {createRoute('/equipment-tablet-remarkable', <Tablet_Remarkable />, true, ['Admin'])}
      {createRoute('/equipment-headset', <Headset />, true, ['Admin'])}
      {createRoute('/equipment-projector', <Proyector />)}
      {createRoute('/equipment-external-disk', <External_Disk />, true, ['Admin'])}
      {createRoute('/equipment-bag', <Bag />, true, ['Admin'])}
      {createRoute('/equipment-click-counter', <Click_Counter />, true, ['Admin'])}
      {createRoute('/equipment-office-supplies', <Office_Supplies />, true, ['Admin'])}
      {createRoute('/equipment-general-inventory', <General_Inventory />, true, ['Admin'])}
      {createRoute('/equipment-report-by-date', <ReportByDate />, true, ['Admin'])}
      {createRoute('/inventory-home', <Inventory_Home />, true, ['Admin'])}
      {createRoute('/inventory-employees', <Employees />, true, ['Admin'])}
      {createRoute('/weekly-reports', <WeeklyReports />, true, ['Admin'])} 
      {createRoute('/promo-reports', <PromoActivities />, true, ['Admin'])}  
      {createRoute('/counties', <Counties />, true, ['Admin'])}
      {createRoute('/location', <Locations />, true, ['Admin'])}

    </Routes>
  );
};

export default AppRoutes;