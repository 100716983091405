import React, { useState, useEffect } from 'react';
import './ManageUsers.css';
import { fetchAuthSession } from 'aws-amplify/auth';
import AWS from 'aws-sdk';
import awsExports from '../../../aws-exports'; // Import aws-exports.js to get the UserPoolId

export default function ManageUsers({ onClose }) {
    const [users, setUsers] = useState([]); // State for users list
    const [message, setMessage] = useState(''); // State for success or error messages
    const [expandedUser, setExpandedUser] = useState(null); // State to track expanded user
    const [userGroups, setUserGroups] = useState([]);
    const groupNames = ['Navigator', 'Senior-Navigator', 'Manager', 'Admin',]; // Define your groups here
    const userPoolId = awsExports.aws_user_pools_id;
    const region = awsExports.aws_project_region;
    const displayMessage = (msg) => {
        setMessage(msg);
        setTimeout(() => {
            setMessage('');
        }, 5000); // Message will disappear after 5 seconds
    };
    

    // Function to fetch users from Cognito User Pool
    const fetchUsers = async () => {
        try {
            const credentials = await fetchAuthSession();
            const { accessKeyId, secretAccessKey, sessionToken } = credentials.credentials;

            AWS.config.update({
                region: region,
                credentials: {
                    accessKeyId,
                    secretAccessKey,
                    sessionToken,
                },
            });

            const cognito = new AWS.CognitoIdentityServiceProvider();

            const params = {
                UserPoolId: userPoolId,
                Limit: 50, // Number of users to fetch (adjust as needed)
            };

            const result = await cognito.listUsers(params).promise();
            setUsers(result.Users); // Store users in state
        } catch (error) {
            console.error('Error fetching users:', error);
            displayMessage(`Error fetching users: ${error.message}`);
        }
    };

    // Call fetchUsers when the component mounts
    useEffect(() => {
        fetchUsers();
    }, []);

    // Function to disable user access
    const handleDisableUser = async (username) => {
        try {
            const credentials = await fetchAuthSession();
            const { accessKeyId, secretAccessKey, sessionToken } = credentials.credentials;

            AWS.config.update({
                region: region,
                credentials: {
                    accessKeyId,
                    secretAccessKey,
                    sessionToken,
                },
            });

            const cognito = new AWS.CognitoIdentityServiceProvider();

            const params = {
                UserPoolId: userPoolId,
                Username: username,
            };

            // Call AdminDisableUser to disable access
            await cognito.adminDisableUser(params).promise();
            displayMessage(`User ${username} disabled successfully.`);
            fetchUsers(); // Refresh user list
        } catch (error) {
            console.error('Error disabling user:', error);
            displayMessage(`Error disabling user: ${error.message}`);
        }
    };

    // Function to reset user's password
    const handleResetPassword = async (username) => {
        try {
            const credentials = await fetchAuthSession();
            const { accessKeyId, secretAccessKey, sessionToken } = credentials.credentials;

            AWS.config.update({
                region: region,
                credentials: {
                    accessKeyId,
                    secretAccessKey,
                    sessionToken,
                },
            });

            const cognito = new AWS.CognitoIdentityServiceProvider();

            const params = {
                UserPoolId: userPoolId,
                Username: username,
                TemporaryPassword: generatePassword(), // Generate a temporary password
                MessageAction: 'RESEND', // Ensure the email is sent to the user
            };

            // Call AdminSetUserPassword to reset the password
            await cognito.adminSetUserPassword(params).promise();
            displayMessage(`Temporary password sent to ${username}.`);
        } catch (error) {
            console.error('Error resetting password:', error);
            displayMessage(`Error resetting password: ${error.message}`);
        }
    };

    // Function to generate a random 9-character temporary password
    const generatePassword = () => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*';
        let password = '';
        for (let i = 0; i < 9; i++) {
            password += chars[Math.floor(Math.random() * chars.length)];
        }
        return password;
    };

    // Function to determine background color based on user status
    const getUserStatusColor = (user) => {
        if (user.Enabled === false) {
            return 'lightcoral'; // Deactivated user
        } else if (user.UserStatus === 'CONFIRMED') {
            return 'lightgray'; // Active user
        } else if (user.UserStatus === 'FORCE_CHANGE_PASSWORD') {
            return 'lightyellow'; // Not validated (requires password change)
        }
        return ''; // Default case, no color
    };

    // Function to handle clicking on a user (expand/collapse details)
    const handleUserClick = async (username) => {
        setExpandedUser((prevUsername) => (prevUsername === username ? null : username));
        try {
            const credentials = await fetchAuthSession();
            const { accessKeyId, secretAccessKey, sessionToken } = credentials.credentials;

            AWS.config.update({
                region: region,
                credentials: {
                    accessKeyId,
                    secretAccessKey,
                    sessionToken,
                },
            });

            const cognito = new AWS.CognitoIdentityServiceProvider();
            const params = {
                Username: username,
                UserPoolId: userPoolId,
            };
            const result = await cognito.adminListGroupsForUser(params).promise();
            setUserGroups(result.Groups.map(group => group.GroupName)); // Store user's groups
        } catch (error) {
            console.error('Error fetching user groups:', error);
            displayMessage(`Error fetching user groups: ${error.message}`);
        }
    };

    const handleGroupToggle = async (username, groupName, isMember) => {
        try {
            const credentials = await fetchAuthSession();
            const { accessKeyId, secretAccessKey, sessionToken } = credentials.credentials;

            AWS.config.update({
                region: region,
                credentials: {
                    accessKeyId,
                    secretAccessKey,
                    sessionToken,
                },
            });

            const cognito = new AWS.CognitoIdentityServiceProvider();
            const params = {
                GroupName: groupName,
                UserPoolId: userPoolId,
                Username: username,
            };

            if (isMember) {
                await cognito.adminRemoveUserFromGroup(params).promise();
                displayMessage(`User ${username} removed from group ${groupName}`);
            } else {
                await cognito.adminAddUserToGroup(params).promise();
                displayMessage(`User ${username} added to group ${groupName}`);
            }

            // Refresh the groups
            handleUserClick(username);
        } catch (error) {
            console.error('Error managing user group:', error);
            displayMessage(`Error managing user group: ${error.message}`);
        }
    };

    // Function to collapse the expanded user when close button is clicked
    const handleCollapseUser = () => {
        setExpandedUser(null);
    };

    return (
        <div className="manage-users-modal">
            <div className="manage-users-content">
                <h2>Manage Compass Users</h2>
                <div className="users-list">
                    {/* Column headers */}
                    <div className="user-item header">
                        <p>Email</p>
                        <p>Status</p>
                    </div>

                    {users.length > 0 ? (
                        users.map((user) => {
                            const isExpanded = expandedUser === user.Username;
                            const email = user.Attributes.find(attr => attr.Name === 'email').Value;
                            return (
                                <div
                                    key={user.Username}
                                    className={`user-item ${isExpanded ? 'expanded' : ''}`}
                                    style={{ backgroundColor: getUserStatusColor(user) }}
                                    onClick={() => handleUserClick(user.Username)}
                                >
                                    {/* Collapsed user row (no labels, truncated email) */}
                                    <p className="user-email">
                                        {email.length > 30 ? `${email.substring(0, 30)}...` : email}
                                    </p>
                                    <p className="user-status">{user.UserStatus}</p>

                                    {/* Expanded user details */}
                                    {isExpanded && (
                                        <div className="user-details">
                                            <p>Email: {email}</p>
                                            <p>Email Verified: {user.Attributes.find(attr => attr.Name === 'email_verified')?.Value}</p>
                                            <p>Status: {user.UserStatus}</p>
                                            <div className="user-groups">
                                                {groupNames.map(group => {
                                                    const isMember = userGroups.includes(group);
                                                    return (
                                                        <label key={group}>
                                                            <input
                                                                type="checkbox"
                                                                checked={isMember}
                                                                onChange={() =>
                                                                    handleGroupToggle(user.Username, group, isMember)
                                                                }
                                                            />
                                                            {group}
                                                        </label>
                                                    );
                                                })}
                                            </div>

                                            {/* Action buttons - displayed only when expanded */}
                                            <div className="user-actions">
                                                <button
                                                    className="disable-user-btn"
                                                    onClick={(e) => {
                                                        e.stopPropagation(); // Prevent triggering user expansion
                                                        handleDisableUser(user.Username);
                                                    }}
                                                    disabled={user.Enabled === false}
                                                >
                                                    Disable User
                                                </button>

                                                <button
                                                    className="reset-password-btn"
                                                    onClick={(e) => {
                                                        e.stopPropagation(); // Prevent triggering user expansion
                                                        handleResetPassword(user.Username);
                                                    }}
                                                    disabled={user.Enabled === false}
                                                >
                                                    Reset Password
                                                </button>
                                            </div>

                                            {/* Close button to collapse the expanded view */}
                                            <button className="collapse-btn" onClick={(e) => {
                                                e.stopPropagation();
                                                handleCollapseUser();
                                            }}>
                                                Close
                                            </button>
                                        </div>
                                    )}
                                </div>

                            );
                        })
                    ) : (
                        <p>No users found.</p>
                    )}
                </div>
                {message && <p>{message}</p>}
                <button className='man-u-close' onClick={onClose}>X</button>
            </div>
        </div>
    );
}
