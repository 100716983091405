import React from 'react';

const Section31 = ({ formData, handleInputChange }) => (
  <>
    {/* Section 31 */}
    <div className="form-section flyer-section">
      <div className="flyer-header">3. Marketplace Application Assessment and Preparation</div>
      <label className="required-asterisk">A. Healthcare.gov Account Creation or Troubleshooting</label>
      <div className="radio-group">
        <label>
          <input
            type="radio"
            name="HealtcareGovAcc"
            value="Creation"
            checked={formData.HealtcareGovAcc === 'Creation'}
            onChange={handleInputChange}
            required
          />
          Creation
        </label>
        <label>
          <input
            type="radio"
            name="HealtcareGovAcc"
            value="Troubleshooting"
            checked={formData.HealtcareGovAcc === 'Troubleshooting'}
            onChange={handleInputChange}
            required
          />
          Troubleshooting
        </label>
        <label>
          <input
            type="radio"
            name="HealtcareGovAcc"
            value="None"
            checked={formData.HealtcareGovAcc === 'None'}
            onChange={handleInputChange}
            required
          />
          None
        </label>
      </div>
    </div>

    <div className='form-section'>
      <label>If account creation, number of accounts created</label>
      <input
            type='number'
            name='createdAccounts'
            value={formData.createdAccounts}
            onChange={handleInputChange}
            placeholder="Your answer"
            min="0"
          />
    </div>

    <div className='form-section'>
      <label>If account troubleshooting, number of accounts</label>
      <input
            type='number'
            name='troubleshootedAccounts'
            value={formData.troubleshootedAccounts}
            onChange={handleInputChange}
            placeholder="Your answer"
            min="0"
          />
    </div>
  </>
);

export default Section31;
