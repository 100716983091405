import React from 'react';

const Section33 = ({ formData, handleInputChange }) => (
  <>
    {/* Section 33 */}
    <div className="form-section flyer-section">
      <div className="flyer-header">4. Marketplace Application Submission and Enrollment (Re-enrollment)</div>
      <label className="required-asterisk">A.  Electronic QHP Enrollment using Healthcare.gov</label>
      <input
        type="number"
        name="qhp_enrollment_hcgov"
        value={formData.qhp_enrollment_hcgov}
        onChange={handleInputChange}
        placeholder="Your answer"
        min="0"
        required
      />
    </div>
    <div className='form-section'>
      <label className="required-asterisk">B. Telephonic QHP Enrollment using Marketplace Call Center</label>
      <input
        type='number'
        name='elctronic_qhp_enrollment_marketplaceCallCenter'
        value={formData.elctronic_qhp_enrollment_marketplaceCallCenter}
        onChange={handleInputChange}
        placeholder="Your answer"
        min="0"
        required
      />
    </div>
    <div className='form-section'>
      <label className="required-asterisk">C. Written QHP Enrollment using a paper Marketplace Application</label>
      <input
        type='number'
        name='written_qhp_enrollment_hcgov'
        value={formData.written_qhp_enrollment_hcgov}
        onChange={handleInputChange}
        placeholder="Your answer"
        min="0"
        required
      />
    </div>
  </>
);

export default Section33;
