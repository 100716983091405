import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { listAllCallLists, listClientFix2s, listUsersByTeamOne } from '../../graphql/queries';
import { CREATE_CALL_LIST, UPDATE_CALL_LIST, DELETE_CALL_LIST, CREATE_OPT_OUT_CLIENT } from '../../graphql/mutations';
import LoadingSpinner from '../../components/layout/LoadingSpinner';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import moment from 'moment';
import './CallList.css';

const CallList = () => {
  const [isAdmin, setIsAdmin] = useState(false); // Tracks if the user has admin privileges
  const [fullName, setFullName] = useState(''); // Stores formatted full name of the logged-in user
  const [selectedCall, setSelectedCall] = useState(null); // Holds data for the selected call (for modal)
  const [callInfo, setCallInfo] = useState(null); // Stores editable data for the modal
  const [callStatusFilter, setCallStatusFilter] = useState('all'); // Filter for call status
  const [contactRoundFilter, setContactRoundFilter] = useState('all'); // State for filtering by contact round
  const [searchTerm, setSearchTerm] = useState(''); // Search term for name, last name, or call date
  const [isGenerating, setIsGenerating] = useState(false); // New loading state for generating call list
  const [selectedNavigator, setSelectedNavigator] = useState('all');
  const [bulkAssignNavigator, setBulkAssignNavigator] = useState('all'); // For bulk assignment only
  const [selectedClients, setSelectedClients] = useState([]); // Track selected client IDs

  const today = new Date();
  const dateThreshold = new Date(today.setDate(today.getDate() - 30));

  const [createCall] = useMutation(CREATE_CALL_LIST);
  const [updateCall] = useMutation(UPDATE_CALL_LIST);
  const [deleteCall] = useMutation(DELETE_CALL_LIST);
  const [createOptOutEntry] = useMutation(CREATE_OPT_OUT_CLIENT);

  // Fetch and format user details (runs on initial render)
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const session = await fetchAuthSession();
        const user = await getCurrentUser();

        const [firstName, lastNameWithDomain] = user.signInDetails.loginId.split('.');
        const lastName = lastNameWithDomain.split('@')[0];
        const formattedName = `${capitalize(firstName)} ${capitalize(lastName)}`;

        setFullName(formattedName);
        setIsAdmin(session.tokens.idToken.payload['cognito:groups']?.some(group => group === 'Manager' || group === 'Admin'));
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };
    fetchUserDetails();
  }, []);

  const capitalize = (name) =>
    name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();

  // Admin query to fetch all call lists or clients
  const { data, loading, refetch } = useQuery(
    listAllCallLists,
    { variables: { limit: 1000 }, skip: !isAdmin }
  );

  const { data: allClientsData, refetch: refetchClients } = useQuery(
    listClientFix2s, { variables: { limit: 1000 }, skip: !isAdmin }
  );

  // Fetch navigators for admin users
  const { data: usersData, loading: usersLoading, error: usersError } = useQuery(
    listUsersByTeamOne,
    {
      variables: {
        filter: {
          navigator_id: { ne: "" }
        },
        limit: 1000,
      },
      skip: !isAdmin, // Only fetch if user is admin
    }
  );

  const navigatorOptions = usersData?.listUsers?.items?.map(user => ({
    id: user.id,
    name: `${capitalize(user.first_name)} ${capitalize(user.last_name)}`
  })) || [];

  // Admin-only function to generate call list entries

  const generateCallList = async () => {
    setIsGenerating(true); // Start loading spinner
    try {
      // Fetch the latest clients and use the returned data directly
      const { data: refetchedClientsData } = await refetchClients();
      const clients = refetchedClientsData?.listClientFix2s?.items || [];

      // Fetch the latest call list and use the returned data directly
      const { data: refetchedCallListData } = await refetch();
      const currentCallList = refetchedCallListData?.listCallLists?.items || [];
      const existingClientIDs = new Set(currentCallList.map((call) => call.clientID));

      const newCalls = [];

      for (let client of clients) {
        const lastContactedDate = client.last_contacted_date ? new Date(client.last_contacted_date) : null;

        // Add client to newCalls if not in call list and hasn't been contacted in 30 days
        if (
          !existingClientIDs.has(client.id) &&
          (!lastContactedDate || lastContactedDate < dateThreshold)
        ) {
          const { data: createdCall } = await createCall({
            variables: {
              input: {
                clientID: client.id,
                navigator: client.navigator,
                callDate: new Date().toISOString().split('T')[0],
                answered: false,
                appointmentMade: false,
                needsFollowUp: true,
                notes: '',
                contactRound: 0,
              },
            },
          });
          newCalls.push(createdCall.createCallList);

          // Remove the break if you want to add more than one item
          break;
        }
      }

      if (newCalls.length > 0) {
        await refetch(); // Refetch the call list to include new entries
      }
    } catch (error) {
      console.error("Error generating call list:", error);
      alert("Failed to generate call list");
    } finally {
      setIsGenerating(false); // Stop loading spinner
    }
  };




  const openModal = (call) => {
    setSelectedCall(call);
    setCallInfo({ ...call });
  };

  const closeModal = () => {
    setSelectedCall(null);
    setCallInfo(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCallInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value === 'true' ? true : value === 'false' ? false : value,
    }));
  };

  const handleUpdateCall = async () => {
    try {
      const updatedRound = (callInfo.contactRound || 0) + 1;
      if (updatedRound > 3) {
        await createOptOutEntry({
          variables: {
            input: {
              clientID: callInfo.clientID,
              optOutDate: new Date().toISOString(),
              reason: "Exceeded contact attempts",
            },
          },
        });
        alert("Client has been moved to the opt-out list");
        closeModal();
        refetch();
        return;
      }

      await updateCall({
        variables: {
          input: {
            id: callInfo.id,
            answered: callInfo.answered,
            appointmentMade: callInfo.appointmentMade,
            needsFollowUp: callInfo.needsFollowUp,
            notes: callInfo.notes,
            contactRound: updatedRound,
          },
        },
      });
      alert('Call updated successfully');
      closeModal();
      refetch();
    } catch (error) {
      console.error("Error updating call:", error);
      alert('Failed to update call');
    }
  };

  const handleDeleteCall = async () => {
    if (window.confirm("Are you sure you want to delete this call list item?")) {
      try {
        await deleteCall({
          variables: { input: { id: callInfo.id } },
        });
        alert("Call list item deleted successfully");
        closeModal();
        refetch();
      } catch (error) {
        console.error("Error deleting call:", error);
        alert("Failed to delete call");
      }
    }
  };

  const getFilteredCallList = () => {
    return (data?.listCallLists?.items || []).filter((call) => {
      if (callStatusFilter === 'called' && !call.answered) return false;
      if (callStatusFilter === 'not_called' && call.answered) return false;
      if (callStatusFilter === 'appointment_made' && !call.appointmentMade) return false;
      if (callStatusFilter === 'no_appointment' && call.appointmentMade) return false;
      // Filter by navigator if selectedNavigator is not 'all'
      if (selectedNavigator !== 'all' && call.navigator !== selectedNavigator) {
        return false;
      }
      if (contactRoundFilter !== 'all' && call.contactRound !== parseInt(contactRoundFilter)) {
        return false;
      }

      const nameMatch = call.client?.name?.toLowerCase().includes(searchTerm.toLowerCase());
      const lastNameMatch = call.client?.last_name?.toLowerCase().includes(searchTerm.toLowerCase());
      const dateMatch = call.callDate?.includes(searchTerm);

      return nameMatch || lastNameMatch || dateMatch;
    });
  };

  const formatDateTime = (dateTimeStr) => {
    if (!dateTimeStr) return 'N/A';
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    const date = new Date(dateTimeStr);
    return date.toLocaleDateString(undefined, options);
  };

  const handleSelectClient = (e, clientId) => {
    setSelectedClients((prevSelected) =>
      e.target.checked ? [...prevSelected, clientId] : prevSelected.filter(id => id !== clientId)
    );
  };

  const handleSelectAll = (e) => {
    const allClientIds = getFilteredCallList().map((call) => call.id);
    setSelectedClients(e.target.checked ? allClientIds : []);
  };

  const handleBulkReassign = async () => {
    try {
      if (!bulkAssignNavigator || bulkAssignNavigator === 'all') {
        alert("Please select a navigator.");
        return;
      }

      for (let clientId of selectedClients) {
        await updateCall({
          variables: {
            input: {
              id: clientId,
              navigator: bulkAssignNavigator, // Use the bulk assign navigator
            },
          },
        });
      }

      alert("Selected clients reassigned successfully.");
      setSelectedClients([]); // Clear selection
      refetch(); // Refresh call list
    } catch (error) {
      console.error("Error during bulk reassignment:", error);
      alert("Bulk reassignment failed.");
    }
  };

  return (
    <div className="call-list-container">
      {loading || isGenerating && <LoadingSpinner />}
      <header className="clients-header">
        <h1>Call List</h1>
        {isAdmin && <button onClick={generateCallList}>Generate Call List</button>}
      </header>
      {/* Filter Control UI */}
      <div className='call-list-filter-control'>
        <h3>Filter Call List:</h3>
        <select value={callStatusFilter} onChange={(e) => setCallStatusFilter(e.target.value)}>
          <option value="all">All Clients</option>
          <option value="called">Called Clients</option>
          <option value="not_called">Not Called Clients</option>
          <option value="appointment_made">Appointment Made</option>
          <option value="no_appointment">No Appointment Made</option>
        </select>
        <select value={contactRoundFilter} onChange={(e) => setContactRoundFilter(e.target.value)}>
          <option value="all">All Rounds</option>
          <option value="1">Round 1</option>
          <option value="2">Round 2</option>
          <option value="3">Round 3</option>
        </select>
        <input
          type="text"
          placeholder="Search by name, last name, or date"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {isAdmin && (
          <select value={selectedNavigator} onChange={(e) => setSelectedNavigator(e.target.value)}>
            <option value="all">All Navigators</option>
            <option value="unassigned">Unassigned</option> {/* New Unassigned Option */}
            {navigatorOptions.map((nav) => (
              <option key={nav.id} value={nav.name}>{nav.name}</option>
            ))}
          </select>
        )}
      </div>
      {usersLoading && <p>Loading navigators...</p>}
      {usersError && <p>Error loading navigators: {usersError.message}</p>}
      {/* Call List Table */}
      <table>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                checked={selectedClients.length === getFilteredCallList().length}
                onChange={handleSelectAll}
              />
            </th>
            <th>Client Name</th>
            <th>Client Last Name</th>
            <th>Navigator</th>
            <th>Call Date</th>
            <th>Answered</th>
            <th>Appointment Made</th>
            <th>Needs Follow Up</th>
            <th>Contact Round</th>
            <th>Notes</th>
            <th>Created At</th>
            <th>Updated At</th>
          </tr>
        </thead>
        <tbody>
          {getFilteredCallList().map((call) => (
            <tr
              key={call.id}
              className={
                call.answered && call.appointmentMade && call.needsFollowUp
                  ? 'yellow-row'
                  : !call.answered
                    ? 'red-row'
                    : call.answered && call.appointmentMade && !call.needsFollowUp
                      ? 'green-row'
                      : ''
              }
              onClick={() => openModal(call)}
            >
              <td onClick={(e) => e.stopPropagation()}>
                <input
                  type="checkbox"
                  checked={selectedClients.includes(call.id)}
                  onChange={(e) => handleSelectClient(e, call.id)}
                />
              </td>
              <td>{capitalize(call.client?.name || "N/A")}</td>
              <td>{capitalize(call.client?.last_name || "N/A")}</td>
              <td>{call.navigator}</td>
              <td>{moment(call.callDate).format('MM-DD-YYYY')}</td>
              <td>{call.answered ? 'Yes' : 'No'}</td>
              <td>{call.appointmentMade ? 'Yes' : 'No'}</td>
              <td>{call.needsFollowUp ? 'Yes' : 'No'}</td>
              <td>{call.contactRound || '0'}</td>
              <td>{call.notes || 'N/A'}</td>
              <td>{formatDateTime(call.createdAt)}</td>
              <td>{formatDateTime(call.updatedAt)}</td>
            </tr>
          ))}
        </tbody>

      </table>
      {isAdmin &&
        <div className="bulk-actions">
          <select onChange={(e) => setBulkAssignNavigator(e.target.value)} value={bulkAssignNavigator}>
            <option value="all">Select Navigator</option>
            {navigatorOptions.map((nav) => (
              <option key={nav.id} value={nav.name}>{nav.name}</option>
            ))}
          </select>
          <button onClick={handleBulkReassign}>Assign Selected Clients</button>
        </div>
      }

      {/* Modal for Editing Call Information */}
      {selectedCall && (
        <div className="call-list-overlay" onClick={closeModal}>
          <div className="call-list-overlay-content" onClick={(e) => e.stopPropagation()}>
            <h3>Edit Call Information</h3>
            <p><strong>Client Name:</strong> {capitalize(selectedCall.client?.name) || 'N/A'}</p>
            <p><strong>Client Last Name:</strong> {capitalize(selectedCall.client?.last_name) || 'N/A'}</p>
            <p><strong>Call Date:</strong> {selectedCall.callDate || 'Not Contacted'}</p>
            <p><strong>Contact Round:</strong> {callInfo.contactRound || '0'}</p>
            <label>
              Answered:
              <select name="answered" value={callInfo.answered} onChange={handleInputChange}>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </label>
            <label>
              Appointment Made on Picktime?:
              <select name="appointmentMade" value={callInfo.appointmentMade} onChange={handleInputChange}>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </label>
            <label>
              Needs Follow Up:
              <select name="needsFollowUp" value={callInfo.needsFollowUp} onChange={handleInputChange}>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </label>
            <label>
              Notes:
              <textarea
                name="notes"
                value={callInfo.notes || ''}
                onChange={handleInputChange}
              />
            </label>
            <p><strong>Created At:</strong> {formatDateTime(selectedCall.createdAt)}</p>
            <p><strong>Updated At:</strong> {formatDateTime(selectedCall.updatedAt)}</p>
            <button onClick={handleUpdateCall}>Update Call</button>
            <button onClick={closeModal}>Close</button>
            {isAdmin && (
              <button onClick={handleDeleteCall} style={{ backgroundColor: '#f44336', color: 'white', marginLeft: '10px' }}>
                Delete Call
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CallList;
