import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { LIST_COUNTIES, listUsersByTeamOne } from '../../graphql/queries';
import {
  CREATE_COUNTY,
  CREATE_CITY,
  CREATE_LOCATION,
  CREATE_NAVIGATOR,
  UPDATE_LOCATION,
  UPDATE_NAVIGATOR,
  UPDATE_COUNTY,
  DELETE_COUNTY,
  DELETE_CITY,
  DELETE_LOCATION,
  UPDATE_CITY
} from '../../graphql/mutations';
import { fetchAuthSession } from 'aws-amplify/auth';
import { FaTrashRestoreAlt, FaChevronUp, FaChevronDown, FaEdit, FaPlus } from 'react-icons/fa';
import './Counties.css';

const Counties = () => {
  const { loading: countiesLoading, error: countiesError, data, refetch } = useQuery(LIST_COUNTIES);
  const { loading: usersLoading, error: usersError, data: usersData } = useQuery(listUsersByTeamOne);
  const [createCounty] = useMutation(CREATE_COUNTY);
  const [createCity] = useMutation(CREATE_CITY);
  const [createLocation] = useMutation(CREATE_LOCATION);
  const [createNavigator] = useMutation(CREATE_NAVIGATOR);
  const [updateLocation] = useMutation(UPDATE_LOCATION);
  const [updateNavigator] = useMutation(UPDATE_NAVIGATOR);
  const [updateCounty] = useMutation(UPDATE_COUNTY);
  const [updateCity] = useMutation(UPDATE_CITY);
  const [deleteCounty] = useMutation(DELETE_COUNTY);
  const [deleteCity] = useMutation(DELETE_CITY);
  const [deleteLocation] = useMutation(DELETE_LOCATION);
  const [locationInputFields, setLocationInputFields] = useState({});
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false); // To track if the form is visible
  const [editMode, setEditMode] = useState(null); // State to track if a county or city is in edit mode
  const [newCity, setNewCity] = useState(''); // Track the new city name
  const [newLocationName, setNewLocationName] = useState('');  // Track new location name
  const [newLocationAddress, setNewLocationAddress] = useState('');  // Track new location address
  const [editedFields, setEditedFields] = useState({}); // State to track edited values

  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible); // Toggle visibility
  };
  
  const toggleEditMode = (countyId) => {
    if (editMode === countyId) {
      setEditMode(null); // Exit edit mode
      setEditedFields({}); // Reset edited fields
    } else {
      setEditMode(countyId); // Enter edit mode for this county
    }
  };

  // Handle changes to the editable fields
  const handleEditChange = (e, field, itemId) => {
    const { value } = e.target;
    setEditedFields(prev => ({
      ...prev,
      [itemId]: {
        ...prev[itemId],
        [field]: value,
      },
    }));
  };

  const [newCountyData, setNewCountyData] = useState({
    county: '',
    city: '',
    locationName: '',
    locationAddress: '',
    navigator: ''
  });
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const navigatorOptions = usersData?.listUsers?.items?.map(user => ({
    id: user.id,
    name: `${capitalizeFirstLetter(user.first_name)} ${capitalizeFirstLetter(user.last_name)}`
  })) || [];

  const [userGroups, setUserGroups] = useState([]);
  const [navigatorInputFields, setNavigatorInputFields] = useState({});

  const fetchUserDetails = async () => {
    try {
      const session = await fetchAuthSession();
      const groups = session.tokens.idToken.payload['cognito:groups'] || [];
      setUserGroups(groups);
    } catch (error) {
      console.log('User not authenticated', error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCountyData({ ...newCountyData, [name]: value });
  };

  const handleLocationInputChange = (e, locationId) => {
    const { name, value } = e.target;

    setLocationInputFields(prev => ({
      ...prev,
      [locationId]: {
        ...prev[locationId],
        [name]: value
      }
    }));
  };

  const handleAddNavigatorField = (locationId) => {
    setNavigatorInputFields(prev => ({
      ...prev,
      [locationId]: [
        ...(prev[locationId] || []),
        { id: Date.now(), name: '' }
      ]
    }));
  };

  const handleNavigatorInputChange = (e, locationId, navigatorId) => {
    const { value } = e.target;

    setNavigatorInputFields(prev => ({
      ...prev,
      [locationId]: prev[locationId].map(navigator =>
        navigator.id === navigatorId
          ? { ...navigator, name: value }
          : navigator
      )
    }));
  };

  const handleRemoveNavigator = async (locationId, navigatorId) => {
    try {
      await updateNavigator({
        variables: {
          input: {
            id: navigatorId,
            locationID: "UNASSIGNED",
          },
        },
        refetchQueries: [{ query: LIST_COUNTIES }],
      });

      const updatedNavigators = navigatorInputFields[locationId]?.filter(nav => nav.id !== navigatorId);
      setNavigatorInputFields(prev => ({
        ...prev,
        [locationId]: updatedNavigators,
      }));

    } catch (error) {
      console.error('Error removing navigator:', error);
    }
  };

  const handleSaveLocation = async (locationId) => {
    try {
      const updatedLocation = locationInputFields[locationId];

      await updateLocation({
        variables: {
          input: {
            id: locationId,
            name: updatedLocation.locationName,
            address: updatedLocation.locationAddress,
          },
        },
        refetchQueries: [{ query: LIST_COUNTIES }],
      });

      setUpdateSuccess(true);

      setLocationInputFields(prev => ({
        ...prev,
        [locationId]: {}
      }));

      setTimeout(() => {
        setUpdateSuccess(false);
      }, 3000);
    } catch (error) {
      console.error('Error updating location:', error);
    }
  };

  // Helper function to check if changes are made
  const isSaveDisabled = (countyId) => {
    const editedCounty = editedFields[countyId];
    return !editedCounty || !editedCounty.name;
  };

  // Helper function to check if add location is disabled
  const isAddLocationDisabled = () => {
    return !newLocationName.trim() || !newLocationAddress.trim();
  };

  // Helper function to check if add city is disabled
  const isAddCityDisabled = () => {
    return !newCity.trim();
  };

  
  // Function to add a new city
  const handleAddCity = async (countyId) => {
    if (newCity.trim()) {
      try {
        await createCity({
          variables: {
            input: { name: newCity, countyID: countyId }
          }
        });
        refetch(); // Refresh data after city is added
        setNewCity(''); // Reset input field
      } catch (error) {
        console.error('Error creating city:', error);
      }
    }
  };

  
  const handleAddLocation = async (cityId) => {
    if (newLocationName.trim() && newLocationAddress.trim()) {
      try {
        await createLocation({
          variables: {
            input: {
              name: newLocationName,
              address: newLocationAddress,
              cityID: cityId
            }
          }
        });
        refetch(); // Refresh data after location is added
        setNewLocationName(''); // Reset location name input
        setNewLocationAddress(''); // Reset location address input
      } catch (error) {
        console.error('Error creating location:', error);
      }
    } else {
      console.error('Both location name and address are required.');
    }
  };



  const handleSaveCounty = async (countyId) => {
    try {
      const updatedCounty = editedFields[countyId];
      await updateCounty({
        variables: {
          input: {
            id: countyId,
            name: updatedCounty.name,
          }
        },
        refetchQueries: [{ query: LIST_COUNTIES }],
      });
      setEditMode(null);
      setEditedFields({});
    } catch (error) {
      console.error('Error updating county:', error);
    }
  };

  const handleDeleteCounty = async (countyId) => {
    try {
      const county = data.listCounties.items.find(c => c.id === countyId);

      if (county && county.cities.items.length > 0) {
        for (const city of county.cities.items) {
          for (const location of city.locations.items) {
            for (const navigator of location.navigators.items) {
              await updateNavigator({
                variables: {
                  input: {
                    id: navigator.id,
                    locationID: "UNASSIGNED",
                  },
                },
              });
            }
            await deleteLocation({ variables: { id: location.id } });
          }
          await deleteCity({ variables: { id: city.id } });
        }
      }

      await deleteCounty({ variables: { id: countyId } });
      await refetch();
    } catch (error) {
      console.error('Error deleting county:', error);
    }
  };

  const handleAddCounty = async () => {
    try {
      let county = data.listCounties?.items?.find(c => c.name === newCountyData.county);
      let countyID = county ? county.id : null;

      if (!countyID) {
        const countyResult = await createCounty({
          variables: {
            input: { name: newCountyData.county }
          }
        });
        countyID = countyResult.data.createCounty.id;
      }

      let city = county?.cities?.items?.find(city => city.name === newCountyData.city);
      let cityID = city ? city.id : null;

      if (!cityID) {
        const cityResult = await createCity({
          variables: {
            input: { name: newCountyData.city, countyID }
          }
        });
        cityID = cityResult.data.createCity.id;
      }

      const locationResult = await createLocation({
        variables: {
          input: {
            name: newCountyData.locationName,
            address: newCountyData.locationAddress,
            cityID
          }
        }
      });
      const locationID = locationResult.data.createLocation.id;

      if (newCountyData.navigator) {
        await createNavigator({
          variables: {
            input: {
              name: newCountyData.navigator,
              locationID
            }
          },
          refetchQueries: [{ query: LIST_COUNTIES }],
        });
      }

      setNewCountyData({
        county: '',
        city: '',
        locationName: '',
        locationAddress: '',
        navigator: ''
      });
    } catch (error) {
      console.error('Error adding County:', error);
    }
  };

  if (countiesLoading || usersLoading) return <p>Loading...</p>;
  if (countiesError || usersError) return <p>Error: {countiesError?.message || usersError?.message}</p>;

  const userRole = userGroups.includes('Admin') ? 'Admin' : userGroups.includes('Manager') ? 'Manager' : 'User';

  return (
    <div className="counties">
      <header className="counties-header">
        <h1>Counties Management</h1>
      </header>

      <div className="add-county-section-toggle" onClick={toggleFormVisibility}>
        <h3>Add New County</h3>
        {isFormVisible ? <FaChevronUp className="toggle-arrow open" /> : <FaChevronDown className="toggle-arrow" />}
      </div>

      {(userRole === 'Manager' || userRole === 'Admin') && (
        <div className={`add-county-section-content ${isFormVisible ? 'show' : ''}`}>
          <h3>Add New County</h3>
          <input
            type="text"
            name="county"
            value={newCountyData.county}
            placeholder="County"
            onChange={handleInputChange}
          />
          <input
            type="text"
            name="city"
            value={newCountyData.city}
            placeholder="City"
            onChange={handleInputChange}
          />
          <input
            type="text"
            name="locationName"
            value={newCountyData.locationName}
            placeholder="Location Name"
            onChange={handleInputChange}
          />
          <input
            type="text"
            name="locationAddress"
            value={newCountyData.locationAddress}
            placeholder="Location Address"
            onChange={handleInputChange}
          />
          <select className='navigator_selector' required name="navigator" value={newCountyData.navigator} onChange={handleInputChange}>
            <option value="">Select Navigator...</option>
            {navigatorOptions.map(option => (
              <option key={option.id} value={option.name}>{option.name}</option>
            ))}
          </select>
          <button className='AddCountyBtn' onClick={handleAddCounty}>Add County</button>
        </div>
      )}
      {[...data?.listCounties?.items || []]
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((county) => (
          <div key={county.id} className="county-section">
            <div className='county-title'>
              {editMode === county.id ? (
                <input
                  type="text"
                  value={editedFields[county.id]?.name || county.name}
                  onChange={(e) => handleEditChange(e, 'name', county.id)}
                />
              ) : (
                <h2>{county.name}</h2>
              )}
              <div className="action-buttons">
                <button className="EditBtn" onClick={() => toggleEditMode(county.id)} title="Edit County">
                  <FaEdit />
                </button>
                {editMode === county.id && (
                  <>
                    <button className='SaveBtn' onClick={() => handleSaveCounty(county.id)} disabled={isSaveDisabled(county.id)}>Save</button>
                    <div className="add-city-section">
                      <input
                        type="text"
                        value={newCity}
                        placeholder="Enter new city"
                        onChange={(e) => setNewCity(e.target.value)}
                      />
                      <button className="AddCityBtn" onClick={() => handleAddCity(county.id)} disabled={isAddCityDisabled()}>
                        <FaPlus /> Add City
                      </button>
                    </div>
                  </>
                )}
                <button className="DeleteCountyBtn" onClick={() => handleDeleteCounty(county.id)} title="Delete County">
                  <FaTrashRestoreAlt />
                </button>
              </div>

            </div>

            {county?.cities?.items?.map((city) => (
              <div key={city.id} className="city-section">
                {editMode === city.id ? (
                  <>
                    <input
                      type="text"
                      value={editedFields[city.id]?.name || city.name}
                      onChange={(e) => handleEditChange(e, 'name', city.id)}
                    />
                    <button className='SaveBtn' onClick={() => handleSaveCounty(county.id)} disabled={isSaveDisabled(city.id)}>Save</button>
                  </>
                ) : (
                  <h3>{city.name}</h3>
                )}
                {editMode === county.id && (
                  <div className="add-location-section">
                    <input
                      type="text"
                      value={newLocationName}
                      placeholder="Enter new location name"
                      onChange={(e) => setNewLocationName(e.target.value)}
                    />
                    <input
                      type="text"
                      value={newLocationAddress}
                      placeholder="Enter new location address"
                      onChange={(e) => setNewLocationAddress(e.target.value)}
                    />
                    <button className="AddLocationBtn" onClick={() => handleAddLocation(city.id)} disabled={isAddLocationDisabled()}>
                      <FaPlus /> Add Location
                    </button>
                  </div>
                )}
                {city?.locations?.items?.map((location) => (
                  <div key={location.id} className="location-card">
                    <div className="location-header">
                      <div>
                        {editMode === county.id ? (
                          <>
                            <input
                              type="text"
                              name="locationName"
                              value={locationInputFields[location.id]?.locationName !== undefined ? locationInputFields[location.id]?.locationName : location.name}
                              onChange={(e) => handleLocationInputChange(e, location.id)}
                            />
                            <input
                              type="text"
                              name="locationAddress"
                              value={locationInputFields[location.id]?.locationAddress !== undefined ? locationInputFields[location.id]?.locationAddress : location.address}
                              onChange={(e) => handleLocationInputChange(e, location.id)}
                            />
                          </>
                        ) : (
                          <>
                            <p className="location-name"><strong>Location:</strong> {location.name}</p>
                            <p className="location-address"><strong>Address:</strong> {location.address}</p>
                          </>
                        )}
                      </div>
                      <div>
                        {editMode === county.id && (
                          <button className='AddNavigatorBtn' onClick={() => handleAddNavigatorField(location.id)}>
                            <FaPlus /> Add Navigator
                          </button>
                        )}
                      </div>
                    </div>
                    {updateSuccess && <p className="success-message">Database updated successfully!</p>}
                    <ul>
                      <strong>Navigators:</strong>
                      {location.navigators?.items?.map((navigator) => (
                        <li key={navigator.id}>
                          {navigator.name}
                          <button
                            className='DeleteBtn'
                            onClick={() => handleRemoveNavigator(location.id, navigator.id)}
                          >
                            <FaTrashRestoreAlt />
                          </button>
                        </li>
                      ))}
                      {navigatorInputFields[location.id]?.map((navigator) => (
                        <li key={navigator.id}>
                          <select
                            className='navigator_selector'
                            required
                            name="navigator"
                            value={navigator.name}
                            onChange={(e) => handleNavigatorInputChange(e, location.id, navigator.id)}
                          >
                            <option value="">Select Navigator...</option>
                            {navigatorOptions.map(option => (
                              <option key={option.id} value={option.name}>{option.name}</option>
                            ))}
                          </select>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
    </div>
  );
};

export default Counties;
