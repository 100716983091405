import React, { useState } from 'react';
import './CreateUsers.css';
import { fetchAuthSession } from 'aws-amplify/auth';
import AWS from 'aws-sdk'; // Import AWS SDK
import awsExports from '../../../aws-exports'; // Import aws-exports.js to get the UserPoolId

export default function CreateUsers({ onClose }) {
    const [email, setEmail] = useState(''); // State for email input
    const [message, setMessage] = useState(''); // State for success or error messages
    // Extract the UserPoolId and region from aws-exports.js
    const userPoolId = awsExports.aws_user_pools_id;
    const region = awsExports.aws_project_region;

    // Function to generate a random 9-character temporary password
    const generatePassword = () => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*';
        let password = '';
        for (let i = 0; i < 9; i++) {
            password += chars[Math.floor(Math.random() * chars.length)];
        }
        return password;
    };

    // Function to handle user creation via AdminCreateUser
    const handleCreateUser = async (event) => {
        event.preventDefault(); // Prevent default form submission

        const generatedPassword = generatePassword(); // Generate temporary password

        try {
            // Get the AWS credentials from Auth
            const credentials = await fetchAuthSession();
            const { accessKeyId, secretAccessKey, sessionToken } = credentials.credentials;

            // Set up AWS CognitoIdentityServiceProvider with temporary credentials
            AWS.config.update({
                region: region, // Replace with your region
                credentials: {
                    accessKeyId,
                    secretAccessKey,
                    sessionToken,
                },
            });

            const cognito = new AWS.CognitoIdentityServiceProvider();

            // AdminCreateUser params
            const params = {
                UserPoolId: userPoolId, // Replace with your Cognito User Pool ID
                Username: email, // The email as the username
                TemporaryPassword: generatedPassword,
                UserAttributes: [
                    {
                        Name: 'email',
                        Value: email,
                    },
                    {
                        Name: 'email_verified',
                        Value: 'true',
                    },
                ],
            };

            // Call AdminCreateUser API to create the user
            const result = await cognito.adminCreateUser(params).promise();

            // Success message
            setMessage(`User created successfully! A temporary password has been generated and should be sent to ${email}: ${generatedPassword}.`);

        } catch (error) {
            // Error handling
            console.error('Error creating user:', error);
            setMessage(`Error creating user: ${error.message}`);
        }
    };

    return (
        <div className="create-users-modal">
            <div className="create-users-content">
                <h2>Create Compass User</h2>
                <form onSubmit={handleCreateUser}>
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <input type="submit" value="Create User" />
                </form>
                {message && <p>{message}</p>}
                <button className='man-u-close' onClick={onClose}>X</button>
            </div>
        </div>
    );
}
