import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { listSupplies, listUsers } from '../../../graphql/queries';
import { createSupplies, deleteSupplies, updateSupplies } from '../../../graphql/mutations';
import './Office_Supplies.css';
import XLSX from 'xlsx-js-style';
import { v4 as uuidv4 } from 'uuid';
import { FaFileExcel } from 'react-icons/fa';

const Office_Supplies = () => {

    const [supplies, setSupplies] = useState([]);
    const [nextToken, setNextToken] = useState(null);
    const [showSuppliesAddOverlay, setShowSuppliesAddOverlay] = useState(false);
    const [selectedSupplies, setSelectedSupplies] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    const [newSupplies, setNewSupplies] = useState({

        id: '',
        name: '',
        tagId: '',
        price: '',
        store: '',
        purchase_date: '',
        assignment_date: '',
        available: '',
        assigned_to: '',
        location: '',
        team: '',
    });

    const [sortConfig, setSortConfig] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchField, setSearchField] = useState('id');
    const [duplicateError, setDuplicateError] = useState('');
    const [priceError, setPriceError] = useState('');
    const { loading1, error: userError, data: userData } = useQuery(listUsers);


    const handleSaveAsExcel = async () => {
        try {
            const suppliesToExport = filteredSuppliess.length > 0 ? filteredSuppliess : supplies;

            if (suppliesToExport.length > 0) {
                const sortedsupplies = [...suppliesToExport].sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp));

                const worksheetData = sortedsupplies.map((supplies, index) => ({
                    '#': index + 1, // Agregar el número de fila
                    'TAG': supplies.tagId,
                    'Name': supplies.name,
                    'Assigned To': supplies.assigned_to,
                    'Available': supplies.available ? 'Yes' : 'No',
                }));

                // Convert JSON to sheet starting from row 2
                const worksheet = XLSX.utils.json_to_sheet([]);
                let endCol = 0;

                XLSX.utils.sheet_add_aoa(worksheet, [['Office Supplies']], { origin: 'A1' });

                if (worksheetData.length > 0) {
                    // Agrega los datos a partir de la fila A2
                    XLSX.utils.sheet_add_json(worksheet, worksheetData, { origin: 'A2', skipHeader: false });

                    // Define el rango de referencia (!ref)
                    const endRow = worksheetData.length + 1; // +1 para el título en A1
                    const endCol = Object.keys(worksheetData[0]).length - 1;
                    worksheet['!ref'] = XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: endRow, c: endCol } });
                } else {
                    console.error("No hay datos para exportar.");
                    return;
                }

                // Merge title cells across the columns
                worksheet['!merges'] = worksheet['!merges'] || [];
                worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 4 } }); // A1 to H1 for the added column

                // Style the title row
                worksheet['A1'].s = {
                    font: {
                        bold: true,
                        sz: 20, // Font size
                        color: { rgb: '1F4E79' } // Dark blue text color
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center'
                    }
                };

                // Apply styling for the header row (now starting at row 2)
                const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
                for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                    const cellAddress = XLSX.utils.encode_cell({ r: 1, c: col }); // Header row is now row 1
                    if (!worksheet[cellAddress]) continue;
                    worksheet[cellAddress].s = {
                        fill: {
                            fgColor: { rgb: '1F4E79' } // Dark blue background
                        },
                        font: {
                            bold: true,
                            color: { rgb: 'FFFFFF' } // White text
                        },
                        alignment: {
                            horizontal: 'center'
                        },
                        border: {
                            top: { style: 'thin', color: { rgb: '000000' } },
                            left: { style: 'thin', color: { rgb: '000000' } },
                            bottom: { style: 'thin', color: { rgb: '000000' } },
                            right: { style: 'thin', color: { rgb: '000000' } },
                        }
                    };
                }

                // Apply alternating row colors and borders starting from row 3
                for (let row = 2; row <= headerRange.e.r; row++) {
                    const isEvenRow = row % 2 === 0;
                    for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
                        if (!worksheet[cellAddress]) continue;

                        worksheet[cellAddress].s = {
                            fill: {
                                fgColor: { rgb: isEvenRow ? 'D3D3D3' : 'FFFFFF' } // Light gray for even rows, white for odd rows
                            },
                            border: {
                                top: { style: 'thin', color: { rgb: '000000' } },
                                left: { style: 'thin', color: { rgb: '000000' } },
                                bottom: { style: 'thin', color: { rgb: '000000' } },
                                right: { style: 'thin', color: { rgb: '000000' } },
                            },
                            alignment: {
                                horizontal: 'center'
                            }
                        };
                    }
                }

                // Adjust columns to fit content
                // Adjust columns to fit content
                worksheet['!cols'] = [
                    { wch: 5 }, // Width for Row Number
                    { wch: 10 },
                    { wch: 15 },
                    { wch: 25 },
                    { wch: 10 },

                ];

                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'supplies');

                // Export the file
                XLSX.writeFile(workbook, 'suppliesData.xlsx');
            } else {
                console.error('No data to export');
            }
        } catch (error) {
            console.error('Error exporting data to Excel:', error);
        }
    };


    const handleEditSupplies = (scanner) => {
        setSelectedSupplies(scanner);
        setIsEditing(true);
        setShowSuppliesAddOverlay(true);
    };

    const { loading, data, fetchMore } = useQuery(listSupplies, {
        variables: { limit: 100 },
    });

    const [addSupplies] = useMutation(createSupplies, {
        refetchQueries: [{ query: listSupplies, variables: { limit: 100 } }]
    });

    const [removeSupplies] = useMutation(deleteSupplies, {
        refetchQueries: [{ query: listSupplies, variables: { limit: 100 } }]
    });
    const [updSupplies] = useMutation(updateSupplies, {
        refetchQueries: [{ query: listSupplies, variables: { limit: 100 } }]
    });

    const [fetchAllSuppliess, { loading: countLoading, data: AllSuppliessData }] = useLazyQuery(listSupplies, {
        variables: { limit: 100 },
        fetchPolicy: "network-only",
    });


    useEffect(() => {
        const fetchAllSuppliess = async () => {
            let AllSuppliess = [];
            let currentNextToken = null;

            do {
                const { data } = await fetchMore({
                    variables: { limit: 100, nextToken: currentNextToken },
                });

                AllSuppliess = [...AllSuppliess, ...data.listSupplies.items];
                currentNextToken = data.listSupplies.nextToken;
            } while (currentNextToken);

            setSupplies(AllSuppliess);
            setNextToken(currentNextToken);
        };

        if (data) {
            fetchAllSuppliess();
        }
    }, [data, fetchMore]);

    const clearNewSupplies = () => {
        setNewSupplies({
            id: '',
            name: '',
            tagId: '',
            price: '',
            store: '',
            purchase_date: '',
            assignment_date: '',
            available: '',
            assigned_to: '',
            location: '',
            team: '',

        });
    };

    // Define loadMore function
    const loadMore = () => {
        fetchMore({
            variables: { limit: 100, nextToken },
            updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;
                setNextToken(fetchMoreResult.listSupplies.nextToken);
                return {
                    listSupplies: {
                        ...fetchMoreResult.listSupplies,
                        items: [...prevResult.listSupplies.items, ...fetchMoreResult.listSupplies.items]
                    }
                };
            }
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let formattedValue = value;

        if (isEditing) {
            setSelectedSupplies({
                ...selectedSupplies,
                [name]: formattedValue
            });
        } else {
            setNewSupplies({
                ...newSupplies,
                id: uuidv4(),
                [name]: formattedValue
            });
        }
    };

    const handleAddSupplies = () => {

        const formattedPurchase = newSupplies.purchase_date
            ? new Date(newSupplies.purchase_date).toISOString().split('T')[0]
            : null;
        const formattedAssignment = newSupplies.assignment_date
            ? new Date(newSupplies.assignment_date).toISOString().split('T')[0]
            : null;

        // Validate that the price contains only digits and is a valid number
        let priceValue = null;

        if (newSupplies.price !== "" && newSupplies.price !== null && newSupplies.price != undefined) {
            priceValue = parseFloat(newSupplies.price);

            if (isNaN(priceValue)) {
                setPriceError('Price must be a valid number.');
                return;
            }
        }
        setPriceError('');

        // If all validations pass, proceed with adding the supplies to the database
        addSupplies({
            variables: { input: { ...newSupplies, price: priceValue, purchase_date: formattedPurchase, assignment_date: formattedAssignment } }
        })
            .then(() => {
                window.location.reload();
            }).catch(error => {
                console.error("Error adding supplies:", error);
            });
    };

    const handleDeleteSupplies = () => {

        removeSupplies({
            variables: { input: { id: selectedSupplies.id } }
        }).then(() => {
            window.location.reload();
        }).catch(error => {
            console.error("Error deleting Supplies", error);
        });

    };

    const handleUpdateSupplies = async () => {

        const formattedData_purchase = selectedSupplies.purchase_date ? new Date(selectedSupplies.purchase_date).toISOString().split('T')[0] : null;
        const formattedData_assignment = selectedSupplies.assignment_date ? new Date(selectedSupplies.assignment_date).toISOString().split('T')[0] : null;

        //Check Price
        let priceValue = null;
        if (selectedSupplies.price !== "" && selectedSupplies.price !== null && selectedSupplies.price != undefined) {
            priceValue = parseFloat(selectedSupplies.price);

            if (isNaN(priceValue)) {
                setPriceError('Price must be a valid number.');
                return;
            }
        }
        setPriceError('');

        updSupplies({
            variables: {
                input: {
                    id: selectedSupplies.id,
                    name: selectedSupplies.name,
                    tagId: selectedSupplies.tagId,
                    price: priceValue,
                    store: selectedSupplies.store,
                    purchase_date: formattedData_purchase,
                    assignment_date: formattedData_assignment,
                    available: selectedSupplies.available,
                    assigned_to: selectedSupplies.assigned_to,
                    location: selectedSupplies.location,
                    team: selectedSupplies.team,
                }
            }
        }).then(() => {
            setIsEditing(true);
            setShowSuppliesAddOverlay(false);
            setPriceError('');
        }).catch(error => {
            console.error("Error updating Supplies:", error);
        });
    };

    const handleSelectSupplies = (supplies) => {

        for (const [key, value] of Object.entries(supplies)) {
            console.log(`${key}: ${typeof value}`);
        }
        const formatDateForInput = (dateString) => {
            if (!dateString) return ''; // Return empty string if the date is not provided
            const date = new Date(dateString);
            return date.toISOString().split('T')[0]; // Convert to YYYY-MM-DD format
        };

        setNewSupplies({
            id: supplies.id,
            name: supplies.name,
            tagId: supplies.tagId,
            price: supplies.price,
            store: supplies.store,
            purchase_date: supplies.purchase_date,
            assignment_date: supplies.assignment_date,
            available: supplies.available,
            assigned_to: supplies.assigned_to,
            location: supplies.location,
            team: supplies.team,
        });
        setShowSuppliesAddOverlay(true);
        setIsEditing(true);
        setSelectedSupplies(supplies);
    };

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedSuppliess = React.useMemo(() => {
        let sortableSuppliess = [...supplies];
        if (sortConfig !== null) {
            sortableSuppliess.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableSuppliess;
    }, [supplies, sortConfig]);

    //listUsers 
    const users = React.useMemo(() => {
        if (userData && userData.listUsers) {
            return userData.listUsers.items || [];
        }
        return [];
    }, [userData]);
    if (userError) return <p>Error loading users: {userError.message}</p>;
    if (loading1) return <p>Error loading users: {userError.message}</p>;

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredSuppliess = sortedSuppliess.filter(supplies => {
        let fieldValue = supplies[searchField].toString().toLowerCase();

        // Convertir "true" a "yes" y "false" a "no"
        if (fieldValue === "true") {
            fieldValue = "yes";
        } else if (fieldValue === "false") {
            fieldValue = "no";
        }

        return fieldValue.includes(searchQuery.toLowerCase());
    });
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(dateString);
        const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

        return localDate.toLocaleDateString(undefined, options);
    };

    const formatPrice = (price) => {
        if (price === null || price === undefined || price === '') {
            return '';
        }
        const parsedPrice = parseFloat(price);
        return isNaN(parsedPrice) ? '' : parsedPrice.toFixed(2);
    };

    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };


    return (
        <div className="supplies-page" >
            <header className="inventory-header">
                <h1>Office Supplies</h1>
            </header>
            <div className="inventory-search">
                <button onClick={handleSaveAsExcel}> <FaFileExcel /> Export </button>

                <input
                    type="text"
                    placeholder="Search by Store, Available, Assigned to"
                    value={searchQuery}
                    onChange={handleSearch}
                />
                <select onChange={(e) => setSearchField(e.target.value)} defaultValue="">
                    <option value="" disabled hidden>Select Option</option>
                    <option value="tagId">TAG</option>
                    <option value="available">Available</option>
                    <option value="store">Store</option>
                    <option value="assigned_to">Assigned to</option>
                    <option value="location">Location</option>
                </select>
            </div>


            <button className="add-supplies-btn" onClick={() => {
                clearNewSupplies();
                setSelectedSupplies(null);
                setIsEditing(false);
                setShowSuppliesAddOverlay(true);
            }}>+ Add New Office Supplies</button>

            <div className="supplies-table-container">
                <table className="supplies-table">

                    <thead>
                        <tr>
                            <th>#</th> {/* Columna para contar las filas */}
                            <th onClick={() => requestSort('name')}>
                                Name
                                {sortConfig?.key === 'name' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('tagId')}>
                                TAG
                                {sortConfig?.key === 'tagId' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('price')}>
                                Price
                                {sortConfig?.key === 'price' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('store')}>
                                Store
                                {sortConfig?.key === 'store' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('purchase_date')}>
                                Purchase Date
                                {sortConfig?.key === 'purchase_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('assignment_date')}>
                                Assignment Date
                                {sortConfig?.key === 'assignment_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('available')}>
                                Available
                                {sortConfig?.key === 'available' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('assigned_to')}>
                                Assigned to
                                {sortConfig?.key === 'assigned_to' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('location')}>
                                Location
                                {sortConfig?.key === 'location' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('team')}>
                                Team
                                {sortConfig?.key === 'team' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th >Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredSuppliess.map((supplies, index) => (
                            <tr key={supplies.id} onClick={() => handleEditSupplies(supplies)} >
                                <td>{index + 1}</td> {/* Número de fila */}
                                <td className="supplies-name">{supplies.name}</td>
                                <td className="supplies-tagId">{supplies.tagId}</td>
                                <td className="supplies-price">${formatPrice(supplies.price)}</td>
                                <td className="supplies-store">{supplies.store}</td>
                                <td className="supplies-purchase_date">{supplies.purchase_date ? formatDate(supplies.purchase_date) : 'No Date'}</td>
                                <td className="supplies-assignment_date">{supplies.assignment_date ? formatDate(supplies.assignment_date) : 'No Date'}</td>
                                <td className="supplies-available">{supplies.available ? 'Yes' : 'No'} </td>
                                <td className="supplies-assigned_to">{supplies.assigned_to}</td>
                                <td className="supplies-location">{supplies.location}</td>
                                <td className="supplies-team">{supplies.team}</td>
                                <td className='supplies-table'>
                                    <button className="delete-supplies-btn" onClick={() => handleSelectSupplies(supplies)}>Delete/Update</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {nextToken && !loading && (
                <button onClick={loadMore}>Load More</button>
            )}


            {loading && <p>Loading...</p>}

            {showSuppliesAddOverlay && (

                <div className="supplies_add_overlay">
                    <div className="overlay-content-inventory">
                        <div className="header-row">
                            <h2 className="header-title">{isEditing ? 'Edit Office Supplies' : 'Add New Office Supplies'}</h2>
                            <button onClick={() => {
                                setShowSuppliesAddOverlay(false);
                                clearNewSupplies();
                                setSelectedSupplies(null);
                                setIsEditing(false);
                            }}>X</button>
                        </div>
                        <label>Name:</label><input name="name" placeholder="name" value={isEditing ? selectedSupplies.name : newSupplies.name} onChange={handleInputChange} required />
                        <label>TAG:</label><input name="tagId" placeholder="TAG" value={isEditing ? selectedSupplies.tagId : newSupplies.tagId} onChange={handleInputChange} />
                        <label>Price:</label><input name="price" placeholder="price" value={isEditing ? selectedSupplies.price : newSupplies.price} onChange={handleInputChange} />
                        <label>Store:</label><input name="store" placeholder="store" value={isEditing ? selectedSupplies.store : newSupplies.store} onChange={handleInputChange} />

                        <label htmlFor="purchase_date">Purchase Date:</label>
                        <input
                            type="Date"
                            id="purchase_date"
                            name="purchase_date"
                            value={isEditing ? selectedSupplies.purchase_date : newSupplies.purchase_date}
                            onChange={handleInputChange}

                        />
                        <label htmlFor="assignment_date">Assigned Date:</label>
                        <input
                            type="Date"
                            id="assignment_date"
                            name="assignment_date"
                            value={isEditing ? selectedSupplies.assignment_date : newSupplies.assignment_date}
                            onChange={handleInputChange}

                        />
                        <label>Available:</label>

                        <select name="available" placeholder="available" value={isEditing ? selectedSupplies.available : newSupplies.available} onChange={handleInputChange} >
                            <option value="" disabled hidden className="placeholder-option">Select Y/N</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>

                        <label>Assigned To:</label>
                        <select
                            name="assigned_to"
                            value={isEditing ? selectedSupplies.assigned_to : newSupplies.assigned_to}
                            onChange={handleInputChange}
                        >
                            <option value="">Select an Employee</option>
                            {users.length > 0 ? (
                                [...users].sort((a, b) => {
                                    const nameA = `${a.first_name} ${a.last_name}`.toLowerCase();
                                    const nameB = `${b.first_name} ${b.last_name}`.toLowerCase();
                                    return nameA.localeCompare(nameB);
                                }).map(user => (
                                    <option key={user.id} value={`${capitalize(user.first_name)} ${capitalize(user.last_name)}`}>
                                        {capitalize(user.first_name)} {capitalize(user.last_name)}
                                    </option>
                                ))
                            ) : (

                                <option value="">No users available</option>
                            )}
                            <option value="n/a">N/A</option> {/*N/A */}
                        </select>
                        <label>Location:</label><input name="location" placeholder="location" value={isEditing ? selectedSupplies.location : newSupplies.location} onChange={handleInputChange} />
                        <label>Team:</label><input name="team" placeholder="team" value={isEditing ? selectedSupplies.team : newSupplies.team} onChange={handleInputChange} />
                        {duplicateError && <p className="error">{duplicateError}</p>} {/* Display duplicate error message */}
                        {priceError && <p className="error">{priceError}</p>} {/* Display price error message */}


                        <div className="button-group">
                            <button className="overlay-add-supplies" onClick={isEditing ? handleUpdateSupplies : handleAddSupplies} >
                                {isEditing ? 'Update Office Supplies' : 'Add Office Supplies'}
                            </button>
                            {isEditing && (
                                <button
                                    className="overlay-delet-supplies"
                                    onClick={() => {
                                        if (window.confirm('Are you sure you want to delete this supplies?')) {
                                            handleDeleteSupplies();
                                        }
                                    }}
                                >
                                    Delete Office Supplies
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}

        </div>


    );

};

export default Office_Supplies;
