import React from 'react';

const Section2 = ({ formData, handleInputChange }) => (
  <>
    {/* Section 2 */}
    <div className="form-section flyer-section">
      <div className="flyer-header">Flyers or Calls</div>
      <label className="required-asterisk">How many flyers did you give out or drop off?</label>
      <input
        type='number'
        name="flyers"
        value={formData.flyers}
        onChange={handleInputChange}
        placeholder="Your answer"
        min="0"
        required
      />
    </div>
    <div className='form-section'>
      <label className="required-asterisk">For existing consumers or follow-ups, how many did you attempt to contact by phone, but they did not respond?</label>
      <input
        type='number'
        name='Number_FollowUp_Attempt_Contact_NoResponse'
        value={formData.Number_FollowUp_Attempt_Contact_NoResponse}
        onChange={handleInputChange}
        placeholder="Your answer"
        min="0"
        required
      />
    </div>
    <div className='form-section'>
      <label className="required-asterisk">How many MAC touches have you conducted today?</label>
      <input
        type='number'
        name='MACtouches'
        value={formData.MACtouches}
        onChange={handleInputChange}
        placeholder="Your answer"
        min="0"
        required
      />
    </div>
  </>
);

export default Section2;
