import React, { useState, useEffect } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import AWS from 'aws-sdk';
import './AdminHome.css';
import ManageUsers from './ManageUsers/ManageUsers'; 
import CreateUsers from './ManageUsers/CreateUsers'; 
import awsExports from '../../aws-exports';

const AdminHome = () => {
    const [showCreateUsers, setShowCreateUsers] = useState(false);
    const [showManageUsers, setShowManageUsers] = useState(false);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    
    const navigate = useNavigate(); // Initialize useNavigate hook

    const region = awsExports.aws_project_region;
    const userPoolId = awsExports.aws_user_pools_id;

    const listUsers = async () => {
        setLoading(true);
        setError(null);

        try {
            const credentials = await fetchAuthSession();
            const essentialCreds = {
                accessKeyId: credentials.credentials.accessKeyId,
                secretAccessKey: credentials.credentials.secretAccessKey,
                sessionToken: credentials.credentials.sessionToken
            };
            const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider({
                region: region,
                credentials: essentialCreds,
            });

            const params = {
                UserPoolId: userPoolId,
                Limit: 10,
            };

            const data = await cognitoIdentityServiceProvider.listUsers(params).promise();
            setUsers(data.Users || []);
        } catch (err) {
            setError('Failed to load users');
            console.error('Error listing users:', err);
        }

        setLoading(false);
    };

    useEffect(() => {
        listUsers();
    }, []);

    const handleCreateUsersClick = () => {
        setShowCreateUsers(true);
    };

    const handleCloseCreateUsers = () => {
        setShowCreateUsers(false);
    };

    const handleManageUsersClick = () => {
        setShowManageUsers(true);
    };

    const handleCloseManageUsers = () => {
        setShowManageUsers(false);
    };

    const handlePromoReportClick = () => {
        navigate('/promo-reports'); // Navigate to the PromoActivities route
    };

    return (
        <div className="admin-home-container">
            <header className="AdminDashboard-header">
                <h1>Admin Dashboard</h1>
            </header>
            <p>This is the admin homepage where you can manage the application.</p>

            <div className="admin-home-links">
                <button onClick={handleCreateUsersClick}>
                    Create User
                </button>
                <button onClick={handleManageUsersClick}>
                    Manage Users
                </button>
                <button onClick={handlePromoReportClick}>
                    Promotional Report
                </button>
            </div>

            {showCreateUsers && (
                <CreateUsers onClose={handleCloseCreateUsers} />
            )}

            {showManageUsers && (
                <ManageUsers onClose={handleCloseManageUsers} />
            )}
        </div>
    );
};

export default AdminHome;
