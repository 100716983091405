import React from 'react';
import './QHP_Report.css'; // Adjust this file for specific styles

// Helper function to format dates in 'YYYY-MM-DD' format
const formatDate = (date) => date.toLocaleDateString('en-CA');

const QHP_Report = ({ allReports, startDate, endDate }) => {
    // Helper function to generate date range
    const generateDateRange = (start, end) => {
        const dates = [];
        let currentDate = new Date(start.getFullYear(), start.getMonth(), start.getDate());
        const endDate = new Date(end.getFullYear(), end.getMonth(), end.getDate());
      
        while (currentDate <= endDate) {
          dates.push(new Date(currentDate));
          currentDate.setDate(currentDate.getDate() + 1);
        }
      
        return dates;
      };
      

    const dateRange = generateDateRange(startDate, endDate);

    // Initialize a structure to store data by navigator and date
    const navigatorData = {};
    const totalByDate = {};

    allReports.forEach((item) => {
        const navigator = item.Navigator || 'Unknown';
      
        // Parse the date components to create a date in local time
        const dateParts = item.Date ? item.Date.split('-').map(Number) : null;
        const parsedDate = dateParts ? new Date(dateParts[0], dateParts[1] - 1, dateParts[2]) : null;
        const reportDate = parsedDate && !isNaN(parsedDate) ? formatDate(parsedDate) : null;
        const qhp_cases = parseInt(item.qhp_enrollment_hcgov, 10) || 0;
      
        if (reportDate) {
            if (!navigatorData[navigator]) {
                navigatorData[navigator] = { dates: {}, total: 0 };
            }

            if (!navigatorData[navigator].dates[reportDate]) {
                navigatorData[navigator].dates[reportDate] = 0;
            }

            navigatorData[navigator].dates[reportDate] += qhp_cases;
            navigatorData[navigator].total += qhp_cases;

            // Calculate total cases by date across all navigators
            if (!totalByDate[reportDate]) {
                totalByDate[reportDate] = 0;
            }
            totalByDate[reportDate] += qhp_cases;
        } else {
            console.warn(`Invalid date found for item: `, item);
        }
    });

    const navigatorEntries = Object.entries(navigatorData);

    return (
        <div className='qhpByNavigator-container'>
            <header className="qhpByNavigator-header">
                <h1>QHPs by Navigator</h1>
            </header>
            <table className="qhpByNavigator-table">
                <thead>
                    <tr>
                        <th>Navigator</th>
                        {dateRange.map(date => (
                            <th key={formatDate(date)}>
                                {date.toLocaleDateString()}
                            </th>
                        ))}
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {navigatorEntries.map(([navigator, { dates, total }]) => (
                        <tr key={navigator}>
                            <td>{navigator}</td>
                            {dateRange.map(date => {
                                const formattedDate = formatDate(date);
                                return (
                                    <td key={formattedDate}>
                                        {dates[formattedDate] || 0}
                                    </td>
                                );
                            })}
                            <td>{total}</td>
                        </tr>
                    ))}
                    <tr>
                        <td><strong>Total of the day</strong></td>
                        {dateRange.map(date => {
                            const formattedDate = formatDate(date);
                            return (
                                <td key={formattedDate}>
                                    <strong>{totalByDate[formattedDate] || 0}</strong>
                                </td>
                            );
                        })}
                        <td><strong>{Object.values(totalByDate).reduce((acc, curr) => acc + curr, 0)}</strong></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default QHP_Report;
