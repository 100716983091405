import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { listClickCounters, listUsers } from '../../../graphql/queries';
import { createClickCounter, deleteClickCounter, updateClickCounter } from '../../../graphql/mutations';
import './Click_Counter.css';
import XLSX from 'xlsx-js-style';
import { v4 as uuidv4 } from 'uuid';
import { FaFileExcel } from 'react-icons/fa';

const Click_Counter = () => {
    const [clickCounter, setClickCounter] = useState([]);
    const [nextToken, setNextToken] = useState(null);
    const [showClickCounterAddOverlay, setShowClickCounterAddOverlay] = useState(false);
    const [selectedClickCounter, setSelectedClickCounter] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    const [newClickCounter, setNewClickCounter] = useState({
        id: '',
        price: '',
        store: '',
        purchase_date: '',
        available: '',
        assigned_to: '',

    });
    const [sortConfig, setSortConfig] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchField, setSearchField] = useState('id');
    const [duplicateError, setDuplicateError] = useState('');
    const [priceError, setPriceError] = useState('');
    const { loading1, error: userError, data: userData } = useQuery(listUsers);



    const handleSaveAsExcel = async () => {
        try {
            const clickCounterToExport = filteredClickCounters.length > 0 ? filteredClickCounters : clickCounter;

            if (clickCounterToExport.length > 0) {
                const sortedclickCounter = [...clickCounterToExport].sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp));

                const worksheetData = sortedclickCounter.map((clickCounter, index) => ({
                    '#': index + 1, // Agregar el número de fila
                    'Number': clickCounter.store,
                    'Assigned To': clickCounter.assigned_to,
                    'Available': clickCounter.available ? 'Yes' : 'No',

                }));

                // Convert JSON to sheet starting from row 2
                const worksheet = XLSX.utils.json_to_sheet([]);
                let endCol = 0;

                XLSX.utils.sheet_add_aoa(worksheet, [['Click Counters']], { origin: 'A1' });

                if (worksheetData.length > 0) {
                    // Agrega los datos a partir de la fila A2
                    XLSX.utils.sheet_add_json(worksheet, worksheetData, { origin: 'A2', skipHeader: false });

                    // Define el rango de referencia (!ref)
                    const endRow = worksheetData.length + 1; // +1 para el título en A1
                    const endCol = Object.keys(worksheetData[0]).length - 1;
                    worksheet['!ref'] = XLSX.utils.encode_range({ s: { r: 0, c: 0 }, e: { r: endRow, c: endCol } });
                } else {
                    console.error("No hay datos para exportar.");
                    return;
                }

                // Merge title cells across the columns
                worksheet['!merges'] = worksheet['!merges'] || [];
                worksheet['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 3 } }); // A1 to H1 for the added column

                // Style the title row
                worksheet['A1'].s = {
                    font: {
                        bold: true,
                        sz: 20, // Font size
                        color: { rgb: '1F4E79' } // Dark blue text color
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center'
                    }
                };

                // Apply styling for the header row (now starting at row 2)
                const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
                for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                    const cellAddress = XLSX.utils.encode_cell({ r: 1, c: col }); // Header row is now row 1
                    if (!worksheet[cellAddress]) continue;
                    worksheet[cellAddress].s = {
                        fill: {
                            fgColor: { rgb: '1F4E79' } // Dark blue background
                        },
                        font: {
                            bold: true,
                            color: { rgb: 'FFFFFF' } // White text
                        },
                        alignment: {
                            horizontal: 'center'
                        },
                        border: {
                            top: { style: 'thin', color: { rgb: '000000' } },
                            left: { style: 'thin', color: { rgb: '000000' } },
                            bottom: { style: 'thin', color: { rgb: '000000' } },
                            right: { style: 'thin', color: { rgb: '000000' } },
                        }
                    };
                }

                // Apply alternating row colors and borders starting from row 3
                for (let row = 2; row <= headerRange.e.r; row++) {
                    const isEvenRow = row % 2 === 0;
                    for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
                        const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
                        if (!worksheet[cellAddress]) continue;

                        worksheet[cellAddress].s = {
                            fill: {
                                fgColor: { rgb: isEvenRow ? 'D3D3D3' : 'FFFFFF' } // Light gray for even rows, white for odd rows
                            },
                            border: {
                                top: { style: 'thin', color: { rgb: '000000' } },
                                left: { style: 'thin', color: { rgb: '000000' } },
                                bottom: { style: 'thin', color: { rgb: '000000' } },
                                right: { style: 'thin', color: { rgb: '000000' } },
                            },
                            alignment: {
                                horizontal: 'center'
                            }
                        };
                    }
                }

                // Adjust columns to fit content
                // Adjust columns to fit content
                worksheet['!cols'] = [
                    { wch: 5 }, // Width for Row Number
                    { wch: 10 },
                    { wch: 20 },
                    { wch: 10 },

                ];

                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'clickCounter');

                // Export the file
                XLSX.writeFile(workbook, 'clickCounterData.xlsx');
            } else {
                console.error('No data to export');
            }
        } catch (error) {
            console.error('Error exporting data to Excel:', error);
        }
    };

    const handleEditClickCounter = (scanner) => {
        setSelectedClickCounter(scanner);
        setIsEditing(true);
        setShowClickCounterAddOverlay(true);
    };

    const { loading, data, fetchMore } = useQuery(listClickCounters, {
        variables: { limit: 100 },
    });

    const [addClickCounter] = useMutation(createClickCounter, {
        refetchQueries: [{ query: listClickCounters, variables: { limit: 100 } }]
    });

    const [removeClickCounter] = useMutation(deleteClickCounter, {
        refetchQueries: [{ query: listClickCounters, variables: { limit: 100 } }]
    });
    const [updClickCounter] = useMutation(updateClickCounter, {
        refetchQueries: [{ query: listClickCounters, variables: { limit: 100 } }]
    });

    const [fetchAllClickCounters, { loading: countLoading, data: AllClickCountersData }] = useLazyQuery(listClickCounters, {
        variables: { limit: 100 },
        fetchPolicy: "network-only",
    });


    useEffect(() => {
        const fetchAllClickCounters = async () => {
            let AllClickCounters = [];
            let currentNextToken = null;

            do {
                const { data } = await fetchMore({
                    variables: { limit: 100, nextToken: currentNextToken },
                });

                AllClickCounters = [...AllClickCounters, ...data.listClickCounters.items];
                currentNextToken = data.listClickCounters.nextToken;
            } while (currentNextToken);

            setClickCounter(AllClickCounters);
            setNextToken(currentNextToken);
        };

        if (data) {
            fetchAllClickCounters();
        }
    }, [data, fetchMore]);

    const clearNewClickCounter = () => {
        setNewClickCounter({
            id: '',
            price: '',
            store: '',
            purchase_date: '',
            available: '',
            assigned_to: '',

        });
    };

    // Define loadMore function
    const loadMore = () => {
        fetchMore({
            variables: { limit: 100, nextToken },
            updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult;
                setNextToken(fetchMoreResult.listClickCounters.nextToken);
                return {
                    listClickCounters: {
                        ...fetchMoreResult.listClickCounters,
                        items: [...prevResult.listClickCounters.items, ...fetchMoreResult.listClickCounters.items]
                    }
                };
            }
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let formattedValue = value;

        if (isEditing) {
            setSelectedClickCounter({
                ...selectedClickCounter,
                [name]: formattedValue
            });
        } else {
            setNewClickCounter({
                ...newClickCounter,
                id: uuidv4(),
                [name]: formattedValue
            });
        }
    };

    const handleAddClickCounter = () => {

        const formattedPurchase = newClickCounter.purchase_date
            ? new Date(newClickCounter.purchase_date).toISOString().split('T')[0]
            : null;

        // Validate that the price contains only digits and is a valid number
        let priceValue = null;

        if (newClickCounter.price !== "" && newClickCounter.price !== null && newClickCounter.price != undefined) {
            priceValue = parseFloat(newClickCounter.price);

            if (isNaN(priceValue)) {
                setPriceError('Price must be a valid number.');
                return;
            }
        }
        setPriceError('');

        // If all validations pass, proceed with adding the clickCounter to the database
        addClickCounter({
            variables: { input: { ...newClickCounter, price: priceValue, purchase_date: formattedPurchase } }
        })
            .then(() => {
                window.location.reload();
            }).catch(error => {
                console.error("Error adding clickCounter:", error);
            });
    };

    const handleDeleteClickCounter = () => {

        removeClickCounter({
            variables: { input: { id: selectedClickCounter.id } }
        }).then(() => {
            window.location.reload();
        }).catch(error => {
            console.error("Error deleting ClickCounter", error);
        });

    };

    const handleUpdateClickCounter = async () => {

        const formattedData_purchase = selectedClickCounter.purchase_date ? new Date(selectedClickCounter.purchase_date).toISOString().split('T')[0] : null;

        //Check Price
        let priceValue = null;
        if (selectedClickCounter.price !== "" && selectedClickCounter.price !== null && selectedClickCounter.price != undefined) {
            priceValue = parseFloat(selectedClickCounter.price);

            if (isNaN(priceValue)) {
                setPriceError('Price must be a valid number.');
                return;
            }
        }
        setPriceError('');

        updClickCounter({
            variables: {
                input: {
                    id: selectedClickCounter.id,
                    price: priceValue,
                    store: selectedClickCounter.store,
                    purchase_date: formattedData_purchase,
                    available: selectedClickCounter.available,
                    assigned_to: selectedClickCounter.assigned_to,
                }
            }
        }).then(() => {
            setIsEditing(true);
            setShowClickCounterAddOverlay(false);
            setPriceError('');

        }).catch(error => {
            console.error("Error updating ClickCounter:", error);
        });
    };

    const handleSelectClickCounter = (clickCounter) => {

        for (const [key, value] of Object.entries(clickCounter)) {
            console.log(`${key}: ${typeof value}`);
        }
        const formatDateForInput = (dateString) => {
            if (!dateString) return ''; // Return empty string if the date is not provided
            const date = new Date(dateString);
            return date.toISOString().split('T')[0]; // Convert to YYYY-MM-DD format
        };

        setNewClickCounter({
            id: clickCounter.id,
            price: clickCounter.price,
            store: clickCounter.store,
            purchase_date: formatDateForInput(clickCounter.purchase_date),
            available: clickCounter.available,
            assigned_to: clickCounter.assigned_to,
        });
        setShowClickCounterAddOverlay(true);
        setIsEditing(true);
        setSelectedClickCounter(clickCounter);
    };

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedClickCounters = React.useMemo(() => {
        let sortableClickCounters = [...clickCounter];
        if (sortConfig !== null) {
            sortableClickCounters.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableClickCounters;
    }, [clickCounter, sortConfig]);

    //listUsers 
    const users = React.useMemo(() => {
        if (userData && userData.listUsers) {
            return userData.listUsers.items || [];
        }
        return [];
    }, [userData]);
    if (userError) return <p>Error loading users: {userError.message}</p>;
    if (loading1) return <p>Error loading users: {userError.message}</p>;

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredClickCounters = sortedClickCounters.filter(clickCounter => {
        let fieldValue = clickCounter[searchField].toString().toLowerCase();

        // Convertir "true" a "yes" y "false" a "no"
        if (fieldValue === "true") {
            fieldValue = "yes";
        } else if (fieldValue === "false") {
            fieldValue = "no";
        }

        return fieldValue.includes(searchQuery.toLowerCase());
    });

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    const formatPrice = (price) => {
        if (price === null || price === undefined || price === '') {
            return '';
        }
        const parsedPrice = parseFloat(price);
        return isNaN(parsedPrice) ? '' : parsedPrice.toFixed(2);
    };

    return (
        <div className="clickCounter-page">
            <header className="inventory-header">
                <h1>Click Counter</h1>
            </header>
            <div className="inventory-search">
                <button onClick={handleSaveAsExcel}> <FaFileExcel /> Export </button>

                <input
                    type="text"
                    placeholder="Search by Available, Assigned to"
                    value={searchQuery}
                    onChange={handleSearch}
                />
                <select onChange={(e) => setSearchField(e.target.value)} defaultValue="">
                    <option value="" disabled hidden>Select Option</option>
                    <option value="available">Available</option>
                    <option value="store">#</option>
                    <option value="assigned_to">Assigned to</option>
                </select>
            </div>


            <button className="add-clickCounter-btn" onClick={() => {
                clearNewClickCounter();
                setSelectedClickCounter(null);
                setIsEditing(false);
                setShowClickCounterAddOverlay(true);
            }}>+ Add New Click Counter</button>


            <div className="clickCounter-table-container">
                <table className="clickCounter-table">

                    <thead>
                        <tr>
                            <th>#</th> {/* Columna para contar las filas */}


                            <th onClick={() => requestSort('store')}>
                                Number
                                {sortConfig?.key === 'store' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('purchase_date')}>
                                Purchase Date
                                {sortConfig?.key === 'purchase_date' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('price')}>
                                Price
                                {sortConfig?.key === 'price' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('available')}>
                                Available
                                {sortConfig?.key === 'available' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>
                            <th onClick={() => requestSort('assigned_to')}>
                                Assigned to
                                {sortConfig?.key === 'assigned_to' && (
                                    <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                                )}
                            </th>

                            <th >Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredClickCounters.map((clickCounter, index) => (
                            <tr key={clickCounter.id} onClick={() => handleEditClickCounter(clickCounter)} >
                                <td>{index + 1}</td> {/* Número de fila */}

                                <td className="clickCounter-store">{clickCounter.store}</td>
                                <td className="clickCounter-purchase_date">{clickCounter.purchase_date ? formatDate(clickCounter.purchase_date) : 'No Date'}</td>
                                <td className="clickCounter-price">${formatPrice(clickCounter.price)}</td>
                                <td className="clickCounter-available">{clickCounter.available ? 'Yes' : 'No'} </td>
                                <td className="clickCounter-assigned_to">{clickCounter.assigned_to}</td>
                                <td className='clickCounter-table'>
                                    <button className="delete-clickCounter-btn" onClick={() => handleSelectClickCounter(clickCounter)}>Delete/Update</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>


            {nextToken && !loading && (
                <button onClick={loadMore}>Load More</button>
            )}


            {loading && <p>Loading...</p>}

            {showClickCounterAddOverlay && (

                <div className="clickCounter_add_overlay">
                    <div className="overlay-content-inventory">
                        <div className="header-row">
                            <h2 className="header-title">{isEditing ? 'Edit Click Counter' : 'Add New Click Counter'}</h2>
                            <button onClick={() => {
                                setShowClickCounterAddOverlay(false);
                                clearNewClickCounter();
                                setSelectedClickCounter(null);
                                setIsEditing(false);
                            }}>X</button>
                        </div>


                        <label>Number:</label><input name="store" placeholder="#" value={isEditing ? selectedClickCounter.store : newClickCounter.store} onChange={handleInputChange} />
                        <label>Price:</label><input name="price" placeholder="price" value={isEditing ? selectedClickCounter.price : newClickCounter.price} onChange={handleInputChange} />

                        <label htmlFor="purchase_date">Purchase Date:</label>
                        <input
                            type="Date"
                            id="purchase_date"
                            name="purchase_date"
                            value={isEditing ? selectedClickCounter.purchase_date : newClickCounter.purchase_date}
                            onChange={handleInputChange}

                        />


                        <label>Available:</label>

                        <select name="available" placeholder="available" value={isEditing ? selectedClickCounter.available : newClickCounter.available} onChange={handleInputChange} >
                            <option value="" disabled hidden className="placeholder-option">Select Y/N</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>

                        <label>Assigned To:</label>
                        <select
                            name="assigned_to"
                            value={isEditing ? selectedClickCounter.assigned_to : newClickCounter.assigned_to}
                            onChange={handleInputChange}
                        >
                            <option value="">Select an Employee</option>
                            {users.length > 0 ? (
                                [...users].sort((a, b) => {
                                    const nameA = `${a.first_name} ${a.last_name}`.toLowerCase();
                                    const nameB = `${b.first_name} ${b.last_name}`.toLowerCase();
                                    return nameA.localeCompare(nameB);
                                }).map(user => (
                                    <option key={user.id} value={`${capitalize(user.first_name)} ${capitalize(user.last_name)}`}>
                                        {capitalize(user.first_name)} {capitalize(user.last_name)}
                                    </option>
                                ))
                            ) : (

                                <option value="">No users available</option>
                            )}
                            <option value="n/a">N/A</option> {/*N/A */}
                        </select>

                        {duplicateError && <p className="error">{duplicateError}</p>} {/* Display duplicate error message */}
                        {priceError && <p className="error">{priceError}</p>} {/* Display price error message */}


                        <div className="button-group">
                            <button className="overlay-add-clickCounter" onClick={isEditing ? handleUpdateClickCounter : handleAddClickCounter} >
                                {isEditing ? 'Update ClickCounter' : 'Add ClickCounter'}
                            </button>
                            {isEditing && (
                                <button
                                    className="overlay-delet-clickCounter"
                                    onClick={() => {
                                        if (window.confirm('Are you sure you want to delete this clickCounter?')) {
                                            handleDeleteClickCounter();
                                        }
                                    }}
                                >
                                    Delete Click Counter
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}

        </div>


    );

};

export default Click_Counter;
