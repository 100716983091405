import React, { useState, useEffect } from 'react';
import WeeklyReports from './WeeklyReports';
import MedicaidReport from './MedicaidReport';
import QHP_Report from './QHP_Report';
import './ParentWeeklyReport.css'; // Create this file for specific styles
import DatePicker from 'react-datepicker';
import { useLazyQuery } from '@apollo/client';
import { GET_REPORTS_BY_DATE, GET_REPORTS_BY_SPECIFIC_DATE } from '../../../graphql/queries'; // Adjust path accordingly

const ParentWeeklyReport = () => {
    const [activeTab, setActiveTab] = useState('weeklyReports');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [dateError, setDateError] = useState('');
    const [allReports, setAllReports] = useState([]); // State to hold all the reports

    const isSameDate = startDate.toDateString() === endDate.toDateString();
    const formatDate = (date) => date.toLocaleDateString('en-CA');


    const [fetchReports, { loading, error }] = useLazyQuery(
        isSameDate ? GET_REPORTS_BY_SPECIFIC_DATE : GET_REPORTS_BY_DATE,
        {
            fetchPolicy: 'network-only', // Always fetch from the network
        }
    );

    // Function to recursively fetch all pages of reports
    const loadAllReports = async () => {
        let allFetchedReports = [];
        let nextToken = null;

        try {
            do {
                const { data } = await fetchReports({
                    variables: isSameDate
                        ? { date: formatDate(startDate), nextToken }
                        : {
                            startDate: formatDate(startDate),
                            endDate: formatDate(endDate),
                            nextToken,
                        }
                });

                if (data && data.listReports && data.listReports.items) {
                    allFetchedReports = [...allFetchedReports, ...data.listReports.items];
                    nextToken = data.listReports.nextToken; // Get the nextToken for pagination
                } else {
                    nextToken = null;
                }
            } while (nextToken);

            setAllReports(allFetchedReports); // Set the combined reports in the state
        } catch (err) {
            console.error('Error fetching reports:', err);
        }
    };

    useEffect(() => {
        if (startDate && endDate) {
            loadAllReports(); // Load reports when the component mounts or dates change
        }
    }, [startDate, endDate]);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const handleEndDateChange = (date) => {
        if (date < startDate) {
            setDateError('"To" date must be after the "From" date.');
            setEndDate(startDate); // Reset "To" date to "From" date
        } else {
            setDateError('');
            setEndDate(date);
        }
    };

    return (
        <div className='reportsTabs-container'>
            <header className="weeklyreports-header">
                <h1>Weekly Reports</h1>
            </header>
            <div className='datePicker'>
                <div className='dp'>
                    <label>From: </label>
                    <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
                </div>
                <div className='dp'>
                    <label>To: </label>
                    <DatePicker selected={endDate} onChange={handleEndDateChange} />
                </div>
                {dateError && <p className="date-error">{dateError}</p>}
            </div>
            <div className='tabs'>
                <button
                    className={activeTab === 'weeklyReports' ? 'active' : ''}
                    onClick={() => handleTabChange('weeklyReports')}
                >
                    Weekly Reports
                </button>
                <button
                    className={activeTab === 'MedicaidReport' ? 'active' : ''}
                    onClick={() => handleTabChange('MedicaidReport')}
                >
                    Medicaid Report
                </button>
                <button
                    className={activeTab === 'QHP_Report' ? 'active' : ''}
                    onClick={() => handleTabChange('QHP_Report')}
                >
                    QHP Report
                </button>
            </div>
            <div className='tab-content'>
                {activeTab === 'weeklyReports' && (
                    <WeeklyReports allReports={allReports} startDate={startDate} endDate={endDate} />
                )}
                {activeTab === 'MedicaidReport' && (
                    <MedicaidReport allReports={allReports} startDate={startDate} endDate={endDate} />
                )}
                {activeTab === 'QHP_Report' && (
                    <QHP_Report allReports={allReports} startDate={startDate} endDate={endDate} />
                )}
            </div>
        </div>
    );
};

export default ParentWeeklyReport;
