import React, { useState, useEffect } from 'react';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { listClientFix2s, listUsersByTeamOne, clientsByNavigator } from '../../graphql/queries';
import { CREATE_CLIENTFIX2, UPDATE_CLIENTFIX2, DELETE_CLIENTFIX2 } from '../../graphql/mutations';
import LoadingSpiner from '../../components/layout/LoadingSpinner';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import * as XLSX from 'xlsx';
import './Clients.css';

const ClientsTable = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [nextToken, setNextToken] = useState(null);
  const [showClientAddOverlay, setShowClientAddOverlay] = useState(false);
  const [fullName, setFullName] = useState('');
  const [paymentError, setPaymentError] = useState('');
  const [selectedRow, setSelectedRow] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false); // Tracks downloading state

  const [newClient, setNewClient] = useState({
    id: '',
    name: '',
    last_name: '',
    email: '',
    address: '',
    city: '',
    county: '',
    zip_code: '',
    prefered_lang: '',
    insurance_picked: '',
    last_contacted_date: '',
    navigator: '',
    payment_per_month: '',
    phone: '',
    plan: ''
  });
  const [sortConfig, setSortConfig] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchField, setSearchField] = useState('name');
  const [phoneError, setPhoneError] = useState('');
  const [duplicateError, setDuplicateError] = useState('');
  const { loading: usersLoading, error: usersError, data: usersData } = useQuery(listUsersByTeamOne);
  const [isLoading, setIsLoading] = useState(false);
  const navigatorOptions = usersData?.listUsers?.items?.map(user => ({
    id: user.id,
    name: `${user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1).toLowerCase()} ${user.last_name.charAt(0).toUpperCase() + user.last_name.slice(1).toLowerCase()}`
  })) || [];
  const [userGroups, setUserGroups] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [fetchAllClients, { data: allClientsData }] = useLazyQuery(listClientFix2s, {
    variables: { limit: 200 }, // Adjust as needed for pagination
    fetchPolicy: "network-only",
  });

  const handleRowClick = (client) => {
    setSelectedRow(client.id);
    handleEditClient(client); // Llama a tu función de edición
  };

  const fetchUserDetails = async () => {
    try {
      const { signInDetails } = await getCurrentUser();
      const session = await fetchAuthSession();
      const groups = session.tokens.idToken.payload['cognito:groups'] || [];
      setUserGroups(groups);

      const [firstName, lastNameWithDomain] = signInDetails.loginId.split('.');
      const lastName = lastNameWithDomain.split('@')[0];
      const capitalize = (name) => name.charAt(0).toUpperCase() + name.slice(1);

      setUserDetails({
        firstName: capitalize(firstName),
        lastName: capitalize(lastName),
        userId: signInDetails.loginId
      });
    } catch (error) {
      console.log('User not authenticated', error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  // Define handleEditClient function
  const handleEditClient = (client) => {
    setSelectedClient(client);
    setIsEditing(true);
    setShowClientAddOverlay(true);
  };

  useEffect(() => {
    if (userDetails) {
      const fullName = `${userDetails.firstName} ${userDetails.lastName}`;
      setFullName(fullName);
    }
  }, [userDetails]);

  const { loading, data, fetchMore } = useQuery(
    userGroups.includes('dev') ? listClientFix2s : clientsByNavigator,
    {
      variables: userGroups.includes('dev')
        ? { limit: 1000 } // Fetch all clients for dev users
        : { navigator: fullName || "Unknown Navigator!", limit: 1000 }, // Personal clients for other users
      skip: !fullName && !userGroups.includes('dev'), // Skip if fullName is not set, unless dev user
    }
  );

  useEffect(() => {
    if (data) {
      const fetchedClients = userGroups.includes('dev') ? data.listClientFix2s.items : data.clientsByNavigator.items;
      // Print the dates immediately after fetching the data
      
      setClients(fetchedClients);
    }
  }, [data, userGroups]);

  const [addClient] = useMutation(CREATE_CLIENTFIX2, {
    refetchQueries: [{ query: listClientFix2s, variables: { limit: 100 } }]
  });

  const [updateClient] = useMutation(UPDATE_CLIENTFIX2, {
    refetchQueries: [{ query: listClientFix2s, variables: { limit: 100 } }]
  });

  const [deleteClient] = useMutation(DELETE_CLIENTFIX2, {
    refetchQueries: [{ query: listClientFix2s, variables: { limit: 100 } }]
  });
  useEffect(() => {
    const fetchAllClients = async () => {
      let allClients = [];
      let currentNextToken = null;

      do {
        const response = await fetchMore({
          variables: userGroups.includes('dev')
            ? { limit: 1000, nextToken: currentNextToken }
            : { navigator: fullName, limit: 1000, nextToken: currentNextToken },
        });

        const fetchedData = userGroups.includes('dev') ? response?.data?.listClientFix2s : response?.data?.clientsByNavigator;

        if (fetchedData && fetchedData.items) {
          allClients = [...allClients, ...fetchedData.items];
          currentNextToken = fetchedData.nextToken;
        } else {
          console.warn("Unexpected response structure from fetchMore:", response);
          break;
        }
      } while (currentNextToken);

      setClients(allClients);
      setNextToken(currentNextToken);
    };

    if (data) {
      fetchAllClients();
    }
  }, [data, fetchMore, userGroups]);


  const clearNewClient = () => {
    setNewClient({
      id: '',
      name: '',
      last_name: '',
      email: '',
      address: '',
      city: '',
      county: '',
      zip_code: '',
      prefered_lang: '',
      insurance_picked: '',
      last_contacted_date: '',
      navigator: '',
      payment_per_month: '',
      phone: '',
      plan: ''
    });
  };

  // Define loadMore function
  const loadMore = () => {
    fetchMore({
      variables: userGroups.includes('dev')
        ? { limit: 1000, nextToken }
        : { navigator: fullName, limit: 1000, nextToken },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prevResult;

        const updatedItems = userGroups.includes('dev')
          ? [...prevResult.listClientFix2s.items, ...fetchMoreResult.listClientFix2s.items]
          : [...prevResult.clientsByNavigator.items, ...fetchMoreResult.clientsByNavigator.items];

        const newNextToken = userGroups.includes('dev')
          ? fetchMoreResult.listClientFix2s.nextToken
          : fetchMoreResult.clientsByNavigator.nextToken;

        return userGroups.includes('dev')
          ? {
            listClientFix2s: {
              ...prevResult.listClientFix2s,
              items: updatedItems,
              nextToken: newNextToken,
            },
          }
          : {
            clientsByNavigator: {
              ...prevResult.clientsByNavigator,
              items: updatedItems,
              nextToken: newNextToken,
            },
          };
      },
    });
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'payment_per_month') {
      // Allow empty input or numeric input
      if (value === '' || /^\d*\.?\d*$/.test(value)) {
        setPaymentError(''); // Reset error when input is valid or empty
        if (isEditing) {
          setSelectedClient({
            ...selectedClient,
            [name]: value
          });
        } else {
          setNewClient({
            ...newClient,
            id: uuidv4(),
            [name]: value
          });
        }
      } else {
        setPaymentError('Payment per month must be a valid number.');
      }
    } else {
      let formattedValue = value;
      if (['name', 'last_name', 'email'].includes(name)) {
        formattedValue = value.toLowerCase();
      }
      if (name === 'phone') {
        formattedValue = value.replace(/[^\d]/g, '');
        if (formattedValue.length > 10) {
          setPhoneError('Phone number cannot be longer than 10 digits.');
        } else {
          setPhoneError('');
        }
      }
      if (name === 'prefered_lang') {
        formattedValue = value.split(',').map(lang => lang.trim());
      }

      // Apply changes for other fields including `plan`
      if (isEditing) {
        setSelectedClient({
          ...selectedClient,
          [name]: formattedValue
        });
      } else {
        setNewClient({
          ...newClient,
          id: uuidv4(),
          [name]: formattedValue
        });
      }
    }
  };


  // Format `payment_per_month` on blur
  const handleBlur = (e) => {
    const { name } = e.target;

    if (name === 'payment_per_month') {
      const value = isEditing ? selectedClient.payment_per_month : newClient.payment_per_month;
      const parsedValue = parseFloat(value);

      // Check if the input is a valid number and format it
      if (!isNaN(parsedValue)) {
        setPaymentError('');
        const formattedValue = parsedValue.toFixed(2);

        if (isEditing) {
          setSelectedClient({
            ...selectedClient,
            [name]: formattedValue
          });
        } else {
          setNewClient({
            ...newClient,
            [name]: formattedValue
          });
        }
      } else {
        setPaymentError('Payment per month must be a valid number.');
      }
    }
  };

  const handleAddClient = () => {
    if (!newClient.name || !newClient.last_name) {
      setDuplicateError('Name and Last Name are required.');
      return;
    }

    setIsLoading(true); // Set loading state to true

    const isDuplicate = clients.some(client =>
      client.name === newClient.name &&
      client.last_name === newClient.last_name &&
      client.email === newClient.email
    );

    if (isDuplicate) {
      setDuplicateError('A client with the same name, last name, and email already exists.');
      setIsLoading(false); // Reset loading state
      return;
    } else {
      setDuplicateError('');
    }

    // Set `last_contacted_date` to current date, format `navigator`, and handle optional fields
    const currentDate = new Date().toISOString().split("T")[0]; // Format as YYYY-MM-DD
    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    const navigatorFullName = `${capitalize(userDetails.firstName)} ${capitalize(userDetails.lastName)}`;

    const clientToAdd = {
      ...newClient,
      last_contacted_date: currentDate,
      navigator: navigatorFullName, // Ensure the navigator field is properly set
      payment_per_month: newClient.payment_per_month ? newClient.payment_per_month : "0.0" // Default to 0.0 if empty
    };

    addClient({
      variables: { input: clientToAdd },
      update: (cache, { data: { createClientFix2 } }) => {
        const existingClients = cache.readQuery({ query: listClientFix2s, variables: { limit: 1000 } });
        cache.writeQuery({
          query: listClientFix2s,
          variables: { limit: 1000 },
          data: {
            listClientFix2s: {
              ...existingClients.listClientFix2s,
              items: [createClientFix2, ...existingClients.listClientFix2s.items]
            }
          }
        });
      }
    }).then(() => {
      clearNewClient(); // Clear new client form
      setShowClientAddOverlay(false); // Hide overlay
    }).catch(error => {
      console.error("Error adding client:", error);
    }).finally(() => {
      setIsLoading(false); // Reset loading state
    });
  };

  const handleUpdateClient = () => {
    if (!selectedClient.name || !selectedClient.last_name) {
      setDuplicateError('Name and Last Name are required.');
      return;
    }

    setIsLoading(true); // Set loading state to true

    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    const navigatorFullName = userGroups.includes('Admin') && isEditing
      ? selectedClient.navigator // Use the selected navigator if user is admin
      : `${capitalize(userDetails.firstName)} ${capitalize(userDetails.lastName)}`;

    updateClient({
      variables: {
        input: {
          id: selectedClient.id,
          name: selectedClient.name,
          last_name: selectedClient.last_name,
          email: selectedClient.email,
          address: selectedClient.address,
          city: selectedClient.city,
          county: selectedClient.county,
          zip_code: selectedClient.zip_code,
          prefered_lang: selectedClient.prefered_lang,
          insurance_picked: selectedClient.insurance_picked,
          last_contacted_date: selectedClient.last_contacted_date, // Use updated or existing date
          navigator: navigatorFullName,
          payment_per_month: selectedClient.payment_per_month ? selectedClient.payment_per_month : "0.0",
          phone: selectedClient.phone,
          plan: selectedClient.plan,
        }
      },
      update: (cache, { data }) => {
        const updateClientFix2 = data?.updateClientFix2;

        if (!updateClientFix2) {
          console.warn("No updateClientFix2 data returned.");
          return;
        }

        const existingClients = cache.readQuery({ query: listClientFix2s, variables: { limit: 1000 } });
        const updatedClients = existingClients.listClientFix2s.items.map(client =>
          client.id === updateClientFix2.id ? updateClientFix2 : client
        );

        cache.writeQuery({
          query: listClientFix2s,
          variables: { limit: 1000 },
          data: {
            listClientFix2s: {
              ...existingClients.listClientFix2s,
              items: updatedClients
            }
          }
        });
      }
    })
      .then(() => {
        setSelectedClient(null); // Clear selected client
        setIsEditing(false); // Reset editing state
        setShowClientAddOverlay(false); // Hide overlay
      })
      .catch(error => {
        console.error("Error updating client:", error);
      })
      .finally(() => {
        setIsLoading(false); // Reset loading state
      });
  };


  const handleDeleteClient = () => {
    setIsLoading(true); // Set loading state to true

    deleteClient({
      variables: {
        input: {
          id: selectedClient.id
        }
      },
      update: (cache) => {
        const existingClients = cache.readQuery({ query: listClientFix2s, variables: { limit: 1000 } });
        const updatedClients = existingClients.listClientFix2s.items.filter(
          client => client.id !== selectedClient.id
        );

        cache.writeQuery({
          query: listClientFix2s,
          variables: { limit: 1000 },
          data: {
            listClientFix2s: {
              ...existingClients.listClientFix2s,
              items: updatedClients
            }
          }
        });
      }
    }).then(() => {
      setSelectedClient(null); // Clear selected client
      setShowClientAddOverlay(false); // Hide overlay
    }).catch(error => {
      console.error("Error deleting client:", error);
    }).finally(() => {
      setIsLoading(false); // Reset loading state
    });
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedClients = React.useMemo(() => {
    let sortableClients = [...clients];
    if (sortConfig !== null) {
      sortableClients.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableClients;
  }, [clients, sortConfig]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredClients = sortedClients.filter(client =>
    client[searchField].toString().toLowerCase().includes(searchQuery.toLowerCase())
  );

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };


  const downloadExcel = async () => {
    setIsDownloading(true); // Start loading spinner

    try {
      let allClients = [];
      let currentNextToken = null;

      // Loop to fetch all clients until no next token is returned
      do {
        const response = await fetchAllClients({
          variables: { limit: 1000, nextToken: currentNextToken },
          fetchPolicy: "network-only",
        });

        const fetchedClients = response.data.listClientFix2s.items;
        allClients = [...allClients, ...fetchedClients];
        currentNextToken = response.data.listClientFix2s.nextToken;
      } while (currentNextToken);

      if (allClients.length === 0) {
        alert("No clients available to download.");
        setIsDownloading(false);
        return;
      }

      // Format the fetched clients for the Excel file
      const formattedClients = allClients.map(client => ({
        ID: client.id,
        Name: capitalize(client.name),
        "Last Name": capitalize(client.last_name),
        Email: client.email.toLowerCase(),
        Address: client.address,
        City: client.city,
        County: client.county,
        zip_code: client.zip_code,
        "Preferred Language": Array.isArray(client.prefered_lang) ? client.prefered_lang.join(', ') : client.prefered_lang,
        "Insurance Picked": client.insurance_picked,
        Plan: client.plan,
        "Last Contacted Date": client.last_contacted_date,
        Navigator: client.navigator,
        "Payment Per Month": client.payment_per_month,
        Phone: formatPhoneNumber(client.phone)
      }));

      // Create the Excel file
      const worksheet = XLSX.utils.json_to_sheet(formattedClients);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Clients");

      // Download the file
      XLSX.writeFile(workbook, "clients.xlsx");
    } catch (error) {
      console.error("Error fetching all clients:", error);
    } finally {
      setIsDownloading(false); // Stop loading spinner after download completes
    }
  };



  return (
    <div className="clients">
      <header className="clients-header">
        <h1>Clients</h1>
      </header>
      <div className="clients-search">
        <input
          type="text"
          placeholder="Client Name, Email, Address"
          value={searchQuery}
          onChange={handleSearch}
        />
        <select onChange={(e) => setSearchField(e.target.value)}>
          <option value="name">Name</option>
          <option value="last_name">Last Name</option>
          <option value="email">Email</option>
          <option value="address">Address</option>
          <option value="insurance_picked">Insurance Picked</option>
          <option value="last_contacted_date">Last Contacted Date</option>
          <option value="navigator">Navigator</option>
          <option value="payment_per_month">Payment Per Month</option>
          <option value="phone">Phone</option>
          <option value="plan">Plan</option>
        </select>
      </div>
      <button
        className="add-user-btn"
        onClick={() => {
          clearNewClient();
          setSelectedClient(null);
          setIsEditing(false);
          setShowClientAddOverlay(true);
        }}
      >
        + Add User
      </button>
      {userGroups?.includes('Admin') && (
        <button className="download-excel-btn" onClick={downloadExcel} disabled={isDownloading}>
          {isDownloading ? <LoadingSpiner /> : "Download Clients Excel"}
        </button>
      )}
      <div className="table-container">
        <table className='clients-table'>
          <thead>
            <tr>
              <th className="client-id" onClick={() => requestSort('id')}>
                Client ID
                {sortConfig?.key === 'id' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th className="client-name" onClick={() => requestSort('name')}>
                Name
                {sortConfig?.key === 'name' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : ' sort-ascending'} />
                )}
              </th>
              <th className="client-last-name" onClick={() => requestSort('last_name')}>
                Last Name
                {sortConfig?.key === 'last_name' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th className="client-phone" onClick={() => requestSort('phone')}>
                Phone
                {sortConfig?.key === 'phone' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th className="client-email" onClick={() => requestSort('email')}>
                Email
                {sortConfig?.key === 'email' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th className="client-preferred-language" onClick={() => requestSort('prefered_lang')}>
                Preferred Language
                {sortConfig?.key === 'prefered_lang' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th className="client-address" onClick={() => requestSort('address')}>
                Address
                {sortConfig?.key === 'address' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th className="client-city" onClick={() => requestSort('city')}>
                City
                {sortConfig?.key === 'city' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th className="client-county" onClick={() => requestSort('county')}>
                County
                {sortConfig?.key === 'county' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th className="client-insurance" onClick={() => requestSort('insurance_picked')}>
                Insurance Picked
                {sortConfig?.key === 'insurance_picked' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th className="client-plan" onClick={() => requestSort('plan')}>
                Plan
                {sortConfig?.key === 'plan' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th className="client-last-contacted" onClick={() => requestSort('last_contacted_date')}>
                Last Contacted Date
                {sortConfig?.key === 'last_contacted_date' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th className="client-navigator" onClick={() => requestSort('navigator')}>
                Navigator
                {sortConfig?.key === 'navigator' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>
              <th className="client-payment" onClick={() => requestSort('payment_per_month')}>
                Payment Per Month
                {sortConfig?.key === 'payment_per_month' && (
                  <span className={sortConfig.direction === 'ascending' ? 'sort-descending' : 'sort-ascending'} />
                )}
              </th>

            </tr>
          </thead>
          <tbody>
            {filteredClients.map(client => (
              <tr
                key={client.id}
                className={selectedRow === client.id ? 'selected-row' : ''}
                onClick={() => handleRowClick(client)}
              >
                <td className="client-id">{client.id}</td>
                <td className="client-name">{capitalize(client.name)}</td>
                <td className="client-last-name">{capitalize(client.last_name)}</td><td className="client-phone">{formatPhoneNumber(client.phone)}</td>
                <td className="client-email">{client.email.toLowerCase()}</td>
                <td className="client-preferred-language">
                  {Array.isArray(client.prefered_lang) ? client.prefered_lang.join(', ') : client.prefered_lang}
                </td><td className="client-address">{client.address}</td>
                <td className="client-city">{client.city}</td><td className="client-county">{client.county}</td>
                <td className="client-insurance">{client.insurance_picked}</td><td className="client-plan">{client.plan}</td>
                <td className="client-last-contacted">{moment(client.last_contacted_date).format('MM-DD-YYYY')}</td>
                <td className="client-navigator">{client.navigator}</td>
                <td className="client-payment">
                  {"$" + (client.payment_per_month !== null ? Number(client.payment_per_month).toLocaleString("en-US", { minimumFractionDigits: 1, maximumFractionDigits: 1 }) : "0.0")}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="item-count">
        <p>Total Clients: {filteredClients.length}</p>
      </div>
      {nextToken && !loading && (
        <button onClick={loadMore}>Load More</button>
      )}
      {showClientAddOverlay && (
        <div className="client_add_overlay">
          <div className="overlay-content-clients">
            <button
              className="add_usr_close-btn"
              onClick={() => {
                setShowClientAddOverlay(false);
                clearNewClient();
                setSelectedClient(null);
                setIsEditing(false);
              }}
            >
              X
            </button>
            <h2>{isEditing ? "Edit Client" : "Add New Client"}</h2>
            {isLoading ? (
              <LoadingSpiner />
            ) : (
              <>
                <label>Name:</label><input name="name" placeholder="Name" value={isEditing ? selectedClient.name : newClient.name} onChange={handleInputChange} required />

                <label>Last Name:</label><input name="last_name" placeholder="Last Name" value={isEditing ? selectedClient.last_name : newClient.last_name} onChange={handleInputChange} required />
                <label>Email:</label><input name="email" placeholder="Email" value={isEditing ? selectedClient.email : newClient.email} onChange={handleInputChange} />
                <label>Address:</label><input name="address" placeholder="Address" value={isEditing ? selectedClient.address : newClient.address} onChange={handleInputChange} />
                <label>City:</label><input name="city" placeholder="City" value={isEditing ? selectedClient.city : newClient.city} onChange={handleInputChange} />
                <label>County:</label><input name="county" placeholder="County" value={isEditing ? selectedClient.county : newClient.county} onChange={handleInputChange} />
                <label>Language:</label><select name="prefered_lang" value={(isEditing ? selectedClient.prefered_lang : newClient.prefered_lang) || ""} onChange={handleInputChange} placeholder="Preferred Language" >
                  <option value="" disabled hidden>Select Language</option>
                  <option value="English">English</option>
                  <option value="Spanish">Spanish</option>
                  <option value="French">French</option>
                  <option value="German">German</option>
                  <option value="Chinese">Chinese</option>
                  <option value="Japanese">Japanese</option>
                  <option value="Arabic">Arabic</option>
                  <option value="Pashto">Pashto</option>
                  <option value="Other">Other</option>
                </select>
                <label>Insurance:</label><select name="insurance_picked" value={(isEditing ? selectedClient.insurance_picked : newClient.insurance_picked) || ""} onChange={handleInputChange} placeholder="Insurance Picked">
                  <option value="" disabled>Select Insurance</option>
                  <option value="Aetna CVS Health">Aetna CVS Health</option>
                  <option value="Ascension">Ascension</option>
                  <option value="Ambetter from Superior HealthPlan">Ambetter from Superior HealthPlan</option>
                  <option value="Blue Cross Blue Shield of Texas (Blue Advantage)">Blue Cross Blue Shield of Texas (Blue Advantage)</option>
                  <option value="Bright HealthCare">Bright HealthCare</option>
                  <option value="CHRISTUS Health Plan">CHRISTUS Health Plan</option>
                  <option value="Cigna">Cigna</option>
                  <option value="Community Health Choice">Community Health Choice</option>
                  <option value="Community First">Community First</option>
                  <option value="FirstCare">FirstCare</option>
                  <option value="Imperial">Imperial</option>
                  <option value="Moda Health">Moda Health</option>
                  <option value="Molina Healthcare">Molina Healthcare</option>
                  <option value="Oscar">Oscar</option>
                  <option value="Baylor Scott & White">Baylor Scott & White</option>
                  <option value="Sendero">Sendero</option>
                  <option value="UnitedHealthCare">UnitedHealthCare</option>
                  <option value="US Health and Life">US Health and Life</option>
                </select>
                <label>Plan:</label><select
                  name="plan"
                  value={(isEditing ? selectedClient.plan : newClient.plan) || ""}
                  onChange={handleInputChange}
                  placeholder="Plan"
                >
                  <option value="" disabled hidden>Select Plan</option>
                  <option value="Bronze">Bronze</option>
                  <option value="Silver">Silver</option>
                  <option value="Gold">Gold</option>
                </select>

                <label>Payment Per Month:</label><input
                  name="payment_per_month"
                  placeholder="Payment Per Month"
                  value={isEditing ? selectedClient.payment_per_month : newClient.payment_per_month}
                  onChange={handleInputChange}
                  onBlur={handleBlur} // Apply formatting on blur
                />
                {paymentError && <p className="error">{paymentError}</p>}

                <label>Phone:</label><input name="phone" placeholder="Phone" value={isEditing ? selectedClient.phone : newClient.phone} onChange={handleInputChange} />
                {phoneError && <p className="error">{phoneError}</p>} {/* Display phone error message */}
                {duplicateError && <p className="error">{duplicateError}</p>} {/* Display duplicate error message */}
                <label>Navigator:</label><select
                  name="navigator"
                  value={(isEditing ? (selectedClient.navigator === 'unassigned' ? 'n/a' : selectedClient.navigator) : (newClient.navigator === 'unassigned' ? 'n/a' : newClient.navigator)) || ""}
                  onChange={handleInputChange}
                  placeholder="Navigator"
                  disabled={!userGroups.includes('Admin') || !isEditing}
                >
                  <option value="" disabled hidden>Select Navigator</option>
                  {navigatorOptions.map(option => (
                    <option key={option.id} value={option.name}>{option.name}</option>
                  ))}
                  <option value="n/a">N/A</option> {/* Opción N/A */}
                </select>
                <label>Date:</label><input
                  name="last_contacted_date"
                  type="date"
                  placeholder="Last Contacted Date"
                  value={isEditing ? selectedClient.last_contacted_date : newClient.last_contacted_date}
                  onChange={handleInputChange}
                />
                <button className='overlay-add-client' onClick={isEditing ? handleUpdateClient : handleAddClient}>
                  {isEditing ? "Update Client" : "Add Client"}
                </button>
                {userGroups?.includes('dev') && isEditing && (
                  <button className='overlay-delete-client' onClick={handleDeleteClient}>
                    Delete Client
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientsTable;
