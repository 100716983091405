import React from 'react';

const Section5 = ({ formData, handleInputChange }) => (
  <>
    {/* Section 5 */}
    <div className="form-section flyer-section">
          <div className="flyer-header">Consumer Appointment</div>
          <label className="required-asterisk">No Show?</label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name="no_show"
                value="yes"
                checked={formData.no_show === 'yes'}
                onChange={handleInputChange}
                required
              />
              Yes, consumer was a no-show
            </label>
            <label>
              <input
                type="radio"
                name="no_show"
                value="no"
                checked={formData.no_show === 'no'}
                onChange={handleInputChange}
                required
              />
              No
            </label>
          </div>
        </div>
  </>
);

export default Section5;
