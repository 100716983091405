import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { GET_GeneralInventory__BY_DATE_RANGE, GET_POWERBANK_BY_DATE_RANGE, GET_MOUSE_BY_DATE_RANGE, GET_KEYBOARDS_BY_DATE_RANGE, GET_MONITORS_BY_DATE_RANGE, GET_LAPTOP_BY_DATE_RANGE, GET_PHONE_BY_DATE_RANGE, GET_PRINTER_BY_DATE_RANGE, GET_SCANNER_BY_DATE_RANGE, GET_TABLET_BY_DATE_RANGE, GET_EXTENSION_BY_DATE_RANGE, GET_BACKUP_BY_DATE_RANGE, GET_USB_BY_DATE_RANGE, GET_DOCKINGSTATION_BY_DATE_RANGE, GET_SPEAKERS_BY_DATE_RANGE, GET_HEADSET_BY_DATE_RANGE, GET_PROJECTOR_BY_DATE_RANGE, GET_EXTERNALDISK_BY_DATE_RANGE, GET_BAG_BY_DATE_RANGE, GET_CLICKCOUNTER_BY_DATE_RANGE, GET_SUPPLIES_BY_DATE_RANGE } from '../../../graphql/queries'; // Adjust the import path accordingly
import './ReportByDate.css';
import DatePicker from 'react-datepicker';
import EquipmentTable  from '../../../components/layout/EquipmentTable';


const ReportByDate = () => {

  const location = useLocation();
  const selectedDateType = location.state?.selectedDate; // Valor recibido o default

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateError, setDateError] = useState('');

  // Mapeo de los campos de fecha a sus etiquetas legibles
  const dateTypeLabels = {
    purchase_date: 'Purchase Date',
    warranty_date: 'Warranty Date',
    returnable_date: 'Returnable Date',
    assignment_date: 'Assignment Date',
    // Añade más tipos si es necesario
  };

  // Si selectedDateType no tiene un valor, muestra un texto por defecto
  const dateLabel = dateTypeLabels[selectedDateType] || 'Date';


  // Construir el filtro dinámico basado en el tipo de fecha seleccionado
  const getFilter = (type) => {
    if (!startDate || !endDate) return null;

    // Para tipos clickCounter y supplies, devuelve un filtro vacío
    if (type === "clickCounter" || type === "supplies") {
      return {}; // No aplicamos filtro de fecha
    }

    // Para bag, aplica una lógica diferente si es necesario
    if (type === "bag") {
      // Puedes aplicar otro filtro específico o simplemente devolver {}
      return {}; // Aquí devuelves un filtro vacío para bag, si no necesitas fechas
    }

    // Si selectedDateType tiene valor y aplica a otros tipos
    return {
      [selectedDateType]: {
        between: [startDate?.toISOString().split('T')[0], endDate?.toISOString().split('T')[0]],
      },
    };
  };


  // Consultas para Mouses, Keyboards, Monitors....
  const { loading: loadingMice, error: errorMice, data: dataMice } = useQuery(GET_MOUSE_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("mouse"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });

  const { loading: loadingKeyboards, error: errorKeyboards, data: dataKeyboards } = useQuery(GET_KEYBOARDS_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("keyboard"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });

  const { loading: loadingMonitors, error: errorMonitors, data: dataMonitors } = useQuery(GET_MONITORS_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("monitor"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });

  const { loading: loadingPhones, error: errorPhones, data: dataPhones } = useQuery(GET_PHONE_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("phone"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });

  const { loading: loadingLaptops, error: errorLaptops, data: dataLaptops } = useQuery(GET_LAPTOP_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("laptop"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });

  const { loading: loadingScanners, error: errorScanners, data: dataScanners } = useQuery(GET_SCANNER_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("scanner"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });

  const { loading: loadingPrinters, error: errorPrinters, data: dataPrinters } = useQuery(GET_PRINTER_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("printer"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });
  const { loading: loadingExtentions, error: errorExtentions, data: dataExtentions } = useQuery(GET_EXTENSION_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("extention"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });
  const { loading: loadingTablets, error: errorTablets, data: dataTablets } = useQuery(GET_TABLET_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("tablet"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });

  const { loading: loadingBackups, error: errorBackups, data: dataBackups } = useQuery(GET_BACKUP_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("backup"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });

  const { loading: loadingUSBS, error: errorUSB, data: dataUSB } = useQuery(GET_USB_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("usb"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });

  const { loading: loadingDockingStations, error: erroDockingStations, data: dataDockingStations } = useQuery(GET_DOCKINGSTATION_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("docking"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });

  const { loading: loadingSpeakers, error: errorSpeaker, data: dataSpeaker } = useQuery(GET_SPEAKERS_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("speaker"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });

  const { loading: loadingHeadsets, error: errorHeadset, data: dataHeadset } = useQuery(GET_HEADSET_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("headset"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });
  const { loading: loadingProjectors, error: errorProjectors, data: dataProjectors } = useQuery(GET_PROJECTOR_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("projector"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });
  const { loading: loadingExternalDisk, error: errorExternalDisk, data: dataExternalDisk } = useQuery(GET_EXTERNALDISK_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("externalDisk"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });
  const { loading: loadingBag, error: errorBag, data: dataBag } = useQuery(GET_BAG_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("bag"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });

  const { loading: loadingClickCounter, error: errorClickCounter, data: dataClickCounter } = useQuery(GET_CLICKCOUNTER_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("clickCounter"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });

  const { loading: loadingSupplies, error: errorSupplies, data: dataSupplies } = useQuery(GET_SUPPLIES_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("supplies"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });


  const { loading: loadingPowerBanks, error: errorPowerBanks, data: dataPowerBank } = useQuery(GET_POWERBANK_BY_DATE_RANGE, {
    variables: {
      filter: getFilter("powerBank"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });

  const { loading: loadinggeneralInventory, error: errorgeneralInventory, data: datageneralInventory } = useQuery(GET_GeneralInventory__BY_DATE_RANGE, {
    variables: {
      filter: getFilter("generalInventory"),
      nextToken: null,
    },
    skip: !startDate || !endDate,
  });
  // Function to handle the "To" date selection
  const handleEndDateChange = (date) => {
    if (startDate && date < startDate) {
      setDateError('"To" date must be after the "From" date.');
      setEndDate(startDate); // Reset "To" date to "From" date
    } else {
      setDateError('');
      setEndDate(date);
    }
  };

  // Función para contar items
  const countItems = (data) => {
    return data ? data.length : 0; // Retorna la cantidad de ítems o 0 si no hay ítems
  };

  // Verificar si alguna consulta está cargando
  const isLoading = loadingMice || loadingKeyboards || loadingMonitors || loadingLaptops || loadingPhones || loadingPrinters || loadingScanners || loadingExtentions || loadingTablets || loadingBackups || loadingUSBS || loadingDockingStations || loadingSpeakers || loadingHeadsets || loadingProjectors || loadingExternalDisk || loadingBag || loadingClickCounter || loadingSupplies || loadingPowerBanks || loadinggeneralInventory;

  // Verificar si alguna consulta tiene error
  const hasError = errorMice || errorKeyboards || errorMonitors || errorLaptops || errorPhones || errorPrinters || errorScanners || errorExtentions || errorTablets || errorBackups || errorUSB || erroDockingStations || errorSpeaker || errorHeadset || errorProjectors || errorExternalDisk || errorBag || errorClickCounter || errorSupplies || errorPowerBanks || errorgeneralInventory;

  const [searchTerm, setSearchTerm] = useState('');

  // Función para manejar el cambio en el campo de búsqueda
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filter to Search
  const filterItems = (items, searchTerm) => {
    return items.filter((item) =>
      Object.values(item).some((val) =>
        String(val).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  };

  // + all Total
  const totalMicePurchase = dataMice?.listMice?.items?.reduce((total, mouse) => total + (mouse.price || 0), 0);
  const totalKeyboardsPurchase = dataKeyboards?.listKeyboards?.items?.reduce((total, keyboard) => total + (keyboard.price || 0), 0);
  const totalMonitorPurchase = dataMonitors?.listMonitors?.items?.reduce((total, monitor) => total + (monitor.price || 0), 0);
  const totalLaptopsPurchase = dataLaptops?.listLaptops?.items?.reduce((total, laptop) => total + (laptop.price || 0), 0);
  const totalPhonesPurchase = dataPhones?.listPhones?.items?.reduce((total, phone) => total + (phone.price || 0), 0);
  const totalScannersPurchase = dataScanners?.listScanners?.items?.reduce((total, scanner) => total + (scanner.price || 0), 0);
  const totalPrintersPurchase = dataPrinters?.listPrinters?.items?.reduce((total, printer) => total + (printer.price || 0), 0);
  const totalExtensionCordPurchase = dataExtentions?.listExtensionCords?.items?.reduce((total, extension) => total + (extension.price || 0), 0);
  const totalTabletsPurchase = dataTablets?.listTabletRemarkables?.items?.reduce((total, tablet) => total + (tablet.price || 0), 0);
  const totalBackupBaterriesPurchase = dataBackups?.listBackupBatteries?.items?.reduce((total, backup) => total + (backup.price || 0), 0);
  const totalUSBPurchase = dataUSB?.listUSBS?.items?.reduce((total, usb) => total + (usb.price || 0), 0);
  const totalDockingStationsPurchase = dataDockingStations?.listDockingStations?.items?.reduce((total, dockingStation) => total + (dockingStation.price || 0), 0);
  const totalSpeakersPurchase = dataSpeaker?.listSpeakers?.items?.reduce((total, speaker) => total + (speaker.price || 0), 0);
  const totalHeadsetsPurchase = dataHeadset?.listHeadsets?.items?.reduce((total, headset) => total + (headset.price || 0), 0);
  const totalProjectorPurchase = dataProjectors?.listProjectors?.items?.reduce((total, projector) => total + (projector.price || 0), 0);
  const totalExternalDiskPurchase = dataExternalDisk?.listExternalDisks?.items?.reduce((total, externalDisk) => total + (externalDisk.price || 0), 0);
  const totalBagPurchase = dataBag?.listBags?.items?.reduce((total, bag) => total + (bag.price || 0), 0);
  const totalClickCounterPurchase = dataClickCounter?.listClickCounters?.items?.reduce((total, clickCounter) => total + (clickCounter.price || 0), 0);
  const totalSuppliesPurchase = dataSupplies?.listSupplies?.items?.reduce((total, supplies) => total + (supplies.price || 0), 0);
  const totalPowerBankPurchase = dataPowerBank?.listPowerBanks?.items?.reduce((total, powerBank) => total + (powerBank.price || 0), 0);
  const totalGeneralInventoryPurchase = datageneralInventory?.listgeneralInventories?.items?.reduce((total, generalInventory) => total + (generalInventory.price || 0), 0);

  const totalPurchase = totalMicePurchase + totalKeyboardsPurchase + totalMonitorPurchase + totalLaptopsPurchase + totalPhonesPurchase + totalScannersPurchase + totalPrintersPurchase + totalExtensionCordPurchase + totalTabletsPurchase + totalBackupBaterriesPurchase + totalUSBPurchase + totalDockingStationsPurchase + totalSpeakersPurchase + totalHeadsetsPurchase + totalProjectorPurchase + totalExternalDiskPurchase + totalPowerBankPurchase + totalGeneralInventoryPurchase; // Suma de los totales

  return (
    <div>


      <div className="datePicker">
        <label className='rangeDate'>Select Date Range: </label>
        <div className="dp">
          <label>From: </label>
          <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} isClearable dateFormat="yyyy/MM/dd" />
        </div>
        <div className="dp">
          <label>To: </label>
          <DatePicker selected={endDate} onChange={handleEndDateChange} isClearable dateFormat="yyyy/MM/dd" />
        </div>
        {dateError && <p className="date-error">{dateError}</p>}
      </div>

      {/* Input para el campo de búsqueda */}
      <div>
        <input
          type="text"
          placeholder="Search for an item"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        {/* Renderiza el total de dinero gastado solo si hay un valor positivo */}
        {selectedDateType === "purchase_date" && (
          <>
            {totalPurchase > 0 && (
              <label className='totalMoney'>Total Money Spent: $ {totalPurchase}</label>
            )}
          </>
        )}

      </div>
      {isLoading ? (
        <p>Loading...</p>
      ) : hasError ? (
        <p>Error: {errorMice?.message || errorKeyboards?.message || errorMonitors?.message || errorPowerBanks?.message || errorgeneralInventory?.message}</p>
      ) : (

        <div className="inventory-page">
        <EquipmentTable
          title="Mice"
          data={dataMice?.listMice?.items}
          totalPurchase={totalMicePurchase}
          itemCount={countItems(dataMice?.listMice?.items)}
          selectedDateType={selectedDateType}
          searchTerm={searchTerm}
          filterItems={filterItems}
        />
  
        <EquipmentTable
          title="Keyboards"
          data={dataKeyboards?.listKeyboards?.items}
          totalPurchase={totalKeyboardsPurchase}
          itemCount={countItems(dataKeyboards?.listKeyboards?.items)}
          selectedDateType={selectedDateType}
          searchTerm={searchTerm}
          filterItems={filterItems}
        />
  
        <EquipmentTable
          title="Monitors"
          data={dataMonitors?.listMonitors?.items}
          totalPurchase={totalMonitorPurchase}
          itemCount={countItems(dataMonitors?.listMonitors?.items)}
          selectedDateType={selectedDateType}
          searchTerm={searchTerm}
          filterItems={filterItems}
        />
  
        <EquipmentTable
          title="Laptops"
          data={dataLaptops?.listLaptops?.items}
          totalPurchase={totalLaptopsPurchase}
          itemCount={countItems(dataLaptops?.listLaptops?.items)}
          selectedDateType={selectedDateType}
          searchTerm={searchTerm}
          filterItems={filterItems}
        />
  
        <EquipmentTable
          title="Phones"
          data={dataPhones?.listPhones?.items}
          totalPurchase={totalPhonesPurchase}
          itemCount={countItems(dataPhones?.listPhones?.items)}
          selectedDateType={selectedDateType}
          searchTerm={searchTerm}
          filterItems={filterItems}
        />
        
        <EquipmentTable
          title="Power Banks"
          data={dataPowerBank?.listPowerBanks?.items}
          totalPurchase={totalPowerBankPurchase}
          itemCount={countItems(dataPowerBank?.listPowerBanks?.items)}
          selectedDateType={selectedDateType}
          searchTerm={searchTerm}
          filterItems={filterItems}
        />
         <EquipmentTable
          title="Power Banks"
          data={dataPowerBank?.listPowerBanks?.items}
          totalPurchase={totalPowerBankPurchase}
          itemCount={countItems(dataPowerBank?.listPowerBanks?.items)}
          selectedDateType={selectedDateType}
          searchTerm={searchTerm}
          filterItems={filterItems}
        />
  
      </div>
  


      )}
    </div>
  );
};



export default ReportByDate;
