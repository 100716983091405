// Maintenance.jsx
import React from 'react';

const Maintenance = () => (
  <div>
    <h1>Site Under Maintenance</h1>
    <p>We are currently performing scheduled maintenance. Please check back soon.</p>
  </div>
);

export default Maintenance;
