import React from 'react';

const Section30 = ({ formData, handleInputChange }) => (
  <>
    {/* Section 30 */}
    <div className="form-section flyer-section">
      <div className="flyer-header">
        2. Understanding basic concepts and rights related to health coverage and how to use it:
      </div>
      <label className="required-asterisk">A. Health Insurance literacy</label>
      <input
        type="number"
        name="HealthInsuranceLiteracy"
        value={formData.HealthInsuranceLiteracy}
        onChange={handleInputChange}
        placeholder="Your answer"
        min="0"
        required
      />
    </div>

    {formData.outreachEvent === 'no' && (
      <>
        <div className="form-section">
          <label className="required-asterisk">B. Locating providers</label>
          <input
            type="number"
            name="Locating_Providers"
            value={formData.Locating_Providers}
            onChange={handleInputChange}
            placeholder="Your answer"
            min="0"
            required
          />
        </div>
        <div className="form-section">
          <label className="required-asterisk">C. Billing and payment questions</label>
          <input
            type="number"
            name="Billing_Payment_Questions"
            value={formData.Billing_Payment_Questions}
            onChange={handleInputChange}
            placeholder="Your answer"
            min="0"
            required
          />
        </div>
        <div className="form-section">
          <label className="required-asterisk">
            D. Evaluating health care options using tools and information available through a consumer's health plan
          </label>
          <input
            type="number"
            name="EvaluateHealtcareOptionsUsingTools"
            value={formData.EvaluateHealtcareOptionsUsingTools}
            onChange={handleInputChange}
            placeholder="Your answer"
            min="0"
            required
          />
        </div>
        <div className="form-section">
          <label className="required-asterisk">E. Accessing preventative health services</label>
          <input
            type="number"
            name="AccessingPreventativeHealthServices"
            value={formData.AccessingPreventativeHealthServices}
            onChange={handleInputChange}
            placeholder="Your answer"
            min="0"
            required
          />
        </div>
      </>
    )}
  </>
);

export default Section30;
