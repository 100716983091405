import React, { useEffect, useRef, useState } from 'react';

const Section3 = ({
  formData,
  handleInputChange,
  clientSearchTerm,
  handleClientSearchChange,
  matchingClients,
  handleClientSelect,
  isFetchingClients
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const handleOtherLanguageChange = (e) => {
    handleInputChange({
      target: {
        name: 'otherLanguage',
        value: e.target.value.toLowerCase(),
      },
    });
  };

  const handleSearchInputChange = (e) => {
    handleInputChange(e);
    handleClientSearchChange(e);
    setIsDropdownVisible(true); // Show dropdown when typing
  };


  const handleInputChangeLowercase = (e) => {
    const { name, value } = e.target;
    // Set formData locally to be lowercase
    handleInputChange({ target: { name, value: value.toLowerCase() } });
  };

  // Detect clicks outside the dropdown to hide it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      {/* Section 3 */}
      <div className="form-section flyer-section">
        <div className="flyer-header">Client Information</div>
        <label className="required-asterisk">Client Name:</label>
        <input
          type="text"
          name="name"
          value={clientSearchTerm}
          onChange={handleSearchInputChange} // For typing with caps support
          onBlur={handleInputChangeLowercase} // Enforces lowercase only when moving out of the field
          placeholder="Your answer"
          required
          autoComplete="new-client-name"
        />
        {isDropdownVisible && matchingClients.length > 0 && (
          <ul ref={dropdownRef} className="client-suggestions">
            {matchingClients.map((client) => (
              <li key={client.id} onClick={() => {
                handleClientSelect(client);
                setIsDropdownVisible(false); // Hide dropdown on selection
              }}>
                {client.name} {client.last_name}
              </li>
            ))}
          </ul>
        )}
        {isFetchingClients && <p>Loading clients...</p>}
      </div>

      <div className="form-section">
        <label className="required-asterisk">Client Last Name:</label>
        <input
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={handleInputChange}
          placeholder="Your answer"
          required
        />
      </div>

      <div className="form-section">
        <label className="required-asterisk">Phone Number:</label>
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleInputChange}
          placeholder="Your answer"
          pattern="[0-9]*"
          inputMode="numeric"
          required
        />
      </div>
      <div className='form-section'>
        <label className="required-asterisk">Address:</label>
        <input
          className='long-text'
          type='text'
          name='address'
          value={formData.address}
          onChange={handleInputChange}
          placeholder="Your answer"
          required
        />
      </div>
      <div className='form-section'>
        <label className="required-asterisk">City</label>
        <input
          type='text'
          name='city'
          value={formData.city}
          onChange={handleInputChange}
          placeholder="Your answer"
          required
        />
      </div>
      {/* <div className='form-section'>
        <label className="required-asterisk">County</label>
        <input
          type='text'
          name='county'
          value={formData.county}
          onChange={handleInputChange}
          placeholder="Your answer"
          required
        />
      </div> */}
      <div className='form-section'>
        <label className="required-asterisk">County</label>
        <select
          name="county"
          value={formData.county}
          onChange={handleInputChange}
          required
        >
          <option value="" disabled>County</option>
          <option value="Aransas">Aransas</option>
          <option value="Atascosa">Atascosa</option>
          <option value="Bandera">Bandera</option>
          <option value="Bee">Bee</option>
          <option value="Bexar">Bexar</option>
          <option value="Brooks">Brooks</option>
          <option value="Caldwell">Caldwell</option>
          <option value="Cameron">Cameron</option>
          <option value="Comal">Comal</option>
          <option value="DeWitt">DeWitt</option>
          <option value="Duval">Duval</option>
          <option value="Frio">Frio</option>
          <option value="Goliad">Goliad</option>
          <option value="Gonzalez">Gonzalez</option>
          <option value="Guadalupe">Guadalupe</option>
          <option value="Hays">Hays</option>
          <option value="Hidalgo">Hidalgo</option>
          <option value="Jim Hogg">Jim Hogg</option>
          <option value="Jim Wells">Jim Wells</option>
          <option value="Karnes">Karnes</option>
          <option value="Kenedy">Kenedy</option>
          <option value="Kleberg">Kleberg</option>
          <option value="La Salle">La Salle</option>
          <option value="Live Oak">Live Oak</option>
          <option value="Maverick">Maverick</option>
          <option value="McMullen">McMullen</option>
          <option value="Medina">Medina</option>
          <option value="Nueces">Nueces</option>
          <option value="Refugio">Refugio</option>
          <option value="San Patricio">San Patricio</option>
          <option value="Uvalde">Uvalde</option>
          <option value="Willacy">Willacy</option>
          <option value="Wilson">Wilson</option>
          <option value="Zavala">Zavala</option>
          <option value="Other:">Other:</option>
        </select>
        {formData.county === 'Other:' && (
          <div>
            <label>Please specify:</label>
            <input
              type="text"
              name="other_county"
              value={formData.other_county}
              onChange={handleInputChange}
              required
            />
          </div>
        )}
      </div>
      <div className='form-section'>
      <label className="required-asterisk">Zip Code</label>
      <input
        type='number'
        name='zip_code'
        value={formData.zip_code}
        onChange={handleInputChange}
        placeholder="Your answer"
        min="0"
        required
      />
    </div>
      <div className='form-section'>
        <label className="required-asterisk">Email</label>
        <input
          type='email'
          name='email'
          value={formData.email}
          onChange={handleInputChange}
          placeholder="Your answer"
          required
        />
      </div>
      <div className="form-section">
        <label className="required-asterisk">Preferred Language</label>
        <div className="check-group">
          <label>
            <input
              type="checkbox"
              name="prefered_lang"
              value="english"
              checked={formData.prefered_lang.includes('english')}
              onChange={handleInputChange}
              required={formData.prefered_lang.length === 0} // Ensure one is selected
            />
            English
          </label>
          <label>
            <input
              type="checkbox"
              name="prefered_lang"
              value="spanish"
              checked={formData.prefered_lang.includes('spanish')}
              onChange={handleInputChange}
              required={formData.prefered_lang.length === 0} // Ensure one is selected
            />
            Spanish
          </label>
          <label>
            <input
              type="checkbox"
              name="prefered_lang"
              value="other"
              checked={formData.prefered_lang.includes('other')}
              onChange={handleInputChange}
              required={formData.prefered_lang.length === 0} // Ensure one is selected
            />
            Other
          </label>
          {formData.prefered_lang.includes('other') && (
            <input
              type="text"
              name="otherLanguage"
              value={formData.otherLanguage}
              onChange={handleOtherLanguageChange}
              placeholder="Your answer"
              required
            />
          )}
        </div>
      </div>
      <div className='form-section'>
        <label className="required-asterisk">Is this a Commit Card?</label>
        <div className="radio-group">
          <label>
            <input
              type="radio"
              name="commitCard"
              value="yes"
              checked={formData.commitCard === 'yes'}
              onChange={handleInputChange}
              required
            />
            Yes
          </label>
          <label>
            <input
              type="radio"
              name="commitCard"
              value="no"
              checked={formData.commitCard === 'no'}
              onChange={handleInputChange}
              required
            />
            No
          </label>
        </div>
      </div>
    </>
  );
};

export default Section3;
